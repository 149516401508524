import PropTypes from 'prop-types'
import { Component } from 'react'

import template from './template.rt'
import './styles.css'

class BannerSlideShow extends Component {
  constructor() {
    super()

    this.state = {
      videoShow: false,
      video: ''
    }
  }

  toggleVideo(video = '') {
    this.setState(prevState => ({ videoShow: !prevState.videoShow, video }))
  }

  render() {
    return template.call(this)
  }
}
export const bannerPropType = PropTypes.shape({
  asset: PropTypes.shape({
    original: PropTypes.string,
    default: PropTypes.string,
    mobile: PropTypes.string,
    mobilexs: PropTypes.string,
    normal: PropTypes.string
  }),
  countdownDate: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string,
  orderDate: PropTypes.string,
  title: PropTypes.string,
  hasGradient: PropTypes.bool
})

BannerSlideShow.propTypes = {
  banners: PropTypes.arrayOf(bannerPropType)
}
BannerSlideShow.defaultProps = {
  banners: []
}

export default BannerSlideShow
