import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import addFraudProtection from 'components/utils/addFraudProtection'
import { paymentMethodsDispatchers as mergeProps } from 'reduxModules/users/dispatchers'
import { paymentMethodSelector as stateSelector } from 'reduxModules/forms/paymentMethod/selectors'

import template from './template.rt'
import './styles.css'

class PaymentMethods extends Component {
  constructor(props) {
    super(props)
    this.saveCreditCard.bind(this)
  }

  componentDidMount() {
    this.props.getBraintreeClientToken()
    this.props.loadPaymentMethods()
  }

  saveCreditCard() {
    const {
      clientToken,
      savePaymentMethod,
      showFraudProtectionError,
      tokenizer,
      recaptcha
    } = this.props

    addFraudProtection(
      savePaymentMethod,
      [tokenizer, recaptcha],
      clientToken,
      showFraudProtectionError
    )
  }

  render() {
    return template.call(this)
  }
}

PaymentMethods.propTypes = {
  getBraintreeClientToken: PropTypes.func.isRequired,
  showNewPaymentMethodForm: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
  clientToken: PropTypes.string.isRequired
}

export default connect(stateSelector, null, mergeProps)(PaymentMethods)
