/* eslint-disable import/prefer-default-export */
import { isMobileSelector } from 'reduxModules/app/selectors'

// selectors
const landingPageSelector = state => state.landingPages.detail
const statusSelector = state => state.landingPages.status

// states (mapStateToProps)
export const landingPageStateSelector = state => ({
  ...landingPageSelector(state),
  status: statusSelector(state),
  isMobile: isMobileSelector(state)
})
