import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    return React.createElement('div', {
        'className': _.transform({
            Tag: true,
            active: this.active
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement('span', {}, this.text));
}