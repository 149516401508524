/* global API_ROOT */
/* eslint-disable import/prefer-default-export */
import { artistsFetch } from '../artists'
import { testimonialRandomFetch } from '../testimonials'

export const schoolDispatchers = dispatch => ({
  loadOscarArtists() {
    // 14: Shane Mahan
    // 15: Lance Anderson
    // 18: Howard Berger
    const oscarWinners = [14, 15, 18]
    dispatch(artistsFetch({ id: oscarWinners.join() })())
  },
  loadRandomTestimonial() {
    dispatch(testimonialRandomFetch())
  }
})
