import * as React from 'react';
import * as _ from 'lodash';
import CollapsibleOrder from '../CollapsibleOrder';
export default function () {
    function repeatTransaction1(transaction, transactionIndex) {
        return React.createElement(CollapsibleOrder, _.assign({}, {
            'key': transactionIndex,
            'isMobile': this.props.isMobile,
            'subscription': this.props.subscription,
            'isTransaction': true
        }, transaction));
    }
    return React.createElement('div', { 'id': 'subscriptionTransactions' }, _.size(this.props.subscription.transactions) > 0 ? [
        React.createElement('div', {
            'className': 'table-header',
            '"': true,
            'key': '1181'
        }, React.createElement('div', { 'className': 'date' }, 'order date'), React.createElement('div', { 'className': 'order-id' }, 'order id'), React.createElement('div', { 'className': 'status' }, 'status'), React.createElement('div', { 'className': 'total' }, 'total')),
        _.map(this.props.subscription.transactions, repeatTransaction1.bind(this))
    ] : null, _.size(this.props.subscription.transactions) === 0 ? [React.createElement('span', { 'key': '7521' }, 'There is no more information available.')] : null);
}