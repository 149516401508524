import { push } from 'react-router-redux'
import {
  setVideoPreview,
  unsetVideoPreview,
  learningPathFetch,
  learningPathsFetch,
  learningPathCoursesFetch,
  yearlyPathEnroll,
  learningPathCoursesFetchMore,
  clearLearningPathList,
  clearLearningPath
} from 'reduxModules/learningPaths/'
import { bannersFetch } from 'reduxModules/banners'
import { addObjectToCart } from 'reduxModules/cart/dispatchers'
import _ from 'lodash'

export default (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    setVideoPreview(tutorial) {
      dispatch(setVideoPreview(tutorial))
    },
    closeVideoPreview: () => dispatch(unsetVideoPreview()),
    resumeWatching: (
      latestBookmark,
      slug,
      learningPath,
      id,
      routePath,
      subscription
    ) => {
      const isBasicSubscriber = subscription?.plan === 'Basic'
      if (latestBookmark) {
        const { chapterOrder, time, tutorialSlug } = latestBookmark
        let urlTutorial = `/tutorials/${tutorialSlug}?watch=${
          isBasicSubscriber ? 0 : 1
        }`
        if (learningPath && id)
          urlTutorial = `${urlTutorial}&path=${learningPath}&path_id=${id}&referer=${routePath}&chapter=${chapterOrder}&time=${time}`
        dispatch(push(urlTutorial))
      } else {
        // If is basic subscriber don't auto-play to display credits consumption warning
        let url = `/tutorials/${slug}?watch=${isBasicSubscriber ? 0 : 1}`
        if (learningPath)
          url = `${url}&path=${learningPath}&path_id=${id}&referer=${routePath}`
        dispatch(push(url))
      }
    },
    loadLearningPathwayCourse(bookmark, courseSlug, routePath) {
      const { subscription, courses, id, slug } = stateProps
      const course = _.find(courses, { slug: courseSlug })
      const { owned } = course
      const isBasicSubscriber = subscription?.plan === 'Basic'

      // Base path
      let watchPath = `/tutorials/${courseSlug}?watch=${
        isBasicSubscriber ? 0 : 1
      }`
      // Check if referrer is pathway to change the UI Player
      if (this.location?.pathname.startsWith('/pathways'))
        watchPath = routePath
          ? `${watchPath}&path=${slug}&path_id=${id}&referer=${routePath}`
          : `${watchPath}&path=${slug}&path_id=${id}`
      // Inject bookmark into URL if available
      if (bookmark)
        watchPath = `${watchPath}&chapter=${bookmark?.chapterOrder}&time=${bookmark?.time}`
      // Check if user has valid subscrpition to watch the course
      const hasSubscription = !_.isNull(subscription)
      const monthlyVideosSeen = _.has(subscription, 'monthlyVideosSeen')
        ? subscription.monthlyVideosSeen
        : []
      if (
        owned ||
        (hasSubscription && subscription.rank > 1) ||
        (hasSubscription && subscription.free) ||
        (hasSubscription && !_.isEmpty(monthlyVideosSeen)) ||
        stateProps.hijacking
      )
        dispatch(push(watchPath))
    },
    getLearningPath() {
      dispatch(learningPathFetch(ownProps.params.learningPathSlug)())
    },
    getAllLearningPathCourses() {
      dispatch(
        learningPathCoursesFetch(
          ownProps.params.learningPathSlug,
          ownProps.params.learningPathSlug,
          true
        )()
      )
    },
    getLearningPathCourses() {
      dispatch(
        learningPathCoursesFetch(
          ownProps.params.learningPathSlug,
          ownProps.params.learningPathSlug
        )()
      )
    },
    getMoreLearningPathCourses(next, courses) {
      dispatch(learningPathCoursesFetchMore(next, courses)())
    },
    enrollUser(learningPathUrl, learningPathSlug, isSubscriber) {
      if (isSubscriber) dispatch(yearlyPathEnroll(learningPathSlug)())
      else addObjectToCart(dispatch, false, learningPathUrl)
    },
    clearLearningPath() {
      dispatch(clearLearningPath())
    }
  }
}

export const learningPathsDispatchers = dispatch => ({
  getLearningPaths() {
    dispatch(learningPathsFetch()())
  },
  loadBanners() {
    dispatch(bannersFetch({ learning_path_banner: true })())
  },
  clearLearningPathList() {
    dispatch(clearLearningPathList())
  }
})
