import React from 'react'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css'
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin'

import { Gallery } from 'react-photoswipe-gallery'

import GalleryItem from './GalleryItem'

import './styles.css'

const options = {
  initialZoomLevel: 'fit',
  secondaryZoomLevel: 1.5,
  maxZoomLevel: 1
}

export function PhotoSwipe(props) {
  return (
    <Gallery
      options={options}
      plugins={pswpLightbox => {
        // eslint-disable-next-line no-unused-vars
        const captionPlugin = new PhotoSwipeDynamicCaption(pswpLightbox, {
          captionContent: slide => slide.data.alt,
          type: 'below',
          mobileLayoutBreakpoint: false
        })
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
        className="images"
      >
        {props.assets.map(photo => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <GalleryItem {...photo} key={photo.src} />
        ))}
      </div>
    </Gallery>
  )
}

export default PhotoSwipe
