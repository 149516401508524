import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import getRoutePath from '../../../utils/getRoutePath';
import keyUpHandler from '../../../utils/keyUpHandler';
import moment from 'moment';
export default function () {
    function onKeyUp1(props, userBookmarks, event) {
        keyUpHandler(event, this.toggle, null, this);
    }
    function onKeyUp2(props, userBookmarks, bookmark, bookmarkIndex, event) {
        keyUpHandler(event, this.props.loadBookmark, null, null, [
            props.slug,
            bookmark,
            getRoutePath(window.location.href)
        ]);
    }
    function onKeyUp3(props, userBookmarks, bookmark, bookmarkIndex, event) {
        keyUpHandler(event, this.props.removeBookmark, null, null, [
            event,
            bookmark.id
        ]);
    }
    function repeatBookmark4(props, userBookmarks, bookmark, bookmarkIndex) {
        return React.createElement('div', {
            'aria-label': 'Load bookmark',
            'className': 'bookmark',
            'key': bookmark.id,
            'onClick': () => this.props.loadBookmark(props.slug, bookmark, getRoutePath(window.location.href)),
            'onKeyUp': onKeyUp2.bind(this, props, userBookmarks, bookmark, bookmarkIndex),
            'tabIndex': '0'
        }, React.createElement('div', { 'className': 'bookmark-body' }, React.createElement('div', { 'className': 'filler' }), React.createElement('i', { 'className': 'icon icon-bookmark-filled blue' }), React.createElement('div', { 'className': 'name' }, _.isEmpty(bookmark.name) ? 'Unnamed bookmark' : bookmark.name), React.createElement('div', { 'className': 'time' }, `Chapter ${ bookmark.chapterOrder } - ${ moment().startOf('day').seconds(bookmark.time).format('H:mm:ss') }`), React.createElement('div', {
            'aria-label': 'Remove bookmark',
            'className': 'remove',
            'onClick': e => this.props.removeBookmark(e, bookmark.id),
            'onKeyUp': onKeyUp3.bind(this, props, userBookmarks, bookmark, bookmarkIndex),
            'tabIndex': '0'
        }, React.createElement('span', {}, 'x'))));
    }
    function scopePropsUserBookmarks5() {
        var props = this.props;
        var userBookmarks = _.filter(props.bookmarks, { autoGenerated: false });
        return React.createElement('div', { 'className': 'bookmarked-tutorial' }, React.createElement('div', {
            'aria-label': 'Toggle bookmarks list',
            'className': 'bookmark-summary',
            'onClick': () => this.toggle(),
            'onKeyUp': onKeyUp1.bind(this, props, userBookmarks),
            'tabIndex': '0'
        }, _.has(props, 'photo.bookmarkThumb') ? React.createElement('div', {
            'className': 'thumbnail',
            'key': '603'
        }, React.createElement('img', {
            'src': props.photo.bookmarkThumb,
            'alt': props.title
        })) : null, React.createElement('div', { 'className': 'tutorial' }, React.createElement('div', { 'className': 'tutorial-description' }, React.createElement('div', {
            'className': 'tutorial-title',
            'onClick': e => e.stopPropagation()
        }, React.createElement(Link, { 'to': '/tutorials/' + props.slug }, props.title)), React.createElement('div', { 'className': 'tutorial-author' }, '\n          By ', React.createElement(Link, { 'to': '/artists/' + props.artist.slug }, props.artist.title)))), React.createElement('div', { 'className': 'amount' }, React.createElement('span', {}, _.size(userBookmarks))), React.createElement('button', {
            'aria-label': this.state.visible ? 'Collapse' : 'Expand',
            'className': _.transform({ animate: this.state.visible }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ') + ' ' + 'button'
        }, React.createElement('i', { 'className': 'icon-right-2 button__icon' }))), this.state.visible ? React.createElement.apply(this, [
            'div',
            {
                'className': 'bookmarks',
                'key': '1433'
            },
            _.map(userBookmarks, repeatBookmark4.bind(this, props, userBookmarks))
        ]) : null);
    }
    return scopePropsUserBookmarks5.apply(this, []);
}