import PropTypes from 'prop-types'

import template from './template.rt'
import './styles.css'

function SearchCategories(props) {
  return template.call({ props })
}

SearchCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired
}

SearchCategories.defaultProps = {
  categories: []
}

export default SearchCategories
