/* global isBrowser, API_ROOT */
import { bind } from 'redux-effects'
import { fetch } from 'redux-effects-fetch'
import { camelizeKeys, decamelizeKeys } from 'humps'

export const deleteCookie = name => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const getUrl = (path, filters) => {
  let query = ''
  if (filters) {
    query = Object.entries(filters)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    query = query ? `?${query}` : ''
  }
  return `${path}${query}`
}

export const fetchHelper = (url, options, success, failure, config = {}) => (
  body = {}
) => {
  const extraOptions = { credentials: 'include' }

  if (Object.keys(body).length) {
    const split = config.decamelizeNumbers ? /(?=[A-Z0-9])/ : /(?=[A-Z])/
    extraOptions.body = JSON.stringify(decamelizeKeys(body, { split }))
  }

  if (!Object.prototype.hasOwnProperty.call(options, 'headers'))
    options.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  return [
    { type: options?.hidden ? 'HIDDEN_FETCHING' : 'FETCHING' },
    bind(
      fetch(url, {
        ...options,
        ...extraOptions
      }),
      ({ value }) =>
        success({
          ...camelizeKeys(value),
          ...(config.requestBodyOnSuccess && { requestBody: body })
        }),
      ({ value, status }) =>
        failure({
          value,
          status,
          ...(config.requestBodyOnFailure && { requestBody: body })
        })
    )
  ]
}

export default (endpoint, options, success, failure, config) =>
  fetchHelper(`${API_ROOT}${endpoint}`, options, success, failure, config)
