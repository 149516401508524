/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'
import './styles.css'

class CourseSummary extends Component {
  getCSSClassesFor(baseClass) {
    switch (baseClass) {
      case 'content':
        return this.props.price ? 'content content--with-buy-info' : 'content'
      case 'content__teacher':
        return this.props.variant === 'darkContentText'
          ? 'content__teacher content__teacher--dark'
          : 'content__teacher content__teacher--clear'
      case 'content__title':
        return this.props.variant === 'darkContentText'
          ? 'content__title content__title--dark'
          : 'content__title content__title--clear'
      default:
        return ''
    }
  }

  render() {
    return template.call(this)
  }
}

const purchaseProps = {
  price: PropTypes.number,
  purchaseUrl: PropTypes.string
}

CourseSummary.propTypes = {
  ...purchaseProps,
  className: PropTypes.string,
  isSpecial: PropTypes.bool,
  isWebinar: PropTypes.bool,
  isCurrentlyWatching: PropTypes.bool,
  elementName: PropTypes.string,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string,
  teacher: PropTypes.string.isRequired,
  teacherSlug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['clearContentText', 'darkContentText'])
}

export default CourseSummary
