import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'
import './styles.css'

const OTHER_OPTION = 'Other'

export default class CancelSubscription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      feedbackMessage: '',
      feedbackOption: ''
    }

    this.handleFeedback = this.handleFeedback.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleTextarea = this.handleTextarea.bind(this)
    this.getResponse = this.getResponse.bind(this)
    this.shouldBeDisabled = this.shouldBeDisabled.bind(this)
  }

  handleFeedback(key, event) {
    this.setState({ [key]: event.target.value }, () => {
      this.props.changeSubmitButtonStatus(this.shouldBeDisabled())
    })
  }

  handleSelect(event) {
    this.handleFeedback('feedbackOption', event)
  }

  handleTextarea(event) {
    this.handleFeedback('feedbackMessage', event)
  }

  getResponse() {
    const { feedbackMessage, feedbackOption } = this.state
    return feedbackOption === OTHER_OPTION ? feedbackMessage : feedbackOption
  }

  shouldBeDisabled() {
    const { feedbackMessage, feedbackOption } = this.state
    return !!this.props.feedback && feedbackOption === OTHER_OPTION && feedbackMessage === ''
  }

  render() {
    return template.call(this)
  }
}

CancelSubscription.propTypes = {
  text: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      resolveValue: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      class: PropTypes.string
    })
  ).isRequired,
  changeSubmitButtonStatus: PropTypes.func.isRequired
}

CancelSubscription.defaultProps = {
  text: ''
}
