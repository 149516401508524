import PropTypes from 'prop-types'
import { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { artistsStateSelector as stateSelector } from 'reduxModules/artists/selectors'
import { artistsMergeProps as mergeProps } from 'reduxModules/artists/dispatchers'
import getLoadLimitByIndex from 'components/utils/getLoadLimitByIndex'
import template from './template.rt'
import './styles.css'

export class ArtistList extends Component {
  constructor(props, context) {
    super(props, context)
    this.routerWillLeave = this.routerWillLeave.bind(this)
  }

  componentDidMount() {
    const {
      loadArtists,
      pendingBackScroll
    } = this.props
    const limit = getLoadLimitByIndex(17, pendingBackScroll.artists)

    loadArtists({ limit })
    const { router, route } = this.props
    if (_.has(router, 'setRouteLeaveHook'))
      router.setRouteLeaveHook(route, this.routerWillLeave)
  }

  routerWillLeave(nextLocation) {
    if (_.startsWith(nextLocation.pathname, '/artists/')) {
      const slug = nextLocation.pathname.split('/')[2]
      const { setScrollingBackElement, artists } = this.props
      const index = _.findIndex(artists, { slug })
      setScrollingBackElement({
        index,
        'name': `artists-${index}`,
        'group': 'artists'
      })
    }
    return true
  }

  render() {
    return template.call(this)
  }
}

ArtistList.propTypes = {
  loadArtists: PropTypes.func.isRequired,
  artists: PropTypes.arrayOf(PropTypes.object),
  pendingBackScroll: PropTypes.shape({
    artists: PropTypes.shape({
      isPending: PropTypes.bool,
      element: PropTypes.shape({
        index: PropTypes.number,
        name: PropTypes.string
      })
    })
  }),
  setScrollingBackElement: PropTypes.func.isRequired
}

ArtistList.defaultProps = {
  artists: [],
  pendingBackScroll: {
    artists: {
      isPending: false,
      element: {
        'index': 0,
        'name': 'artists-0'
      }
    }
  },
  setScrollingBackElement: () => {}
}

export default connect(
  stateSelector,
  null,
  mergeProps
)(withRouter(ArtistList))
