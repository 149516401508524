import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import dispatchers from 'reduxModules/generic/favoriteButton/dispatchers'
import selectors from 'reduxModules/generic/favoriteButton/selectors'
import './styles.css'

export function FavoriteButton(props) {
  const { favorited, isAuth, setFavorite, unsetFavorite, url } = props

  if (!isAuth || !url) return null

  const isFavorited = _.isInteger(favorited)

  return (
    <div className="FavoriteButton">
      <span className={`${isFavorited ? 'favorited' : ''}`}>
        <button
          onClick={() =>
            isFavorited ? unsetFavorite(favorited) : setFavorite(url)
          }
          tabIndex="0"
          type="button"
        >
          <i>☆</i> {isFavorited ? 'Favorite' : 'Make Favorite'}
        </button>
      </span>
    </div>
  )
}

FavoriteButton.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  setFavorite: PropTypes.func.isRequired,
  unsetFavorite: PropTypes.func.isRequired,
  url: PropTypes.string,
  favorited: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  loading: PropTypes.number.isRequired
}

FavoriteButton.defaultProps = {
  favorited: null,
  url: null
}

export default connect(selectors, dispatchers)(FavoriteButton)
