import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function PaymentMethod(props) {
  return template.call({ props })
}

export const PaymentMethodStructure = {
  cardType: PropTypes.string,
  expirationMonth: PropTypes.string,
  expirationYear: PropTypes.string,
  default: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  last4: PropTypes.string,
  token: PropTypes.string.isRequired,
  isMobile: PropTypes.bool
}

PaymentMethod.defaultProps = {
  isMobile: false,
  expirationMonth: '',
  expirationYear: '',
  default: false,
  last4: '',
  cardType: ''
}

PaymentMethod.propTypes = PaymentMethodStructure

export default PaymentMethod
