import { push } from 'react-router-redux'

import {
  resendEmail,
  verifyRequest,
  types,
  enableResendEmailCTA,
  disableResendEmailCTA
} from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

export const dispatchVerifyRequest = (dispatch, data) =>
  dispatch(verifyRequest(data))

export const dispatchResendEmail = dispatch => dispatch(resendEmail())

export default dispatch => ({
  verifyEmail: data =>
    dispatchVerifyRequest(dispatch, data).then(([_, res]) => {
      if (res.type === types.VERIFY_REQUEST_SUCCESS) {
        dispatch(push('/'))
        toastSuccess(notifications.emailVerified)
      } else toastError(notifications.emailVerified)
    }),
  resendEmail: () => {
    dispatch(disableResendEmailCTA())
    return dispatchResendEmail(dispatch).then(([_, res]) => {
      dispatch(enableResendEmailCTA())
      if (res.type === types.RESEND_EMAIL_SUCCESS)
        toastSuccess(notifications.resendVerifyEmail)
      else toastError(notifications.resendVerifyEmail)
      if (res.type === types.RESEND_EMAIL_FAILURE)
        if (
          // eslint-disable-next-line no-undef
          res?.payload?.value?.errors[0]
        )
          toastServerError(res.payload.value.errors[0])
    })
  }
})
