import { CountryRegionData } from 'react-country-region-selector'
import bs from 'binarysearch'
import normalize from 'normalize-strings'
import logger from 'components/utils/logger'

export default countryName => {
  const currentCountryName = normalize(countryName)
  let code = ''
  const comparator = (country, countryName) => {
    const toCompare = normalize(country[0])
    if (toCompare > normalize(countryName)) return 1
    if (toCompare < normalize(countryName)) return -1
    return 0
  }
  const countryIndex = bs(CountryRegionData, currentCountryName, comparator)
  try {
    code = CountryRegionData[countryIndex][1]
  } catch (e) {
    logger.error('Country code not found', {
      country: currentCountryName,
      index: countryIndex
    })
  }
  return code
}
