import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll'

import Chapter from './Chapter'

// If a chapter is at least 97% watched, consider it completed.
export const COMPLETION_THRESHOLD = 97

function ChapterList({
  playlist,
  currentChapter,
  playChapter,
  shouldShowRelevantToggle,
  userProgress,
  isPlaying,
  markChapterAsCompleted,
  tutorial,
  updateUserProgress
}) {
  useEffect(() => {
    // Scroll to playlist item
    const chapter = playlist[currentChapter]

    scroller.scrollTo(`chapter-${chapter?.extra?.id}`, {
      smooth: 'easeInSine',
      delay: 250,
      containerId: 'chapterList'
    })
  }, [currentChapter])
  return (
    <ul
      className={`chapters-list ${
        shouldShowRelevantToggle ? 'with-relevant-toggle' : ''
      }`}
      id="chapterList"
    >
      {playlist.map((chapter, index) => {
        // Contains completed, currentPosition, duration and progress (%)
        const chapterUserProgress = userProgress[chapter?.extra?.id]
        return (
          <Chapter
            chapter={chapter}
            isCurrentChapter={index === currentChapter}
            wasCompleted={
              chapter.completed ||
              chapterUserProgress?.progress >= COMPLETION_THRESHOLD
            }
            index={index}
            playChapter={playChapter}
            percentageProgress={chapterUserProgress?.progress}
            currentPosition={chapterUserProgress?.currentPosition}
            key={chapter?.extra?.id}
            isPlaying={isPlaying}
            markAsCompleted={markChapterAsCompleted}
            tutorial={tutorial}
            updateUserProgress={updateUserProgress}
          />
        )
      })}
    </ul>
  )
}

ChapterList.propTypes = {
  playlist: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      tracks: PropTypes.arrayOf(
        PropTypes.shape({
          // TODO: fill this
        })
      ),
      image: PropTypes.string,
      sources: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  ),
  currentChapter: PropTypes.number.isRequired,
  playChapter: PropTypes.func.isRequired,
  shouldShowRelevantToggle: PropTypes.bool.isRequired
}

export default ChapterList
