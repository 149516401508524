import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { types as checkoutTypes } from 'reduxModules/checkout'
import authTypes from 'reduxModules/auth/types'
import fetchApi, { getUrl } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  VALIDATE_DISCOUNT_CODE_SUCCESS
  VALIDATE_DISCOUNT_CODE_REQUEST_FAILURE
  DISCOUNT_CODE_LOADING
  DISCOUNT_CODE_SUCCESS
  DISCOUNT_CODE_ERROR
  TOGGLE_DISCOUNT_CODE
  CLEAR_DISCOUNT_CODE
  SAVE_VALIDATED_CODE
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  validatedCode: {}
}

export const codeStatus = {
  loading: types.DISCOUNT_CODE_LOADING,
  success: types.DISCOUNT_CODE_SUCCESS,
  error: types.DISCOUNT_CODE_ERROR
}

export const validateCodeSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  status: codeStatus.success,
  validatedCode: action.payload,
  showDiscountCodeForm: false
})

export const setValidatedCode = (state = INITIAL_STATE, action) => ({
  ...state,
  validatedCode: action.payload
})

export const validateCodeFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: codeStatus.error
})

export const clearCode = () => ({ ...INITIAL_STATE })

export const toggleDiscountForm = (state = INITIAL_STATE) => {
  const prevState = state.showDiscountCodeForm ? clearCode() : state
  // Removing all keys when closing in order to avoid issues with different forms
  return {
    ...prevState,
    showDiscountCodeForm: !state.showDiscountCodeForm
  }
}

export const HANDLERS = {
  [types.VALIDATE_DISCOUNT_CODE_SUCCESS]: validateCodeSuccess,
  [types.VALIDATE_DISCOUNT_CODE_REQUEST_FAILURE]: validateCodeFailure,
  [types.TOGGLE_DISCOUNT_CODE]: toggleDiscountForm,
  [types.CLEAR_DISCOUNT_CODE]: clearCode,
  [types.SAVE_VALIDATED_CODE]: setValidatedCode,
  [checkoutTypes.SETTLE_ORDER_SUCCESS]: clearCode,
  [authTypes.AUTH_LOGOUT_SUCCESS]: clearCode
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const validateDiscountCodeSuccess = createAction(
  types.VALIDATE_DISCOUNT_CODE_SUCCESS
)
export const validateDiscountCodeFailure = createAction(
  types.VALIDATE_DISCOUNT_CODE_REQUEST_FAILURE
)
export const validateDiscountCode = (code, plan) => {
  let url = `/coupons/${encodeURI(code)}/`
  if (typeof plan === 'number') url = getUrl(url, { plan })
  return fetchApi(
    url,
    { method: 'GET' },
    validateDiscountCodeSuccess,
    validateDiscountCodeFailure
  )
}

export const toggleDiscountCode = createAction(types.TOGGLE_DISCOUNT_CODE)

export const clearDiscountCode = createAction(types.CLEAR_DISCOUNT_CODE)

export const saveValidatedCode = createAction(types.SAVE_VALIDATED_CODE)
