/* global process, isBrowser, API_ROOT, API_AUTH_ROOT, SENTRY_DSN */
/* eslint-disable no-underscore-dangle */

import { createStore, compose, applyMiddleware } from 'redux'
import {
  browserHistory,
  useRouterHistory,
  createMemoryHistory
} from 'react-router'
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import effects from 'redux-effects'
import fetch, { fetchEncodeJSON } from 'redux-effects-fetch'
import { bearer } from 'redux-effects-credentials'
import persistState from 'redux-localstorage'
import _ from 'lodash'
import cookie from 'redux-effects-cookie'

import rootReducer from './modules/reducer'
import { tokenSelector } from './modules/auth/selectors'
import {
  customRavenMiddleware,
  fetchFailureMiddleware,
  authFailureMiddleware,
  multiEffectsMiddleware,
  confirmationMiddleware
} from './middlewares'

const routingMiddleware = routerMiddleware(browserHistory)
const urlMatcher = url =>
  _.startsWith(url, API_ROOT) || _.startsWith(url, API_AUTH_ROOT)
const credentials = bearer(
  urlMatcher,
  ({ getState }) => tokenSelector(getState()),
  'JWT'
)
const cookieMiddleware = cookie()
const baseMiddlewares = [
  routingMiddleware,
  effects,
  credentials,
  fetch,
  fetchEncodeJSON,
  fetchFailureMiddleware,
  authFailureMiddleware,
  multiEffectsMiddleware,
  confirmationMiddleware,
  cookieMiddleware
]
const extraMiddlewares = []

if (process.env.NODE_ENV !== 'test' && typeof localStorage !== 'undefined')
  extraMiddlewares.push(persistState(['auth', 'checkout', 'hijack']))

function configureStoreProd() {
  const middlewares = [customRavenMiddleware, ...baseMiddlewares]
  return createStore(
    rootReducer,
    compose(
      applyMiddleware(...middlewares),
      ...extraMiddlewares
    )
  )
}

function configureStoreDev() {
  const middlewares = [reduxImmutableStateInvariant(), ...baseMiddlewares]

  // add support for Redux dev tools
  let composeEnhancers
  const extendedCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  if (process.env.NODE_ENV !== 'test' && _.isFunction(extendedCompose))
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  else composeEnhancers = compose

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares), ...extraMiddlewares)
  )

  if (module.hot)
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules/reducer', () => {
      const nextReducer = require('./modules/reducer').default // eslint-disable-line global-require
      store.replaceReducer(nextReducer)
    })

  return store
}

const store =
  process.env.NODE_ENV === 'production' || !isBrowser
    ? configureStoreProd()
    : configureStoreDev()

export default store

let syncHistory
if (isBrowser)
  // Create an enhanced history that syncs navigation events with the store
  syncHistory = syncHistoryWithStore(browserHistory, store)
// only used on NodeJS server-side rendering initialization
else syncHistory = useRouterHistory(createMemoryHistory)

export const history = syncHistory
