import * as React from 'react';
import * as _ from 'lodash';
import { RegionDropdown } from 'react-country-region-selector';
import keyUpHandler from '../../utils/keyUpHandler';
export default function () {
    function onKeyUp1(event) {
        keyUpHandler(event, this.props.iconAction);
    }
    return React.createElement('div', {
        'className': 'field' + ' ' + _.transform({ '-error': this.props.hasError }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, this.props.label && !this.props.ariaLabel ? React.createElement('label', {
        'className': _.transform({ ['field__label--hidden']: this.props.labelHidden }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'htmlFor': this.props.labelFor || '',
        'key': '216'
    }, '\n    ', this.props.label, '\n  ') : null, React.createElement('div', { 'className': 'field-container' }, [
        'text',
        'password',
        'email'
    ].indexOf(this.props.type) > -1 ? React.createElement('input', _.assign({}, {
        'type': this.props.type,
        'placeholder': this.props.placeholder,
        'disabled': this.props.disabled,
        'aria-label': this.props.ariaLabel || '',
        'autoFocus': this.props.autoFocus,
        'id': this.props.id || '',
        'key': '455'
    }, this.props.input)) : null, this.props.type === 'number' ? React.createElement('input', _.assign({}, {
        'aria-label': this.props.ariaLabel || '',
        'type': this.props.type,
        'placeholder': this.props.placeholder,
        'disabled': this.props.disabled,
        'min': this.props.min,
        'max': this.props.max,
        'autoFocus': this.props.autoFocus,
        'id': this.props.id || '',
        'key': '812'
    }, this.props.input)) : null, this.props.type === 'textarea' ? React.createElement('textarea', _.assign({}, {
        'aria-label': this.props.ariaLabel || '',
        'type': this.props.type,
        'placeholder': this.props.placeholder,
        'disabled': this.props.disabled,
        'autoFocus': this.props.autoFocus,
        'id': this.props.id || '',
        'key': '1196'
    }, this.props.input)) : null, this.props.type === 'select' ? React.createElement('select', _.assign({}, {
        'aria-label': this.props.ariaLabel || '',
        'type': this.props.type,
        'placeholder': this.props.placeholder,
        'disabled': this.props.disabled,
        'id': this.props.id || '',
        'key': '1527'
    }, this.props.input), '\n      ', this.props.children, '\n    ') : null, this.props.type === 'region' ? React.createElement(RegionDropdown, _.assign({}, {
        'country': this.props.country,
        'onChange': this.props.input.onChange,
        'valueType': 'short',
        'disabled': this.props.disabled,
        'defaultOptionLabel': 'Select Region/State',
        'id': this.props.id || '',
        'key': '1856'
    }, this.props.input)) : null, this.props.icon ? React.createElement('i', {
        'className': _.transform({ [this.props.icon]: true }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ') + ' ' + 'icon',
        'aria-label': this.props.iconLabel || '',
        'onClick': this.props.iconAction,
        'onKeyUp': onKeyUp1.bind(this),
        'tabIndex': '0',
        'key': '2177'
    }) : null, React.createElement('span', {
        'className': 'field__error' + (!this.props.hasError ? ' field__error--hidden' : ''),
        'role': 'alert'
    }, '\n      ', this.props.meta.error || this.props.meta.warning, '\n    ')));
}