import { Component } from 'react'
import template from './template.rt'
import './styles.css'

class CollapsibleOrder extends Component {
  static getHumanReadableStatus(status = '') {
    switch (status) {
      case 'authorization_expired':
      case 'failed':
      case 'gateway_rejected':
      case 'processor_declined':
      case 'settlement_declined':
      case 'settlement_failed':
      case 'unrecognized':
        return 'Failed'
      case 'authorizing':
      case 'authorized':
      case 'settlement_pending':
      case 'settlement_confirmed':
      case 'settling':
      case 'submitted_for_settlement':
        return 'Processing'
      case 'settled':
        return 'Completed'
      case 'voided':
        return 'Voided'
      default:
        return 'Not available'
    }
  }

  constructor(props) {
    super(props)
    this.state = { visible: false }
  }

  toggle() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    return template.call(this)
  }
}

export default CollapsibleOrder
