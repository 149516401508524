import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import dispatchSelector, {
  onApprovedSubmit
} from '../../redux/modules/contact/dispatchers'
import template from './template.rt'
import './styles.css'
import { recaptchaSelector } from '../../redux/modules/app/selectors'

export function Contact(props) {
  return template.call({ props })
}

Contact.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

const onSubmit = props => data => {
  props.verifyRecaptcha(
    { ...data, recaptcha: props.recaptcha },
    onApprovedSubmit
  )
}

export const ContactWrapper = reduxForm({ form: 'contact-us' })(Contact)
export default connect(
  state => ({
    initialValues: { subject: 'unselected' },
    recaptcha: recaptchaSelector(state)
  }),
  dispatchSelector,
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
    onSubmit: onSubmit({ ...dispatchProps, ...stateProps })
  })
)(ContactWrapper)
