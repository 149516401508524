import { createStructuredSelector, createSelector } from 'reselect'
import { loadingSelector } from 'reduxModules/app/selectors'
import { planSelectedSelector } from 'reduxModules/checkout/selectors'

export const showDiscountCodeForm = state =>
  state.discountCode.showDiscountCodeForm

export const validatedDiscountCode = state => state.discountCode

const confirmationSelector = state => state.app.confirmation

const planIdSelector = createSelector(
  planSelectedSelector,
  confirmationSelector,
  (planSelected, plan) => {
    if (
      planSelected &&
      Object.prototype.hasOwnProperty.call(planSelected, 'id')
    )
      return planSelected.id
    if (plan && Object.prototype.hasOwnProperty.call(plan, 'plan'))
      return plan.plan

    return null
  }
)

// states (mapStateToProps)
export default createStructuredSelector({
  loading: loadingSelector,
  showDiscountCodeForm,
  validatedDiscountCode,
  plan: planIdSelector
})
