import PropTypes from 'prop-types'
import { Component } from 'react'
import { SubscriptionPlanPropTypes } from '../../../SubscriptionPlans/SubscriptionPlanList'

import template from './template.rt'
import './styles.css'


export default class ChangeSubscriptionConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      calculatedPrice: props.newPlan.price,
      isProrated: props.isProrated
    }

    this.getResponse = this.getResponse.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { newPlan, validatedDiscountCode } = newProps
    let { calculatedPrice } = this.state
    if (
      validatedDiscountCode &&
      validatedDiscountCode.status === 'DISCOUNT_CODE_SUCCESS'
    )
      calculatedPrice = parseFloat(
        newPlan.price - validatedDiscountCode.validatedCode.discount
      ).toFixed(2)
    else if (
      this.props.validatedDiscountCode !== validatedDiscountCode.status
    )
      calculatedPrice = newPlan.price

    if (calculatedPrice !== this.state.calculatedPrice)
      this.setState({ calculatedPrice })
  }

  getResponse() {
    const { validatedDiscountCode } = this.props
    return validatedDiscountCode && validatedDiscountCode.validatedCode
  }

  render() {
    return template.call(this)
  }
}

ChangeSubscriptionConfirmation.propTypes = {
  validatedCode: PropTypes.shape({
    codename: PropTypes.string,
    discount: PropTypes.number,
    status: PropTypes.string
  }),
  newPlan: PropTypes.shape(SubscriptionPlanPropTypes).isRequired,
  planInfo: PropTypes.shape(SubscriptionPlanPropTypes).isRequired
}

ChangeSubscriptionConfirmation.defaultProps = {
  validatedCode: {}
}
