/* global FB */
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { contestStateSelector as stateSelector } from 'reduxModules/contests/selectors'
import { contestDispatchers } from 'reduxModules/contests/dispatchers'
import { contestsStatus } from 'reduxModules/contests/'
import template from './template.rt'
import './styles.css'

export class Contest extends Component {
  componentDidMount() {
    this.props.loadContest()
  }

  render() {
    if (this.props.status === contestsStatus.success) return template.call(this)
    return null
  }
}

Contest.propTypes = {
  loadContest: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

export const ContestWrapper = reduxForm({ form: 'contest' })(Contest)
export default connect(
  stateSelector,
  contestDispatchers
)(ContestWrapper)
