import { createTypes } from 'reduxsauce'

export default createTypes(`
  AUTH_INITIAL
  AUTH_LOGIN_SUCCESS
  AUTH_LOGIN_FAILURE
  AUTH_LOGIN_REQUEST_FAILURE
  AUTH_FACEBOOK_LOGIN_SUCCESS
  AUTH_FACEBOOK_LOGIN_FAILURE
  AUTH_FACEBOOK_LOGIN_REQUEST_FAILURE
  AUTH_MICROSOFT_LOGIN_SUCCESS
  AUTH_MICROSOFT_LOGIN_FAILURE
  AUTH_MICROSOFT_LOGIN_REQUEST_FAILURE
  AUTH_SIGNUP_SUCCESS
  AUTH_SIGNUP_FAILURE
  AUTH_SIGNUP_REQUEST_FAILURE
  AUTH_FORGOT_PASSWORD_SUCCESS
  AUTH_FORGOT_PASSWORD_FAILURE
  AUTH_FORGOT_PASSWORD_REQUEST_FAILURE
  AUTH_RESET_PASSWORD_SUCCESS
  AUTH_RESET_PASSWORD_FAILURE
  AUTH_RESET_PASSWORD_REQUEST_FAILURE
  AUTH_CHANGE_PASSWORD_SUCCESS
  AUTH_CHANGE_PASSWORD_FAILURE
  AUTH_CHANGE_PASSWORD_REQUEST_FAILURE
  AUTH_INVALID
  AUTH_LOGOUT_SUCCESS
  AUTH_LOGOUT_FAILURE
  AUTH_CANCEL_SUBSCRIPTION_SUCCESS
  AUTH_CANCEL_SUBSCRIPTION_REQUEST_FAILURE
  ADD_VIDEO_SEEN
  REMOVE_SUBSCRIPTION
  SUBSCRIPTION_CANCELLATION_PENDING
  CANCEL_FREE_TRIAL_SUCCESS
  CANCEL_FREE_TRIAL_REQUEST_FAILURE
  AUTH_FROM_EMAIL
  AUTH_FETCH_USER_SUCCESS
  AUTH_FETCH_USER_FAILURE
  REVOKE_GIFT_SUBSCRIPTION
  REVOKE_FREE_TRIAL
  UPDATE_GIFT_AVAILABILITY
`)
