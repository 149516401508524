import * as React from 'react';
import * as _ from 'lodash';
import CountdownTimer from '../../CountdownTimer';
import keyUpHandler from '../../../utils/keyUpHandler';
export default function () {
    function onKeyUp1(props, withTimer, event) {
        keyUpHandler(event, this.onClickCTA);
    }
    function onKeyUp2(props, withTimer, event) {
        keyUpHandler(event, props.displayVideo, null, null, props.video);
    }
    function scopePropsWithTimer3() {
        var props = this.props;
        var withTimer = !_.isNull(this.props.countdownDate);
        return React.createElement('div', {
            'className': 'banner-slide' + ' ' + _.transform({ linkable: !_.isNull(this.props.link) }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'style': { backgroundImage: 'url(' + props.asset.original + ')' },
            'onClick': this.onClickCTA,
            'onKeyUp': onKeyUp1.bind(this, props, withTimer),
            'tabIndex': '0'
        }, _.isNull(props.ctaUrl) ? React.createElement('div', {
            'className': 'overlay gradient',
            'key': '500'
        }, !_.isNull(this.props.link) ? React.createElement('a', {
            'aria-label': 'props.title',
            'href': props.link,
            'key': '569'
        }) : null) : null, !_.isNull(props.ctaUrl) ? React.createElement('a', {
            'aria-label': 'props.title',
            'className': 'overlay' + ' ' + _.transform({ 'gradient': props.hasGradient }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'href': props.ctaUrl,
            'key': '700'
        }) : null, React.createElement('div', { 'className': 'wrapper' }, React.createElement('div', { 'className': 'banner-info' }, React.createElement('div', {
            'className': 'title' + ' ' + _.transform({ expand: !withTimer }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, '\n              ', props.title, '\n          '), React.createElement('div', {
            'className': 'description' + ' ' + _.transform({ expand: !withTimer }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('p', {}, '\n                  ', props.description, '\n              ')), withTimer ? React.createElement('div', {
            'className': 'countdown',
            'key': '1215'
        }, React.createElement(CountdownTimer, {
            'targetDate': props.countdownDate,
            'startDelay': 2000,
            'interval': 1000,
            'leadingZero': true
        })) : null, !_.isNull(props.ctaLabel) && !_.isNull(props.ctaUrl) ? React.createElement('div', {
            'className': 'cta',
            'key': '1464'
        }, React.createElement('a', { 'href': props.ctaUrl }, React.createElement('button', { 'className': 'btn -thin -orange' }, props.ctaLabel))) : null), props.video && props.videoThumbnail ? React.createElement('div', {
            'aria-labelledby': 'video-cta-label',
            'className': 'video-banner',
            'onClick': () => props.displayVideo(props.video),
            'onKeyUp': onKeyUp2.bind(this, props, withTimer),
            'tabIndex': '0',
            'key': '1686'
        }, React.createElement('div', {
            'className': 'video-banner__thumb',
            'style': { backgroundImage: 'url(' + props.videoThumbnail + ')' }
        }, React.createElement('div', { 'className': 'video-cta' }, React.createElement('i', { 'className': 'icon-play video-cta__icon' }), React.createElement('span', {
            'className': 'video-cta__text',
            'id': 'video-cta-label'
        }, '\n                Learn more\n              ')))) : null));
    }
    return scopePropsWithTimer3.apply(this, []);
}