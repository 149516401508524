import * as React from 'react';
import * as _ from 'lodash';
import utils from '../../utils/cartItemUtils';
import AddressDetails from '../AddressDetails';
import PaymentMethodDetails from '../PaymentMethodDetails';
import math from '../../../redux/modules/utils/math';
export default function () {
    function repeatItem1(props, subOrder, item, itemIndex) {
        return React.createElement('div', {
            'className': 'item',
            'key': item.id
        }, React.createElement('div', { 'className': 'item-name' }, React.createElement('div', { 'className': 'type blue' }, React.createElement('span', {}, utils.getNaturalType(item.orderedModel, item.ordered))), React.createElement('div', { 'className': 'title' }, React.createElement('span', {}, utils.getTitle(item.orderedModel, item.ordered)))), React.createElement('div', { 'className': 'quantity' }, '\n                ', item.quantity, '\n            '), React.createElement('div', { 'className': 'item-price' }, '\n                $', math.toFixed(item.price * item.quantity), '\n            '));
    }
    function scopeCreditCardPaymentPaypalPayment2(props, subOrder) {
        var creditCardPayment = props.creditCard;
        var paypalPayment = props.paypalAccount;
        return React.createElement('div', {
            'className': 'payment-info' + ' ' + _.transform({ 'only-payment-method': !props.shippingAddress }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'key': '2320'
        }, React.createElement('div', { 'className': 'small-header' }, React.createElement('span', {}, 'payment method')), React.createElement(PaymentMethodDetails, {
            creditCardPayment,
            paypalPayment
        }));
    }
    function scopeCreditCardPaymentPaypalPayment3(props, subOrder) {
        var creditCardPayment = props.creditCardDetails;
        var paypalPayment = props.paypalDetails;
        return React.createElement('div', {
            'className': 'payment-info',
            'key': '2875'
        }, React.createElement('div', { 'className': 'small-header' }, React.createElement('span', {}, 'payment method')), React.createElement(PaymentMethodDetails, {
            creditCardPayment,
            paypalPayment
        }));
    }
    function scopePropsSubOrder4() {
        var props = this.props;
        var subOrder = this.props.subscriptionTransaction;
        return React.createElement('div', { 'className': 'order-description' }, !props.isMobile ? React.createElement('div', {
            'className': 'items-header',
            'key': '434'
        }, React.createElement('div', { 'className': 'item-name' }, 'items'), !subOrder ? React.createElement('div', {
            'className': 'quantity',
            'key': '536'
        }, props.width < 980 ? 'qt' : 'quantity') : null, React.createElement('div', { 'className': 'item-price' }, 'price')) : null, React.createElement.apply(this, [
            'div',
            { 'className': 'items' },
            !subOrder ? _.map(props.items, repeatItem1.bind(this, props, subOrder)) : null,
            subOrder ? React.createElement('div', {
                'className': 'item',
                'key': '1381'
            }, React.createElement('div', { 'className': 'item-name' }, React.createElement('div', { 'className': 'type blue' }, React.createElement('span', {}, 'Subscription')), _.has(props.subscription, 'plan') ? React.createElement('div', {
                'className': 'title',
                'key': '1578'
            }, React.createElement('span', {}, 'Subscription renewal')) : null), React.createElement('div', { 'className': 'item-price' }, '\n                $', math.toFixed(props.amount), '\n            ')) : null
        ]), React.createElement('div', {
            'className': 'bottom-section' + ' ' + _.transform({ simplified: !props.showFullDetail }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, props.showFullDetail ? React.createElement('div', {
            'className': 'full-details',
            'key': '1952'
        }, props.shippingAddress ? React.createElement('div', {
            'className': 'shipping-info',
            'key': '2053'
        }, React.createElement('div', { 'className': 'small-header' }, React.createElement('span', {}, 'shipped to')), React.createElement(AddressDetails, props.shippingAddress)) : null, _.isObject(props.creditCard) || _.isObject(props.paypalAccount) ? scopeCreditCardPaymentPaypalPayment2.apply(this, [
            props,
            subOrder
        ]) : null, subOrder ? scopeCreditCardPaymentPaypalPayment3.apply(this, [
            props,
            subOrder
        ]) : null, props.billingAddress && props.billingAddress.firstName ? React.createElement('div', {
            'className': 'billing-info',
            'key': '3306'
        }, React.createElement('div', { 'className': 'small-header' }, React.createElement('span', {}, 'billed to')), React.createElement(AddressDetails, props.billingAddress)) : null) : null, !subOrder ? React.createElement('div', {
            'className': 'order-details',
            'key': '3614'
        }, React.createElement('div', { 'className': 'small-header' }, '\n                order details\n            '), !_.isEmpty(props.items) ? React.createElement('div', {
            'className': 'order-details-container',
            'key': '3760'
        }, React.createElement('div', { 'className': 'order-details-items' }, props.discounted && props.discounted !== '0.00' ? React.createElement('div', { 'key': '3945' }, 'Discount') : null, React.createElement('div', {}, 'Items total:'), props.shippingCost !== '0.00' ? React.createElement('div', { 'key': '4085' }, 'Shipping:') : null, props.taxAmount && props.taxAmount !== '0.00' || props.taxPctg && props.taxPctg !== '0.00' ? React.createElement('div', { 'key': '4164' }, 'Taxes:') : null), React.createElement('div', { 'className': 'order-details-values' }, props.discounted && props.discounted !== '0.00' ? React.createElement('div', { 'key': '4375' }, '-$', math.toFixed(Math.abs(props.discounted))) : null, React.createElement('div', {}, '$', math.toFixed(props.itemsCost)), props.shippingCost !== '0.00' ? React.createElement('div', { 'key': '4571' }, '$', math.toFixed(props.shippingCost)) : null, React.createElement('div', { 'className': 'taxes-amount' }, props.taxPctg && props.taxPctg !== '0.00' ? React.createElement('div', { 'key': '4727' }, '%', props.taxPctg) : null, props.taxAmount && props.taxAmount !== '0.00' ? React.createElement('div', {
            'className': 'tax-divider',
            'key': '4829'
        }, '-') : null, props.taxAmount && props.taxAmount !== '0.00' ? React.createElement('div', { 'key': '4940' }, '$', props.taxAmount) : null))) : null, React.createElement('div', { 'className': 'order-total' }, React.createElement('div', {}, '\n                    order total:\n                '), React.createElement('div', {}, '\n                    $', math.toFixed(subOrder ? props.amount : props.cost), '\n                '))) : null));
    }
    return scopePropsSubOrder4.apply(this, []);
}