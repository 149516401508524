const toFixed = (number, position = 2) => {
  let value = Number(number)
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${position || -1}})?`)

  if (String(value).indexOf('.') === -1)
    // round safely to .00 if no decimals exist
    value = value.toFixed(2).toString()
  else {
    // reduce to two decimals without rounding
    value = number.toString().match(re)[0]
    if (value.split('.')[1].length === 1)
      // include zero for cases like 96.3
      value = `${value}0`
  }

  return value
}

export default { toFixed }
