import * as React from 'react';
import * as _ from 'lodash';
import { RadioGroup } from 'react-radio-group';
import { Radio } from 'react-radio-group';
export default function () {
    function repeatMethod1(method, index) {
        return React.createElement('div', {
            'className': 'method' + ' ' + _.transform({ selected: method.code === this.props.selected }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'key': method.code,
            'onClick': () => this.props.chooseDeliveryMethod(method.code, method.cost)
        }, React.createElement('label', {}, React.createElement('div', { 'className': 'radio-input' }, React.createElement(Radio, {
            'value': method.code,
            'key': method.code
        })), React.createElement('div', { 'className': 'icon' }, React.createElement('i', { 'className': 'icon-truck-' + (3 - this.props.methods.length + index < 1 ? '0' : 3 - this.props.methods.length + index) })), React.createElement('div', { 'className': 'description' }, React.createElement('span', {}, method.name), React.createElement('span', {}, method.description), method.warning ? React.createElement('span', {
            'className': 'warning',
            'key': '1116'
        }, method.warning) : null), React.createElement('div', { 'className': 'cost' }, '$', parseFloat(Math.round(method.cost * 100) / 100).toFixed(2))));
    }
    return React.createElement('div', { 'id': 'DeliveryMethods' }, React.createElement.apply(this, [
        RadioGroup,
        {
            'name': 'deliveryMethods',
            'selectedValue': this.props.selected,
            'className': 'radio-group'
        },
        this.props.methods ? _.map(this.props.methods, repeatMethod1.bind(this)) : null
    ]));
}