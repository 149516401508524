import { createStructuredSelector } from 'reselect'
import { isMobileSelector } from 'reduxModules/app/selectors'

export const isResendCTADisabledSelector = state =>
  state.emailConfirmation.isResendCTADisabled

export default createStructuredSelector({
  isResendCTADisabled: isResendCTADisabledSelector,
  isMobile: isMobileSelector
})
