import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    function onMouseEnter1() {
        this.props.showMenuItem();
    }
    function onMouseLeave2() {
        this.props.hideMenuItem();
    }
    function onKeyUp3(event) {
        this.toggleSubmenu(event);
    }
    function repeatOption4(option, optionIndex) {
        return React.createElement('li', { 'key': optionIndex }, !option.isExternal ? React.createElement(Link, {
            'to': option.url,
            'tabIndex': '0',
            'key': '740'
        }, '\n          ', option.title, '\n        ') : null, option.isExternal ? React.createElement('a', {
            'href': option.url,
            'target': '_blank',
            'tabIndex': '0',
            'key': '893'
        }, '\n          ', option.title, '\n        ') : null);
    }
    return React.createElement('li', {
        'className': 'menu-item',
        'onMouseEnter': onMouseEnter1.bind(this),
        'onMouseLeave': onMouseLeave2.bind(this)
    }, React.createElement('div', {
        'className': `item item--${ this.props.title }` + ' ' + _.transform({ active: this.props.title.toLowerCase() === this.props.activeSection }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, !this.props.url ? React.createElement('span', {
        'tabIndex': '0',
        'onKeyUp': onKeyUp3.bind(this),
        'key': '309'
    }, '\n      ', this.props.title, '\n    ') : null, this.props.url ? React.createElement(Link, {
        'to': this.props.url,
        'tabIndex': '0',
        'key': '464'
    }, '\n      ', this.props.title, '\n    ') : null), this.props.show && this.props.options.length ? React.createElement('div', {
        'className': 'submenu',
        'key': '578'
    }, React.createElement.apply(this, [
        'ul',
        {},
        _.map(this.props.options, repeatOption4.bind(this))
    ])) : null);
}