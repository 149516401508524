import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import SubscriptionPlanList from '../../SubscriptionPlans/SubscriptionPlanList';
import PLANS from '../../SubscriptionPlans/fixturePlans';
export default function () {
    function scopePropsIsSubscribed1() {
        var props = this.props;
        var isSubscribed = props.isSubscribed;
        return React.createElement('div', {
            'id': 'ChangeSubscription',
            'className': 'account-section wrapper'
        }, React.createElement('h3', { 'className': 'account-section__title' }, '\n    ', isSubscribed ? 'upgrade subscription plan' : 'choose your subscription plan', '\n  '), React.createElement('div', { 'className': 'message' }, React.createElement('span', {}, '\n      ', isSubscribed ? 'To upgrade your subscription, select a new plan from the chart below.' : 'To start a subscription, select a plan from the chart below.', '\n    ')), React.createElement('div', { 'className': 'personal-information' }, React.createElement(SubscriptionPlanList, {
            'plans': PLANS,
            'upgrading': true,
            'currentPlan': isSubscribed ? props.subscription.rank : 0,
            'currentPlanName': isSubscribed ? props.subscription.plan : '',
            'currentPlanId': isSubscribed ? props.subscription.planId : '',
            'isSubscribed': isSubscribed,
            'changeSubscription': true,
            'upgradeAction': (plans, newPlan, oldPlan) => props.dispatchUpgradePlan(plans, newPlan, oldPlan)
        })));
    }
    return scopePropsIsSubscribed1.apply(this, []);
}