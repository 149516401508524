import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function CourseListPlaceholder(props) {
  return template.call(props)
}

CourseListPlaceholder.propTypes = {
  title: PropTypes.string.isRequired
}

export default CourseListPlaceholder
