import { connect } from 'react-redux'
import { navBarStateSelector as stateSelector } from 'reduxModules/navBar/selectors'
import dispatchers from 'reduxModules/navBar/dispatchers'
import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'
import './styles.css'

export class NavBar extends Component {
  constructor(props, context) {
    super(props, context)
    this.inputKeyUp = this.inputKeyUp.bind(this)
    this.showNav = this.showNav.bind(this)
    this.isContest = this.isContest.bind(this)
    this.flowContest = this.flowContest.bind(this)
  }

  componentDidMount() {
    this.props.getInitialShowCookieNoticeState()
  }

  flowContest() {
    const { props } = this
    if (this.isContest())
      return props.pathName && props.pathName.endsWith('/thank-you')
    return true
  }

  isContest() {
    const { props } = this
    return props.pathName && props.pathName.startsWith('/contest')
  }

  showNav() {
    const { props } = this
    if (this.isContest()) return false
    return !props.openedSearchBox && !props.hideInnerNav
  }

  inputKeyUp(event) {
    if (event.key === 'Enter') {
      // Enter key
      const value = event.currentTarget.value
      this.context.router.push(`/search?q=${encodeURIComponent(value)}`)
      this.props.toggleSearchBox()
      this.props.clearSuggestions()
    }
    if (event.key === 'Escape') {
      // Escape key
      this.props.toggleSearchBox()
      this.props.clearSuggestions()
    }
  }

  render() {
    return template.call(this)
  }
}

NavBar.propTypes = {
  openedSearchBox: PropTypes.bool,
  pathName: PropTypes.string.isRequired,

  // Dispatchers
  toggleSearchBox: PropTypes.func.isRequired,
  releaseHijacked: PropTypes.func.isRequired
}

NavBar.contextTypes = {
  router: PropTypes.object
}

NavBar.defaultProps = {
  openedSearchBox: false
}

export default connect(stateSelector, dispatchers)(NavBar)
