import React from 'react'
import moment from 'moment'

import _ from 'lodash'

function Bookmark({ bookmark, playChapter, playlist, removeBookmark }) {
  const playBookmark = bookmark => {
    const chapterId = bookmark.chapter
    const index = _.findIndex(playlist, o => o?.extra?.id === chapterId)

    playChapter(index, bookmark.time)
  }
  const currentChapterIndex = _.findIndex(
    playlist,
    o => o?.extra?.id === bookmark.chapter
  )
  return (
    <li className="bookmark">
      <button
        onClick={() => playBookmark(bookmark)}
        type="button"
        className="bookmark-cta"
      >
        <i className="icon icon-bookmark-filled blue" />
        <div className="chapter-info-container">
          <span className="name">{bookmark.name}</span>
          <div className="bookmark-time">
            <span>{`Chapter ${currentChapterIndex + 1} - ${moment()
              .startOf('day')
              .seconds(bookmark.time)
              .format('H:mm:ss')}`}</span>
          </div>
        </div>
      </button>
      <button
        className="remove-bookmark"
        onClick={e => removeBookmark(e, bookmark.id)}
        type="button"
        title="Remove bookmark"
      >
        <i className="icon-x" />
      </button>
    </li>
  )
}

export default Bookmark
