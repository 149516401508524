import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import keyUpHandler from '../../utils/keyUpHandler';
import MenuItem from './MenuItem';
import SearchBox from './SearchBox';
import getOptions from './options';
import SubscribeDialogCTA from '../SubscribeDialogCTA';
import logoMain from '../../../global/assets/images/logo/sw-logo.svg';
import logoMainMobile from '../../../global/assets/images/logo/swsca-logo-white.svg';
import CookieNotice from '../../generic/CookieNotice';
import CartModal from '../../Cart/CartModal';
import { getActiveSection } from './utils';
import AccountMenu from './AccountMenu/';
export default function () {
    function repeatOption1(props, hasMobileSubsCTA, option, optionIndex) {
        return React.createElement(MenuItem, _.assign({}, {
            'activeSection': getActiveSection(props.pathName),
            'key': optionIndex
        }, option));
    }
    function onKeyUp2(props, hasMobileSubsCTA, event) {
        keyUpHandler(event, props.toggleSearchBox);
    }
    function onKeyUp3(props, hasMobileSubsCTA, event) {
        keyUpHandler(event, props.releaseHijacked);
    }
    function scopePropsHasMobileSubsCTA4() {
        var props = this.props;
        var hasMobileSubsCTA = !props.isSubscriber && props.dimensions.width < 870;
        return React.createElement('header', {
            'id': 'NavBar',
            'className': _.transform({
                'not-verified': !props.userSelector || !props.userSelector.isVerified && props.isLoggedIn && props.pathName !== '/checkout',
                'is-fullscreen': props.isFullscreen,
                'with-mobile-subscribe-cta': hasMobileSubsCTA,
                'with-cookie-notice': props.showCookieNotice && props.dimensions.width < 870
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, props.showCookieNotice ? React.createElement(CookieNotice, {
            'hideCookieNotice': props.hideCookieNotice,
            'key': '1330'
        }) : null, hasMobileSubsCTA ? React.createElement(SubscribeDialogCTA, { 'key': '1435' }, React.createElement('div', { 'className': 'mobile-subscribe-cta' }, React.createElement('span', {}, props.usedFreeTrial ? 'Subscribe now' : 'Start Free 7-Day Trial'))) : null, React.createElement('div', {
            'className': 'wrapper' + ' ' + _.transform({ 'center-logo': props.hideInnerNav && props.isMobile }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, !props.hideInnerNav && !props.openedSearchBox ? React.createElement('div', {
            'className': 'burger-menu' + ' ' + _.transform({ 'shrink-burger': props.openedSearchBox }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'key': '1739'
        }, React.createElement('span', {
            'className': 'burger-icon',
            'onClick': !props.showMobileMenu && props.isMobile ? () => props.toggleMobileMenu() : null
        }, '\u2630')) : null, !(props.openedSearchBox && props.isMobile) ? React.createElement('div', {
            'className': 'main-menu',
            'key': '2079'
        }, React.createElement(Link, {
            'className': 'logo' + ' ' + _.transform({
                '-mobile': props.isMobile,
                'with-mobile-subscribe-cta': hasMobileSubsCTA
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'to': '/',
            'tabIndex': '0'
        }, React.createElement('img', {
            'src': logoMain,
            'alt': 'Stan Winston School'
        })), this.showNav() ? React.createElement('nav', {
            'id': 'internalNav',
            'key': '2404'
        }, React.createElement.apply(this, [
            'ul',
            { 'className': 'nav' },
            _.map(getOptions(false), repeatOption1.bind(this, props, hasMobileSubsCTA))
        ])) : null) : null, !props.hideInnerNav ? React.createElement('div', {
            'className': `account-menu${ props.openedSearchBox ? ' account-menu--search' : '' }`,
            'key': '2734'
        }, !_.isEmpty(props.itemAdded) ? React.createElement(CartModal, {
            'itemAdded': props.itemAdded,
            'closeItemModal': this.props.closeItemModal,
            'key': '2872'
        }) : null, props.openedSearchBox && this.flowContest() ? React.createElement(SearchBox, {
            'inputKeyUp': this.inputKeyUp,
            'toggleSearchBox': props.toggleSearchBox,
            'getSuggestions': props.getSearchSuggestions,
            'autocompleteResults': props.autocompleteResults,
            'clearSuggestions': props.clearSuggestions,
            'key': '2999'
        }) : null, !props.isSubscriber && props.dimensions.width >= 870 && this.flowContest() && !props.openedSearchBox ? React.createElement(SubscribeDialogCTA, { 'key': '3342' }, React.createElement('div', { 'className': 'subscribe-cta' }, React.createElement('span', {}, 'Subscribe'))) : null, !props.openedSearchBox ? React.createElement('div', {
            'className': 'account-actions',
            'key': '3590'
        }, !props.openedSearchBox && !this.isContest() ? React.createElement('i', {
            'aria-label': 'Toggle search box',
            'className': 'account-actions__action icon-search in-navbar',
            'onClick': () => {
                props.toggleSearchBox();
            },
            'onKeyUp': onKeyUp2.bind(this, props, hasMobileSubsCTA),
            'tabIndex': '0',
            'key': '3659'
        }) : null, !props.openedSearchBox && !props.isLibrarySubscriber && !this.isContest() ? React.createElement(Link, {
            'aria-label': 'Go to cart, ' + (props.cartCount ? props.cartCount : '0') + ' items in cart',
            'className': 'account-actions__action cart',
            'to': '/cart',
            'key': '4001'
        }, React.createElement('i', {
            'className': 'icon-cart',
            'role': 'presentation',
            'aria-label': 'Cart icon'
        }), this.props.cartCount ? React.createElement('span', {
            'className': 'cart-count',
            'key': '4355'
        }, '\n            ', this.props.cartCount > 99 ? '+99' : this.props.cartCount, '\n          ') : null) : null, !props.openedSearchBox && props.isLoggedIn && !props.isMobile && !this.isContest() ? React.createElement(AccountMenu, {
            'logout': props.logout,
            'createdByProxy': props.createdByProxy,
            'key': '4523'
        }) : null, !props.openedSearchBox && !props.isLoggedIn && !this.isContest() ? React.createElement(Link, {
            'className': 'account-actions__action login-button',
            'to': {
                pathname: '/login',
                state: { next: props.pathName }
            },
            'key': '4742'
        }, '\n          login\n        ') : null) : null, props.isLoggedIn && !props.openedSearchBox && props.hijacking ? React.createElement('span', {
            'onClick': () => props.releaseHijacked(),
            'className': 'btn -mini -orange release-button',
            'onKeyUp': onKeyUp3.bind(this, props, hasMobileSubsCTA),
            'tabIndex': '0',
            'key': '5033'
        }, '\n        Release\n      ') : null) : null));
    }
    return scopePropsHasMobileSubsCTA4.apply(this, []);
}