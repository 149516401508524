import { decamelizeKeys } from 'humps'
import { closeBanner, openBanner } from 'reduxModules/subscriptions'
import modalDispatchers from 'reduxModules/generic/modal/dispatchers'
import { blogPostsFetch } from '../blogPosts'
import {
  clearTutorialList,
  latestTutorialsFetch,
  tutorialsFetch
} from '../tutorials'
import {
  learningPathRecentFetch,
  learningPathRecentMoreFetch
} from '../learningPaths'
import { FBImagesFetch } from '../assets'
import { bannersFetch, secondaryBannersFetch, freeTrialimageFetch } from '../banners'
import {
  homeFetch,
  clearHome,
  homeFetchCurrent,
  homeFetchCurrentMore
} from './'

export default dispatch => ({
  loadBlogPosts(filters) {
    dispatch(blogPostsFetch(decamelizeKeys(filters))())
  },
  loadTutorials(filters) {
    dispatch(tutorialsFetch(decamelizeKeys(filters))())
  },
  clearTutorialList() {
    dispatch(clearTutorialList())
  },
  loadFBImages() {
    dispatch(FBImagesFetch('home')())
  },
  loadBanners() {
    dispatch(bannersFetch({ home_banner: true })())
  },
  loadFreeTrialImage() {
    dispatch(freeTrialimageFetch()())
  },
  loadSecondaryBanners() {
    dispatch(secondaryBannersFetch({ home_secondary_banner: true })())
  },
  loadHome() {
    dispatch(homeFetch())
  },
  loadRecentPathways() {
    dispatch(learningPathRecentFetch({ limit: 4 })())
  },
  loadMoreRecentPathways() {
    dispatch(learningPathRecentMoreFetch({ offset: 4 })())
  },
  loadCurrent() {
    dispatch(homeFetchCurrent({ auto_generated: true, limit: 4, full: true, ordering: '-id' })())
  },
  loadMoreCurrent() {
    dispatch(
      homeFetchCurrentMore({ auto_generated: true, offset: 4, full: true, ordering: '-id' })()
    )
  },
  clearHome() {
    dispatch(clearHome())
  },
  closeBannerSubscription() {
    dispatch(closeBanner())
  },
  openBannerSubscription() {
    dispatch(openBanner())
  },
  loadLatestTutorials() {
    dispatch(latestTutorialsFetch()())
  },
  ...modalDispatchers(dispatch)
})
