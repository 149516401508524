import * as React from 'react';
import * as _ from 'lodash';
import SearchCategory from './SearchCategory';
export default function () {
    function repeatCategory1(category, categoryIndex) {
        return React.createElement(SearchCategory, {
            'name': category,
            'active': this.props.active,
            'key': categoryIndex,
            'select': category => this.props.select(category)
        });
    }
    return React.createElement('div', { 'id': 'SearchCategories' }, React.createElement.apply(this, [
        'div',
        { 'className': 'wrapper' },
        _.map(this.props.categories, repeatCategory1.bind(this))
    ]));
}