/* global API_AUTH_ROOT, API_ROOT */
import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import _ from 'lodash'
import { types as checkoutTypes } from 'reduxModules/checkout'
import { types as subscriptionTypes } from 'reduxModules/subscriptions'
import authTypes from 'reduxModules/auth/types'
import fetchApi, { fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  USERS_INITIAL
  PROFILE_LOADING
  PROFILE_SUCCESS
  PROFILE_ERROR
  FAVORITES_LOADING
  FAVORITES_SUCCESS
  FAVORITES_ERROR
  ADDRESSES_LOADING
  ADDRESSES_SUCCESS
  ADDRESSES_ERROR
  ORDERS_LOADING
  ORDERS_SUCCESS
  ORDERS_ERROR
  PAYMENT_METHODS_LOADING
  PAYMENT_METHODS_SUCCESS
  PAYMENT_METHODS_ERROR
  USERS_PROFILE_FETCH_SUCCESS
  USER_TUTORIALS_FETCH_SUCCESS
  USER_TUTORIALS_REQUEST_FAILURE
  USERS_TUTORIALS_FETCH_LOADING
  USERS_TUTORIALS_FETCH_MORE_SUCCESS
  USERS_TUTORIALS_REQUEST_MORE_FAILURE
  USERS_PROFILE_REQUEST_FAILURE
  USERS_PROFILE_UPDATE_SUCCESS
  USERS_PROFILE_UPDATE_REQUEST_FAILURE
  USERS_FAVORITES_FETCH_SUCCESS
  USERS_FAVORITES_REQUEST_FAILURE
  USERS_FAVORITE_SET_SUCCESS
  USERS_FAVORITE_SET_REQUEST_FAILURE
  USERS_FAVORITE_UNSET_SUCCESS
  USERS_FAVORITE_UNSET_REQUEST_FAILURE
  USERS_ADDRESS_CREATE_SUCCESS
  USERS_ADDRESS_CREATE_REQUEST_FAILURE
  USERS_ADDRESSES_FETCH_SUCCESS
  USERS_ADDRESSES_REQUEST_FAILURE
  USERS_ADDRESS_REMOVE_SUCCESS
  USERS_ADDRESS_REMOVE_REQUEST_FAILURE
  USERS_ADDRESS_UPDATE_SUCCESS
  USERS_ADDRESS_UPDATE_REQUEST_FAILURE
  USERS_ADDRESS_SHOW_FORM
  USERS_ADDRESS_HIDE_FORM
  USERS_BRAINTREE_TOKEN_FETCH_SUCCESS
  USERS_BRAINTREE_TOKEN_REQUEST_FAILURE
  USERS_PAYMENT_METHODS_FETCH_SUCCESS
  USERS_PAYMENT_METHODS_FAILURE
  USERS_MARK_PAYMENT_METHOD_DEFAULT_SUCCESS
  USERS_MARK_PAYMENT_METHOD_DEFAULT_REQUEST_FAILURE
  USERS_PAYMENT_METHOD_REMOVE_SUCCESS
  USERS_PAYMENT_METHOD_REMOVE_REQUEST_FAILURE
  USERS_PAYMENT_METHOD_SAVE_SUCCESS
  USERS_PAYMENT_METHOD_REQUEST_FAILURE
  USERS_CHECKOUT_ADDRESS_FORM_FILL
  USERS_EDITING_ADDRESS
  USERS_ORDERS_FETCH_SUCCESS
  USERS_ORDERS_FETCH_REQUEST_FAILURE
  USERS_ORDER_DETAIL_FETCH_SUCCESS
  USERS_ORDER_DETAIL_REQUEST_FAILURE
  USERS_CHANGE_PLAN_SUCCESS
  USERS_CHANGE_PLAN_REQUEST_FAILURE
  USER_LEARNING_PATHS_FETCH_SUCCESS
  USER_LEARNING_PATHS_REQUEST_FAILURE
  LEARNING_PATHS_LOADING
  LEARNING_PATHS_SUCCESS
  LEARNING_PATHS_ERROR
  USERS_PROFILE_OPEN_SUCCESS
  USERS_PROFILE_CLOSE_SUCCESS
`)

export const usersStatus = {
  initial: types.USERS_INITIAL
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: usersStatus.initial,
  profile: {},
  favorites: {},
  addresses: {
    results: [],
    showAddressForm: false
  },
  paymentMethods: {
    results: {
      creditCards: [],
      paypalAccounts: []
    }
  },
  orders: {
    results: [],
    settled: {}
  },
  subscriptionTransactions: [],
  learningPaths: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  profileIsOpen: false
}

export const profileStatus = {
  loading: types.PROFILE_LOADING,
  success: types.PROFILE_SUCCESS,
  error: types.PROFILE_ERROR
}

export const favoritesStatus = {
  loading: types.FAVORITES_LOADING,
  success: types.FAVORITES_SUCCESS,
  error: types.FAVORITES_ERROR
}

export const tutorialsStatus = {
  loading: types.USERS_TUTORIALS_FETCH_LOADING,
  success: types.USER_TUTORIALS_FETCH_SUCCESS,
  error: types.USER_TUTORIALS_REQUEST_FAILURE
}

export const paymentMethodsStatus = {
  loading: types.PAYMENT_METHODS_LOADING,
  success: types.PAYMENT_METHODS_SUCCESS,
  error: types.PAYMENT_METHODS_ERROR
}

export const addressStatus = {
  loading: types.ADDRESSES_LOADING,
  success: types.ADDRESSES_SUCCESS,
  error: types.ADDRESSES_ERROR
}

export const ordersStatus = {
  loading: types.ORDERS_LOADING,
  success: types.ORDERS_SUCCESS,
  error: types.ORDERS_ERROR
}

export const learningPathsStatus = {
  loading: types.LEARNING_PATHS_LOADING,
  success: types.LEARNING_PATHS_SUCCESS,
  error: types.LEARNING_PATHS_ERROR
}

export const fetchProfileSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  profile: { ...state.profile, ...action.payload },
  status: profileStatus.success
})

export const closeProfileSuccess = (state = INITIAL_STATE) => ({
  ...state,
  profileIsOpen: false
})

export const openProfileSuccess = (state = INITIAL_STATE) => ({
  ...state,
  profileIsOpen: true
})

export const fetchMoreTutorialsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  tutorials: {
    list: [...state.tutorials.list, ...action.payload.results],
    next: action.payload.next,
    status: tutorialsStatus.success
  }
})

export const fetchMoreTutorialsFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: tutorialsStatus.error
})

export const fetchTutorialsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  tutorials: {
    list: action.payload.results,
    next: action.payload.next,
    status: tutorialsStatus.success
  }
})

export const fetchFavoritesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  favorites: { ...action.payload },
  status: favoritesStatus.success
})

export const fetchProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: profileStatus.error
})

export const fetchTutorialsFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: tutorialsStatus.error
})

export const updateProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: profileStatus.error
})

export const fetchFavoritesFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: favoritesStatus.error
})

export const fetchAddressesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  addresses: {
    ...state.addresses,
    results: [...action.payload.results]
  },
  status: addressStatus.success
})

export const fetchAddressesFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: addressStatus.error
})

export const createAddressSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  addresses: {
    ...state.addresses,
    results: [...state.addresses.results, action.payload]
  },
  status: addressStatus.success
})

export const updateAddressSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  addresses: {
    ...state.addresses,
    results: [...state.addresses.results, action.payload],
    editing: false
  },
  status: addressStatus.success
})

export const removeAddressSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  addresses: {
    ...state.addresses,
    results: [
      ..._.reject(state.addresses.results, {
        id: action.payload.requestBody.pk
      })
    ]
  },
  status: addressStatus.success
})

export const fetchPaymentMethodsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  paymentMethods: {
    ...state.paymentMethods,
    results: action.payload
  },
  status: paymentMethodsStatus.success
})

export const fetchPaymentMethodsFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: paymentMethodsStatus.error
})

export const setPaymentMethodDefaultSuccess = (
  state = INITIAL_STATE,
  action
) => {
  const cardsClone = _.cloneDeep(state.paymentMethods.results.creditCards)
  const paypalAccountsClone = _.cloneDeep(
    state.paymentMethods.results.paypalAccounts
  )
  const currentToken = action.payload.token

  cardsClone.forEach((method, index) => {
    if (method.token === currentToken) cardsClone[index].default = true
    else if (cardsClone[index].default) cardsClone[index].default = false
  })

  paypalAccountsClone.forEach((method, index) => {
    if (method.token === currentToken) paypalAccountsClone[index].default = true
    else if (paypalAccountsClone[index].default)
      paypalAccountsClone[index].default = false
  })

  return {
    ...state,
    paymentMethods: {
      ...state.paymentMethods,
      results: {
        ...state.paymentMethods.results,
        creditCards: [...cardsClone],
        paypalAccounts: [...paypalAccountsClone]
      }
    },
    status: paymentMethodsStatus.success
  }
}

export const paymentMethodSavedSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  paymentMethods: {
    ...state.paymentMethods,
    results: {
      ...state.paymentMethods.results,
      creditCards: [...state.paymentMethods.results.creditCards, action.payload]
    }
  },
  status: paymentMethodsStatus.success
})

export const paymentMethodRemovedSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  paymentMethods: {
    ...state.paymentMethods,
    results: {
      ...state.paymentMethods.results,
      creditCards: [
        ..._.reject(state.paymentMethods.results.creditCards, {
          token: action.payload.requestBody.token
        })
      ],
      paypalAccounts: [
        ..._.reject(state.paymentMethods.results.paypalAccounts, {
          token: action.payload.requestBody.token
        })
      ]
    }
  },
  status: paymentMethodsStatus.success
})

export const editingAddressSuccess = (state = INITIAL_STATE) => ({
  ...state,
  addresses: {
    ...state.addresses,
    editing: true
  },
  status: addressStatus.success
})

export const fetchOrdersSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  orders: {
    ...action.payload
  },
  status: ordersStatus.success
})

export const fetchOrderDetailSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  orders: {
    ...state.orders,
    results: [
      ..._.reject(state.orders.results, { id: action.payload.id }),
      { ...action.payload }
    ]
  },
  status: ordersStatus.success
})

export const settledOrderSucess = (state = INITIAL_STATE, action) => ({
  ...state,
  orders: {
    ...state.orders,
    settled: action.payload
  },
  status: ordersStatus.success
})

export const showAddressForm = (state = INITIAL_STATE) => ({
  ...state,
  addresses: {
    ...state.addresses,
    showAddressForm: true
  }
})

export const hideAddressForm = (state = INITIAL_STATE) => ({
  ...state,
  addresses: {
    ...state.addresses,
    showAddressForm: false,
    editing: false
  }
})

export const clearState = (state = INITIAL_STATE) => ({
  ...state,
  paymentMethods: {
    ...INITIAL_STATE.paymentMethods
  },
  addresses: {
    ...INITIAL_STATE.addresses
  }
})

export const subscriptionTransactionsFetchSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  subscriptionTransactions: {
    ...action.payload
  },
  status: ordersStatus.success
})

export const fetchLearningPathsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  learningPaths: {
    ...action.payload
  },
  status: ordersStatus.success
})

export const HANDLERS = {
  [types.USERS_PROFILE_FETCH_SUCCESS]: fetchProfileSuccess,
  [types.USERS_PROFILE_REQUEST_FAILURE]: fetchProfileFailure,
  [types.USERS_TUTORIALS_FETCH_MORE_SUCCESS]: fetchMoreTutorialsSuccess,
  [types.USERS_TUTORIALS_REQUEST_MORE_FAILURE]: fetchMoreTutorialsFailure,
  [types.USER_TUTORIALS_FETCH_SUCCESS]: fetchTutorialsSuccess,
  [types.USER_TUTORIALS_REQUEST_FAILURE]: fetchTutorialsFailure,
  [types.USERS_PROFILE_UPDATE_SUCCESS]: fetchProfileSuccess,
  [types.USERS_PROFILE_UPDATE_REQUEST_FAILURE]: updateProfileFailure,
  [types.USERS_FAVORITES_FETCH_SUCCESS]: fetchFavoritesSuccess,
  [types.USERS_FAVORITES_REQUEST_FAILURE]: fetchFavoritesFailure,
  [types.USERS_ADDRESSES_FETCH_SUCCESS]: fetchAddressesSuccess,
  [types.USERS_ADDRESSES_REQUEST_FAILURE]: fetchAddressesFailure,
  [types.USERS_ADDRESS_CREATE_SUCCESS]: createAddressSuccess,
  [types.USERS_ADDRESS_REMOVE_SUCCESS]: removeAddressSuccess,
  [types.USERS_ADDRESS_UPDATE_SUCCESS]: updateAddressSuccess,
  [types.USERS_ADDRESS_SHOW_FORM]: showAddressForm,
  [types.USERS_ADDRESS_HIDE_FORM]: hideAddressForm,
  [types.USERS_PAYMENT_METHODS_FETCH_SUCCESS]: fetchPaymentMethodsSuccess,
  [types.USERS_PAYMENT_METHODS_FAILURE]: fetchPaymentMethodsFailure,
  [types.USERS_MARK_PAYMENT_METHOD_DEFAULT_SUCCESS]: setPaymentMethodDefaultSuccess,
  [types.USERS_PAYMENT_METHOD_SAVE_SUCCESS]: paymentMethodSavedSuccess,
  [types.USERS_PAYMENT_METHOD_REMOVE_SUCCESS]: paymentMethodRemovedSuccess,
  [types.USERS_EDITING_ADDRESS]: editingAddressSuccess,
  [types.USERS_ORDERS_FETCH_SUCCESS]: fetchOrdersSuccess,
  [types.USERS_ORDER_DETAIL_FETCH_SUCCESS]: fetchOrderDetailSuccess,
  [checkoutTypes.SETTLE_ORDER_SUCCESS]: settledOrderSucess,
  [authTypes.AUTH_LOGOUT_SUCCESS]: clearState,
  [subscriptionTypes.SUBSCRIPTION_TRANSACTIONS_FETCH_SUCCESS]: subscriptionTransactionsFetchSuccess,
  [types.USER_LEARNING_PATHS_FETCH_SUCCESS]: fetchLearningPathsSuccess,
  [types.USERS_PROFILE_OPEN_SUCCESS]: openProfileSuccess,
  [types.USERS_PROFILE_CLOSE_SUCCESS]: closeProfileSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const getUserProfileFetchSuccess = createAction(
  types.USERS_PROFILE_FETCH_SUCCESS
)
export const getUserProfileFetchFailure = createAction(
  types.USERS_PROFILE_REQUEST_FAILURE
)
export const getUserProfile = fetchApi(
  '/profile/',
  { method: 'GET' },
  getUserProfileFetchSuccess,
  getUserProfileFetchFailure
)

export const getUserTutorialsFetchSuccess = createAction(
  types.USER_TUTORIALS_FETCH_SUCCESS
)
export const getUserTutorialsFetchFailure = createAction(
  types.USER_TUTORIALS_REQUEST_FAILURE
)
export const getUserTutorials = fetchApi(
  '/tutorials/owns/?limit=100',
  { method: 'GET' },
  getUserTutorialsFetchSuccess,
  getUserTutorialsFetchFailure
)

export const userTutorialsFetchMoreSuccess = createAction(
  types.USERS_TUTORIALS_FETCH_MORE_SUCCESS
)
export const userTutorialsFetchMoreFailure = createAction(
  types.USERS_TUTORIALS_REQUEST_MORE_FAILURE
)
export const userTutorialsFetchMore = next =>
  fetchHelper(
    next,
    { method: 'GET' },
    userTutorialsFetchMoreSuccess,
    userTutorialsFetchMoreFailure
  )

export const updateUserProfileSuccess = createAction(
  types.USERS_PROFILE_UPDATE_SUCCESS
)
export const updateUserProfileFailure = createAction(
  types.USERS_PROFILE_UPDATE_REQUEST_FAILURE
)
export const updateUserProfile = fetchApi(
  '/profile/',
  { method: 'PATCH' },
  updateUserProfileSuccess,
  updateUserProfileFailure
)

export const getUserFavoritesFetchSuccess = createAction(
  types.USERS_FAVORITES_FETCH_SUCCESS
)
export const getUserFavoritesFetchFailure = createAction(
  types.USERS_FAVORITES_REQUEST_FAILURE
)
export const getUserFavorites = fetchApi(
  '/favorites/',
  { method: 'GET' },
  getUserFavoritesFetchSuccess,
  getUserFavoritesFetchFailure
)

export const setUserFavoriteSuccess = createAction(
  types.USERS_FAVORITE_SET_SUCCESS
)
export const setUserFavoriteFailure = createAction(
  types.USERS_FAVORITE_SET_REQUEST_FAILURE
)
export const setUserFavorite = fetchApi(
  '/favorites/',
  { method: 'POST' },
  setUserFavoriteSuccess,
  setUserFavoriteFailure
)

export const unsetUserFavoriteSuccess = createAction(
  types.USERS_FAVORITE_UNSET_SUCCESS
)
export const unsetUserFavoriteFailure = createAction(
  types.USERS_FAVORITE_UNSET_REQUEST_FAILURE
)
export const unsetUserFavorite = pk =>
  fetchApi(
    `/favorites/${pk}/`,
    { method: 'DELETE' },
    unsetUserFavoriteSuccess,
    unsetUserFavoriteFailure
  )

export const createUserAddressSuccess = createAction(
  types.USERS_ADDRESS_CREATE_SUCCESS
)
export const createUserAddressFailure = createAction(
  types.USERS_ADDRESS_CREATE_REQUEST_FAILURE
)
export const createUserAddress = fetchApi(
  '/addresses/',
  { method: 'POST' },
  createUserAddressSuccess,
  createUserAddressFailure,
  { decamelizeNumbers: false }
)

export const updateUserAddressSuccess = createAction(
  types.USERS_ADDRESS_UPDATE_SUCCESS
)
export const updateUserAddressFailure = createAction(
  types.USERS_ADDRESS_UPDATE_REQUEST_FAILURE
)
export const updateUserAddress = pk =>
  fetchApi(
    `/addresses/${pk}/`,
    { method: 'PUT' },
    updateUserAddressSuccess,
    updateUserAddressFailure,
    { decamelizeNumbers: false }
  )

export const getUserAddressesFetchSuccess = createAction(
  types.USERS_ADDRESSES_FETCH_SUCCESS
)
export const getUserAddressesFetchFailure = createAction(
  types.USERS_ADDRESSES_REQUEST_FAILURE
)
export const getUserAddresses = fetchApi(
  '/addresses/',
  { method: 'GET' },
  getUserAddressesFetchSuccess,
  getUserAddressesFetchFailure
)

export const removeUserAddressSuccess = createAction(
  types.USERS_ADDRESS_REMOVE_SUCCESS
)
export const removeUserAddressFailure = createAction(
  types.USERS_ADDRESS_REMOVE_REQUEST_FAILURE
)
export const removeUserAddress = pk =>
  fetchApi(
    `/addresses/${pk}/`,
    { method: 'DELETE' },
    removeUserAddressSuccess,
    removeUserAddressFailure,
    { requestBodyOnSuccess: true }
  )({ pk })

export const showAddressFormAction = createAction(types.USERS_ADDRESS_SHOW_FORM)
export const hideAddressFormAction = createAction(types.USERS_ADDRESS_HIDE_FORM)

export const getUserBraintreeTokenFetchSuccess = createAction(
  types.USERS_BRAINTREE_TOKEN_FETCH_SUCCESS
)
export const getUserBraintreeTokenFetchFailure = createAction(
  types.USERS_BRAINTREE_TOKEN_REQUEST_FAILURE
)
export const getUserBraintreeToken = fetchApi(
  '/payment-methods/token/',
  { method: 'GET' },
  getUserBraintreeTokenFetchSuccess,
  getUserBraintreeTokenFetchFailure
)

export const getUserPaymentMethodsFetchSuccess = createAction(
  types.USERS_PAYMENT_METHODS_FETCH_SUCCESS
)
export const getUserPaymentMethodsFetchFailure = createAction(
  types.USERS_PAYMENT_METHODS_FAILURE
)
export const getUserPaymentMethods = fetchApi(
  '/payment-methods/',
  { method: 'GET' },
  getUserPaymentMethodsFetchSuccess,
  getUserPaymentMethodsFetchFailure
)

export const markDefaultUserPaymentMethodSuccess = createAction(
  types.USERS_MARK_PAYMENT_METHOD_DEFAULT_SUCCESS
)
export const markDefaultUserPaymentMethodFailure = createAction(
  types.USERS_MARK_PAYMENT_METHOD_DEFAULT_REQUEST_FAILURE
)
export const markDefaultUserPaymentMethod = token =>
  fetchApi(
    `/payment-methods/${token}/mark_default/`,
    { method: 'PUT' },
    markDefaultUserPaymentMethodSuccess,
    markDefaultUserPaymentMethodFailure
  )

export const removePaymentMethodSuccess = createAction(
  types.USERS_PAYMENT_METHOD_REMOVE_SUCCESS
)
export const removePaymentMethodFailure = createAction(
  types.USERS_PAYMENT_METHOD_REMOVE_REQUEST_FAILURE
)
export const removePaymentMethod = token =>
  fetchApi(
    `/payment-methods/${token}/`,
    { method: 'DELETE' },
    removePaymentMethodSuccess,
    removePaymentMethodFailure,
    { requestBodyOnSuccess: true }
  )({ token })

export const savePaymentMethodSuccess = createAction(
  types.USERS_PAYMENT_METHOD_SAVE_SUCCESS
)
export const savePaymentMethodFailure = createAction(
  types.USERS_PAYMENT_METHOD_REQUEST_FAILURE
)
export const savePaymentMethod = recaptcha =>
  fetchApi(
    '/payment-methods/',
    {
      method: 'POST',
      headers: {
        'x-recaptcha-token': recaptcha.token,
        'x-recaptcha-version': recaptcha.version,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    savePaymentMethodSuccess,
    savePaymentMethodFailure,
    { requestBodyOnFailure: true }
  )

export const editingAddress = createAction(types.USERS_EDITING_ADDRESS)

export const getUserOrdersFetchSuccess = createAction(
  types.USERS_ORDERS_FETCH_SUCCESS
)
export const getUserOrdersFetchFailure = createAction(
  types.USERS_ORDERS_FETCH_REQUEST_FAILURE
)
export const getUserOrders = fetchApi(
  '/orders/',
  { method: 'GET' },
  getUserOrdersFetchSuccess,
  getUserOrdersFetchFailure
)

export const orderDetailFetchSuccess = createAction(
  types.USERS_ORDER_DETAIL_FETCH_SUCCESS
)
export const orderDetailFetchFailure = createAction(
  types.USERS_ORDER_DETAIL_REQUEST_FAILURE
)
export const getOrderDetail = pk => {
  if (typeof pk === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/orders/${pk}/`,
    { method: 'GET' },
    orderDetailFetchSuccess,
    orderDetailFetchFailure
  )
}

export const changePlanSuccess = createAction(types.USERS_CHANGE_PLAN_SUCCESS)
export const changePlanFailure = createAction(
  types.USERS_CHANGE_PLAN_REQUEST_FAILURE
)
export const changePlan = fetchApi(
  '/subscriptions/change_plan/',
  { method: 'PATCH' },
  changePlanSuccess,
  changePlanFailure
)

export const getUserLearningPathsFetchSuccess = createAction(
  types.USER_LEARNING_PATHS_FETCH_SUCCESS
)
export const getUserLearningPathsFetchFailure = createAction(
  types.USER_LEARNING_PATHS_REQUEST_FAILURE
)
export const getUserLearningPaths = fetchApi(
  '/paths/owns/?limit=100',
  { method: 'GET' },
  getUserLearningPathsFetchSuccess,
  getUserLearningPathsFetchFailure
)

export const openProfile = createAction(types.USERS_PROFILE_OPEN_SUCCESS)

export const closeProfile = createAction(types.USERS_PROFILE_CLOSE_SUCCESS)
