import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { subscriptionTransactionsDispatchers as dispatchSelector } from 'reduxModules/subscriptions/dispatchers'
import template from './template.rt'
import './styles.css'

export class SubscriptionTransactions extends Component {
  componentDidMount() {
    const { isLoaded, subscription, loadSubscriptionTransactions } = this.props
    const { id } = subscription

    if (!isLoaded) loadSubscriptionTransactions(id)
  }

  render() {
    const { subscription } = this.props

    if (_.has(subscription, 'transactions')) return template.call(this)
    return null
  }
}

SubscriptionTransactions.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  loadSubscriptionTransactions: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    active: PropTypes.bool,
    braintreeId: PropTypes.string,
    free: PropTypes.bool,
    id: PropTypes.number,
    plan: PropTypes.string,
    transactions: PropTypes.arrayOf(PropTypes.object)
  }),
  subscriptionTransaction: PropTypes.bool,
  isTransaction: PropTypes.bool
}

SubscriptionTransactions.defaultProps = {
  orders: [],
  subscription: null,
  subscriptionTransaction: false,
  isTransaction: false
}

export default connect(
  null,
  dispatchSelector
)(SubscriptionTransactions)
