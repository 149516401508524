import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { Element } from 'react-scroll';
import AddToCartButton from '../../generic/AddToCartButton/';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('div', {
            'className': 'course-summary' + ' ' + _.transform({ [props.className]: props.className }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement(Element, { 'name': props.elementName }, props.isQuickPlay ? React.createElement('a', {
            'className': 'quickplay',
            'onClick': () => props.onQuickPlayClick(),
            'href': '#',
            'role': 'button',
            'aria-label': `Interact to play ${ props.title }`,
            'key': '342'
        }, React.createElement('div', {
            'className': 'header__thumb',
            'style': { 'backgroundImage': `url(${ props.thumb })` },
            'role': 'presentation'
        }), React.createElement('div', { 'className': 'info' }, React.createElement('i', { 'className': 'icon-play' }))) : null, !props.isQuickPlay ? React.createElement(Link, {
            'to': props.isSpecial ? `/special-videos/${ props.slug }` : props.isWebinar ? `/webcourses/${ props.slug }` : props.isCurrentlyWatching ? `/tutorials/${ props.slug }?watch=1` : `/tutorials/${ props.slug }`,
            'key': '735'
        }, React.createElement('div', { 'className': 'header' }, props.new ? React.createElement('div', {
            'className': 'new-course-badge',
            'key': '1056'
        }, 'new course') : null, props.completed ? React.createElement('div', {
            'className': 'completed-course-badge',
            'key': '1133'
        }, 'Completed') : null, React.createElement('div', {
            'className': 'header__thumb',
            'style': { 'backgroundImage': `url(${ props.thumb })` },
            'role': 'presentation'
        }), React.createElement('div', { 'className': 'info' }, !props.isQuickPlay ? React.createElement('p', {
            'className': 'info__text',
            'key': '1402'
        }, '\n            ', props.summary, '\n          ') : null), !!props.isSpecial ? React.createElement('div', {
            'className': 'badge badge--special',
            'key': '1518'
        }, React.createElement('span', { 'className': 'badge--special__text' }, 'Special Video')) : null, !!props.isWebinar ? React.createElement('div', {
            'className': 'badge badge--live',
            'key': '1668'
        }, React.createElement('span', {
            'className': 'badge--live__dot',
            'role': 'presentation'
        }), React.createElement('span', { 'className': 'badge--live__text' }, 'Live Course')) : null)) : null, React.createElement('div', { 'className': this.getCSSClassesFor('content') }, !props.isSpecial ? React.createElement(Link, {
            'to': `/artists/${ props.teacherSlug }`,
            'key': '1950'
        }, React.createElement('p', {
            'className': this.getCSSClassesFor('content__teacher'),
            'title': props.teacher
        }, '\n          ', props.teacher, '\n        ')) : null, props.isSpecial ? React.createElement(Link, {
            'to': '/stan-winston-school-of-character-arts',
            'key': '2220'
        }, React.createElement('p', {
            'className': this.getCSSClassesFor('content__teacher'),
            'title': 'Stan Winston School'
        }, '\n          Stan Winston School\n        ')) : null, !props.isQuickPlay ? React.createElement(Link, {
            'to': props.isSpecial ? `/special-videos/${ props.slug }` : props.isWebinar ? `/webcourses/${ props.slug }` : `/tutorials/${ props.slug }`,
            'key': '2503'
        }, React.createElement('h3', { 'className': this.getCSSClassesFor('content__title') }, props.title)) : null, React.createElement('a', {
            'href': '#',
            'onClick': () => props.onQuickPlayClick(),
            'role': 'button',
            'aria-label': `Interact to play ${ props.title }`
        }, props.isQuickPlay ? React.createElement('h3', {
            'className': this.getCSSClassesFor('content__title'),
            'key': '2963'
        }, props.title) : null), props.price !== undefined ? React.createElement('div', {
            'className': 'buy-info',
            'key': '3081'
        }, React.createElement('p', { 'className': 'buy-info__price' }, '$', props.price), React.createElement(AddToCartButton, {
            'className': 'buy-info__cta',
            'type': 'secondary',
            'url': props.purchaseUrl
        }, '\n          Add To Cart\n        ')) : null)));
    }
    return scopeProps1.apply(this, []);
}