import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { giftSubscriptionSelector as stateSelector } from 'reduxModules/subscriptions/selectors'
import { giftSubscriptionRedeemDispatcher as dispatchSelector } from 'reduxModules/subscriptions/dispatchers'
import { validatePasswordMatching as validateResetPassword } from 'reduxModules/auth/validators'

import template from './template.rt'
import './styles.css'

export class PreSignUp extends Component {
  constructor(props) {
    super(props)
    props.hideGlobalNav()
  }

  componentDidMount() {
    const { params, getDetails } = this.props

    if (params.token) getDetails(params.token)
  }

  componentWillUnmount() {
    this.props.showGlobalNav()
  }

  render() {
    return template.call(this)
  }
}

PreSignUp.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hideGlobalNav: PropTypes.func.isRequired,
  showGlobalNav: PropTypes.func.isRequired
}

export const PreSignUpWrapper = reduxForm({
  form: 'pre-sign-up',
  validate: validateResetPassword
})(PreSignUp)
export default connect(stateSelector, dispatchSelector)(PreSignUpWrapper)
