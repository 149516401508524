import * as React from 'react';
import * as _ from 'lodash';
import CartItem from './CartItem';
export default function () {
    function repeatItem1(item, itemIndex) {
        return React.createElement(CartItem, _.assign({}, {
            'key': item.id,
            'loading': this.props.loading,
            'isMobile': this.props.isMobile,
            'subscription': this.props.subscription,
            'discountPercentage': this.props.subscriptionDiscount
        }, item));
    }
    return React.createElement('article', { 'id': 'CartItemList' }, !this.props.isMobile ? React.createElement('div', {
        'className': 'header',
        'key': '93'
    }, React.createElement('div', { 'className': 'items' }, 'items'), React.createElement('div', { 'className': 'price' }, 'price'), React.createElement('div', { 'className': 'quantity' }, 'quantity'), React.createElement('div', { 'className': 'subtotal' }, 'subtotal'), React.createElement('div', { 'className': 'remove' })) : null, this.props.isMobile ? React.createElement('div', {
        'className': 'header-mobile',
        'key': '361'
    }, React.createElement('section', { 'className': 'heading' }, React.createElement('h1', {}, 'Items'))) : null, React.createElement.apply(this, [
        'section',
        { 'className': 'cart-item-list-content' },
        this.props.items ? _.map(this.props.items, repeatItem1.bind(this)) : null
    ]));
}