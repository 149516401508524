import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import VideoPlayer from '../../NewVideoPlayer';
import PlayerHeader from '../../Tutorials/TutorialPlayer/PlayerHeader';
export default function () {
    return React.createElement('article', { 'id': 'SpecialVideoPlayer' }, React.createElement('div', { 'className': 'wrapper' }, React.createElement(PlayerHeader, {
        'title': this.props.title,
        'artist': this.props.artist,
        'location': this.props.location,
        'to': `/special-videos/${ this.props.slug }`
    }), React.createElement('section', { 'id': 'player' }, React.createElement(VideoPlayer, {
        'chaptered': false,
        'videoId': 'special-video-' + this.props.id,
        'isMobile': this.props.isMobile,
        'chapters': this.buildVideo(this.props),
        'isSpecialVideo': true,
        'disableStats': true,
        'router': this.props.router,
        'slug': this.props.slug,
        'location': this.props.location,
        'savePlayerId': this.props.savePlayerId
    }))));
}