/* global __DEV__ */

import Raven from 'raven-js'

function log(level, data, extra) {
  if (!__DEV__)
    if (level === 'error') Raven.captureException(data, extra)
    else Raven.captureMessage(data, extra)
  else console[level](`[Logger] ${data}`, extra) // eslint-disable-line no-console
}

export default {
  info: (data, extra) => log('info', data, { level: 'info', extra }),
  warning: (data, extra) => log('warn', data, { level: 'warning', extra }),
  error: (data, extra) => log('error', data, { level: 'error', extra })
}
