/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { artistsStatus } from 'reduxModules/artists'
import {
  artistStateSelector as stateSelector
} from 'reduxModules/artists/selectors'
import {
  artistDispatchers as dispatchers
} from 'reduxModules/artists/dispatchers'

import {
  TutorialSummaryPropTypes,
  WebinarSummaryPropTypes
} from 'components/generic/CourseSummary/propTypes'
import BlogPostPropTypes from 'components/Blog/BlogPosts/BlogPost/propTypes'
import AssetGalleryPropTypes from 'components/generic/AssetsGallery/propTypes'

import _ from 'lodash'
import template from './template.rt'
import './styles.css'

const SUPPORTED_NETWORKS = [
  'facebook',
  'twitter',
  'instagram',
  'youtube',
  'pinterest',
  'vimeo',
  'linkedin',
  'behance',
  'deviantart',
  'flickr',
  'tumblr',
  'google',
  'imdb'
]

export class ArtistDetail extends Component {
  static getIconName(network) {
    const target = network.toLowerCase().trim()
    if (_.findIndex(SUPPORTED_NETWORKS, n => n === target) >= 0) return network

    return 'website'
  }

  constructor(props) {
    super(props)
    // TODO: set the banner value. Use assets?
    this.state = {
      banner: '../../../global/assets/images/artists/teachers-bio-bg.jpg'
    }
    this.simplifiedFirstName = this.simplifiedFirstName.bind(this)
    this.routerWillLeave = this.routerWillLeave.bind(this)
  }

  componentDidMount() {
    const {
      loadArtistInfo,
      loadArtistTutorials,
      loadArtistWebinars,
      router, 
      route
    } = this.props

    if (_.has(router, 'setRouteLeaveHook'))
      router.setRouteLeaveHook(route, this.routerWillLeave)

    loadArtistInfo()
    loadArtistTutorials()
    loadArtistWebinars()
  }

  componentWillUnmount() {
    this.props.clearArtist()
  }

  routerWillLeave(nextLocation) {
    const { triggerPendingScroll } = this.props

    if (nextLocation.pathname === '/artists') triggerPendingScroll()
    return true
  }

  simplifiedFirstName() {
    if (!this.props.firstName) return ''
    if (this.props.firstName.toLowerCase() === 'chiodo') return 'chiodo bros'
    return this.props.firstName.split(' ', 2)[0] // Remove the middlename if it exists
  }

  render() {
    if (this.props.status === artistsStatus.success) return template.call(this)
    return null
  }
}

ArtistDetail.propTypes = {
  loadArtistInfo: PropTypes.func.isRequired,
  loadArtistTutorials: PropTypes.func.isRequired,
  loadArtistWebinars: PropTypes.func.isRequired,
  clearArtist: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,

  slug: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  intro: PropTypes.string,
  photoThumbnail: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  testimonialBody: PropTypes.string,
  testimonialBy: PropTypes.string,
  testimonialByDescription: PropTypes.string,
  bio: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,

  networks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,

  tutorials: PropTypes
    .arrayOf(PropTypes.shape(TutorialSummaryPropTypes))
    .isRequired,
  webinars: PropTypes
    .arrayOf(PropTypes.shape(WebinarSummaryPropTypes))
    .isRequired,
  blogPosts: PropTypes.arrayOf(PropTypes.shape(BlogPostPropTypes)).isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape(AssetGalleryPropTypes)).isRequired
}

ArtistDetail.defaultProps = {
  slug: '',
  title: '',
  url: '',
  firstName: '',
  lastName: '',
  photoThumbnail: {
    medium: ''
  },
  tutorials: [],
  webinars: [],
  blogPosts: [],
  assets: []
}

export default connect(
  stateSelector,
  dispatchers
)(withRouter(ArtistDetail))
