import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import Video from '../generic/CourseSummary';
import BannerSlide from '../generic/BannerSlideShow/BannerSlide';
import VideoModal from '../generic/VideoModal';
export default function () {
    function scopeMetaTitleMetaDescription1(banner, videos, player) {
        var metaTitle = this.props.title || this.props.subtitle;
        var metaDescription = this.props.subtitle;
        return React.createElement(Helmet, {}, React.createElement('title', {}, metaTitle, ' | Stan Winston School of Character Arts'), React.createElement('meta', {
            'name': 'description',
            'content': metaDescription
        }), React.createElement('meta', {
            'property': 'og:title',
            'content': this.props.fbTitle || metaTitle
        }), React.createElement('meta', {
            'property': 'og:description',
            'content': this.props.fbDescription || metaDescription
        }), React.createElement('meta', {
            'content': 'article',
            'property': 'og:type'
        }));
    }
    function repeatVideo2(banner, videos, player, video, videoIndex) {
        return React.createElement(Video, {
            'className': 'video',
            'key': 'video-' + video.slug,
            'slug': video.slug,
            'teacher': video.artist.title,
            'teacherSlug': video.artist.slug,
            'title': video.title,
            'thumb': video.firstFrameImage.default,
            'isQuickPlay': true,
            'onQuickPlayClick': () => this.props.loadLeadMagnetPageVideo({
                urlHi: video.videoHi,
                urlLow: video.videoLow,
                vtt: video.vttFile,
                subtitles: video.subtitles
            })
        });
    }
    function scopeBannerVideosPlayer3() {
        var banner = this.props.banner;
        var videos = this.props.videos;
        var player = this.props.player;
        return React.createElement('div', { 'id': 'LeadMagnetPage' }, scopeMetaTitleMetaDescription1.apply(this, [
            banner,
            videos,
            player
        ]), React.createElement(BannerSlide, _.assign({}, {
            'video': banner.video,
            'displayVideo': () => {
                this.toggleVideo(banner.video);
            }
        }, banner)), React.createElement('section', { 'className': 'page-title wrapper heading' }, React.createElement('h1', {}, this.props.title)), React.createElement.apply(this, [
            'section',
            {
                'id': 'videos',
                'className': 'wrapper'
            },
            _.map(videos, repeatVideo2.bind(this, banner, videos, player))
        ]), player.isPlaying ? React.createElement(VideoModal, {
            'close': () => this.props.clearLeadMagnetPageVideo(),
            'urlHi': player.urlHi,
            'urlLow': player.urlLow,
            'subtitles': player.subtitles,
            'vtt': player.vtt,
            'visible': player.isPlaying,
            'key': '1635'
        }) : null);
    }
    return scopeBannerVideosPlayer3.apply(this, []);
}