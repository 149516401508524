import * as React from 'react';
import * as _ from 'lodash';
import Rodal from 'rodal';
import Link from 'react-router';
import Tutorial from '../../generic/CourseSummary';
export default function () {
    function repeatTutorial1(tutorial, tutorialIndex) {
        return [React.createElement('div', {
                'className': 'tutorial',
                'key': 'tutorial-' + tutorial.id,
                'onClick': () => this.props.close()
            }, React.createElement(Tutorial, {
                'slug': this.getFullSlug(tutorial.slug),
                'summary': tutorial.description,
                'teacher': tutorial.artist.title,
                'teacherSlug': tutorial.artist.slug,
                'title': tutorial.title,
                'thumb': tutorial.photo.boxThumb,
                'variant': 'darkContentText',
                'new': tutorial.new
            }))];
    }
    return React.createElement('div', { 'id': 'RelatedModal' }, this.props.visible ? React.createElement(Rodal, {
        'visible': this.props.visible,
        'onClose': () => this.props.close(),
        'width': this.props.isMobile ? 350 : 700,
        'height': 500,
        'key': '214'
    }, React.createElement('div', { 'className': 'related-modal-rodal' }, React.createElement('header', { 'className': 'header' }, React.createElement('h2', {}, this.getTitle())), React.createElement.apply(this, [
        'div',
        { 'className': 'recommend-list' },
        _.map(this.props.recommends, repeatTutorial1.bind(this))
    ]))) : null);
}