/* eslint-disable import/prefer-default-export */
import { isMobileSelector } from 'reduxModules/app/selectors'

// selectors
const contestSelector = state => state.contests.detail
const statusSelector = state => state.contests.status
const usedFreeTrialSelector = state => state.auth.usedFreeTrial

// states (mapStateToProps)
export const contestStateSelector = state => ({
  ...contestSelector(state),
  status: statusSelector(state),
  isMobile: isMobileSelector(state),
  usedFreeTrial: usedFreeTrialSelector(state)
})
