/* eslint-disable import/prefer-default-export */
import { assetsFetch } from '../assets'
import {
  openImageModal,
  closeImageModal,
  prevImageModal,
  nextImageModal
} from './'

export const dispatchOpenImageModal = (dispatch, imageIndex) =>
  dispatch(openImageModal({ imageIndex }))

export const dispatchCloseImageModal = dispatch => dispatch(closeImageModal())

export const dispatchPrevImageModal = dispatch => dispatch(prevImageModal())

export const dispatchNextImageModal = dispatch => dispatch(nextImageModal())

export default dispatch => ({
  openImageModal: imageIndex => dispatchOpenImageModal(dispatch, imageIndex),
  closeImageModal: () => dispatchCloseImageModal(dispatch),
  prevImageModal: () => dispatchPrevImageModal(dispatch),
  nextImageModal: () => dispatchNextImageModal(dispatch),
  loadAssets() {
    dispatch(assetsFetch()())
  }
})
