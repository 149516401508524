/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { pagesStatus } from 'reduxModules/pages'
import { pageStateSelector as stateSelector } from 'reduxModules/pages/selectors'
import { pageDispatchers as dispatchers } from 'reduxModules/pages/dispatchers'
import AssetGalleryPropTypes from 'components/generic/AssetsGallery/propTypes'

import template from './template.rt'

import './styles.css'

export class Page extends Component {
  componentDidMount() {
    this.props.loadPage()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const params = nextProps.params
    const oldParams = this.props.params
    if (params.slug && (!oldParams || params.slug !== oldParams.slug))
      this.props.loadPage(nextProps.params.slug)
  }

  getBody() {
    return { __html: this.props.body }
  }

  render() {
    if (this.props.status === pagesStatus.success) return template.call(this)
    return null
  }
}

Page.propTypes = {
  loadPage: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string,
  body: PropTypes.string,
  photo: PropTypes.objectOf(PropTypes.string),
  photoThumbnail: PropTypes.objectOf(PropTypes.string),
  assets: PropTypes.arrayOf(PropTypes.shape(AssetGalleryPropTypes)).isRequired,

  params: PropTypes.shape({ slug: PropTypes.string })
}

Page.defaultProps = {
  body: '',
  photo: {},
  photoThumbnail: {},
  assets: []
}

export default connect(
  stateSelector,
  dispatchers
)(Page)
