import { createStructuredSelector, createSelector } from 'reselect'
import { assetsGallerySelector } from '../assets/selectors'
import { isLoggedInSelector } from '../auth/selectors'
import {
  pendingBackScrollSelector,
  isMobileSelector,
  dimensionsSelector,
  scrollPercentageSelector
} from '../app/selectors'

// selectors
export const requestListStatusSelector = state =>
  state.blogPosts.requestListStatus
export const requestDetailStatusSelector = state =>
  state.blogPosts.requestDetailStatus
export const postSelector = state => state.blogPosts.detail
export const categoriesSelector = state => state.blogPosts.categories
export const nextBlogPostsSelector = state => state.blogPosts.next
export const featuredBlogPostSelector = state => state.blogPosts.list[0]
export const blogPostsSelector = state => state.blogPosts.list.slice(1)
export const adBannersSelector = state => state.banners.adList
export const leadMagnetDialogVisibilitySelector = state =>
  state.leadMagnetPages.dialog.displayLeadMagnetFunnelModal
export const leadMagnetDialogWasDismissedSelector = state =>
  state.leadMagnetPages.dialog.wasLeadMagnetFunnelModalDismissed
export const leadMagnetDialogWasSubmittedSelector = state =>
  state.leadMagnetPages.dialog.wasLeadMagnetFunnelModalSubmitted
const assets = state => assetsGallerySelector(state)

const BlogPostCategoriesSelector = createSelector(
  categoriesSelector,
  categories =>
    categories.map(c => ({
      name: c.name,
      slug: c.slug
    }))
)

const featuredPostSelector = createSelector(
  featuredBlogPostSelector,
  featuredBlogPost => {
    if (!featuredBlogPost) return null
    return {
      title: featuredBlogPost.title,
      description: featuredBlogPost.description,
      btn: {
        action: `/blog/${featuredBlogPost.slug}`,
        text: 'view more'
      },
      categoryName: featuredBlogPost.category.name,
      categoryLinkTo: `/blog/?category=${featuredBlogPost.category.slug}`,
      photo: featuredBlogPost.photo
    }
  }
)

// states (mapStateToProps)
export const blogPostsStateSelector = createStructuredSelector({
  blogPosts: blogPostsSelector,
  nextBlogPosts: nextBlogPostsSelector,
  featuredBlogPost: featuredPostSelector,
  categories: BlogPostCategoriesSelector,
  requestListStatus: requestListStatusSelector,
  isMobile: isMobileSelector,
  dimensions: dimensionsSelector,
  pendingBackScroll: pendingBackScrollSelector
})

export const postStateSelector = createSelector(
  [
    postSelector,
    adBannersSelector,
    requestDetailStatusSelector,
    isLoggedInSelector,
    assets,
    scrollPercentageSelector,
    isMobileSelector,
    leadMagnetDialogVisibilitySelector,
    leadMagnetDialogWasDismissedSelector,
    leadMagnetDialogWasSubmittedSelector
  ],
  (
    blogPost,
    adBanners,
    requestDetailStatus,
    isAuth,
    assets,
    scrollPercentage,
    isMobile,
    displayLeadMagnetFunnelModal,
    wasLeadMagnetFunnelModalDismissed,
    wasLeadMagnetFunnelModalSubmitted
  ) => ({
    ...blogPost,
    adBanners,
    requestDetailStatus,
    isAuth,
    assets,
    ...scrollPercentage,
    isMobile,
    displayLeadMagnetFunnelModal,
    wasLeadMagnetFunnelModalDismissed,
    wasLeadMagnetFunnelModalSubmitted
  })
)
