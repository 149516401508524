import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import SubscribeDialogCTA from '../App/SubscribeDialogCTA';
import NewSearch from '../NewSearch/index';
export default function () {
    function repeatFbImage1(metaTitle, metaDescription, fbImage, fbImageIndex) {
        return React.createElement('meta', {
            'property': 'og:image',
            'content': fbImage.original,
            'key': fbImageIndex
        });
    }
    function scopeMetaTitleMetaDescription2() {
        var metaTitle = this.props.metaTitle || this.props.title;
        var metaDescription = this.props.metaDescription || this.props.description;
        return React.createElement.apply(this, [
            Helmet,
            {},
            React.createElement('title', {}, metaTitle, ' | Stan Winston School of Character Arts'),
            React.createElement('meta', {
                'name': 'description',
                'content': metaDescription
            }),
            React.createElement('meta', {
                'property': 'og:title',
                'content': metaTitle
            }),
            React.createElement('meta', {
                'property': 'og:description',
                'content': metaDescription
            }),
            this.props.facebookShareImages.length ? _.map(this.props.facebookShareImages, repeatFbImage1.bind(this, metaTitle, metaDescription)) : null,
            React.createElement('meta', {
                'content': 'article',
                'property': 'og:type'
            })
        ]);
    }
    return React.createElement('div', { 'id': 'CourseCategoryLandingPage' }, scopeMetaTitleMetaDescription2.apply(this, []), React.createElement('section', {
        'id': 'banner',
        'style': { backgroundImage: 'url(' + (this.props.isMobile ? '' : this.props.photo.default) + ')' }
    }, React.createElement('div', { 'className': 'title-description wrapper' }, React.createElement('h1', {}, this.props.title), React.createElement('p', {}, this.props.description), !this.props.hasSubscription && !this.props.usedFreeTrial ? React.createElement(SubscribeDialogCTA, { 'key': '1129' }, React.createElement('div', { 'className': 'btn -orange' }, React.createElement('span', {}, this.props.usedFreeTrial ? 'Subscribe' : 'Start My Free Trial'))) : null)), React.createElement('section', { 'id': 'content' }, React.createElement(NewSearch, { 'embeddedFetchQuery': this.props.slug })));
}