import * as React from 'react';
import * as _ from 'lodash';
import getPlanIcon from '../../utils/getPlanIcon';
import { Link } from 'react-router';
export default function () {
    function repeatIcon1(icon, iconIndex) {
        return React.createElement('i', {
            'className': 'icon-monster-2 icon-group',
            'key': iconIndex,
            'role': 'presentation'
        });
    }
    return React.createElement('div', { 'className': 'subscription-types' }, React.createElement(Link, {
        'onClick': () => this.props.onClose(),
        'to': '/subscriptions/plans'
    }, React.createElement('div', { 'className': 'type' }, React.createElement('div', { 'className': 'icon' }, React.createElement('i', { 'className': 'icon-monster-3' })), React.createElement('span', { 'className': 'name' }, 'Individual'), React.createElement('span', {}, 'Start a FREE 7-Day Trial of any subscription plan.'))), React.createElement('a', {
        'onClick': () => this.props.onClose(),
        'href': 'https://pages.stanwinstonschool.com/group-subscription-info-request-v2-swsca/'
    }, React.createElement('div', { 'className': 'type' }, React.createElement('div', { 'className': 'icon' }, React.createElement.apply(this, [
        'div',
        { 'className': 'icons-container' },
        _.map(new Array(3), repeatIcon1.bind(this))
    ])), React.createElement('span', { 'className': 'name' }, 'Groups'), React.createElement('span', {}, 'Get a FREE Demo for your school or organization.'))));
}