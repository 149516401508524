import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { proxySSODispatchers as stateProps } from '../../redux/modules/app/dispatchers'

function ProxySSO(props) {
  useEffect(() => {
    props.proxyLoginSSODispatcher(props)
  }, [])

  return <div id="ProxySSO" />
}

export default connect(null, stateProps)(ProxySSO)
