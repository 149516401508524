import * as React from 'react';
import * as _ from 'lodash';
import PaymentMethod from './PaymentMethod';
export default function () {
    function repeatPaymentMethod1(paymentMethod, paymentMethodIndex) {
        return React.createElement(PaymentMethod, _.assign({}, {
            'key': paymentMethod.token,
            'remove': () => this.props.removePaymentMethodDispatcher(paymentMethod.token),
            'setDefault': () => this.props.markPaymentDefault(paymentMethod.token),
            'setDefaultWithConfirmation': () => this.props.markPaymentDefaultWithconfirmation(paymentMethod.token),
            'isSubscribed': this.props.isSubscribed,
            'openModal': this.props.showModal,
            'isMobile': this.props.isMobile
        }, paymentMethod));
    }
    return React.createElement.apply(this, [
        'div',
        { 'id': 'PaymentMethodList' },
        _.map(this.props.paymentMethods, repeatPaymentMethod1.bind(this))
    ]);
}