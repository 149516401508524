/* eslint-disable import/prefer-default-export */
import _ from 'lodash'
import { searchFetch, setCategory, generateCategories, clearResults } from './'

const generateAvailableCategories = (dispatch, results) => {
  if (results) {
    const categories = ['all']

    if (results?.tutorials.count > 0) categories.push('courses')
    if (results?.posts.count > 0) categories.push('posts')
    if (results?.artists.count > 0) categories.push('artists')

    dispatch(generateCategories(categories))
  }
}

export const searchDispatchers = (dispatch, props) => ({
  getResults(params) {
    const fetchParams = params || { ...props.location.query }
    if (fetchParams && typeof fetchParams.category !== 'undefined')
      delete fetchParams.category
    dispatch(searchFetch(params || fetchParams)()).then(([_, res]) =>
      generateAvailableCategories(dispatch, res.payload)
    )
  },
  selectCategory(category) {
    const searchQuery = { ...props?.location?.query, category }

    if (searchQuery.category === 'all') delete searchQuery.category
    dispatch(setCategory(category))
    window.scrollTo(0, 0)
  },
  clearResults() {
    dispatch(clearResults())
  }
})
