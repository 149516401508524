import { createSelector } from 'reselect'
import _ from 'lodash'
// selectors
export const hasSubscriptionSelector = state =>
  state.auth &&
  !_.isNull(state.auth.subscription) &&
  !_.isNull(state.auth.subscription.plan)
const showBannerSelector = state => state.subscriptions.showBannerSubscription
export const usedFreeTrialSelector = state => state.auth.usedFreeTrial

const notificationSelector = state => {
  const notification = state.notifications.globalNotification
  if (notification && !notification.level)
    Object.assign(notification, { level: 'success' })
  if (notification && !notification.position)
    Object.assign(notification, { position: 'bc' })
  return notification
}

export const dimensionsSelector = state => ({
  width: state.app.width,
  height: state.app.height
})
const hideNavSelector = state => state.app.hideNav
export const isMobileSelector = state => state.app.isMobile
export const showMobileMenuSelector = state => state.app.showMobileMenu
// states (mapStateToProps)

const authSelector = state => state.auth
const isAuthenticatedSelector = createSelector(
  authSelector,
  auth => !_.isEmpty(auth.email)
)

export const loadingSelector = state => state.app.loading
const confirmation = state => state.app.confirmation
export const confirmationSelector = createSelector(
  confirmation,
  confirmationState => {
    const c = confirmationState
    if (!confirmationState.options) return { active: false }
    return {
      active: true,
      showReject: c.showReject !== undefined ? c.showReject : true,
      componentId: c.componentId,
      ...c
    }
  }
)

export const pendingBackScrollSelector = state => state.app.pendingBackScroll

export const isFullscreenSelector = state => state.app.isFullscreen

const subscriptionSelector = state => state.auth.subscription

export const userSelector = state => {
  if (state.auth.token !== '') return _.omit(state.auth, ['token', 'status'])
  return {}
}

const showCookieNoticeSelector = state => state.navBar.showCookieNotice

export const scrollPercentageSelector = state => ({
  scrollPercentage: state.app.scrollPercentage
})

export const promotionSelector = state => ({
  promotion: state.app.promotion
})

export const recaptchaSelector = state => state.app.recaptcha

export default createSelector(
  [
    isAuthenticatedSelector,
    userSelector,
    notificationSelector,
    showBannerSelector,
    dimensionsSelector,
    hideNavSelector,
    loadingSelector,
    confirmationSelector,
    isMobileSelector,
    showMobileMenuSelector,
    isFullscreenSelector,
    hasSubscriptionSelector,
    subscriptionSelector,
    usedFreeTrialSelector,
    showCookieNoticeSelector
  ],
  (
    isAuthenticated,
    userSelector,
    globalNotification,
    showBannerSubscription,
    dimensions,
    hideNav,
    loading,
    confirmation,
    isMobile,
    showMobileMenu,
    isFullscreen,
    isSubscriber,
    subscription,
    usedFreeTrial,
    showCookieNotice
  ) => ({
    isAuthenticated,
    user: userSelector,
    globalNotification,
    showBannerSubscription,
    ...dimensions,
    hideNav,
    loading,
    confirmation,
    isMobile,
    showMobileMenu,
    isFullscreen,
    isSubscriber,
    subscription,
    usedFreeTrial,
    showCookieNotice
  })
)

export const loginBoxSelector = createSelector(
  [recaptchaSelector],
  recaptcha => ({
    recaptcha
  })
)
