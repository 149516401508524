import _ from 'lodash'

export default difficulties => {
  const difficultyMap = {
    1: 'Beginner',
    2: 'Intermediate',
    3: 'Advanced'
  }
  const amount = _.size(difficulties)

  if (amount === 1) return difficultyMap[difficulties[0]]
  if (amount === 2)
    return `${difficultyMap[difficulties[0]]}-${difficultyMap[difficulties[1]]}`
  if (amount === 3)
    return `${difficultyMap[difficulties[0]]}-${
      difficultyMap[difficulties[1]]
    }-${difficultyMap[difficulties[2]]}`
  return ''
}
