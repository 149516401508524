import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi, { getUrl, fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  ASSETS_INITIAL
  ASSETS_FETCH_SUCCESS
  ASSETS_FETCH_FAILURE
  ASSETS_SUCCESS
  ASSETS_ERROR
  ASSETS_FETCH_MORE_SUCCESS
  ASSETS_FETCH_MORE_REQUEST_FAILURE
  FACEBOOK_IMAGES_FETCH_SUCCESS
  FACEBOOK_IMAGES_FETCH_FAILURE
`)

export const assetsStatus = {
  initial: types.ASSETS_INITIAL,
  success: types.ASSETS_SUCCESS,
  error: types.ASSETS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: assetsStatus.initial,
  list: [],
  fbImages: []
}

export const successList = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.results,
  status: assetsStatus.success,
  next: action.payload.next
})

export const failureList = (state = INITIAL_STATE) => ({
  ...state,
  status: assetsStatus.error
})

export const successFBList = (state = INITIAL_STATE, action) => ({
  ...state,
  fbImages: action.payload.results.map(a => ({ original: a.image.original })),
  status: assetsStatus.success
})

export const successListMore = (state = INITIAL_STATE, action) => ({
  ...state,
  list: [...state.list, ...action.payload.results],
  next: action.payload.next,
  status: assetsStatus.success
})

export const HANDLERS = {
  [types.ASSETS_FETCH_SUCCESS]: successList,
  [types.ASSETS_FETCH_FAILURE]: failureList,
  [types.FACEBOOK_IMAGES_FETCH_SUCCESS]: successFBList,
  [types.FACEBOOK_IMAGES_FETCH_FAILURE]: failureList,
  [types.ASSETS_FETCH_MORE_SUCCESS]: successListMore,
  [types.ASSETS_FETCH_MORE_REQUEST_FAILURE]: failureList
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------
export const assetsFetchSuccess = createAction(types.ASSETS_FETCH_SUCCESS)
export const assetsFetchFailure = createAction(types.ASSETS_FETCH_FAILURE)
export const assetsFetch = (type, id, pageSize) => {
  const url = getUrl('/assets/', { type, id, page_size: pageSize })
  return fetchApi(
    url,
    { method: 'GET' },
    assetsFetchSuccess,
    assetsFetchFailure
  )
}

export const assetsFetchMoreSuccess = createAction(
  types.ASSETS_FETCH_MORE_SUCCESS
)
export const assetsFetchMoreFailure = createAction(
  types.ASSETS_FETCH_MORE_FAILURE
)
export const assetsFetchMore = next =>
  fetchHelper(
    next,
    { method: 'GET' },
    assetsFetchMoreSuccess,
    assetsFetchMoreFailure
  )

export const FBImagesFetchSuccess = createAction(
  types.FACEBOOK_IMAGES_FETCH_SUCCESS
)
export const FBImagesFetchFailure = createAction(
  types.FACEBOOK_IMAGES_FETCH_FAILURE
)
export const FBImagesFetch = page => {
  const url = getUrl('/fb-share-images/', { page })
  return fetchApi(
    url,
    { method: 'GET' },
    FBImagesFetchSuccess,
    FBImagesFetchFailure
  )
}
