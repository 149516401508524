import _ from 'lodash'

export default type => {
  const paymentsUrl = 'https://assets.braintreegateway.com/payment_method_logo'
  // src: https://www.braintreepayments.com/features/credit-cards
  const braintreeSupportedCards = [
    'visa',
    'Visa',
    'master-card',
    'MasterCard',
    'american-express',
    'American Express',
    'discover',
    'Discover',
    'jcb',
    'JCB',
    'maestro',
    'Maestro',
    'UK Maestro'
  ]
  const nameToUrlMapping = {
    visa: 'visa',
    'master-card': 'mastercard',
    'master card': 'mastercard',
    mastercard: 'mastercard',
    'american-express': 'american_express',
    'american express': 'american_express',
    discover: 'discover',
    jcb: 'jcb',
    maestro: 'maestro',
    'uk maestro': 'maestro'
  }
  const mappedName = type ? nameToUrlMapping[type.toLowerCase()] : ''
  if (mappedName && _.includes(braintreeSupportedCards, type))
    return `${paymentsUrl}/${mappedName}.png`
  return `${paymentsUrl}/unknown.png`
}
