import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'

export class StaticCategories extends Component {
  constructor(props) {
    super(props)
    this.getCategoryURL = value => `/search?q=${encodeURIComponent(value)}`
  }

  render() {
    return template.call(this)
  }
}

StaticCategories.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default StaticCategories
