import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import { isEmpty } from 'lodash';
import Tutorial from '../../generic/CourseSummary';
import BlogPosts from '../../Blog/BlogPosts';
import AssetsGallery from '../../generic/AssetsGallery/_lazy';
import FavoriteButton from '../../generic/FavoriteButton';
export default function () {
    function repeatFbImage1(props, metaTitle, metaDescription, fbImage, fbImageIndex) {
        return React.createElement('meta', {
            'property': 'og:image',
            'content': fbImage.original,
            'key': fbImageIndex
        });
    }
    function scopeMetaTitleMetaDescription2(props) {
        var metaTitle = props.metaTitle || `${ props.firstName } ${ props.lastName } biography`;
        var metaDescription = props.metaDescription || props.intro;
        return React.createElement.apply(this, [
            Helmet,
            {},
            React.createElement('title', {}, metaTitle, ' | Stan Winston School of Character Arts'),
            React.createElement('meta', {
                'name': 'description',
                'content': metaDescription
            }),
            React.createElement('meta', {
                'property': 'og:title',
                'content': metaTitle
            }),
            React.createElement('meta', {
                'property': 'og:description',
                'content': metaDescription
            }),
            !props.facebookShareImages.length ? React.createElement('meta', {
                'property': 'og:image',
                'content': props.photoThumbnail.original,
                'key': '929'
            }) : null,
            props.facebookShareImages.length ? _.map(props.facebookShareImages, repeatFbImage1.bind(this, props, metaTitle, metaDescription)) : null,
            React.createElement('meta', {
                'property': 'og:type',
                'content': 'profile'
            })
        ]);
    }
    function scopeIconName3(props, network, networkIndex) {
        var iconName = this.constructor.getIconName(network.name);
        return React.createElement('a', {
            'className': iconName,
            'title': network.name,
            'href': network.url,
            'target': '_blank',
            'key': '19651'
        }, React.createElement('i', { 'className': 'icon icon-' + iconName }), React.createElement('span', {}, network.name));
    }
    function repeatNetwork4(props, network, networkIndex) {
        return [scopeIconName3.apply(this, [
                props,
                network,
                networkIndex
            ])];
    }
    function repeatWebinar5(props, webinar, webinarIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'webinar-' + webinar.id,
            'slug': webinar.slug,
            'summary': webinar.tagline,
            'teacher': webinar.artist.title,
            'teacherSlug': webinar.artist.slug,
            'title': webinar.title,
            'thumb': webinar.listingPhoto.boxThumb,
            'isWebinar': true
        });
    }
    function repeatTutorial6(props, tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'title': tutorial.title,
            'thumb': tutorial.photo.boxThumb,
            'new': tutorial.new
        });
    }
    function scopeProps7() {
        var props = this.props;
        return React.createElement('div', { 'id': 'ArtistDetail' }, scopeMetaTitleMetaDescription2.apply(this, [props]), this.state.banner && props.firstName && props.lastName ? React.createElement('section', {
            'id': 'header',
            'key': '1277'
        }, React.createElement('img', {
            'src': this.state.banner,
            'alt': `${ props.firstName } ${ props.lastName }`
        })) : null, React.createElement('div', { 'className': 'wrapper main-about-holder' }, React.createElement('section', {
            'id': 'main',
            'className': _.transform({ '-push': !this.state.banner }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, props.firstName && props.lastName ? React.createElement('div', {
            'className': 'photo',
            'key': '1581'
        }, React.createElement('img', {
            'src': props.photoThumbnail.medium,
            'role': 'presentation',
            'alt': `${ props.firstName } ${ props.lastName }`
        })) : null, React.createElement('h1', {}, props.title), props.intro ? React.createElement('div', {
            'className': 'intro',
            'key': '1813'
        }, props.intro) : null, !isEmpty(props.networks) ? React.createElement.apply(this, [
            'div',
            {
                'className': 'social',
                'key': '1880'
            },
            React.createElement('h3', {}, 'Links'),
            _.map(props.networks, repeatNetwork4.bind(this, props))
        ]) : null, props.testimonialBody ? React.createElement('hr', { 'key': '2331' }) : null, props.testimonialBody ? React.createElement('div', {
            'className': 'testimonial',
            'key': '2375'
        }, React.createElement('div', { 'className': 'body quoted' }, props.testimonialBody), React.createElement('div', { 'className': 'by' }, props.testimonialBy), props.testimonialByDescription ? React.createElement('div', {
            'className': 'byDescription',
            'key': '2558'
        }, props.testimonialByDescription) : null) : null), React.createElement('section', {
            'id': 'about',
            'className': _.transform({
                wrapper: true,
                '-push': !this.state.banner
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement(FavoriteButton, {
            'favorited': props.favorited,
            'url': props.url
        }), React.createElement('div', { 'className': 'bio' }, React.createElement('h2', {}, 'About ', this.simplifiedFirstName()), props.bio ? React.createElement('div', {
            'className': 'bio-content formatted',
            'dangerouslySetInnerHTML': { __html: props.bio },
            'key': '2930'
        }) : null))), props.assets && props.assets.length ? React.createElement(AssetsGallery, {
            'assets': props.assets,
            'key': '3118'
        }) : null, React.createElement('div', { 'className': 'wrapper' }, props.tutorials.length ? React.createElement('section', {
            'id': 'courses',
            'key': '3230'
        }, React.createElement('h2', { 'className': 'title-division' }, 'Courses by ', props.title), React.createElement.apply(this, [
            'div',
            { 'className': 'tutorials-list-content' },
            _.map(this.props.webinars, repeatWebinar5.bind(this, props)),
            _.map(this.props.tutorials, repeatTutorial6.bind(this, props))
        ])) : null, props.blogPosts.length ? React.createElement('section', {
            'id': 'posts',
            'key': '4269'
        }, React.createElement('h2', { 'className': 'title-division' }, 'Related Posts'), React.createElement(BlogPosts, { 'blogPosts': props.blogPosts })) : null));
    }
    return scopeProps7.apply(this, []);
}