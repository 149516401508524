import React from 'react'
import LoadingComponent from 'components/generic/LoadingComponent'
import LazyLoad from 'react-lazyload'

const LazyWrapper = props => (
  <LazyLoad height="{'50vh'}" offset={50} placeholder={<LoadingComponent />}>
    {props.children}
  </LazyLoad>
)

export default LazyWrapper
