import { createSelector } from 'reselect'
import _ from 'lodash'
import { subscriptionSelector } from 'reduxModules/auth/selectors'

// selectors
export const hijackingSelector = state =>
  _.isString(state.auth.hijackerToken) && _.size(state.auth.hijackerToken) > 0
export const canHijackSelector = state =>
  state.auth.isStaff || state.auth.isSuperuser

export default createSelector(
  [hijackingSelector, canHijackSelector, subscriptionSelector],
  (hijacking, canHijack, subscription) => ({
    hijacking,
    canHijack,
    subscription
  })
)
