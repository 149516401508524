import React, { useEffect } from 'react'

import Helmet from 'react-helmet'
import _ from 'lodash'
import { connect } from 'react-redux'

import { searchStateSelector as stateSelector } from 'reduxModules/newSearch/selectors'
import { searchDispatchers as dispatchers } from 'reduxModules/newSearch/dispatchers'

import SearchCategories from './SearchCategories'
import Tutorial from '../generic/CourseSummary'
import Teacher from '../generic/ProfileSummary'
import BlogPost from '../Blog/BlogPosts/BlogPost/_lazy'
import getArtistTagline from '../utils/getArtistTagline'

import './styles.css'

const NUMBER_OF_RESULTS = 16

export function NewSearch(props) {
  const {
    results,
    location,
    active,
    selectCategory,
    loading,
    categories,
    clearResults,
    embeddedFetchQuery
  } = props

  const { tutorials, posts, artists } = results
  const resultsAvailable =
    tutorials?.count > 0 || posts?.count > 0 || artists?.count > 0

  useEffect(() => {
    const { getResults, selectCategory, location, embeddedFetchQuery } = props

    // Pass embedded search query if any (tutorial_categories case), if not, fetch directly from location query
    if (embeddedFetchQuery) getResults({ q: embeddedFetchQuery })
    else getResults()

    if (location?.query?.category) selectCategory(location.query.category)

    return function cleanup() {
      clearResults()
    }
  }, [location?.search, embeddedFetchQuery])

  return (
    <div id="NewSearch">
      {!embeddedFetchQuery && (
        <Helmet>
          <title>{`Search results for "${location?.query.q}" | Stan Winston School of Character Arts`}</title>
          <meta
            property="og:title"
            content={`Results for "${location?.query.q}"`}
          />
          <meta content="article" property="og:type" />
        </Helmet>
      )}
      {!embeddedFetchQuery && (
        <header className="header">
          <h3 className="header__title wrapper">
            Results for &quot;{location?.query.q}&quot;
          </h3>
        </header>
      )}

      {resultsAvailable ? (
        <SearchCategories
          categories={categories}
          active={active}
          select={selectCategory}
        />
      ) : null}

      {resultsAvailable ? (
        <div className="all-results wrapper">
          {active === 'all' || active === 'courses' ? (
            <div className="courses-results results">
              {tutorials.count ? (
                <h2 className="results__title">courses</h2>
              ) : null}
              <div className="results__content">
                {tutorials.results
                  .slice(
                    0,
                    active === 'all'
                      ? NUMBER_OF_RESULTS
                      : tutorials.results.length
                  )
                  .map(course => (
                    <Tutorial
                      className="tutorial"
                      isWebinar={course.type === 'webinar'}
                      key={`tutorial-${course.id}`}
                      slug={course.slug}
                      summary={course?.description}
                      teacher={course?.artist?.fullName}
                      teacherSlug={course?.artist?.slug}
                      title={course?.title}
                      thumb={course?.photo?.boxThumb}
                      new={course?.new}
                    />
                  ))}
              </div>
              <div className="view-more">
                {active === 'all' && tutorials.count > NUMBER_OF_RESULTS ? (
                  <button
                    type="button"
                    onClick={() => selectCategory('courses')}
                  >
                    <i className="icon-plus" />
                    view more
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}

          {active === 'all' || active === 'posts' ? (
            <div className="posts-results results">
              {posts.count > 0 ? (
                <h2 className="results__title">posts</h2>
              ) : null}
              <div className="results__content">
                {posts.results
                  .slice(
                    0,
                    active === 'all' ? NUMBER_OF_RESULTS : posts.results.length
                  )
                  .map(blogPost => (
                    <BlogPost
                      key={blogPost.id}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...blogPost}
                      showSocial={false}
                    />
                  ))}
              </div>
              <div className="view-more">
                {active === 'all' && posts.count > NUMBER_OF_RESULTS ? (
                  <button type="button" onClick={() => selectCategory('posts')}>
                    <i className="icon-plus" />
                    view more
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}

          {active === 'all' || active === 'artists' ? (
            <div className="artists-results results">
              {artists.count > 0 ? (
                <h2 className="results__title">teachers</h2>
              ) : null}
              <div className="results__content">
                {artists.results
                  .slice(
                    0,
                    active === 'all'
                      ? NUMBER_OF_RESULTS
                      : artists.results.length
                  )
                  .map(teacher => (
                    <Teacher
                      className="artist"
                      key={`teacher-${teacher.id}`}
                      name={teacher?.title}
                      photo={teacher?.photo?.boxThumb}
                      tagline={getArtistTagline(teacher.tags)}
                      slug={teacher?.slug}
                    />
                  ))}
              </div>
              <div className="view-more">
                {active === 'all' && artists.count > NUMBER_OF_RESULTS ? (
                  <button
                    type="button"
                    onClick={() => selectCategory('teachers')}
                  >
                    <i className="icon-plus" />
                    view more
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {!resultsAvailable ? (
        <div className="no-results">
          {loading ? (
            <div className="spinner">
              <div className="double-bounce1" />
              <div className="double-bounce2" />
            </div>
          ) : (
            <div>
              No results found
              {location?.query?.text ? (
                <span> for &quot;{location.query.text}&quot;</span>
              ) : null}
              .
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default connect(stateSelector, dispatchers)(NewSearch)
