import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi, { getUrl } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  HOME_INITIAL
  HOME_FETCH_SUCCESS
  HOME_REQUEST_FAILURE
  HOME_SUCCESS
  HOME_ERROR
  CLEAR_HOME_SUCCESS
  HOME_CURRENT_SUCCESS
  HOME_CURRENT_FAILURE
  HOME_CURRENT_MORE_SUCCESS
  HOME_CURRENT_MORE_FAILURE
`)

export const homeStatus = {
  initial: types.HOME_INITIAL,
  success: types.HOME_SUCCESS,
  error: types.HOME_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: homeStatus.initial,
  banners: [],
  categoryImages: [],
  currentlyWatching: {
    results: [],
    count: 0,
    next: '',
    prev: ''
  },
  metaInformation: []
}
export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload,
  status: homeStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: homeStatus.error
})

export const clearHomeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE
})

export const fetchCurrent = (state = INITIAL_STATE, action) => ({
  ...state,
  currentlyWatching: action.payload
})

export const fetchCurrentMore = (state = INITIAL_STATE, action) => ({
  ...state,
  currentlyWatching: {
    ...state.currentlyWatching,
    ...action.payload,
    results: [...state.currentlyWatching.results, ...action.payload.results]
  }
})

export const HANDLERS = {
  [types.HOME_FETCH_SUCCESS]: success,
  [types.HOME_REQUEST_FAILURE]: failure,
  [types.CLEAR_HOME_SUCCESS]: clearHomeSuccess,
  [types.HOME_CURRENT_SUCCESS]: fetchCurrent,
  [types.HOME_CURRENT_MORE_SUCCESS]: fetchCurrentMore
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const homeFetchSuccess = createAction(types.HOME_FETCH_SUCCESS)
export const homeFetchFailure = createAction(types.HOME_REQUEST_FAILURE)
export const homeFetch = fetchApi(
  '/home/',
  { method: 'GET' },
  homeFetchSuccess,
  homeFetchFailure
)

export const homeFetchCurrentSuccess = createAction(types.HOME_CURRENT_SUCCESS)
export const homeFetchCurrentFailure = createAction(types.HOME_CURRENT_FAILURE)
export const homeFetchCurrent = filters => {
  const url = getUrl('/bookmarks/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    homeFetchCurrentSuccess,
    homeFetchCurrentFailure
  )
}

export const homeFetchCurrentMoreSuccess = createAction(
  types.HOME_CURRENT_MORE_SUCCESS
)
export const homeFetchCurrentMoreFailure = createAction(
  types.HOME_CURRENT_MORE_FAILURE
)
export const homeFetchCurrentMore = filters => {
  const url = getUrl('/bookmarks/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    homeFetchCurrentMoreSuccess,
    homeFetchCurrentMoreFailure
  )
}

export const clearHome = createAction(types.CLEAR_HOME_SUCCESS)
