import PropTypes from 'prop-types'
import React from 'react'

const FieldError = ({ type, show }) => {
  if (show) return <div className="error">{`Invalid ${type}`}</div>
  return null
}

FieldError.propTypes = {
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
}

export default FieldError
