/* global API_AUTH_ROOT */
import { createAction } from 'redux-actions'
import { createTypes, createReducer } from 'reduxsauce'
import { fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  VERIFY_REQUEST_SUCCESS
  VERIFY_REQUEST_FAILURE
  RESEND_EMAIL_SUCCESS
  RESEND_EMAIL_FAILURE
  ENABLE_RESEND_EMAIL_CTA
  DISABLE_RESEND_EMAIL_CTA
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  isResendCTADisabled: false
}

const enableResendEmailCtaHandler = (state = INITIAL_STATE) => ({
  ...state,
  isResendCTADisabled: false
})

const disableResendEmailCtaHandler = (state = INITIAL_STATE) => ({
  ...state,
  isResendCTADisabled: true
})

export const HANDLERS = {
  [types.ENABLE_RESEND_EMAIL_CTA]: enableResendEmailCtaHandler,
  [types.DISABLE_RESEND_EMAIL_CTA]: disableResendEmailCtaHandler
}

export default createReducer(INITIAL_STATE, HANDLERS)
//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const verifyRequestSuccess = createAction(types.VERIFY_REQUEST_SUCCESS)
export const verifyRequestFailure = createAction(types.VERIFY_REQUEST_FAILURE)

export const verifyRequest = fetchHelper(
  `${API_AUTH_ROOT}/registration/verify-email/`,
  {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  },
  verifyRequestSuccess,
  verifyRequestFailure
)

export const resendEmailSuccess = createAction(types.RESEND_EMAIL_SUCCESS)
export const resendEmailFailure = createAction(types.RESEND_EMAIL_FAILURE)
export const resendEmail = fetchHelper(
  `${API_AUTH_ROOT}/registration/resend-email/`,
  {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  },
  resendEmailSuccess,
  resendEmailFailure
)

export const enableResendEmailCTA = createAction(types.ENABLE_RESEND_EMAIL_CTA)
export const disableResendEmailCTA = createAction(
  types.DISABLE_RESEND_EMAIL_CTA
)
