import _ from 'lodash'

import { tutorialsSelector } from '../tutorials/selectors'
import { isLoggedInSelector } from '../auth/selectors'
import { webinarsSelector } from '../webinars/selectors'
import { blogPostsSelector } from '../blogPosts/selectors'
import { assetsGallerySelector } from '../assets/selectors'
import { pendingBackScrollSelector} from '../app/selectors'

// selectors
const artistsSelector = state => state.artists.list
const artistSelector = state => state.artists.detail
const statusSelector = state => state.artists.status
const nextArtistsSelector = state => state.artists.next

// states (mapStateToProps)
export const artistsStateSelector = state => ({
  artists: artistsSelector(state),
  nextArtists: nextArtistsSelector(state),
  status: statusSelector(state),
  pendingBackScroll: pendingBackScrollSelector(state)
})

export const artistStateSelector = state => ({
  ...artistSelector(state),
  isAuth: isLoggedInSelector(state),
  tutorials: tutorialsSelector(state),
  webinars: webinarsSelector(state),
  blogPosts: blogPostsSelector(state),
  assets: assetsGallerySelector(state),
  status: statusSelector(state)
})
