import * as React from 'react';
import * as _ from 'lodash';
import { HostedField } from 'react-braintree-fields';
import { Braintree } from 'react-braintree-fields';
import FieldError from './FieldError';
import getCardIcon from '../../utils/getCardIcon';
import keyUpHandler from '../../utils/keyUpHandler';
import { SLATE } from '../../../global/assets/styles/vars';
export default function () {
    function onKeyUp1(event) {
        keyUpHandler(event, this.props.choosePayWithCreditCard);
    }
    function onKeyUp2(event) {
        keyUpHandler(event, this.props.toggleStorePayment);
    }
    function onClick3() {
        this.props.toggleStorePayment();
    }
    function onKeyUp4(event) {
        keyUpHandler(event, this.props.choosePayWithPaypal);
    }
    return React.createElement('div', { 'id': 'PaymentMethodForm' }, React.createElement('div', {}, React.createElement('div', {
        'className': _.transform({ selected: this.props.payWithCreditCard }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ') + ' ' + 'form',
        'aria-label': 'Choose to pay with credit card',
        'onClick': () => this.props.choosePayWithCreditCard(),
        'onKeyUp': onKeyUp1.bind(this),
        'tabIndex': '0'
    }, this.props.enablePaypal ? React.createElement('div', {
        'className': 'pay-with-card',
        'key': '786'
    }, React.createElement('input', {
        'aria-label': 'Pay with credit card',
        'checked': this.props.payWithCreditCard,
        'name': 'paypal',
        'type': 'radio'
    }), React.createElement('span', {}, 'Credit Card')) : null, React.createElement('div', { 'className': 'card-type' }, React.createElement('img', {
        'src': getCardIcon(this.props.type),
        'alt': this.props.type
    })), React.createElement(Braintree, {
        'authorization': this.props.clientToken,
        'getTokenRef': t => this.props.storeTokenizer(t),
        'onCardTypeChange': this.props.cardTypeChanged,
        'styles': { 'input': { 'color': SLATE } }
    }, React.createElement('div', { 'className': 'payment-container' }, React.createElement('div', { 'className': 'payment-fields' }, React.createElement('div', {}, React.createElement(HostedField, {
        'type': 'number',
        'className': 'hosted-field number',
        'placeholder': '1234 1234 1234 1234'
    }), React.createElement(FieldError, {
        'show': _.includes(this.props.invalidFields, 'number'),
        'type': 'credit card number'
    })), React.createElement('div', { 'className': 'mobile-grouping' }, React.createElement(HostedField, {
        'type': 'expirationDate',
        'className': 'hosted-field expiration-date',
        'placeholder': 'MM / YY'
    }), React.createElement(FieldError, {
        'show': _.includes(this.props.invalidFields, 'expirationDate'),
        'type': 'expiration date'
    }), React.createElement(HostedField, {
        'type': 'cvv',
        'className': 'hosted-field cvv',
        'placeholder': 'Security Code'
    }), React.createElement(FieldError, {
        'show': _.includes(this.props.invalidFields, 'cvv'),
        'type': 'cvv'
    }))), this.props.enableStoreMethodOption ? React.createElement('div', {
        'className': 'optional-saving',
        'key': '2809'
    }, React.createElement('input', {
        'id': 'save-payment-method-check',
        'type': 'checkbox',
        'value': this.props.storeMethod,
        'checked': this.props.storeMethod,
        'onChange': () => this.props.toggleStorePayment()
    }), React.createElement('label', {
        'tabIndex': '0',
        'onKeyUp': onKeyUp2.bind(this),
        'htmlFor': 'save-payment-method-check',
        'onClick': onClick3.bind(this)
    }, '\n                Save new payment method\n              ')) : null))), this.props.enablePaypal ? React.createElement('div', {
        'className': _.transform({ selected: !this.props.payWithCreditCard }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ') + ' ' + 'paypal',
        'aria-label': 'Choose to pay with PayPal',
        'onClick': () => this.props.choosePayWithPaypal(),
        'onKeyUp': onKeyUp4.bind(this),
        'tabIndex': '0',
        'key': '3571'
    }, React.createElement('div', { 'className': 'inner-container' }, React.createElement('div', { 'className': 'option' }, React.createElement('input', {
        'aria-label': 'Pay with Paypal',
        'checked': !this.props.payWithCreditCard,
        'name': 'paypal',
        'type': 'radio'
    }), React.createElement('span', {}, 'PayPal')), React.createElement('div', { 'className': 'icon' }, React.createElement('img', {
        'src': 'https://assets.braintreegateway.com/payment_method_logo/paypal.png',
        'alt': 'paypal'
    })))) : null, React.createElement('div', { 'className': 'buttons-container' }, React.createElement('div', { 'className': 'secure-payment' }, React.createElement('img', {
        'src': '/global/assets/images/icons/securePayment.svg',
        'className': 'secure-payment-icon',
        'alt': 'secure payment'
    }), React.createElement('span', {}, 'secure payment')), '        \n        ', this.props.children, '\n      ')));
}