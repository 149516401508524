import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'
import './styles.css'

export class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true
    }
    this.toggleCollapsing = this.toggleCollapsing.bind(this)
  }

  toggleCollapsing() {
    this.setState({ collapsed: !this.state.collapsed })
  }

  render() {
    return template.call(this)
  }
}

Sidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  active: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    free: PropTypes.bool,
    rank: PropTypes.number,
    plan: PropTypes.string
  }),
  name: PropTypes.string.isRequired
}

Sidebar.defaultProps = {
  subscription: null
}

export default Sidebar
