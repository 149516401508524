import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { userProfileReselector as stateSelector } from 'reduxModules/users/selectors'
import { profileDispatchers as dispatchSelector } from 'reduxModules/users/dispatchers'
import { connect } from 'react-redux'
import { validators } from '../../../redux/modules/utils/forms'
import RenderField from '../../generic/RenderedField'

function Profile(props) {
  useEffect(() => {
    props.loadUserProfile()
  }, [])
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="input-group">
        <Field
          name="firstName"
          validate={validators.required}
          component={RenderField}
          type="text"
          placeholder="First Name"
          props={{ ariaLabel: 'First Name' }}
        />
      </div>
      <div className="input-group">
        <Field
          name="lastName"
          validate={validators.required}
          component={RenderField}
          type="text"
          placeholder="Last Name"
          props={{ ariaLabel: 'Last Name' }}
        />
      </div>
      <div
        className="buttons"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <button
          className="btn -mini -blue-2"
          type="button"
          onClick={() => props.cancel()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="submit-btn btn -orange -mini"
          disabled={props.pristine || props.submitting}
          style={{ width: 120, marginLeft: 20 }}
        >
          Save changes
        </button>
      </div>
    </form>
  )
}

Profile.propTypes = {
  loadUserProfile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.string).isRequired
}

export const ProfileWrapper = reduxForm({ form: 'profile' })(Profile)
export default connect(stateSelector, dispatchSelector)(ProfileWrapper)
