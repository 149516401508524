import { createStructuredSelector, createSelector } from 'reselect'
import { loadingSelector, isMobileSelector } from 'reduxModules/app/selectors'
import {
  subscriptionSelector,
  isLoggedInSelector
} from 'reduxModules/auth/selectors'
import math from 'reduxModules/utils/math'
import { subscriptionPlansSelector } from 'reduxModules/subscriptions/selectors'

export const cartItemsSelector = state => state.cart.list
export const cartItemsCountSelector = state => state.cart.itemsCount
export const cartItemAddedSelector = state => state.cart.cartItemAdded
export const validatedDiscountCode = state => state.discountCode.validatedCode
export const subscriptionDiscountSelector = state =>
  state.cart.subscriptionDiscount
export const relatedItemsSelector = state => state.cart.related

export const subtotalSelector = createSelector(
  cartItemsSelector,
  items =>
    math.toFixed(
      parseFloat(
        items.reduce((acc, item) => acc + item.price * item.quantity, 0)
      )
    )
)
export const discountSelector = createSelector(
  validatedDiscountCode,
  ({ discount }) => {
    if (discount) return math.toFixed(discount)
    return 0
  }
)
export const totalSelector = createSelector(
  subtotalSelector,
  discountSelector,
  (subtotal, discount) => {
    if (discount) return (math.toFixed(subtotal) - discount).toFixed(2)
    return subtotal
  }
)

// states (mapStateToProps)
export default createStructuredSelector({
  cartItems: cartItemsSelector,
  count: cartItemsCountSelector,
  subtotal: subtotalSelector,
  total: totalSelector,
  loading: loadingSelector,
  validatedDiscountCode,
  isMobile: isMobileSelector,
  subscription: subscriptionSelector,
  plans: subscriptionPlansSelector,
  subscriptionDiscount: subscriptionDiscountSelector,
  related: relatedItemsSelector,
  isLoggedIn: isLoggedInSelector
})
