/* eslint-disable import/prefer-default-export */
import { isMobileSelector } from 'reduxModules/app/selectors'

// selectors
const leadMagnetPageSelector = state => state.leadMagnetPages.detail
const statusSelector = state => state.leadMagnetPages.status
const playerSelector = state => state.leadMagnetPages.player
const funnelFormSelector = state => state.leadMagnetPages.form

// states (mapStateToProps)
export const leadMagnetPageStateSelector = state => ({
  ...leadMagnetPageSelector(state),
  status: statusSelector(state),
  isMobile: isMobileSelector(state),
  player: playerSelector(state),
  formValues: funnelFormSelector(state)
})
