import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/school/selectors'
import { schoolDispatchers as dispatchers } from 'reduxModules/school/dispatchers'

import template from './template.rt'
import './styles.css'

export class School extends Component {
  constructor(props) {
    super(props)
    this.state = { showVideo: false }
    this.showVideo = this.showVideo.bind(this)
  }

  componentDidMount() {
    this.props.loadOscarArtists()
    this.props.loadRandomTestimonial()
  }

  showVideo() {
    this.setState({ showVideo: true })
  }

  closeVideo() {
    this.setState({ showVideo: false })
  }

  render() {
    return template.call(this)
  }
}

School.propTypes = {
  artists: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      photo: PropTypes.shape({
        boxThumb: PropTypes.string
      }).isRequired,
      intro: PropTypes.string
    })
  ).isRequired,
  testimonial: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,

  /* Dispatchers */
  loadOscarArtists: PropTypes.func.isRequired,
  loadRandomTestimonial: PropTypes.func.isRequired
}

export default connect(
  stateSelector,
  dispatchers
)(School)
