/* eslint-disable import/prefer-default-export */

import { reset } from 'redux-form'
import { clearRecaptchaToken } from 'reduxModules/app'
import { captchaRequest, contactRequest, types } from './'
import notifications, {
  toastError,
  toastSuccess
} from '../../../global/notifications'

export const dispatchContactRequest = (dispatch, data) =>
  dispatch(contactRequest(data))

export const dispatchCaptchaRequest = (dispatch, data) =>
  dispatch(captchaRequest(data))

export const onApprovedSubmit = (dispatch, data) => {
  dispatchContactRequest(dispatch, data).then(([_, res]) => {
    if (res.type === types.CONTACT_REQUEST_SUCCESS) {
      toastSuccess(notifications.messageSent)
      dispatch(reset('contact-us'))
    } else toastError(notifications.messageSent)
  })
}

export default dispatch => ({
  verifyRecaptcha: (data, callbackFunction) =>
    dispatchCaptchaRequest(dispatch, {
      token: data?.recaptcha.token,
      version: data?.recaptcha.version
    })
      .then(([_, res]) => {
        if (res.type === types.CAPTCHA_REQUEST_SUCCESS)
          callbackFunction(dispatch, data)
        else toastError(notifications.captchaVerification)
      })
      .catch(() => toastError(notifications.captchaVerification))
      .finally(() => dispatch(clearRecaptchaToken()))
})
