import { Component } from 'react'

import template from './template.rt'
import './styles.css'

class BookmarkedTutorial extends Component {
  constructor(props) {
    super(props)
    this.state = { visible: false }
  }

  toggle() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    return template.call(this)
  }
}

export default BookmarkedTutorial
