import * as React from 'react';
import * as _ from 'lodash';
import BlogPost from './BlogPost/_lazy';
import LoadMore from '../../generic/LoadMore';
import { blogPostsFetchMore } from '../../../redux/modules/blogPosts';
export default function () {
    function repeatBlogPost1(blogPost, idx) {
        return React.createElement(BlogPost, _.assign({}, {
            'key': idx,
            'elementName': 'posts-' + idx,
            'showSocial': this.props.showSocial
        }, blogPost));
    }
    return React.createElement('div', { 'id': 'BlogPosts' }, React.createElement.apply(this, [
        'div',
        { 'className': 'blog-posts-content' },
        _.map(this.props.blogPosts, repeatBlogPost1.bind(this))
    ]), this.props.next ? React.createElement('div', {
        'className': 'load-more',
        'key': '480'
    }, React.createElement(LoadMore, {
        'fetchMoreAction': blogPostsFetchMore,
        'next': this.props.next
    })) : null);
}