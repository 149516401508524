import * as React from 'react';
import * as _ from 'lodash';
import LoadMore from '../../generic/LoadMore';
import { tutorialsFetchMore } from '../../../redux/modules/tutorials';
import { tutorialsStatus } from '../../../redux/modules/tutorials';
import { categories as filterCategories } from './filters';
import { difficulties as filterDifficulties } from './filters';
import { ordering as orderingOptions } from './filters';
import TutorialListContent from './TutorialListContent';
import BannerSlideShow from '../../generic/BannerSlideShow';
import keyUpHandler from '../../utils/keyUpHandler';
export default function () {
    function onKeyUp1(props, event) {
        keyUpHandler(event, this.clearSearch, null, this);
    }
    function repeatDifficulty2(props, difficulty, difficultyIndex) {
        return React.createElement('option', {
            'value': difficulty.value,
            'key': difficultyIndex
        }, '\n          ', difficulty.name, '\n        ');
    }
    function repeatCategory3(props, category, categoryIndex) {
        return React.createElement('option', {
            'value': category,
            'key': categoryIndex
        }, category);
    }
    function onKeyUp4(props, event) {
        keyUpHandler(event, this.clearFilters, null, this);
    }
    function scopeProps5() {
        var props = this.props;
        return React.createElement('article', { 'id': 'TutorialList' }, props.banners ? React.createElement(BannerSlideShow, {
            'banners': props.banners,
            'isMobile': this.props.isMobile,
            'key': '747'
        }) : null, React.createElement('section', { 'className': 'heading wrapper' }, React.createElement('div', { 'className': 'search-box search-box-mobile' }, React.createElement('form', {
            'action': '.',
            'onSubmit': e => {
                e.preventDefault();
            }
        }, React.createElement('i', { 'className': 'icon-search' }), React.createElement('input', {
            'aria-label': 'Search Courses',
            'type': 'search',
            'name': 'search-input',
            'onChange': this.onChange,
            'value': this.state.search,
            'placeholder': 'Search Courses'
        }))), this.props.banners ? React.createElement('h2', { 'key': '1313' }, 'Courses') : null, !this.props.banners ? React.createElement('h1', { 'key': '1361' }, 'Courses') : null, React.createElement('span', {
            'className': 'filter-toggle',
            'onClick': () => this.props.toggleFilters()
        }, '\n      Filters ', React.createElement('i', {
            'className': _.transform({
                'icon-right-2': true,
                rotate: props.showFilters
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        })), !_.isEmpty(props.filters) ? React.createElement('span', {
            'className': 'mobile-clear-filters',
            'onClick': () => this.clearFilters(),
            'key': '1583'
        }, '\n      Clear\n      ', React.createElement('i', { 'className': 'remove-filters icon-close' })) : null, !props.showFilters ? React.createElement('div', {
            'className': 'search-box search-box-bar',
            'key': '1763'
        }, React.createElement('i', { 'className': 'icon-search' }), React.createElement('form', {
            'action': '.',
            'onSubmit': e => {
                e.preventDefault();
            }
        }, React.createElement('input', {
            'aria-label': 'Search Courses',
            'type': 'search',
            'name': 'search-input',
            'onChange': this.onChange,
            'value': this.state.search,
            'placeholder': 'Search Courses'
        })), this.state.search.length > 0 ? React.createElement('i', {
            'aria-label': 'Clear search on course list',
            'className': 'clear-search icon-close',
            'onClick': () => this.clearSearch(),
            'onKeyUp': onKeyUp1.bind(this, props),
            'tabIndex': '0',
            'key': '2181'
        }) : null) : null, React.createElement('div', {
            'className': _.transform({
                filters: true,
                expanded: props.showFilters
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement.apply(this, [
            'select',
            {
                'aria-label': 'Difficulty',
                'className': 'difficulty-select',
                'onChange': e => this.onSelectFilter('difficulty', e.target.value),
                'value': props.filters.difficulty ? props.filters.difficulty : '-1'
            },
            React.createElement('option', { 'value': '-1' }, 'Difficulty'),
            _.map(filterDifficulties, repeatDifficulty2.bind(this, props))
        ]), React.createElement.apply(this, [
            'select',
            {
                'aria-label': 'Category',
                'className': 'category-select',
                'onChange': e => this.onSelectFilter('tags', e.target.value),
                'value': props.filters.tags ? props.filters.tags : '-1'
            },
            React.createElement('option', { 'value': '-1' }, 'Category'),
            _.map(filterCategories, repeatCategory3.bind(this, props))
        ]), React.createElement('div', { 'className': 'filters-close-container' }, (props.filters.tags || props.filters.difficulty) && !props.isMobile ? React.createElement('span', {
            'aria-label': 'Remove filters on course list',
            'className': 'remove-filters',
            'onClick': () => this.clearFilters(),
            'onKeyUp': onKeyUp4.bind(this, props),
            'tabIndex': '0',
            'key': '3460'
        }, 'Clear filters') : null))), React.createElement(TutorialListContent, {
            'specialVideos': this.props.specialVideos,
            'webinars': this.props.webinars,
            'tutorials': this.props.tutorials,
            'requestStatus': this.props.requestStatus,
            'filters': this.props.filters
        }), this.props.tutorials.length === 0 && !_.isEmpty(this.props.filters) && this.props.requestStatus === tutorialsStatus.success ? React.createElement('div', {
            'className': 'no-results',
            'key': '4089'
        }, '\n    No results\n  ') : null, this.props.nextTutorials ? React.createElement('div', {
            'className': 'load-more wrapper',
            'key': '4296'
        }, React.createElement(LoadMore, {
            'fetchMoreAction': tutorialsFetchMore,
            'next': this.props.nextTutorials,
            'listContainerId': 'TutorialList'
        })) : null);
    }
    return scopeProps5.apply(this, []);
}