import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { types as checkoutTypes } from 'reduxModules/checkout'
import authTypes from 'reduxModules/auth/types'

import _ from 'lodash'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  CART_INITIAL
  ADD_TO_CART_SUCCESS
  ADD_TO_CART_REQUEST_FAILURE
  ADD_TO_CART_SUCCESS
  ADD_TO_CART_REQUEST_FAILURE
  CART_ITEMS_FETCH_SUCCESS
  CART_ITEMS_REQUEST_FAILURE
  CART_SUCCESS
  CART_ERROR
  CART_ITEM_ADDED
  CLOSE_CART_ITEM_MODAL
  REMOVE_CART_ITEM_SUCCESS
  REMOVE_CART_ITEM_REQUEST_FAILURE
  UPDATE_CART_SUCCESS
  UPDATE_CART_REQUEST_FAILURE
  TOGGLE_DISCOUNT_CODE
  SET_SUBSCRIPTION_DISCOUNT
  RELATED_ITEMS_FETCH_SUCCESS
  RELATED_ITEMS_REQUEST_FAILURE
`)

export const cartStatus = {
  initial: types.CART_INITIAL,
  success: types.CART_SUCCESS,
  error: types.CART_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: cartStatus.initial,
  list: [],
  itemsCount: 0,
  cartItemAdded: {},
  related: []
}

export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.results,
  itemsCount: action.payload.results.reduce(
    (acc, item) => acc + 1 * item.quantity,
    0
  ),
  next: action.payload.next,
  status: cartStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: cartStatus.error
})

export const removeCartItemRequestSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  list: [..._.reject(state.list, { id: action.payload.requestBody.pk })],
  itemsCount: [
    ..._.reject(state.list, { id: action.payload.requestBody.pk })
  ].reduce((acc, item) => acc + 1 * item.quantity, 0),
  status: cartStatus.success
})

export const updateCartItemRequestSuccess = (state = INITIAL_STATE, action) => {
  const itemUpdated = action.payload.id
  const newList = _.cloneDeep(state.list)
  const itemIndex = _.findIndex(newList, o => o.id === itemUpdated)
  newList[itemIndex].quantity = action.payload.quantity
  return {
    ...state,
    list: [...newList],
    itemsCount: [...newList].reduce((acc, item) => acc + 1 * item.quantity, 0),
    status: cartStatus.success
  }
}

export const cartItemAdded = (state, action) => ({
  ...state,
  cartItemAdded: action.payload
})
export const closeCartItemModal = state => ({ ...state, cartItemAdded: {} })

export const clearCartSuccess = state => ({ ...state, ...INITIAL_STATE })

export const setSubscriptionDiscountSuccess = (state, action) => ({
  ...state,
  subscriptionDiscount: action.payload
})

export const relatedItemsSuccess = (state, action) => ({
  ...state,
  related: action.payload
})

export const HANDLERS = {
  [types.CART_ITEMS_FETCH_SUCCESS]: success,
  [types.CART_ITEMS_REQUEST_FAILURE]: failure,
  [types.REMOVE_CART_ITEM_SUCCESS]: removeCartItemRequestSuccess,
  [types.CART_ITEM_ADDED]: cartItemAdded,
  [types.CLOSE_CART_ITEM_MODAL]: closeCartItemModal,
  [types.UPDATE_CART_SUCCESS]: updateCartItemRequestSuccess,
  [checkoutTypes.SETTLE_ORDER_SUCCESS]: clearCartSuccess,
  [authTypes.AUTH_LOGOUT_SUCCESS]: clearCartSuccess,
  [authTypes.AUTH_INVALID]: clearCartSuccess,
  [types.SET_SUBSCRIPTION_DISCOUNT]: setSubscriptionDiscountSuccess,
  [types.RELATED_ITEMS_FETCH_SUCCESS]: relatedItemsSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const addToCartSuccess = createAction(types.ADD_TO_CART_SUCCESS)
export const addToCartFailure = createAction(types.ADD_TO_CART_REQUEST_FAILURE)
export const addToCart = fetchApi(
  '/order-items/',
  { method: 'POST' },
  addToCartSuccess,
  addToCartFailure
)

export const cartItemsFetchSuccess = createAction(
  types.CART_ITEMS_FETCH_SUCCESS
)
export const cartItemsFetchFailure = createAction(
  types.CART_ITEMS_REQUEST_FAILURE
)
export const cartItemsFetch = fetchApi(
  '/order-items/',
  { method: 'GET' },
  cartItemsFetchSuccess,
  cartItemsFetchFailure
)

export const removeCartItemSuccess = createAction(
  types.REMOVE_CART_ITEM_SUCCESS
)
export const removeCartItemFailure = createAction(
  types.REMOVE_CART_ITEM_REQUEST_FAILURE
)
export const removeCartItem = pk =>
  fetchApi(
    `/order-items/${pk}/`,
    { method: 'DELETE' },
    removeCartItemSuccess,
    removeCartItemFailure,
    { requestBodyOnSuccess: true }
  )({ pk })

export const updateCartSuccess = createAction(types.UPDATE_CART_SUCCESS)
export const updateCartFailure = createAction(types.UPDATE_CART_REQUEST_FAILURE)
export const updateCart = (pk, payload) =>
  fetchApi(
    `/order-items/${pk}/`,
    { method: 'PATCH' },
    updateCartSuccess,
    updateCartFailure
  )(payload)

export const setSubscriptionDiscount = createAction(
  types.SET_SUBSCRIPTION_DISCOUNT
)

export const itemAdded = createAction(types.CART_ITEM_ADDED)
export const closeItemModal = createAction(types.CLOSE_CART_ITEM_MODAL)

export const relatedItemsFetchSuccess = createAction(
  types.RELATED_ITEMS_FETCH_SUCCESS
)
export const relatedItemsFetchFailure = createAction(
  types.RELATED_ITEMS_REQUEST_FAILURE
)
export const relatedItemsFetch = fetchApi(
  '/order-items/related/',
  { method: 'GET' },
  relatedItemsFetchSuccess,
  relatedItemsFetchFailure
)
