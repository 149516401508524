import PropTypes from 'prop-types'
import { Component } from 'react'
import _ from 'lodash'
import template from './template.rt'
import './styles.css'
import { cartItemPropTypes } from './CartItem'

export class CartItemList extends Component {
  render() {
    return template.call(this)
  }
}

CartItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(cartItemPropTypes)),
  loading: PropTypes.number,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      description1: PropTypes.string,
      description2: PropTypes.string,
      description3: PropTypes.string,
      firstMonthPrice: PropTypes.number,
      id: PropTypes.number,
      name: PropTypes.string,
      owned: PropTypes.bool,
      price: PropTypes.number,
      rank: PropTypes.number,
      regularPrice: PropTypes.number,
      trial: PropTypes.string,
      url: PropTypes.string,
      videoTutorialDiscount: PropTypes.number,
      webinarDiscount: PropTypes.number,
      merchDiscount: PropTypes.number
    })
  )
}

CartItemList.defaultProps = {
  items: [],
  loading: 0,
  plans: []
}

export default CartItemList
