const getLoadLimitByIndex = (limit, data) => {
    const { element, isPending } = data

    if (isPending && element.index > limit) {
      let i = element.index + 1
      while (i % limit !== 0) i += 1
      limit = i
    }

    return limit
  }
  
  export default getLoadLimitByIndex
  