import * as React from 'react';
import * as _ from 'lodash';
import NumericInput from './NumericInput';
import utils from '../../../utils/cartItemUtils';
import math from '../../../../redux/modules/utils/math';
export default function () {
    function scopeHasSubscribeDiscount1(props) {
        var hasSubscribeDiscount = props.price !== props.regularPrice;
        return React.createElement('div', { 'className': 'price' }, hasSubscribeDiscount ? React.createElement('span', {
            'className': 'subscribe-discount',
            'key': '1099'
        }, '$', math.toFixed(props.regularPrice)) : null, React.createElement('span', {}, '$', math.toFixed(props.price)), hasSubscribeDiscount && props.discountPercentage > 0 ? React.createElement('span', {
            'className': 'discount-message',
            'key': '1262'
        }, '\n            Subscribers ', React.createElement('br', {}), ' save ', props.discountPercentage, '%\n        ') : null);
    }
    function scopeProps2() {
        var props = this.props;
        return React.createElement('div', { 'className': 'cart-item' }, React.createElement('div', { 'className': 'thumbnail' }, props.type !== 'plan' ? React.createElement('img', {
            'alt': 'Cart item image',
            'src': props.thumbnail.cartThumb,
            'key': '318'
        }) : null, props.type === 'plan' ? React.createElement('div', {
            'className': 'plan-icon',
            'key': '418'
        }, React.createElement('i', { 'className': 'icon-monster-' + props.ordered.rank })) : null), React.createElement('div', { 'className': 'description' }, React.createElement('div', { 'className': 'type blue' }, React.createElement('span', {}, utils.getNaturalType(props.orderedModel, props.ordered))), React.createElement('div', { 'className': 'title' }, React.createElement('span', {}, utils.getTitle(props.orderedModel, props.ordered))), React.createElement('div', { 'className': 'extra' }, props.orderedModel == 'path' ? React.createElement('span', { 'key': '878' }, `${ props.ordered.numTutorials } courses`) : null)), scopeHasSubscribeDiscount1.apply(this, [props]), React.createElement('div', { 'className': 'quantity' }, props.type === 'physical' ? React.createElement(NumericInput, {
            'value': props.quantity,
            'id': props.id,
            'update': (pk, qt) => props.updateCartItemQuantity(pk, qt),
            'disabled': props.loading > 0,
            'remove': pk => props.removeCartItemDispatcher(pk),
            'key': '1482'
        }) : null, props.type !== 'physical' ? React.createElement('span', { 'key': '1802' }, this.props.quantity) : null), React.createElement('div', { 'className': 'subtotal' }, React.createElement('span', {}, '$', math.toFixed(parseFloat(props.price * props.quantity)))), React.createElement('div', { 'className': 'remove-item' }, React.createElement('div', {
            'onClick': () => props.removeCartItemDispatcher(props.id),
            'className': 'cross'
        }, React.createElement('i', { 'className': 'icon-x' }))));
    }
    return scopeProps2.apply(this, []);
}