import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  SPECIAL_VIDEOS_INITIAL
  SPECIAL_VIDEOS_FETCH_SUCCESS
  SPECIAL_VIDEOS_REQUEST_FAILURE
  SPECIAL_VIDEOS_SUCCESS
  SPECIAL_VIDEOS_ERROR
  SPECIAL_VIDEO_FETCH_SUCCESS
  SPECIAL_VIDEO_REQUEST_FAILURE
  SPECIAL_VIDEOS_FETCH_MORE_SUCCESS
  SPECIAL_VIDEO_CLEAR
  SPECIAL_VIDEO_LIST_CLEAR
`)

export const specialVideosStatus = {
  initial: types.SPECIAL_VIDEOS_INITIAL,
  success: types.SPECIAL_VIDEOS_SUCCESS,
  error: types.SPECIAL_VIDEOS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: specialVideosStatus.initial,
  list: [],
  detail: {
    title: '',
    slug: '',
    socialUrl: '',
    //
    id: 0,
    url: '',
    description: '',
    photo: {},
    //
    body: '',
    chapters: '',
    subtitles: [],
    video: {},
    firstFrameImage: {}
  }
}

export const successList = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.results,
  next: action.payload.next,
  status: specialVideosStatus.success
})

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  status: specialVideosStatus.success
})

export const failureList = (state = INITIAL_STATE) => ({
  ...state,
  status: specialVideosStatus.error
})

export const failureDetail = (state = INITIAL_STATE) => ({
  ...state,
  detail: INITIAL_STATE.detail,
  status: specialVideosStatus.error
})

export const clearSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: specialVideosStatus.initial,
  detail: INITIAL_STATE.detail
})

export const clearListSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: specialVideosStatus.initial,
  list: INITIAL_STATE.list
})

export const HANDLERS = {
  [types.SPECIAL_VIDEOS_FETCH_SUCCESS]: successList,
  [types.SPECIAL_VIDEOS_REQUEST_FAILURE]: failureList,
  [types.SPECIAL_VIDEO_FETCH_SUCCESS]: successDetail,
  [types.SPECIAL_VIDEO_REQUEST_FAILURE]: failureDetail,
  [types.SPECIAL_VIDEOS_FECTH_MORE_REQUEST_FAILURE]: failureList,
  [types.SPECIAL_VIDEO_CLEAR]: clearSuccess,
  [types.SPECIAL_VIDEO_LIST_CLEAR]: clearListSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const specialVideosFetchSuccess = createAction(
  types.SPECIAL_VIDEOS_FETCH_SUCCESS
)
export const specialVideosFetchFailure = createAction(
  types.SPECIAL_VIDEOS_REQUEST_FAILURE
)
export const specialVideosFetch = () =>
  fetchApi(
    '/special-videos/',
    { method: 'GET' },
    specialVideosFetchSuccess,
    specialVideosFetchFailure
  )

export const specialVideoFetchSuccess = createAction(
  types.SPECIAL_VIDEO_FETCH_SUCCESS
)
export const specialVideoFetchFailure = createAction(
  types.SPECIAL_VIDEO_REQUEST_FAILURE
)
export const specialVideoFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/special-videos/${slug}/`,
    { method: 'GET' },
    specialVideoFetchSuccess,
    specialVideoFetchFailure
  )
}

export const clearSpecialVideo = createAction(types.SPECIAL_VIDEO_CLEAR)
export const clearSpecialVideoList = createAction(
  types.SPECIAL_VIDEO_LIST_CLEAR
)
