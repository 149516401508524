/* eslint-disable import/prefer-default-export */
import { testimonialRandomFetch, testimonialsFetch } from './'

export const testimonialsDispatchers = dispatch => ({
  loadRandomTestimonial() {
    dispatch(testimonialRandomFetch())
  },
  loadTestimonials() {
    dispatch(testimonialsFetch())
  }
})
