import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { userOrdersReselector as stateSelector } from 'reduxModules/users/selectors'
import { ordersDispatchers as dispatchSelector } from 'reduxModules/users/dispatchers'
import template from './template.rt'
import './styles.css'

export class Orders extends Component {
  componentDidMount() {
    this.props.loadOrders()
    this.props.loadSubscriptions()
  }

  render() {
    if (!this.props.orders) return null
    return template.call(this)
  }
}

Orders.propTypes = {
  loadOrders: PropTypes.func.isRequired,
  loadSubscriptions: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  loadSubscriptionTransactions: PropTypes.func,
  subscription: PropTypes.shape({
    active: PropTypes.bool,
    braintreeId: PropTypes.string,
    free: PropTypes.bool,
    id: PropTypes.number,
    plan: PropTypes.string
  }),
  subscriptionTransaction: PropTypes.bool
}

Orders.defaultProps = {
  orders: [],
  subscription: null,
  subscriptionTransaction: false,
  loadSubscriptionTransactions: null
}

export default connect(
  stateSelector,
  dispatchSelector
)(Orders)
