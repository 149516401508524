import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import _ from 'lodash'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  SUBSCRIPTION_PLANS_INITIAL
  SUBSCRIPTION_PLANS_FETCH_SUCCESS
  SUBSCRIPTION_PLANS_REQUEST_FAILURE
  SUBSCRIPTION_SUMMARY_FETCH_SUCCESS
  SUBSCRIPTION_SUMMARY_REQUEST_FAILURE
  SUBSCRIPTION_PLANS_SUCCESS
  SUBSCRIPTION_PLANS_ERROR
  CLOSE_BANNER_SUBSCRIPTION
  OPEN_BANNER_SUBSCRIPTION
  BANNER_SUBSCRIPTION_READY
  GROUP_SUBSCRIPTIONS_CONTACT_REQUEST_SUCCESS
  GROUP_SUBSCRIPTIONS_CONTACT_REQUEST_FAILURE
  SUBSCRIPTION_TRANSACTIONS_FETCH_SUCCESS
  SUBSCRIPTION_TRANSACTIONS_REQUEST_FAILURE
  SUBSCRIPTIONS_FETCH_SUCCESS
  SUBSCRIPTIONS_FETCH_REQUEST_FAILURE
  SUBSCRIPTIONS_SUCCESS
  SUBSCRIPTIONS_ERROR
  IS_SUBSCRIPTION_PENDING_SUCCESS
  IS_SUBSCRIPTION_PENDING_REQUEST_FAILURE
  MARK_SUBSCRIPTION_PENDING_SUCCESS
  MARK_SUBSCRIPTION_PENDING_REQUEST_FAILURE
  GIFT_SUBSCRIPTION_SUCCESS
  GIFT_SUBSCRIPTION_FAILURE
  GIFT_SUBSCRIPTION_DETAIL_SUCCESS
  GIFT_SUBSCRIPTION_DETAIL_FAILURE
  REDEEM_GIFT_SUBSCRIPTION_SUCCESS
  REDEEM_GIFT_SUBSCRIPTION_FAILURE
  SUBSCRIPTION_PLANS_LANGUAGE_SUCCESS
  SUBSCRIPTION_PLANS_SET_DEFAULT
`)

export const subscriptionsStatus = {
  initial: types.SUBSCRIPTIONS_INITIAL,
  success: types.SUBSCRIPTIONS_SUCCESS,
  error: types.SUBSCRIPTIONS_ERROR
}

export const giftSubscriptionStatus = {
  success: types.GIFT_SUBSCRIPTION_SUCCESS,
  error: types.GIFT_SUBSCRIPTION_FAILURE
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: subscriptionsStatus.initial,
  list: [],
  showBannerSubscription: false,
  bannerSubscriptionIsReady: false,
  giftSubscriptionDetail: {
    gifterName: '',
    firstName: '',
    lastName: '',
    email: ''
  },
  subscriptionPlansLanguage: {
    headline: '',
    subheadline: ''
  },
  isLoading: false
}

export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  plans: action.payload.results,
  status: subscriptionsStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: subscriptionsStatus.error
})

export const closeBannerSubscription = (state = INITIAL_STATE) => ({
  ...state,
  showBannerSubscription: false
})

export const openBannerSubscription = (state = INITIAL_STATE) => ({
  ...state,
  showBannerSubscription: true
})

export const bannerSubscriptionReady = (state = INITIAL_STATE) => ({
  ...state,
  bannerSubscriptionIsReady: true
})

export const subscriptionsFetchRequestSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  list: action.payload.results,
  status: subscriptionsStatus.success
})

export const subscriptionTransactionsFetchRequestSuccess = (
  state = INITIAL_STATE,
  action
) => {
  const { requestBody, ...transactions } = action.payload
  const id = requestBody
  const subClone = _.cloneDeep(_.find(state.list, { id }))
  const newSub = { ...subClone, transactions }

  return {
    ...state,
    list: [..._.reject(state.list, { id }), newSub],
    status: subscriptionsStatus.success
  }
}

export const gitfSubscriptionRequestSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: giftSubscriptionStatus.success
})

export const gitfSubscriptionRequestFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: giftSubscriptionStatus.Failure
})

export const giftSubscriptionDetailRequestSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  giftSubscriptionDetail: action.payload
})

export const subscriptionPlansLanguageRequestSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  subscriptionPlansLanguage: action.payload
})

export const subscriptionPlansLanguageSetDefaultSuccess = (
  state = INITIAL_STATE
) => ({
  ...state,
  subscriptionPlansLanguage: {
    headline: 'Become a subscriber',
    subheadline: 'Select a learning plan and start your 7-Day Free Trial'
  }
})

export const HANDLERS = {
  [types.SUBSCRIPTION_PLANS_FETCH_SUCCESS]: success,
  [types.SUBSCRIPTION_PLANS_REQUEST_FAILURE]: failure,
  [types.CLOSE_BANNER_SUBSCRIPTION]: closeBannerSubscription,
  [types.OPEN_BANNER_SUBSCRIPTION]: openBannerSubscription,
  [types.BANNER_SUBSCRIPTION_READY]: bannerSubscriptionReady,
  [types.SUBSCRIPTIONS_FETCH_SUCCESS]: subscriptionsFetchRequestSuccess,
  [types.SUBSCRIPTION_TRANSACTIONS_FETCH_SUCCESS]: subscriptionTransactionsFetchRequestSuccess,
  [types.GIFT_SUBSCRIPTION_SUCCESS]: gitfSubscriptionRequestSuccess,
  [types.GIFT_SUBSCRIPTION_FAILURE]: gitfSubscriptionRequestFailure,
  [types.GIFT_SUBSCRIPTION_DETAIL_SUCCESS]: giftSubscriptionDetailRequestSuccess,
  [types.SUBSCRIPTION_PLANS_LANGUAGE_SUCCESS]: subscriptionPlansLanguageRequestSuccess,
  [types.SUBSCRIPTION_PLANS_SET_DEFAULT]: subscriptionPlansLanguageSetDefaultSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const subscriptionPlansFetchSuccess = createAction(
  types.SUBSCRIPTION_PLANS_FETCH_SUCCESS
)
export const subscriptionPlansFetchRequestFailure = createAction(
  types.SUBSCRIPTION_PLANS_REQUEST_FAILURE
)
export const subscriptionPlansFetch = fetchApi(
  '/plans/',
  { method: 'GET' },
  subscriptionPlansFetchSuccess,
  subscriptionPlansFetchRequestFailure
)

export const subscriptionSummaryFetchSuccess = createAction(
  types.SUBSCRIPTION_SUMMARY_FETCH_SUCCESS
)
export const subscriptionSummaryFetchRequestFailure = createAction(
  types.SUBSCRIPTION_SUMMARY_REQUEST_FAILURE
)
export const subscriptionSummaryFetch = pk =>
  fetchApi(
    `/subscriptions/${pk}/`,
    { method: 'GET' },
    subscriptionSummaryFetchSuccess,
    subscriptionSummaryFetchRequestFailure
  )

export const subscriptionsFetchSuccess = createAction(
  types.SUBSCRIPTIONS_FETCH_SUCCESS
)
export const subscriptionsFetchRequestFailure = createAction(
  types.SUBSCRIPTIONS_FETCH_REQUEST_FAILURE
)
export const subscriptionsFetch = fetchApi(
  '/subscriptions/',
  { method: 'GET' },
  subscriptionsFetchSuccess,
  subscriptionsFetchRequestFailure
)

export const closeBanner = createAction(types.CLOSE_BANNER_SUBSCRIPTION)
export const openBanner = createAction(types.OPEN_BANNER_SUBSCRIPTION)
export const bannerIsReady = createAction(types.BANNER_SUBSCRIPTION_READY)

export const subscriptionTransactionsFetchSuccess = createAction(
  types.SUBSCRIPTION_TRANSACTIONS_FETCH_SUCCESS
)
export const subscriptionTransactionsFetchRequestFailure = createAction(
  types.SUBSCRIPTION_TRANSACTIONS_REQUEST_FAILURE
)
export const subscriptionTransactionsFetch = pk =>
  fetchApi(
    `/subscriptions/${pk}/transactions/`,
    { method: 'GET' },
    subscriptionTransactionsFetchSuccess,
    subscriptionTransactionsFetchRequestFailure,
    { requestBodyOnSuccess: true }
  )(pk)

export const isSubscriptionPendingSuccess = createAction(
  types.IS_SUBSCRIPTION_PENDING_SUCCESS
)
export const isSubscriptionPendingRequestFailure = createAction(
  types.IS_SUBSCRIPTION_PENDING_REQUEST_FAILURE
)
export const isSubscriptionPending = fetchApi(
  '/pending-subscription/',
  { method: 'GET' },
  isSubscriptionPendingSuccess,
  isSubscriptionPendingRequestFailure
)

export const markSubscriptionPendingSuccess = createAction(
  types.MARK_SUBSCRIPTION_PENDING_SUCCESS
)
export const markSubscriptionPendingRequestFailure = createAction(
  types.MARK_SUBSCRIPTION_PENDING_REQUEST_FAILURE
)
export const markSubscriptionPending = plan =>
  fetchApi(
    '/pending-subscription/',
    { method: 'POST' },
    markSubscriptionPendingSuccess,
    markSubscriptionPendingRequestFailure
  )(plan)

export const gitfSubscriptionSuccess = createAction(
  types.GIFT_SUBSCRIPTION_SUCCESS
)
export const giftSubscriptionFailure = createAction(
  types.GIFT_SUBSCRIPTION_FAILURE
)

export const giftSubscription = recipient =>
  fetchApi(
    '/gift-certificates/',
    { method: 'POST' },
    gitfSubscriptionSuccess,
    giftSubscriptionFailure
  )(recipient)

export const giftSubscriptionDetailSuccess = createAction(
  types.GIFT_SUBSCRIPTION_DETAIL_SUCCESS
)

export const giftSubscriptionDetailFailure = createAction(
  types.GIFT_SUBSCRIPTION_DETAIL_FAILURE
)

export const giftSubscriptionDetail = token =>
  fetchApi(
    `/gift-certificates/${token}`,
    { method: 'GET' },
    giftSubscriptionDetailSuccess,
    giftSubscriptionDetailFailure
  )()

export const giftSubscriptionRedeemSuccess = createAction(
  types.REDEEM_GIFT_SUBSCRIPTION_SUCCESS
)

export const giftSubscriptionRedeemFailure = createAction(
  types.REDEEM_GIFT_SUBSCRIPTION_FAILURE
)

export const gitfSubscriptionRedeem = (token, data) =>
  fetchApi(
    `/gift-certificates/${token}/subscribe/`,
    { method: 'POST' },
    giftSubscriptionRedeemSuccess,
    giftSubscriptionRedeemFailure
  )(data)

export const subscriptionPlansLanguageFetchSuccess = createAction(
  types.SUBSCRIPTION_PLANS_LANGUAGE_SUCCESS
)

export const subscriptionPlansLanguageFetchFailure = createAction(
  types.SUBSCRIPTION_PLANS_LANGUAGE_FAILURE
)

export const subscriptionPlansLanguageFetch = fetchApi(
  '/subscription-page-settings/',
  { method: 'GET' },
  subscriptionPlansLanguageFetchSuccess,
  subscriptionPlansLanguageFetchFailure
)

export const subscriptionPlansLanguageDefault = createAction(
  types.SUBSCRIPTION_PLANS_SET_DEFAULT
)
