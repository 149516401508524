/* global API_ROOT */
/* eslint-disable import/prefer-default-export */
import {
  setElementToScroll,
  setPendingScroll,
  unsetPendingScroll
} from 'reduxModules/app'
import { scroller } from 'react-scroll'
import { artistsFetch, artistFetch, clearArtist, types } from './'
import { tutorialsFetch } from '../tutorials'
import { webinarsFetch } from '../webinars'
import { blogPostsFetch } from '../blogPosts'
import { assetsFetch } from '../assets'

export const artistsMergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    loadArtists(filters) {
      dispatch(artistsFetch(filters)()).then(([_, res]) => {
        if (res.type === types.ARTIST_FETCH_SUCCESS) {
          const { artists } = stateProps.pendingBackScroll
          const { isPending, element } = artists
          if (isPending) {
            scroller.scrollTo(element.name, {
              smooth: false,
              offset: 0
            })
            dispatch(unsetPendingScroll({ group: 'artists' }))
          }
        }
      })
    },
    setScrollingBackElement(payload) {
      dispatch(setElementToScroll(payload))
    }
  }
}

export const artistDispatchers = (dispatch, props) => ({
  loadArtistInfo() {
    dispatch(artistFetch(props.params.artistSlug)()).then(([_, res]) => {
      dispatch(blogPostsFetch({ author: res.payload.user })())
      dispatch(assetsFetch('artist', res.payload.id, 50)())
    })
  },
  loadArtistTutorials() {
    dispatch(tutorialsFetch({ artist: props.params.artistSlug })())
  },
  loadArtistWebinars() {
    dispatch(webinarsFetch({ artist: props.params.artistSlug })())
  },
  clearArtist() {
    dispatch(clearArtist())
  },
  triggerPendingScroll() {
    dispatch(setPendingScroll({ group: 'artists' }))
  }
})
