import PropTypes from 'prop-types'
import React from 'react'
import './styles.css'

const AddressDetails = ({
  streetAddress,
  extendedAddress,
  locality,
  region,
  postalCode,
  countryName,
  firstName,
  lastName
}) => (
  <div>
    <div className="name-header">
      <div className="name">{`${firstName} ${lastName}`}</div>
    </div>
    <div className="address-description">
      <span>{`${streetAddress}, ${
        extendedAddress ? `${extendedAddress},` : ''
      } ${locality}, ${region} ${postalCode}, ${countryName}.`}</span>
    </div>
  </div>
)

AddressDetails.propTypes = {
  streetAddress: PropTypes.string.isRequired,
  extendedAddress: PropTypes.string,
  locality: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
}

AddressDetails.defaultProps = {
  extendedAddress: null
}

export default AddressDetails
