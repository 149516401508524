// Original code from https://github.com/sungwoncho/react-cntdwn
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import './styles.css'

export const COUNTDOWN_NOT_STARTED = 1
export const COUNTDOWN_STARTED = 2
export const COUNTDOWN_FINISHED = 3

export default class CountdownTimer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      remainingTime: 0,
      status: COUNTDOWN_NOT_STARTED,
      intervalId: null
    }
  }

  componentDidMount = () => {
    setTimeout(() => {
      const timer = setInterval(() => {
        this.tick()
      }, this.props.interval)

      this.setState({
        status: COUNTDOWN_STARTED,
        intervalId: timer
      })

      this.tick()
    }, this.props.startDelay)
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId)
  }

  calculateRemainingTime = () => -1 * moment().diff(this.props.targetDate)

  addLeadingZero = value => {
    if (value < 10) return `0${value.toString()}`

    return value
  }

  tick = () => {
    this.setState({
      remainingTime: this.calculateRemainingTime()
    })

    if (this.state.remainingTime <= 0) {
      this.setState({
        status: COUNTDOWN_FINISHED
      })

      if (this.props.onFinished) this.props.onFinished()

      clearInterval(this.state.intervalId)
    }
  }

  renderRemainingTime = () => {
    const html = []
    const { format, leadingZero, targetDate } = this.props
    const { remainingTime } = this.state
    const todaysdate = moment()
    const targetDateMoment = moment(targetDate)
    if (format.day) {
      let days = targetDateMoment.diff(todaysdate, 'days')
      const label = days === 1 ? 'Day' : 'Days'
      if (leadingZero) days = this.addLeadingZero(days)

      html.push(
        <div className="day-holder" key="day">
          <div className="value">
            <span>{days}</span>
          </div>
          <div className="label">{label}</div>
        </div>
      )
    }

    if (format.hour) {
      let hours = moment.duration(remainingTime).get('hours')
      const label = hours === 1 ? 'Hour' : 'Hours'
      if (leadingZero) hours = this.addLeadingZero(hours)

      html.push(
        <div className="hour-holder" key="hour">
          <div className="value">
            <span>{hours}</span>
          </div>
          <div className="label">{label}</div>
        </div>
      )
    }

    if (format.minute) {
      let minutes = moment.duration(remainingTime).get('minutes')
      const label = minutes === 1 ? 'Minute' : 'Minutes'

      if (leadingZero) minutes = this.addLeadingZero(minutes)

      html.push(
        <div className="minute-holder" key="minute">
          <div className="value">
            <span>{minutes}</span>
          </div>
          <div className="label">{label}</div>
        </div>
      )
    }

    if (format.second) {
      let seconds = moment.duration(remainingTime).get('seconds')
      const label = seconds === 1 ? 'Second' : 'Seconds'
      if (leadingZero) seconds = this.addLeadingZero(seconds)

      html.push(
        <div className="second-holder" key="second">
          <div className="value">
            <span>{seconds}</span>
          </div>
          <div className="label">{label}</div>
        </div>
      )
    }

    return html
  }

  render = () => {
    if (this.state.status === COUNTDOWN_NOT_STARTED) return <span />

    return <div className="CountdownTimer">{this.renderRemainingTime()}</div>
  }
}

CountdownTimer.propTypes = {
  targetDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date).isRequired,
    PropTypes.string.isRequired
  ]).isRequired,
  interval: PropTypes.number,
  startDelay: PropTypes.number,
  onFinished: PropTypes.func,
  format: PropTypes.shape({
    hour: PropTypes.string.isRequired,
    minute: PropTypes.string.isRequired,
    second: PropTypes.string.isRequired
  }),
  leadingZero: PropTypes.bool
}

CountdownTimer.defaultProps = {
  interval: 1000,
  startDelay: 0,
  format: {
    day: 'DD',
    hour: 'HH',
    minute: 'MM',
    second: 'SS'
  },
  leadingZero: false,
  onFinished: () => null
}
