import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import LoadMore from '../../generic/LoadMore';
import Artist from '../../generic/ProfileSummary';
import { artistsFetchMore } from '../../../redux/modules/artists';
export default function () {
    function repeatArtist1(props, artist, idx) {
        return React.createElement(Artist, {
            'className': 'artist',
            'key': 'artist-' + idx,
            'elementName': 'artists-' + idx,
            'name': artist.title,
            'photo': artist.photo.boxThumb,
            'slug': artist.slug
        });
    }
    function scopeProps2() {
        var props = this.props;
        return React.createElement('article', {
            'id': 'ArtistList',
            'className': 'wrapper'
        }, React.createElement(Helmet, {}, React.createElement('title', {}, 'Teachers | Stan Winston School of Character Arts'), React.createElement('meta', {
            'name': 'description',
            'content': 'Find the most talented professionals in the field of Special Effects Character Creation, Character Design & Makeup FX'
        }), React.createElement('meta', {
            'property': 'og:title',
            'content': 'Teachers | Stan Winston School of Character Arts'
        }), React.createElement('meta', {
            'property': 'og:description',
            'content': 'Find the most talented professionals in the field of Special Effects Character Creation, Character Design & Makeup FX'
        })), React.createElement('section', { 'className': 'heading' }, React.createElement('h1', {}, 'Teachers')), React.createElement.apply(this, [
            'section',
            { 'className': 'artists-list-content' },
            _.map(props.artists, repeatArtist1.bind(this, props))
        ]), this.props.nextArtists ? React.createElement('div', {
            'className': 'load-more',
            'key': '1234'
        }, React.createElement(LoadMore, {
            'fetchMoreAction': artistsFetchMore,
            'next': this.props.nextArtists,
            'listContainerId': 'ArtistList'
        })) : null);
    }
    return scopeProps2.apply(this, []);
}