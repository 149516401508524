import PropTypes from 'prop-types'
import React from 'react'
import {
  TutorialSummaryPropTypes
} from 'components/generic/CourseSummary/propTypes'
import LazyWrapper from 'components/generic/LazyWrapper'
import template from './template.rt'
import './styles.css'

export const CourseRelated = props => template.call({ props })

const CourseRelatedLazyWrapper = props => (
  <LazyWrapper>
    <CourseRelated {...props} />
  </LazyWrapper>
)

CourseRelated.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape(TutorialSummaryPropTypes))
    .isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default CourseRelatedLazyWrapper
