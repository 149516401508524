const keyUpHandler = (
  event,
  enterCallback,
  escapeCallback,
  thisValue,
  enterCallbackArgs = [],
  escapeCallbackArgs = []
) => {
  if (event.key === 'Enter') {
    if (enterCallback)
      if (thisValue)
        enterCallback.call(thisValue, ...enterCallbackArgs)
      else
        enterCallback(...enterCallbackArgs)
  } else if (event.key === 'Escape')
    if (escapeCallback)
      if (thisValue)
        escapeCallback.call(thisValue, ...escapeCallbackArgs)
      else
        escapeCallback(...escapeCallbackArgs)
}

export default keyUpHandler
