import * as React from 'react';
import * as _ from 'lodash';
import LearningPath from '../LearningPathSummary';
import BannerSlideShow from '../../generic/BannerSlideShow';
export default function () {
    function repeatPath1(props, path, pathIndex) {
        return React.createElement(LearningPath, _.assign({}, {
            'key': path.id,
            'className': 'content-list__item'
        }, path));
    }
    function scopeProps2() {
        var props = this.props;
        return React.createElement('article', { 'id': 'LearningPathList' }, props.banners ? React.createElement(BannerSlideShow, {
            'banners': props.banners,
            'isMobile': this.props.isMobile,
            'key': '229'
        }) : null, React.createElement('section', { 'className': 'heading wrapper' }, React.createElement('h1', {}, 'Learning Pathways')), React.createElement.apply(this, [
            'div',
            { 'className': 'content-list wrapper' },
            _.map(props.learningPaths, repeatPath1.bind(this, props))
        ]));
    }
    return scopeProps2.apply(this, []);
}