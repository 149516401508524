import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { cartItemDispatchers as dispatchers } from 'reduxModules/cart/dispatchers'
import template from './template.rt'
import mobileTemplate from './mobileTemplate.rt'
import './styles.css'

export class CartItem extends Component {
  render() {
    const { isMobile } = this.props

    if (isMobile) return mobileTemplate.call(this)
    return template.call(this)
  }
}

export const cartItemPropTypes = {
  id: PropTypes.number.isRequired,
  ordered: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object
    ])
  ).isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  thumbnail: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string
}

CartItem.propTypes = {
  ...cartItemPropTypes,
  loading: PropTypes.number.isRequired,
  removeCartItemDispatcher: PropTypes.func.isRequired
}

CartItem.defaultProps = {
  title: '',
  loading: 0,
  thumbnail: { thumb: '' }
}

export default connect(
  null,
  dispatchers
)(CartItem)
