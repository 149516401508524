import moment from 'moment'

const CHAPTER_QUERY_PARAM_NAME = 'chapter'
const POSITION_QUERY_PARAM_NAME = 'time'
// eslint-disable-next-line import/prefer-default-export

export const getSecondsFromTimestamp = ({ durationH, durationM, durationS }) =>
  Number(
    Number(durationH || 0) * 3600 +
      Number(durationM || 0) * 60 +
      Number(durationS || 0)
  )

export const getChapterFromURL = numberOfChapters => {
  const url = new URL(window.location.href)
  const chapterViaQueryParam = Number(
    url.searchParams.get(CHAPTER_QUERY_PARAM_NAME)
  )

  // Number should be a valid positive integer, and no bigger than the number of chapters on the tutorial
  return chapterViaQueryParam > 0 && chapterViaQueryParam <= numberOfChapters
    ? chapterViaQueryParam - 1
    : undefined
}

export const getChapterTimePositionFromURL = maxDuration => {
  const url = new URL(window.location.href)
  const positionViaQueryParam = Number(
    url.searchParams.get(POSITION_QUERY_PARAM_NAME)
  )

  // Number should be a valid positive integer and less than the duration of the course
  if (positionViaQueryParam < 0) return 0
  if (positionViaQueryParam > maxDuration) return maxDuration
  return positionViaQueryParam
}

export const getHumanReadableChapterLength = ({
  durationH,
  durationM,
  durationS
}) => {
  const chapterLengthInSeconds = getSecondsFromTimestamp({
    durationH,
    durationM,
    durationS
  })
  const duration = moment.duration(chapterLengthInSeconds, 'seconds')
  const chapterLengthInMinutes = duration.minutes()
  const isLessThanAMinute = chapterLengthInMinutes === 0

  return `${isLessThanAMinute ? 1 : chapterLengthInMinutes}min`
}
