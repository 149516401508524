import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import _ from 'lodash'
import fetchApi, { getUrl, fetchHelper } from '../utils/fetch'

export const INITIAL_LIMIT_TUTORIALS = 4
//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  LEARNING_PATHS_INITIAL
  LEARNING_PATHS_FETCH_SUCCESS
  LEARNING_PATHS_REQUEST_FAILURE
  LEARNING_PATHS_SUCCESS
  LEARNING_PATHS_ERROR
  LEARNING_PATH_FETCH_SUCCESS
  LEARNING_PATH_REQUEST_FAILURE
  LEARNING_PATHS_FETCH_MORE_SUCCESS
  LEARNING_PATHS_FECTH_MORE_REQUEST_FAILURE
  LEARNING_PATH_CLEAR
  LEARNING_PATH_LIST_CLEAR
  LEARNING_PATH_SET_VIDEO_PREVIEW
  LEARNING_PATH_UNSET_VIDEO_PREVIEW
  LEARNING_PATH_COURSES_FETCH_SUCCESS
  LEARNING_PATH_COURSES_REQUEST_FAILURE
  LEARNING_PATH_FREE_ENROLL_SUCCESS
  LEARNING_PATH_FREE_ENROLL_REQUEST_FAILURE
  LEARNING_PATH_COURSES_FETCH_MORE_SUCCESS
  LEARNING_PATH_COURSES_FETCH_MORE_REQUEST_FAILURE
  LEARNING_PATH_RECENT_FETCH_SUCCESS
  LEARNING_PATH_RECENT_FETCH_FAILURE
  LEARNING_PATH_RECENT_MORE_FETCH_SUCCESS
  LEARNING_PATH_RECENT_MORE_FETCH_FAILURE
`)

export const learningPathsStatus = {
  initial: types.LEARNING_PATHS_INITIAL,
  success: types.LEARNING_PATHS_SUCCESS,
  error: types.LEARNING_PATHS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: learningPathsStatus.initial,
  videoPreview: false,
  videoPreviewDetail: {
    path: '',
    isYoutube: false
  },
  list: [],
  recents: {
    results: [],
    count: 0,
    next: null,
    previous: null
  },
  detail: {
    title: '',
    slug: '',
    photo: {
      original: '',
      cartThumb: '',
      default: ''
    },
    duration: [],
    numTutorials: 0,
    metaTitle: '',
    metaKeywords: '',
    metaDescription: '',
    url: '',
    regularPrice: '',
    tagline: '',
    overview: '',
    artists: [],
    difficulty: [],
    overallProgress: null,
    progressByCategory: {},
    tags: [],
    price: 0,
    owned: false,
    courses: {
      isEnrolled: false,
      count: 0,
      next: null,
      previous: null,
      results: []
    }
  }
}

export const successSetVideoPreview = (state = INITIAL_STATE, action) => {
  let info = null
  const tutorial = action.payload

  if (
    _.has(tutorial, 'trailer.raw.high') &&
    !_.isEmpty(tutorial.trailer.raw.high)
  )
    info = { path: tutorial.trailer.raw.high, isYoutube: false }
  if (_.has(tutorial, 'trailer.web') && !_.isEmpty(tutorial.trailer.web))
    info = { path: tutorial.trailer.web, isYoutube: true }
  if (
    _.has(tutorial, 'trailer.raw.low') &&
    !_.isEmpty(tutorial.trailer.raw.low)
  )
    info = { path: tutorial.trailer.raw.low, isYoutube: false }

  return {
    ...state,
    videoPreviewDetail: info,
    videoPreview: true
  }
}

export const successUnsetVideoPreview = (state = INITIAL_STATE) => ({
  ...state,
  videoPreviewDetail: null,
  videoPreview: false
})

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: {
    ...state.detail,
    ...action.payload
  },
  status: learningPathsStatus.success
})

export const successDetailCourses = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: {
    ...state.detail,
    courses: { ...state.detail?.courses, ...action.payload }
  },
  status: learningPathsStatus.success
})

export const successEnrollYearly = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: {
    ...state.detail,
    ...action.payload,
    courses: { ...state.detail?.courses, isEnrolled: true }
  },
  status: learningPathsStatus.success
})

export const successDetailMoreCourses = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: {
    ...state.detail,
    courses: {
      ...state.detail.courses,
      results: [...state.detail.courses.results, ...action.payload.results],
      next: action.payload.next
    }
  },
  status: learningPathsStatus.success
})

export const successList = (state = INITIAL_STATE, action) => ({
  ...state,
  list: {
    ...action.payload
  },
  status: learningPathsStatus.success
})

export const successCleaningList = (state = INITIAL_STATE) => ({
  ...state,
  list: INITIAL_STATE.list,
  status: learningPathsStatus.initial
})

export const successCleaningDetail = (state = INITIAL_STATE) => ({
  ...state,
  detail: INITIAL_STATE.detail,
  status: learningPathsStatus.initial
})

export const successRecentPaths = (state = INITIAL_STATE, action) => ({
  ...state,
  recents: action.payload
})

export const successRecentPathsMore = (state = INITIAL_STATE, action) => ({
  ...state,
  recents: {
    ...state.recents,
    ...action.payload,
    results: [...state.recents.results, ...action.payload.results]
  }
})

export const HANDLERS = {
  [types.LEARNING_PATH_SET_VIDEO_PREVIEW]: successSetVideoPreview,
  [types.LEARNING_PATH_UNSET_VIDEO_PREVIEW]: successUnsetVideoPreview,
  [types.LEARNING_PATH_FETCH_SUCCESS]: successDetail,
  [types.LEARNING_PATH_COURSES_FETCH_SUCCESS]: successDetailCourses,
  [types.LEARNING_PATH_FREE_ENROLL_SUCCESS]: successEnrollYearly,
  [types.LEARNING_PATH_COURSES_FETCH_MORE_SUCCESS]: successDetailMoreCourses,
  [types.LEARNING_PATHS_FETCH_SUCCESS]: successList,
  [types.LEARNING_PATH_LIST_CLEAR]: successCleaningList,
  [types.LEARNING_PATH_CLEAR]: successCleaningDetail,
  [types.LEARNING_PATH_RECENT_FETCH_SUCCESS]: successRecentPaths,
  [types.LEARNING_PATH_RECENT_MORE_FETCH_SUCCESS]: successRecentPathsMore
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const setVideoPreview = createAction(
  types.LEARNING_PATH_SET_VIDEO_PREVIEW
)

export const unsetVideoPreview = createAction(
  types.LEARNING_PATH_UNSET_VIDEO_PREVIEW
)

export const learningPathFetchSuccess = createAction(
  types.LEARNING_PATH_FETCH_SUCCESS
)
export const learningPathFetchFailure = createAction(
  types.LEARNING_PATH_REQUEST_FAILURE
)
export const learningPathFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/paths/${slug}/`,
    { method: 'GET' },
    learningPathFetchSuccess,
    learningPathFetchFailure
  )
}

export const learningPathCoursesFetchSuccess = createAction(
  types.LEARNING_PATH_COURSES_FETCH_SUCCESS
)
export const learningPathCoursesFetchFailure = createAction(
  types.LEARNING_PATH_COURSES_REQUEST_FAILURE
)
export const learningPathCoursesFetch = (slug, path, noLimit) => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  let url = noLimit
    ? `/paths/${slug}/tutorials/?`
    : `/paths/${slug}/tutorials/?limit=${INITIAL_LIMIT_TUTORIALS}`
  if (path) url = `${url}&path=${path}`
  return fetchApi(
    url,
    { method: 'GET' },
    learningPathCoursesFetchSuccess,
    learningPathCoursesFetchFailure
  )
}

export const yearlyPathEnrollSuccess = createAction(
  types.LEARNING_PATH_FREE_ENROLL_SUCCESS
)
export const yearlyPathEnrollFetchFailure = createAction(
  types.LEARNING_PATH_FREE_ENROLL_REQUEST_FAILURE
)
export const yearlyPathEnroll = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/paths/${slug}/give_free_access/`,
    { method: 'POST' },
    yearlyPathEnrollSuccess,
    yearlyPathEnrollFetchFailure
  )
}

export const learningPathCoursesFetchMoreSuccess = createAction(
  types.LEARNING_PATH_COURSES_FETCH_MORE_SUCCESS
)
export const learningPathCoursesFetchMoreFailure = createAction(
  types.LEARNING_PATH_COURSES_FETCH_MORE_REQUEST_FAILURE
)
export const learningPathCoursesFetchMore = (next, courses) => {
  if (courses && next.includes(`limit=${INITIAL_LIMIT_TUTORIALS}`))
    next = next.replace(`limit=${INITIAL_LIMIT_TUTORIALS}`, `limit=${courses}`)
  const url = getUrl(next, {})
  return fetchHelper(
    url,
    { method: 'GET' },
    learningPathCoursesFetchMoreSuccess,
    learningPathCoursesFetchMoreFailure
  )
}

export const learningPathsFetchSuccess = createAction(
  types.LEARNING_PATHS_FETCH_SUCCESS
)
export const learningPathsFetchFailure = createAction(
  types.LEARNING_PATHS_REQUEST_FAILURE
)
export const learningPathsFetch = filters => {
  const url = getUrl('/paths/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    learningPathsFetchSuccess,
    learningPathsFetchFailure
  )
}

export const learningPathRecentFetchSuccess = createAction(
  types.LEARNING_PATH_RECENT_FETCH_SUCCESS
)
export const learningPathRecentFetchFailure = createAction(
  types.LEARNING_PATH_RECENT_FETCH_FAILURE
)
export const learningPathRecentFetch = filters => {
  const url = getUrl('/paths/recent/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    learningPathRecentFetchSuccess,
    learningPathFetchFailure
  )
}

export const learningPathRecentMoreFetchSuccess = createAction(
  types.LEARNING_PATH_RECENT_MORE_FETCH_SUCCESS
)
export const learningPathRecentMoreFetchFailure = createAction(
  types.LEARNING_PATH_RECENT_MORE_FETCH_FAILURE
)
export const learningPathRecentMoreFetch = filters => {
  const url = getUrl('/paths/recent/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    learningPathRecentMoreFetchSuccess,
    learningPathRecentMoreFetchFailure
  )
}
export const clearLearningPath = createAction(types.LEARNING_PATH_CLEAR)
export const clearLearningPathList = createAction(
  types.LEARNING_PATH_LIST_CLEAR
)
