/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { subscriptionReselector as stateSelector } from 'reduxModules/auth/selectors'
import { accountMenuDispatcher as dispatcherSelector } from 'reduxModules/auth/dispatchers'
import template from './template.rt'
import './styles.css'
import sidebarItems from './sidebarItems'

export class Account extends Component {
  constructor(props) {
    super(props)
    this.state = { sidebarItems }
  }

  componentDidMount() {
    const { canGiftSubscription, getUserInfo } = this.props
    const { sidebarItems } = this.state
    getUserInfo()
    const giftItem = {
      url: '/gift_subscription',
      name: 'Gift Subscription'
    }
    if (canGiftSubscription && !_.find(sidebarItems, giftItem)) {
      const newSidebarItems = this.state.sidebarItems
      newSidebarItems.push(giftItem)
      this.setState({ sidebarItems: newSidebarItems})
    }
  }

  componentDidUpdate(prevProps) {
    const { canGiftSubscription } = this.props
    const { sidebarItems } = this.state
    const giftItem = {
      url: '/gift_subscription',
      name: 'Gift Subscription'
    }
    if (!canGiftSubscription && (prevProps.canGiftSubscription !== canGiftSubscription)) {
      const filteredArray = sidebarItems
      filteredArray.pop()
      this.setState({ sidebarItems: filteredArray })
    }
    if (!prevProps.canGiftSubscription &&
      canGiftSubscription &&
      !_.find(sidebarItems, giftItem) &&
      prevProps.canGiftSubscription !== canGiftSubscription
      ) {
      const newSidebarItems = this.state.sidebarItems
      newSidebarItems.push(giftItem)
      this.setState({ sidebarItems: newSidebarItems })
    }
  }

  render() {
    return template.call(this)
  }
}

Account.propTypes = {
  subscription: PropTypes.shape({
    free: PropTypes.bool,
    rank: PropTypes.number,
    plan: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

Account.defaultProps = {
  subscription: null,
  location: {
    pathname: ''
  }
}

export default connect(stateSelector, dispatcherSelector)(Account)
