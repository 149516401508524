import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Slider from 'react-slick'
import _ from 'lodash'
import keyUpHandler from '../../utils/keyUpHandler'
import './styles.css'

const NextArrow = ({ className, onClick, style }) => (
  <div
    className={className}
    onClick={onClick}
    onKeyUp={(event) => keyUpHandler(event, onClick)}
    style={{ ...style }}
    tabIndex="0"
  >
    Next
  </div>
)

const PrevArrow = ({ className, onClick, style }) => (
  <div
    className={className}
    onClick={onClick}
    onKeyUp={(event) => keyUpHandler(event, onClick)}
    style={{ ...style }}
    tabIndex="0"
  >
    Previous
  </div>
)

export class SlideShow extends Component {
  render() {
    const { children, darkTheme, ...settings } = this.props
    const defaultSettings = {
      dots: true,
      focusOnSelect: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: settings.autoPlay,
      arrows: false,
      autoplaySpeed: 10000,
      pauseOnHover: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      ...settings
    }
    return (
      <div
        className={`slider-container${
          !!darkTheme ? ' slider-container--dark' : ''
        }`}
        ref={c => (this.sliderContainer = c)}
      >
        <Slider {...defaultSettings} ref={slider => (this.slider = slider)}>
          {children}
        </Slider>
      </div>
    )
  }
}

SlideShow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
}

SlideShow.defaultProps = {
  children: []
}

export default SlideShow
