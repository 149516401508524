/* eslint-disable import/prefer-default-export */
import { pageFetch } from './'
import { assetsFetch } from '../assets'

export const pageDispatchers = (dispatch, props) => ({
  loadPage(slug) {
    const page = slug || props.params.slug
    dispatch(pageFetch(page)()).then(([_, res]) => {
      dispatch(assetsFetch('page', res.payload.id, 50)())
    })
  }
})
