import { bannerPropType } from ".."
import template from './template.rt'
import './styles.css'

function BannerSlide(props) {
  return template.call({ props })
}

BannerSlide.propTypes = bannerPropType

export default BannerSlide
