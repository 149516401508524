import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import keyUpHandler from '../../utils/keyUpHandler'

import { subscriptionBannerSelector } from '../../../redux/modules/subscriptions/selectors'

import './styles.css'

function SubscriptionBanner(props) {
  useEffect(() => {
    if (
      props.bannerSubscriptionIsReady &&
      props.location &&
      props.location.pathname
    )
      if (
        props.location.pathname === '/' ||
        location.pathname.startsWith('/subscriptions/plans') ||
        location.pathname.startsWith('/checkout')
      )
        props.onClose()
  }, [props.location, props.bannerSubscriptionIsReady])

  const {
    subscriptionBannerText,
    subscriptionBannerButtonText,
    subscriptionBannerButtonUrl
  } = props.subscriptionPlansLanguage
  if (
    subscriptionBannerText &&
    subscriptionBannerButtonUrl &&
    subscriptionBannerButtonText &&
    props.bannerSubscriptionIsReady
  )
    return (
      <div
        id="SubscriptionBanner"
        className={`${props.isMobile ? 'is-mobile' : ''} ${
          props.userIsAuthenticated && !props.userIsVerified
            ? 'user-not-verified'
            : ''
        }`}
      >
        <div className="wrapper">
          <span className="banner-text">{subscriptionBannerText}</span>
          <a
            className="banner-button btn -small -border-orange"
            href={subscriptionBannerButtonUrl}
          >
            <span>{subscriptionBannerButtonText}</span>
          </a>
          <button
            onClick={() => props.onClose()}
            onKeyUp={event => keyUpHandler(event, props.onClose)}
            tabIndex="0"
            type="button"
            className="banner-close-icon"
          >
            <i aria-label="Close" className="icon-close" />
          </button>
        </div>
      </div>
    )
  return null
}

export default connect(subscriptionBannerSelector, null)(SubscriptionBanner)
