/* eslint-disable import/prefer-default-export */
import { createStructuredSelector, createSelector } from 'reselect'
import { isMobileSelector } from 'reduxModules/app/selectors'
import { isLoggedInSelector } from 'reduxModules/auth/selectors'

// selectors
export const specialVideosSelector = state => state.specialVideos.list
export const specialVideoSelector = state => state.specialVideos.detail
export const statusSelector = state => state.specialVideos.status

// states (mapStateToProps)
export const specialVideosStateSelector = createStructuredSelector({
  specialVideos: specialVideosSelector,
  status: statusSelector
})

export const specialVideoStateSelector = createSelector(
  [specialVideoSelector, statusSelector, isMobileSelector, isLoggedInSelector],
  (specialVideo, status, isMobile, isAuth) => ({
    ...specialVideo,
    status,
    isMobile,
    isAuth
  })
)

export const specialVideoPlayerStateSelector = createSelector(
  [specialVideoSelector, statusSelector, isMobileSelector],
  (tutorial, status, subscription, isMobile) => ({
    ...tutorial,
    status,
    subscription,
    isMobile
  })
)
