/* globals FACEBOOK_APP_ID */
import PropTypes from 'prop-types'
import React from 'react'
import { FacebookProvider, Like, LikeLayout } from 'react-facebook'

export function FacebookLikeButton({ url, layout }) {
  if (!FACEBOOK_APP_ID) return null

  return (
    <FacebookProvider appId={FACEBOOK_APP_ID} lazy>
      <Like share href={url} layout={layout} />
    </FacebookProvider>
  )
}

FacebookLikeButton.propTypes = {
  url: PropTypes.string,
  layout: PropTypes.string.isRequired
}

FacebookLikeButton.defaultProps = {
  url: undefined,
  layout: LikeLayout.BUTTON_COUNT
}

export default FacebookLikeButton
