/*
This function parse every url with params
into an url without params
parsePathname('/demo?test=example') = '/demo'
*/

export default pathname => {
  if (pathname === undefined) return undefined
  return pathname.split('?')[0]
}
