import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import getOverallDifficulty from '../../../LearningPaths/LearningPathDetail/utils';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('div', { 'className': 'my-learning-path' }, React.createElement('div', { 'className': 'clickable-overlay' }, React.createElement(Link, {
            'to': `/pathways/${ props.slug }`,
            'aria-label': props.title
        })), React.createElement('div', { 'className': 'left side' }, React.createElement('div', { 'className': 'title' }, React.createElement('span', {}, props.title)), React.createElement('div', { 'className': 'info' }, React.createElement('div', { 'className': 'courses-amount' }, React.createElement('i', { 'className': 'icon-stream' }), React.createElement('span', {}, props.numTutorials, ' Courses')), React.createElement('div', { 'className': 'duration' }, React.createElement('i', { 'className': 'icon-movies' }), React.createElement('div', { 'className': 'text' }, props.duration ? React.createElement('span', { 'key': '769' }, props.duration[0], 'h ') : null, React.createElement('span', {}, props.duration[1], 'm'), React.createElement('span', {}, 'of duration'))), React.createElement('div', { 'className': 'difficulty' }, React.createElement('i', { 'className': 'icon-level' + _.max(props.difficulty) }), React.createElement('span', {}, getOverallDifficulty(props.difficulty))))), React.createElement('div', { 'className': 'right side' }, React.createElement('div', {
            'style': { 'backgroundImage': `url(${ this.props.photo.default })` },
            'className': 'image'
        })));
    }
    return scopeProps1.apply(this, []);
}