import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import dispatchSelector from 'reduxModules/discountCode/dispatchers'
import stateSelector from 'reduxModules/discountCode/selectors'

import template from './template.rt'
import './styles.css'

export class DiscountCode extends Component {
  render() {
    return template.call(this)
  }
}

DiscountCode.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleDiscountCode: PropTypes.func.isRequired,
  showDiscountCodeForm: PropTypes.bool,
  discountCode: PropTypes.bool,
  className: PropTypes.string
}

DiscountCode.defaultProps = {
  showDiscountCodeForm: false,
  discountCode: false,
  className: undefined
}

export default connect(
  stateSelector,
  dispatchSelector
)(reduxForm({ form: 'discount-code' })(DiscountCode))
