export default [
  {
    url: '/account',
    name: 'Personal Info'
  },
  {
    url: '/history',
    name: 'Watch History'
  },
  {
    url: '/my_pathways',
    name: 'Learning Pathways'
  },
  {
    url: '/interests',
    name: 'Interests'
  },
  {
    url: '/favorites',
    name: 'Favorites'
  },
  {
    url: '/bookmarks',
    name: 'Bookmarks'
  },
  {
    url: '/courses',
    name: 'My Courses'
  }
]
