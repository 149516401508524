import { createSelector } from 'reselect'
import { dimensionsSelector } from 'reduxModules/app/selectors'
import { isLoggedInSelector } from 'reduxModules/auth/selectors'
import { isLibrarySubscriberSelector } from 'reduxModules/users/selectors'

const doesTitleMatch = (state, props) => props.title === state.menuItem.open
const activeSelector = state => state.menuItem.open

export default createSelector(
  [doesTitleMatch, isLibrarySubscriberSelector],
  (show, isLibrarySubscriber) => ({ show, isLibrarySubscriber })
)

export const mobileMenuSelector = createSelector(
  [activeSelector, dimensionsSelector, isLoggedInSelector],
  (active, dimensions, isLoggedIn) => ({ active, ...dimensions, isLoggedIn })
)
