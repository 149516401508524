import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import dispatchers from 'reduxModules/cart/dispatchers'
import './styles.css'

const addToCartButton = ({ children, addToCart, url, type = 'main' }) => (
  <button
    className={`add-to-cart-btn add-to-cart-btn--${type}`}
    onClick={() => addToCart(url)}
    type="button"
  >
    {children}
  </button>
)

addToCartButton.propTypes = {
  addToCart: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onCart: PropTypes.bool,
  type: PropTypes.oneOf(['main', 'secondary']),
  url: PropTypes.string.isRequired
}

addToCartButton.defaultProps = {
  onCart: false
}

export default connect(null, dispatchers)(addToCartButton)
