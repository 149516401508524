const getArtistTagline = (tags) => {
  const totalTags = tags.length
  switch (totalTags) {
    case 0:
      return ''
    case 1:
      return tags[0]
    case 2:
      return `${tags[0]} & ${tags[1]}`
    default:
      return `${tags[0]}, ${tags[1]} & ${tags[2]}`
  }
}

export default getArtistTagline
