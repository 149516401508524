import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import loadMoreDispatcher from 'reduxModules/generic/loadMore/dispatchers'
import loadMoreSelector from 'reduxModules/generic/loadMore/selectors'
import './styles.css'

const getWindowHeight = () => {
  if ('innerHeight' in window) return window.innerHeight
  return document.documentElement.offsetHeight
}

const fetchToBottom = props => {
  const { listContainerId, next, loadMore } = props
  const listDOMNode = document.getElementById(listContainerId)

  // if list hasn't reached bottom request more elements
  if (next && listDOMNode && listDOMNode.clientHeight < getWindowHeight() - 250)
    // substracting header and footer
    loadMore(next)
}

export class LoadMore extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      message: 'not at bottom'
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    fetchToBottom(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.next !== nextProps.next) fetchToBottom(nextProps)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom =
      getWindowHeight() + window.pageYOffset + this.props.loadingOffset
    if (windowBottom >= docHeight && this.props.next && !this.props.loading)
      this.props.loadMore(this.props.next)
  }

  render() {
    if (this.props.loading)
      return (
        <span className="load-more">
          <div className="spinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
          </div>
          Loading More...
        </span>
      )
    return null
  }
}

LoadMore.propTypes = {
  loadMore: PropTypes.func.isRequired,
  loadingOffset: PropTypes.number,
  fetchMoreAction: PropTypes.func.isRequired,
  next: PropTypes.string,
  loading: PropTypes.number.isRequired,
  listContainerId: PropTypes.string.isRequired
}

LoadMore.defaultProps = {
  next: null,
  loadingOffset: 200
}

export default connect(
  loadMoreSelector,
  loadMoreDispatcher
)(LoadMore)
