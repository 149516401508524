import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { userFavoritesReselector as stateSelector } from 'reduxModules/users/selectors'
import { favoritesDispatchers as dispatchSelector } from 'reduxModules/users/dispatchers'
import { favoritesStatus } from 'reduxModules/users'
import template from './template.rt'
import './styles.css'

export class FavoritesList extends Component {
  componentDidMount() {
    this.props.loadFavorites()
  }

  render() {
    if (this.props.status === favoritesStatus.success)
      return template.call(this)
    return null
  }
}

FavoritesList.propTypes = {
  loadFavorites: PropTypes.func.isRequired,
  favorites: PropTypes.objectOf(PropTypes.any),
  status: PropTypes.string
}

FavoritesList.defaultProps = {
  favorites: [],
  status: ''
}

export default connect(
  stateSelector,
  dispatchSelector
)(FavoritesList)
