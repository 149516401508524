import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import { OrderDetail } from '../generic/OrderDetail';
import PaymentMethodDetails from '../generic/PaymentMethodDetails';
import moment from 'moment';
import AddressDetails from '../generic/AddressDetails';
export default function () {
    function scopeMethod1(props, address) {
        var method = props.deliveryMethod;
        return React.createElement('div', {}, React.createElement('span', { 'className': 'accent' }, 'Delivery: '), React.createElement('span', {}, `${ method.name } ${ method.description }`));
    }
    function scopeAddress2(props) {
        var address = props.shippingAddress;
        return React.createElement('div', {
            'className': 'ship-to row',
            'key': '1570'
        }, React.createElement('div', { 'className': 'subheader' }, '\n                        ship to\n                    '), props.deliveryMethod ? React.createElement(AddressDetails, _.assign({}, { 'key': '1805' }, address)) : null, scopeMethod1.apply(this, [
            props,
            address
        ]));
    }
    function scopeAddressCreditCardPaymentPaypalPayment3(props) {
        var address = props.billingAddress;
        var creditCardPayment = props.creditCard;
        var paypalPayment = props.paypalAccount;
        return React.createElement('div', { 'className': 'bill-to row' }, !_.isEmpty(address) || !_.isEmpty(creditCardPayment) || !_.isEmpty(paypalPayment) ? React.createElement('div', {
            'className': 'subheader',
            'key': '2371'
        }, '\n                        bill to\n                    ') : null, !_.isEmpty(creditCardPayment) || !_.isEmpty(paypalPayment) ? React.createElement(PaymentMethodDetails, _.assign({}, {
            'width': props.width,
            'key': '2612'
        }, {
            creditCardPayment,
            paypalPayment
        })) : null, address && !_.isNull(address.firstName) ? [
            React.createElement('div', {
                'className': 'small-subheader',
                'key': '28861'
            }, React.createElement('span', {}, 'billing address')),
            React.createElement(AddressDetails, _.assign({}, { 'key': '28863' }, address))
        ] : null);
    }
    function scopeProps4() {
        var props = this.props;
        return React.createElement('div', { 'id': 'Receipt' }, React.createElement(Helmet, {}, React.createElement('title', {}, 'Receipt | Stan Winston School of Character Arts')), React.createElement('div', { 'className': 'your-receipt' }, React.createElement('div', { 'className': 'wrapper' }, React.createElement('h1', {}, 'your receipt'))), React.createElement('div', { 'className': 'hero' }, React.createElement('div', { 'className': 'icon' }, React.createElement('i', { 'className': 'icon-check' })), React.createElement('div', { 'className': 'message' }, React.createElement('span', {}, 'Your order has been placed')), React.createElement('div', { 'className': 'thanks' }, React.createElement('span', {}, 'Thank you for shopping at Stan Winston School of Character Arts'))), React.createElement('div', { 'className': 'order-background' }, React.createElement('div', { 'className': 'order-container wrapper' }, React.createElement('div', { 'className': 'column' }, React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'order-number' }, React.createElement('span', { 'className': 'accent' }, 'order number: '), React.createElement('span', {}, props.braintreeId)), React.createElement('div', { 'className': 'order-date' }, React.createElement('span', { 'className': 'accent' }, 'date: '), React.createElement('span', {}, moment(props.created).format(DISPLAY_DATE_FORMAT)))), !_.isEmpty(props.shippingAddress) ? scopeAddress2.apply(this, [props]) : null, scopeAddressCreditCardPaymentPaypalPayment3.apply(this, [props])), React.createElement('div', { 'className': 'order-details-table' }, React.createElement(OrderDetail, _.assign({}, { 'isLoaded': true }, props))))));
    }
    return scopeProps4.apply(this, []);
}