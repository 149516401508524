import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { userTutorialsReselector as stateSelector } from 'reduxModules/users/selectors'
import { tutorialsDispatchers as dispatchSelector } from 'reduxModules/users/dispatchers'
import template from './template.rt'
import './styles.css'

export const MAX_MONTHLY_COURSES = {
  basic: 4,
  default: 6
}

export class Courses extends Component {
  componentDidMount() {
    this.props.loadUserTutorials()
  }

  render() {
    if (!this.props.tutorials) return null
    return template.call(this)
  }
}

Courses.propTypes = {
  loadUserTutorials: PropTypes.func.isRequired,
  tutorials: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.number.isRequired
}

Courses.defaultProps = {
  tutorials: []
}

export default connect(
  stateSelector,
  dispatchSelector
)(Courses)
