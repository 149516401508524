import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { onChangePasswordDispatch as dispatchSelector } from 'reduxModules/auth/dispatchers'
import { validatePasswordMatching as validateChangePassword } from 'reduxModules/auth/validators'
import template from './template.rt'
import './styles.css'

export class ChangePassword extends Component {
  constructor(props) {
    super(props)
    props.hideGlobalNav()
  }

  componentWillUnmount() {
    this.props.showGlobalNav()
  }

  render() {
    return template.call(this)
  }
}

ChangePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hideGlobalNav: PropTypes.func.isRequired,
  showGlobalNav: PropTypes.func.isRequired
}

export const ChangePasswordWrapper = reduxForm({
  form: 'change-password',
  validate: validateChangePassword
})(ChangePassword)
export default connect(
  null,
  dispatchSelector
)(ChangePasswordWrapper)
