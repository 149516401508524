import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bookmarkedTutorialsReselector as stateSelector } from 'reduxModules/tutorials/selectors'
import { bookmarkedTutorialsDispatchers as dispatchSelector } from 'reduxModules/tutorials/dispatchers'
import template from './template.rt'

export class BookmarkList extends Component {
  componentDidMount() {
    this.props.fetchBookmarkedTutorials()
  }

  render() {
    return template.call(this)
  }
}

BookmarkList.propTypes = {
  fetchBookmarkedTutorials: PropTypes.func.isRequired
}

export default connect(
  stateSelector,
  dispatchSelector
)(BookmarkList)
