import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { types as userTypes } from 'reduxModules/users/'
import URL from 'url-parse'
import _ from 'lodash'

import fetchApi, { getUrl, fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  TUTORIALS_INITIAL
  TUTORIALS_FETCH_SUCCESS
  TUTORIALS_REQUEST_FAILURE
  TUTORIALS_SUCCESS
  TUTORIALS_FOR_SEARCH_FETCH_SUCCESS
  TUTORIALS_FOR_SEARCH_REQUEST_FAILURE
  TUTORIALS_ERROR
  TUTORIAL_FETCH_SUCCESS
  TUTORIAL_REQUEST_FAILURE
  TUTORIALS_FETCH_MORE_SUCCESS
  TUTORIALS_FECTH_MORE_REQUEST_FAILURE
  TUTORIALS_FILTER
  TUTORIAL_CLEAR
  TUTORIAL_LIST_CLEAR
  TOGGLE_PREVIEW_VIDEO
  SET_FILTER_LIST
  CLEAR_FILTER_LIST
  TOGGLE_LIST_FILTERS
  TUTORIALS_SET_AS_COMPLETED_SUCCESS
  TUTORIALS_SET_AS_COMPLETED_REQUEST_FAILURE
  TUTORIAL_CREATE_BOOKMARK_SUCCESS
  TUTORIAL_CREATE_BOOKMARK_REQUEST_FAILURE
  TUTORIAL_DELETE_BOOKMARK_SUCCESS
  TUTORIAL_DELETE_BOOKMARK_REQUEST_FAILURE
  BOOKMARKED_TUTORIALS_FETCH_SUCCESS
  BOOKMARKED_TUTORIALS_REQUEST_FAILURE
  TUTORIAL_FETCH_RECOMMENDS_SUCCESS
  TUTORIAL_FETCH_RECOMMENDS_FAILURE
  TUTORIALS_LATEST_FETCH_SUCCESS
  TUTORIALS_LATEST_REQUEST_FAILURE
  CLEAR_SEARCH_LIST
  TUTORIAL_USER_DATA_FETCH_SUCCESS
  TUTORIAL_USER_DATA_REQUEST_FAILURE
  TUTORIAL_USER_DATA_SUCCESS
  TUTORIAL_TRAILER_FETCH_SUCCESS
  TUTORIAL_TRAILER_REQUEST_FAILURE
  TUTORIAL_TRAILER_SUCCESS
`)

export const tutorialsStatus = {
  initial: types.TUTORIALS_INITIAL,
  success: types.TUTORIALS_SUCCESS,
  error: types.TUTORIALS_ERROR,
  filter: types.TUTORIALS_FILTER,
  userDetailSuccess: types.TUTORIAL_USER_DATA_SUCCESS,
  tutorialTrailerSuccess: types.TUTORIAL_TRAILER_SUCCESS
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: tutorialsStatus.initial,
  listFilters: {},
  showFilters: false,
  list: [],
  latest: [],
  detail: {
    artist: {
      photoThumbnail: {}
    },
    title: '',
    slug: '',
    body: '',
    materials: '',
    favorited: null,
    facebookShareImages: [],
    status: '',
    relatedTutorials: [],
    bookmarks: []
  },
  videoPreview: false,
  bookmarked: [],
  recommends: [],
  userDetailStatus: tutorialsStatus.initial,
  tutorialTrailerStatus: tutorialsStatus.initial
}

export const successList = (state = INITIAL_STATE, action) => {
  const next = action?.payload?.next
    ? new URL(action?.payload?.next, null, true)
    : null

  // if coming from a scrolling event, limit > 16, then reset it
  if (parseInt(next?.query?.limit, 10) > 16) next.query.limit = 16

  return {
    ...state,
    list: action.payload.results,
    status: tutorialsStatus.success,
    next: next ? next.toString() : null
  }
}

export const successLatestList = (state = INITIAL_STATE, action) => ({
  ...state,
  latest: action.payload.results,
  status: tutorialsStatus.success
})

export const successDetail = (state = INITIAL_STATE, action) => {
  // Merging of clone to prevent losing the video URLs when successDetail executes before successUserDataDetail
  const detailClone = _.cloneDeep(state.detail)
  const updatedDetail = _.merge(detailClone, action.payload)

  return {
    ...state,
    detail: updatedDetail,
    status: tutorialsStatus.success
  }
}

export const successUserDataDetail = (state = INITIAL_STATE, action) => {
  // Merging of clone to prevent losing the video URLs when successUserDataDetail executes after successDetail
  const detailClone = _.cloneDeep(state.detail)
  const updatedDetail = _.merge(detailClone, action.payload)

  return {
    ...state,
    detail: updatedDetail,
    userDetailStatus: tutorialsStatus.userDetailSuccess
  }
}

export const successTutorialTrailer = (state = INITIAL_STATE, action) => {
  // Merging of clone to prevent losing the video URLs when successUserDataDetail executes after successDetail
  const detailClone = _.cloneDeep(state.detail)
  const updatedDetail = _.merge(detailClone, action.payload)

  return {
    ...state,
    detail: updatedDetail,
    tutorialTrailerStatus: tutorialsStatus.tutorialTrailerSuccess
  }
}

export const failureList = (state = INITIAL_STATE) => ({
  ...state,
  status: tutorialsStatus.error
})

export const failureLatestList = (state = INITIAL_STATE) => ({
  ...state,
  status: tutorialsStatus.error
})

export const successListMore = (state = INITIAL_STATE, action) => ({
  ...state,
  list: [
    ...state.list,
    ...(_.has(action, 'payload.results') ? action.payload.results : [])
  ],
  next: action.payload.next,
  status: tutorialsStatus.success
})

export const failureDetail = (state = INITIAL_STATE) => ({
  ...state,
  detail: INITIAL_STATE.detail,
  status: tutorialsStatus.error
})

export const failureUserDataDetail = (state = INITIAL_STATE) => ({
  ...state
})

export const successSetFavorite = (state = INITIAL_STATE, action) => {
  const objectType = action.payload.favoritedModel
  if (objectType === 'videotutorial') {
    const objectId = action.payload.id
    return {
      ...state,
      detail: {
        ...state.detail,
        favorited: objectId
      },
      status: tutorialsStatus.success
    }
  }
  return state
}

export const successUnsetFavorite = (state = INITIAL_STATE, action) => {
  const objectType = action.payload.favoritedModel
  if (objectType === 'videotutorial')
    return {
      ...state,
      detail: {
        ...state.detail,
        favorited: null
      },
      status: tutorialsStatus.success
    }
  return state
}

export const clearListSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: tutorialsStatus.initial,
  list: INITIAL_STATE.list
})

export const clearSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: tutorialsStatus.initial,
  detail: INITIAL_STATE.detail
})

export const toggleVideo = (state = INITIAL_STATE) => ({
  ...state,
  detail: { ...state.detail, videoPreview: !state.detail.videoPreview }
})

export const setFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  listFilters: action.payload
})

export const clearFilters = (state = INITIAL_STATE) => ({
  ...state,
  listFilters: _.omit(state.listFilters, ['difficulty', 'tags'])
})

export const clearSearch = (state = INITIAL_STATE) => ({
  ...state,
  listFilters: _.omit(state.listFilters, 'search')
})

export const toggleListFilters = (state = INITIAL_STATE) => ({
  ...state,
  showFilters: !state.showFilters
})

export const successCreateBookmark = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: {
    ...state.detail,
    bookmarks: [...state.detail.bookmarks, action.payload]
  }
})

export const successRemoveBookmark = (state = INITIAL_STATE, action) => {
  const bookmarkId = action.payload.requestBody.pk
  let tutorialsClone

  if (_.size(state.bookmarked) > 0) {
    tutorialsClone = _.cloneDeep(state.bookmarked)
    const tutorial = _.find(tutorialsClone, { bookmarks: [{ id: bookmarkId }] })
    const tutorialKey = _.findKey(tutorialsClone, { id: tutorial.id })

    if (_.has(tutorialsClone[tutorialKey], 'bookmarks'))
      tutorialsClone[tutorialKey].bookmarks = _.reject(
        tutorialsClone[tutorialKey].bookmarks,
        { id: bookmarkId }
      )
  }

  return {
    ...state,
    detail: {
      ...state.detail,
      bookmarks: [..._.reject(state.detail.bookmarks, { id: bookmarkId })]
    },
    bookmarked: {
      ...state.bookmarked,
      ...tutorialsClone
    }
  }
}

export const successFetchRecommends = (state = INITIAL_STATE, action) => ({
  ...state,
  recommends: Object.keys(action.payload).map(item => action.payload[item])
})

export const successFetchBookmarkedTutorials = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  bookmarked: action.payload
})

export const filterTutorials = (state = INITIAL_STATE) => ({
  ...state,
  status: tutorialsStatus.filter
})

export const HANDLERS = {
  [types.TUTORIALS_FETCH_SUCCESS]: successList,
  [types.TUTORIALS_REQUEST_FAILURE]: failureList,
  [types.TUTORIALS_LATEST_FETCH_SUCCESS]: successLatestList,
  [types.TUTORIALS_LATEST_REQUEST_FAILURE]: failureLatestList,
  [types.TUTORIAL_USER_DATA_FETCH_SUCCESS]: successUserDataDetail,
  [types.TUTORIAL_FETCH_SUCCESS]: successDetail,
  [types.TUTORIALS_FOR_SEARCH_FETCH_SUCCESS]: successList,
  [types.TUTORIAL_REQUEST_FAILURE]: failureDetail,
  [types.TUTORIAL_USER_DATA_REQUEST_FAILURE]: failureUserDataDetail,
  [types.TUTORIALS_FETCH_MORE_SUCCESS]: successListMore,
  [types.TUTORIALS_FECTH_MORE_REQUEST_FAILURE]: failureList,
  [userTypes.USERS_FAVORITE_SET_SUCCESS]: successSetFavorite,
  [userTypes.USERS_FAVORITE_UNSET_SUCCESS]: successUnsetFavorite,
  [types.TUTORIAL_CLEAR]: clearSuccess,
  [types.TUTORIAL_LIST_CLEAR]: clearListSuccess,
  [types.TOGGLE_PREVIEW_VIDEO]: toggleVideo,
  [types.SET_FILTER_LIST]: setFilter,
  [types.TUTORIALS_FILTER]: filterTutorials,
  [types.CLEAR_SEARCH_LIST]: clearSearch,
  [types.CLEAR_FILTER_LIST]: clearFilters,
  [types.TOGGLE_LIST_FILTERS]: toggleListFilters,
  [types.TUTORIAL_CREATE_BOOKMARK_SUCCESS]: successCreateBookmark,
  [types.TUTORIAL_DELETE_BOOKMARK_SUCCESS]: successRemoveBookmark,
  [types.BOOKMARKED_TUTORIALS_FETCH_SUCCESS]: successFetchBookmarkedTutorials,
  [types.TUTORIAL_FETCH_RECOMMENDS_SUCCESS]: successFetchRecommends,
  [types.TUTORIAL_TRAILER_FETCH_SUCCESS]: successTutorialTrailer
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const tutorialsFetchSuccess = createAction(types.TUTORIALS_FETCH_SUCCESS)
export const tutorialsFetchFailure = createAction(
  types.TUTORIALS_REQUEST_FAILURE
)
export const tutorialsFetch = filters => {
  const url = getUrl('/tutorials/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    tutorialsFetchSuccess,
    tutorialsFetchFailure
  )
}

export const tutorialsForSearchFetchSuccess = createAction(
  types.TUTORIALS_FOR_SEARCH_FETCH_SUCCESS
)
export const tutorialsForSearchFetchFailure = createAction(
  types.TUTORIALS_FOR_SEARCH_REQUEST_FAILURE
)
export const tutorialsForSearchFetch = filters => {
  const url = getUrl('/new-search/video-tutorial/', {
    ...filters,
    ...(!_.has(filters, 'search') && { ordering: '-order_date' })
  })
  return fetchApi(
    url,
    { method: 'GET' },
    tutorialsForSearchFetchSuccess,
    tutorialsForSearchFetchFailure
  )
}

export const latestTutorialsFetchSuccess = createAction(
  types.TUTORIALS_LATEST_FETCH_SUCCESS
)
export const latestTutorialsFetchFailure = createAction(
  types.TUTORIALS_LATEST_REQUEST_FAILURE
)
export const latestTutorialsFetch = () => {
  const url = getUrl('/tutorials/latest/')
  return fetchApi(
    url,
    { method: 'GET' },
    latestTutorialsFetchSuccess,
    latestTutorialsFetchFailure
  )
}

export const tutorialFetchSuccess = createAction(types.TUTORIAL_FETCH_SUCCESS)
export const tutorialFetchFailure = createAction(types.TUTORIAL_REQUEST_FAILURE)
export const tutorialFetch = (slug, learningPath) => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  let url = `/tutorials/${slug}/`
  if (learningPath) url = `${url}?path=${learningPath}`
  return fetchApi(
    url,
    { method: 'GET' },
    tutorialFetchSuccess,
    tutorialFetchFailure
  )
}

export const tutorialUserDataFetchSuccess = createAction(
  types.TUTORIAL_USER_DATA_FETCH_SUCCESS
)

export const tutorialUserDataFetchFailure = createAction(
  types.TUTORIAL_USER_DATA_REQUEST_FAILURE
)

export const tutorialUserDataFetch = (slug, learningPath) => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  let url = `/tutorials/${slug}/user/`
  if (learningPath) url = `${url}?path=${learningPath}`
  return fetchApi(
    url,
    { method: 'GET' },
    tutorialUserDataFetchSuccess,
    tutorialUserDataFetchFailure
  )
}

export const tutorialTrailerFetchSuccess = createAction(
  types.TUTORIAL_TRAILER_FETCH_SUCCESS
)

export const tutorialTrailerFetchFailure = createAction(
  types.TUTORIAL_TRAILER_REQUEST_FAILURE
)
export const tutorialTrailerFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  const url = `/tutorials/${slug}/trailer/`
  return fetchApi(
    url,
    { method: 'GET' },
    tutorialTrailerFetchSuccess,
    tutorialTrailerFetchFailure
  )
}

export const tutorialsFetchMoreSuccess = createAction(
  types.TUTORIALS_FETCH_MORE_SUCCESS
)
export const tutorialsFetchMoreFailure = createAction(
  types.TUTORIALS_FECTH_MORE_REQUEST_FAILURE
)
export const tutorialsFetchMore = next => {
  const url = getUrl(next, {})
  return fetchHelper(
    url,
    { method: 'GET' },
    tutorialsFetchMoreSuccess,
    tutorialsFetchMoreFailure
  )
}

export const tutorialFetchRecommendsSuccess = createAction(
  types.TUTORIAL_FETCH_RECOMMENDS_SUCCESS
)
export const tutorialFetchRecommendsFailure = createAction(
  types.TUTORIAL_FETCH_RECOMMENDS_FAILURE
)
export const tutorialFetchRecommends = (tutorial, path) => {
  let url = `/tutorials/${tutorial}/next_tutorials/`
  if (path) url = `${url}?path_id=${path}`
  return fetchApi(
    url,
    { method: 'GET' },
    tutorialFetchRecommendsSuccess,
    tutorialFetchRecommendsFailure
  )
}

export const clearTutorial = createAction(types.TUTORIAL_CLEAR)

export const clearTutorialList = createAction(types.TUTORIAL_LIST_CLEAR)

export const setFilterList = createAction(types.SET_FILTER_LIST)

export const loadFilterList = createAction(types.TUTORIALS_FILTER)

export const clearFilterList = createAction(types.CLEAR_FILTER_LIST)

export const clearSearchList = createAction(types.CLEAR_SEARCH_LIST)

export const togglePreviewVideo = createAction(types.TOGGLE_PREVIEW_VIDEO)

export const toggleFilters = createAction(types.TOGGLE_LIST_FILTERS)

export const setChapterAsCompletedSuccess = createAction(
  types.TUTORIALS_SET_AS_COMPLETED_SUCCESS
)
export const setChapterAsCompletedFailure = createAction(
  types.TUTORIALS_SET_AS_COMPLETED_REQUEST_FAILURE
)
export const setChapterAsCompleted = (slug, chapterId) => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/tutorials/${slug}/mark_completed/`,
    { method: 'POST' },
    setChapterAsCompletedSuccess,
    setChapterAsCompletedFailure
  )({ chapterId })
}

export const createBookmarkSuccess = createAction(
  types.TUTORIAL_CREATE_BOOKMARK_SUCCESS
)
export const createBookmarkFailure = createAction(
  types.TUTORIAL_CREATE_BOOKMARK_REQUEST_FAILURE
)
export const createBookmark = fetchApi(
  '/bookmarks/',
  { method: 'POST' },
  createBookmarkSuccess,
  createBookmarkFailure
)

export const deleteBookmarkSuccess = createAction(
  types.TUTORIAL_DELETE_BOOKMARK_SUCCESS
)
export const deleteBookmarkFailure = createAction(
  types.TUTORIAL_DELETE_BOOKMARK_REQUEST_FAILURE
)
export const deleteBookmark = pk => {
  if (typeof pk === 'undefined') throw TypeError('id param cannot be undefined')
  return fetchApi(
    `/bookmarks/${pk}/`,
    { method: 'DELETE' },
    deleteBookmarkSuccess,
    deleteBookmarkFailure,
    { requestBodyOnSuccess: true }
  )({ pk })
}

export const bookmarkedTutorialsFetchSuccess = createAction(
  types.BOOKMARKED_TUTORIALS_FETCH_SUCCESS
)
export const bookmarkedTutorialsFetchFailure = createAction(
  types.BOOKMARKED_TUTORIALS_REQUEST_FAILURE
)
export const getBookmarkedTutorials = () =>
  fetchApi(
    '/tutorials/bookmarked/',
    { method: 'GET' },
    bookmarkedTutorialsFetchSuccess,
    bookmarkedTutorialsFetchFailure
  )
