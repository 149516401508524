import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { types as tutorialTypes } from 'reduxModules/tutorials'
import fetchApi from '../utils/fetch'

//
// TYPES
// -----------------------------------------------------------------------------

export const types = createTypes(`
  SEARCH_INITIAL
  SEARCH_FACETED_SUCCESS
  SEARCH_SUCCESS
  SEARCH_ERROR
  SET_SEARCH_CATEGORY
  GENERATE_CATEGORIES
  SEARCH_SUGGESTIONS_SUCCESS
  SEARCH_SUGGESTIONS_ERROR
  CLEAR_SUGGESTIONS
`)

export const searchStatus = {
  initial: types.SEARCH_INITIAL,
  facetedSuccess: types.SEARCH_FACETED_SUCCESS,
  success: types.SEARCH_SUCCESS,
  error: types.SEARCH_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: searchStatus.initial,
  tags: [],
  results: {
    artists: {
      count: 0,
      next: null,
      previous: null,
      results: []
    },
    posts: {
      count: 0,
      next: null,
      previous: null,
      results: []
    },
    tutorials: {
      count: 0,
      next: null,
      previous: null,
      results: []
    },
    webinar: {
      count: 0,
      next: null,
      previous: null,
      results: []
    }
  },
  activeCategory: 'all',
  categories: [],
  autocompleteResults: {
    posts: [],
    tutorials: [],
    suggestions: []
  }
}

export const facetedSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  tags: action.payload.fields.tags,
  results: action.payload.objects.results,
  status: searchStatus.facetedSuccess
})

export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  results: action.payload,
  status: searchStatus.success
})

export const error = (state = INITIAL_STATE) => ({
  ...state,
  status: searchStatus.error
})

export const setCategorySuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  activeCategory: action.payload
})

export const generateCategoriesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  categories: action.payload
})

export const clearSearchResults = (state = INITIAL_STATE) => ({
  ...state,
  status: searchStatus.initial,
  ...INITIAL_STATE
})

export const setSuggestionsResults = (state = INITIAL_STATE, action) => ({
  ...state,
  autocompleteResults: action.payload,
  status: searchStatus.success
})

export const clearSuggestionsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: searchStatus.initial,
  autocompleteResults: INITIAL_STATE.autocompleteResults
})

export const HANDLERS = {
  [types.SEARCH_FACETED_SUCCESS]: facetedSuccess,
  [types.SEARCH_SUCCESS]: success,
  [types.SEARCH_ERROR]: error,
  [types.SET_SEARCH_CATEGORY]: setCategorySuccess,
  [types.GENERATE_CATEGORIES]: generateCategoriesSuccess,
  [tutorialTypes.TUTORIAL_CLEAR]: clearSearchResults, // TODO: it can be cleaned
  [types.SEARCH_INITIAL]: clearSearchResults,
  [types.SEARCH_SUGGESTIONS_SUCCESS]: setSuggestionsResults,
  [types.CLEAR_SUGGESTIONS]: clearSuggestionsSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------
export const searchError = createAction(types.SEARCH_ERROR)

export const facetedSearchSuccess = createAction(types.SEARCH_FACETED_SUCCESS)
export const facetedSearchFetch = searchParams =>
  fetchApi(
    `/search/facets/${searchParams}`,
    { method: 'GET' },
    facetedSearchSuccess,
    searchError
  )

export const searchSuccess = createAction(types.SEARCH_SUCCESS)
export const searchFetch = params =>
  fetchApi(
    `/new-search/global/?search=${params.q || params.text}`,
    { method: 'GET' },
    searchSuccess,
    searchError
  )

export const setCategory = createAction(types.SET_SEARCH_CATEGORY)
export const generateCategories = createAction(types.GENERATE_CATEGORIES)
export const clearResults = createAction(types.SEARCH_INITIAL)

export const searchSuggestError = createAction(types.SEARCH_SUGGESTIONS_ERROR)
export const searchSuggestSuccess = createAction(
  types.SEARCH_SUGGESTIONS_SUCCESS
)
export const searchSuggestFetch = query =>
  fetchApi(
    `/new-search/autocomplete/?search=${query}`,
    { method: 'GET' },
    searchSuggestSuccess,
    searchSuggestError
  )

export const clearSuggestions = createAction(types.CLEAR_SUGGESTIONS)
