/* eslint-disable no-underscore-dangle */
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { reviewOrderSelector as stateSelector } from 'reduxModules/checkout/selectors'
import { reviewOrderDispatchers as mergeProps } from 'reduxModules/checkout/dispatchers'
import initPayPalFlow from 'reduxModules/utils/paypalFlow'
import { cartPropTypes } from 'components/Cart'
import { deliveryMethodsPropTypes } from 'components/Checkout/DeliveryMethods'
import { addressProptypes } from 'components/Account/Addresses/AddressList/Address'
import addFraudProtection from 'components/utils/addFraudProtection'

import withAsyncScriptLoader from '../../hooks/useScriptAsync'

import template from './template.rt'
import './styles.css'

export class ReviewOrder extends Component {
  constructor(props) {
    super(props)
    props.hideInnerNav()
    this.state = {
      proceedToSubscribe: false,
      initPayPalFlow: true
    }
    this.proceedPlaceOrder = this.proceedPlaceOrder.bind(this)
    this.proceedPlaceSubscription = this.proceedPlaceSubscription.bind(this)
    this._initPayPalFlow = this._initPayPalFlow.bind(this)
  }

  componentDidMount() {
    const { clientToken, getBraintreeClientToken, isLoggedIn } = this.props
    if (_.isEmpty(clientToken) && isLoggedIn) getBraintreeClientToken()
  }

  componentDidUpdate() {
    const {
      clientToken,
      isSubscribing,
      payWithCreditCard,
      showFraudProtectionError,
      useExistentPaymentMethod
    } = this.props
    const plan = isSubscribing ? this.props.planSelected.id : null

    // PayPal flow
    if (clientToken && !payWithCreditCard && !useExistentPaymentMethod)
      addFraudProtection(
        this._initPayPalFlow,
        [plan],
        clientToken,
        showFraudProtectionError
      )
  }

  componentWillUnmount() {
    const { showInnerNav, clearCheckout, router } = this.props
    if (router.location.pathname !== '/checkout')
      // if aborting checkout, clear state
      clearCheckout()
    showInnerNav()
  }

  _initPayPalFlow(deviceData, plan) {
    const {
      clientToken,
      isSubscribing,
      order,
      orderTotal,
      placePayPalOrder,
      placePayPalSubscription,
      usedFreeTrial,
      userEmail
    } = this.props
    const handler = isSubscribing ? placePayPalSubscription : placePayPalOrder
    initPayPalFlow(
      clientToken,
      orderTotal,
      handler,
      order,
      isSubscribing,
      plan,
      usedFreeTrial,
      deviceData,
      userEmail
    )
  }

  proceedPlaceOrder() {
    this.props.placeOrder(this.props.order)
  }

  proceedPlaceSubscription(planId) {
    this.props.placeSubscription(
      this.props.order,
      planId,
      this.props.usedFreeTrial,
      this.props.userEmail
    )
  }

  render() {
    return template.call(this)
  }
}

ReviewOrder.propTypes = {
  cart: PropTypes.shape(cartPropTypes).isRequired,
  clientToken: PropTypes.string.isRequired,
  deliveryMethodDetails: PropTypes.shape(deliveryMethodsPropTypes),
  order: PropTypes.shape({
    billingAddress: PropTypes.shape(addressProptypes),
    delivery: PropTypes.shape({
      code: PropTypes.string
    }),
    payment: PropTypes.oneOfType([
      PropTypes.shape({ paymentMethodToken: PropTypes.string }),
      PropTypes.shape({ paymentMethodNonce: PropTypes.string })
    ]),
    shippingAddress: PropTypes.shape(addressProptypes)
  }).isRequired,
  planSelected: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rank: PropTypes.number.isRequired
  }),
  orderTotal: PropTypes.string.isRequired,
  payWithCreditCard: PropTypes.bool.isRequired,
  placeOrder: PropTypes.func.isRequired,
  placePayPalOrder: PropTypes.func.isRequired,
  tax: PropTypes.number.isRequired,
  isSubscribing: PropTypes.bool.isRequired,
  placePayPalSubscription: PropTypes.func.isRequired,
  showInnerNav: PropTypes.func.isRequired,
  hideInnerNav: PropTypes.func.isRequired,
  clearCheckout: PropTypes.func.isRequired,
  router: PropTypes.shape([
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ]).isRequired,
  useExistentPaymentMethod: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  usedFreeTrial: PropTypes.bool.isRequired
}

ReviewOrder.defaultProps = {
  deliveryMethodDetails: {},
  planSelected: {}
}

export default withAsyncScriptLoader([
  'https://www.paypalobjects.com/api/checkout.min.js',
  'https://js.braintreegateway.com/web/3.26.0/js/client.min.js',
  'https://js.braintreegateway.com/web/3.26.0/js/paypal-checkout.min.js'
])(connect(stateSelector, null, mergeProps)(ReviewOrder))
