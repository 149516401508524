import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { onResetPasswordDispatch as dispatchSelector } from 'reduxModules/auth/dispatchers'
import { validatePasswordMatching as validateResetPassword } from 'reduxModules/auth/validators'

import template from './template.rt'

export class ResetPassword extends Component {
  constructor(props) {
    super(props)
    props.hideGlobalNav()
  }

  componentWillUnmount() {
    this.props.showGlobalNav()
  }

  render() {
    return template.call(this)
  }
}

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hideGlobalNav: PropTypes.func.isRequired,
  showGlobalNav: PropTypes.func.isRequired
}

export const ResetPasswordWrapper = reduxForm({
  form: 'reset-password',
  validate: validateResetPassword
})(ResetPassword)
export default connect(
  null,
  dispatchSelector
)(ResetPasswordWrapper)
