import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function ProfileSummary(props) {
  return template.call({ props })
}

ProfileSummary.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  variant: PropTypes.oneOf(['artist', 'teamMember']),
  elementName: PropTypes.string
}

export default ProfileSummary
