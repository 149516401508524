import React from 'react'
import Switch from '../../generic/Switch'

function RelevantToggle({
  toggleShowingOnlyRelevantChapters,
  shouldOnlyShowRelevants
}) {
  return (
    <div className="switch-relevant">
      <span className="switch-relevant__text">
        Chapters relevant to your pathway
      </span>
      <Switch
        isOn={shouldOnlyShowRelevants}
        handleToggle={() => {
          toggleShowingOnlyRelevantChapters()
        }}
      />
    </div>
  )
}
export default RelevantToggle
