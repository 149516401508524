import * as React from 'react';
import * as _ from 'lodash';
import RadialProgress from '../../generic/RadialProgress';
import FacebookLikeButton from '../../generic/FacebookLikeButton';
import LearningPathCourses from './LearningPathCourses';
import RadialProgressCategory from './RadialProgressCategory';
import { Link } from 'react-router';
import Artist from '../../generic/ProfileSummary';
import SlideShow from '../../generic/SlideShow';
import VideoModal from '../../generic/VideoModal';
import getOverallDifficulty from './utils';
import Helmet from 'react-helmet';
import SubscribeDialogCTA from '../../App/SubscribeDialogCTA';
export default function () {
    function repeatFbImage1(props, numCategories, owned, isSubscriber, onTrial, metaTitle, metaDescription, fbImage, fbImageIndex) {
        return React.createElement('meta', {
            'property': 'og:image',
            'content': fbImage.original,
            'key': fbImageIndex
        });
    }
    function scopeMetaTitleMetaDescription2(props, numCategories, owned, isSubscriber, onTrial) {
        var metaTitle = props.metaTitle || props.title;
        var metaDescription = props.metaDescription || props.description;
        return React.createElement.apply(this, [
            Helmet,
            {},
            React.createElement('title', {}, metaTitle, ' | Stan Winston School of Character Arts'),
            React.createElement('meta', {
                'name': 'description',
                'content': metaDescription
            }),
            React.createElement('meta', {
                'property': 'og:title',
                'content': metaTitle
            }),
            React.createElement('meta', {
                'property': 'og:description',
                'content': metaDescription
            }),
            !props.facebookShareImages.length && _.has(props, 'photo.original') ? React.createElement('meta', {
                'property': 'og:image',
                'content': props.photo.original,
                'key': '1461'
            }) : null,
            props.facebookShareImages.length ? _.map(props.facebookShareImages, repeatFbImage1.bind(this, props, numCategories, owned, isSubscriber, onTrial, metaTitle, metaDescription)) : null,
            React.createElement('meta', {
                'property': 'og:type',
                'content': 'article'
            })
        ]);
    }
    function scopeCourseProgress3(props, numCategories, owned, isSubscriber, onTrial) {
        var courseProgress = _.sumBy(props.statsByCategory, 'completed');
        return React.createElement('div', {
            'className': 'progress',
            'key': '2072'
        }, owned ? React.createElement('span', { 'key': '2190' }, `${ courseProgress } course${ courseProgress === 1 ? '' : 's' } completed of ${ props.numTutorials }`) : null, props.numTutorials > 0 ? React.createElement('div', {
            'className': 'progress-line',
            'key': '2323'
        }, React.createElement('div', {
            'style': { width: props.overallProgress + '%' },
            'className': 'current-progress'
        })) : null);
    }
    function repeatProgress4(props, numCategories, owned, isSubscriber, onTrial, radialProgressSize, categoriesShouldScroll, progress, progressIndex) {
        return React.createElement(RadialProgressCategory, {
            'current': progress,
            'amount': numCategories,
            'key': progressIndex,
            'owned': owned,
            'filtrable': true,
            'filterActive': this.state.filter.includes(progress.category),
            'filtered': this.state.filter,
            'onClick': () => this.filterCourse(progress.category)
        });
    }
    function repeatProgress5(props, numCategories, owned, isSubscriber, onTrial, radialProgressSize, categoriesShouldScroll, progress, progressIndex) {
        return React.createElement('div', { 'key': progressIndex }, React.createElement(RadialProgressCategory, {
            'current': progress,
            'amount': numCategories,
            'owned': owned,
            'onClick': this.filterCourse
        }));
    }
    function scopeRadialProgressSizeCategoriesShouldScroll6(props, numCategories, owned, isSubscriber, onTrial) {
        var radialProgressSize = this.props.width / (numCategories + 3);
        var categoriesShouldScroll = numCategories > 3 && this.props.width < numCategories * 150;
        return React.createElement.apply(this, [
            'div',
            {
                'className': 'categories-progress-container' + ' ' + _.transform({ static: !categoriesShouldScroll }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' '),
                'key': '2502'
            },
            !categoriesShouldScroll ? _.map(props.statsByCategory, repeatProgress4.bind(this, props, numCategories, owned, isSubscriber, onTrial, radialProgressSize, categoriesShouldScroll)) : null,
            categoriesShouldScroll ? React.createElement('div', {
                'className': 'slide-categories',
                'key': '3294'
            }, React.createElement.apply(this, [
                SlideShow,
                {
                    'arrows': false,
                    'draggable': this.props.isMobile,
                    'dots': true,
                    'autoPlay': false,
                    'slidesToShow': this.props.isMobile ? 2 : 3,
                    'slidesToScroll': 1
                },
                _.map(props.statsByCategory, repeatProgress5.bind(this, props, numCategories, owned, isSubscriber, onTrial, radialProgressSize, categoriesShouldScroll))
            ])) : null
        ]);
    }
    function repeatArtist7(props, numCategories, owned, isSubscriber, onTrial, artist, artistIndex) {
        return React.createElement(Artist, {
            'className': 'artist',
            'key': 'artist-' + artistIndex,
            'name': artist.title,
            'photo': artist.photo.boxThumb,
            'slug': artist.slug
        });
    }
    function scopePropsNumCategoriesOwnedIsSubscriberOnTrial8() {
        var props = this.props;
        var numCategories = _.size(props.statsByCategory);
        var owned = props.owned;
        var isSubscriber = props.isSubscriber;
        var onTrial = props.subscription && props.subscription.onTrial;
        return React.createElement('article', { 'id': 'LearningPathDetail' }, scopeMetaTitleMetaDescription2.apply(this, [
            props,
            numCategories,
            owned,
            isSubscriber,
            onTrial
        ]), React.createElement('div', {
            'className': 'header',
            'style': { 'backgroundImage': `url(${ props.photo.original })` }
        }, React.createElement('div', { 'className': 'overlay' }), React.createElement('div', { 'className': 'wrapper' }, React.createElement('h1', { 'className': 'title' }, props.title), React.createElement('h2', { 'className': 'tagline' }, props.tagline), owned ? scopeCourseProgress3.apply(this, [
            props,
            numCategories,
            owned,
            isSubscriber,
            onTrial
        ]) : null, numCategories > 0 ? scopeRadialProgressSizeCategoriesShouldScroll6.apply(this, [
            props,
            numCategories,
            owned,
            isSubscriber,
            onTrial
        ]) : null, _.size(props.courses) > 0 ? React.createElement('div', {
            'className': 'continue-btn',
            'key': '3971'
        }, !isSubscriber ? React.createElement(SubscribeDialogCTA, { 'key': '4040' }, React.createElement('div', { 'className': 'btn -wide -orange wide-webkit' }, React.createElement('span', {}, 'subscribe'))) : null, isSubscriber ? React.createElement('button', {
            'type': 'button',
            'className': 'btn -wide -blue-2',
            'onClick': () => this.onClickMainCTA(),
            'key': '4227'
        }, '\n          ', this.state.cta, '\n        ') : null) : null)), React.createElement('div', { 'className': 'description wrapper-1080' }, React.createElement('div', { 'className': 'overview' }, React.createElement('section', { 'className': 'heading' }, React.createElement('h1', {}, 'Overview')), React.createElement('p', {}, props.overview)), React.createElement('div', { 'className': 'info' }, React.createElement('div', { 'className': 'difficulty' }, React.createElement('i', { 'className': 'icon-level' + _.max(props.difficulty) }), React.createElement('span', {}, getOverallDifficulty(props.difficulty))), React.createElement('div', { 'className': 'courses-amount' }, React.createElement('i', { 'className': 'icon-stream' }), React.createElement('span', {}, props.numTutorials, ' Courses')), React.createElement('div', { 'className': 'duration' }, React.createElement('i', { 'className': 'fa-clock' }), props.duration ? React.createElement('span', { 'key': '5027' }, props.duration[0], 'h ') : null, React.createElement('span', {}, props.duration[1], 'm'), '\n        of duration\n      '), React.createElement('hr', {}), React.createElement('div', { 'className': 'forum' }, React.createElement('a', {
            'href': 'https://forums.stanwinstonschool.com',
            'target': '_blank'
        }, React.createElement('i', { 'className': 'icon icon-forum' }), React.createElement('span', {}, 'Discuss on our Forums'))), React.createElement('hr', {}), React.createElement('div', { 'className': 'share' }, React.createElement('h3', {}, 'Share'), React.createElement('div', { 'className': 'networks' }, React.createElement(FacebookLikeButton, { 'url': props.socialUrl }))))), React.createElement('div', { 'className': 'wrapper learning-path-courses' }, React.createElement(LearningPathCourses, {
            'courses': props.courses || [],
            'filter': this.state.filter,
            'key': props.courses.length,
            'loadLearningPathwayCourse': props.loadLearningPathwayCourse,
            'loadBookmarkContext': { location: props.location },
            'owned': props.owned,
            'pathId': props.id,
            'routePath': props.route.path,
            'setVideoPreview': props.setVideoPreview,
            'slug': props.slug,
            'isEnrolled': props.isEnrolled,
            'isBasicSubscriber': props.subscription && props.subscription.plan === 'Basic'
        })), React.createElement('div', { 'className': 'wrapper' }, React.createElement('section', { 'className': 'heading' }, React.createElement('h1', {}, 'Teachers in this Specialization')), React.createElement.apply(this, [
            'div',
            { 'className': 'teachers' },
            _.map(props.artists, repeatArtist7.bind(this, props, numCategories, owned, isSubscriber, onTrial))
        ])), this.props.videoPreview ? React.createElement(VideoModal, {
            'url': this.props.videoPreviewDetail.path,
            'visible': this.props.videoPreview,
            'close': () => this.props.closeVideoPreview(),
            'isYoutube': this.props.videoPreviewDetail.isYoutube,
            'key': '6550'
        }) : null);
    }
    return scopePropsNumCategoriesOwnedIsSubscriberOnTrial8.apply(this, []);
}