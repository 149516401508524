import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import LoginBox from '../../generic/LoginBox';
export default function () {
    return React.createElement('div', {
        'id': 'SignIn',
        'className': 'auth-page'
    }, React.createElement('div', {}, React.createElement('header', { 'className': 'logo' }, React.createElement(Link, { 'to': '/' }, React.createElement('img', {
        'src': '/global/assets/images/logo/logo-big.png',
        'alt': 'Stan Winston School Of Character Arts'
    }))), React.createElement(LoginBox, _.assign({}, {
        'autoFocus': true,
        'isMain': true
    }, this.props))));
}