import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import dispatchSelector from 'reduxModules/menuItem/dispatchers'
import stateSelector from 'reduxModules/menuItem/selectors'
import _ from 'lodash'
import template from './template.rt'
import './styles.css'

export class MenuItem extends Component {
  toggleSubmenu(event) {
    if (event.key === 'Enter')
      if (this.props.show)
        this.props.hideMenuItem()
      else
        this.props.showMenuItem()
    else if (event.key === 'Escape')
      this.props.hideMenuItem()
  }

  render() {
    const { isLibrarySubscriber, hide } = this.props
    if (_.includes(hide, 'isLibrarySubscriber') && isLibrarySubscriber)
      return null
    return template.call(this)
  }
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  show: PropTypes.bool.isRequired,
  showMenuItem: PropTypes.func.isRequired,
  hideMenuItem: PropTypes.func.isRequired,
  isLibrarySubscriber: PropTypes.bool,
  hide: PropTypes.arrayOf(PropTypes.string)
}

MenuItem.defaultProps = {
  title: '',
  options: [],
  show: false,
  isLibrarySubscriber: false,
  hide: []
}

export default connect(
  stateSelector,
  dispatchSelector
)(MenuItem)
