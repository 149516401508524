import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function Address(props) {
  return template.call({ props })
}

export const addressProptypes = {
  countryCodeAlpha2: PropTypes.string,
  countryName: PropTypes.string,
  extendedAddress: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  locality: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.string,
  streetAddress: PropTypes.string
}

Address.defaultProps = {
  extendedAddress: '',
  countryCodeAlpha2: '',
  countryName: '',
  firstName: '',
  lastName: '',
  locality: '',
  postalCode: '',
  region: '',
  streetAddress: ''
}

Address.propTypes = {
  ...addressProptypes,
  id: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired
}

export default Address
