import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notFoundDispatchers as dispatchSelector } from 'reduxModules/app/dispatchers'
import { Link } from 'react-router'
import Helmet from 'react-helmet'
import StaticPage from '..'
import './styles.css'

export class NotFound extends Component {
  constructor(props) {
    super(props)
    props.hideGlobalNav()
  }
  componentWillUnmount() {
    this.props.showGlobalNav()
  }
  render() {
    return (
      <StaticPage id="NotFound">
        <Helmet>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <div className="info">
          <img src="/global/assets/images/404/rex.svg" alt="404" />
          <h2>our t-rex is misbehaving again. we apologize.</h2>
          <h3>But hey, at least you weren&apos;t inside the car!</h3>
          <h4>
            Distract our T-Rex by tossing a flare or by visiting the{' '}
            <Link to="/">Home Page.</Link>
          </h4>
        </div>
      </StaticPage>
    )
  }
}

NotFound.propTypes = {
  hideGlobalNav: PropTypes.func.isRequired,
  showGlobalNav: PropTypes.func.isRequired
}

export default connect(
  null,
  dispatchSelector
)(NotFound)
