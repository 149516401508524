import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import LoadingComponent from 'components/generic/LoadingComponent'
import getLoadLimitByIndex from 'components/utils/getLoadLimitByIndex'
import { tutorialsStatus } from 'reduxModules/tutorials'
import { tutorialsStateSelector as stateSelector } from 'reduxModules/tutorials/selectors'
import { tutorialsMergeProps as mergeProps } from 'reduxModules/tutorials/dispatchers'
import { TutorialSummaryPropTypes as tutorialProptypes } from 'components/generic/CourseSummary/propTypes'
import template from './template.rt'
import './styles.css'

export class TutorialList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      search: ''
    }
    this.routerWillLeave = this.routerWillLeave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.inputKey = this.inputKey.bind(this)

    // debounce
    this.onChangeDebounce = _.debounce(this.inputKey, 350)
  }

  componentDidMount() {
    const {
      loadTutorials,
      loadWebinars,
      loadBanners,
      loadSpecialVideos,
      location,
      pendingBackScroll,
      setFilters
    } = this.props
    const limit = getLoadLimitByIndex(16, pendingBackScroll.tutorials)

    if (!_.isEmpty(location && location.query)) {
      setFilters(location.query)
      loadTutorials({ limit, ...location.query })
      if (location.query.search)
        this.setState({ search: location.query.search })
    } else loadTutorials({ limit })

    loadWebinars()
    loadBanners()
    // TODO dont load special video if anon
    loadSpecialVideos()

    const { router, route } = this.props
    if (router.setRouteLeaveHook)
      router.setRouteLeaveHook(route, this.routerWillLeave)
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps.filters, this.props.filters))
      this.props.loadTutorials(this.props.filters)
  }

  componentWillUnmount() {
    this.props.clearWebinarList()
    this.props.clearTutorialList()
    this.props.clearSpecialVideoList()
    this.props.clearFilters()
    this.onChangeDebounce.cancel()
  }

  routerWillLeave(nextLocation) {
    if (_.startsWith(nextLocation.pathname, '/tutorials/')) {
      const slug = nextLocation.pathname.split('/')[2]
      const { setScrollingBackElement, tutorials } = this.props
      const index = _.findIndex(tutorials, { slug })
      setScrollingBackElement({
        index,
        name: `tutorial-${index}`,
        group: 'tutorials'
      })
    }
    return true
  }

  onChange(event) {
    event.persist()
    this.setState({ search: event.target.value })
    this.onChangeDebounce(event)
  }

  inputKey(event) {
    const { location } = window
    const { value } = event.target
    const searchParams = new URLSearchParams(location.search)
    if (value) {
      searchParams.set('search', value)
      this.onSelectFilter('search', value)
    } else {
      this.onSelectFilter('search', '-1')
      searchParams.delete('search')
    }
    const newRelativePathQuery = `${
      location.pathname
    }?${searchParams.toString()}`
    this.context.router.push(newRelativePathQuery)
  }

  onSelectFilter(filter, value) {
    const { location } = window
    const searchParams = new URLSearchParams(location.search)
    let query = ''
    if (value === '-1' && filter in this.props.filters) {
      query = _.omit(this.props.filters, filter)
      this.props.setFilters(query)
      searchParams.delete(filter)
    } else {
      query = { ...this.props.filters, [filter]: value }

      if (!_.isEqual(query, this.props.location.query))
        this.props.setFilters(query)

      searchParams.set(filter, value)
    }
    if ('URLSearchParams' in window) {
      const newRelativePathQuery = `${
        location.pathname
      }?${searchParams.toString()}`
      this.context.router.push(newRelativePathQuery)
    }
  }

  clearFilters() {
    const searchParams = new URLSearchParams(window.location?.search)
    searchParams.delete('difficulty')
    searchParams.delete('tags')
    this.context.router.push(`/tutorials/?${searchParams.toString()}`)
    this.props.clearFilters()
    if (this.props.isMobile && this.props.showFilters)
      this.props.toggleFilters()
  }

  clearSearch() {
    this.props.clearSearch()
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('search')
    this.context.router.push(`/tutorials/?${searchParams.toString()}`)

    this.setState({ search: '' })
  }

  render() {
    if (
      !this.props.tutorials ||
      (this.props.requestStatus !== tutorialsStatus.success &&
        this.props.requestStatus !== tutorialsStatus.filter)
    )
      return <LoadingComponent />

    return template.call(this)
  }
}

TutorialList.propTypes = {
  requestStatus: PropTypes.string.isRequired,
  showFilters: PropTypes.bool,
  loadTutorials: PropTypes.func.isRequired,
  loadWebinars: PropTypes.func.isRequired,
  loadBanners: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  clearTutorialList: PropTypes.func.isRequired,
  clearWebinarList: PropTypes.func.isRequired,
  tutorials: PropTypes.arrayOf(PropTypes.shape(tutorialProptypes)),
  filters: PropTypes.shape({
    difficulty: PropTypes.string,
    category: PropTypes.string
  }),
  loadSpecialVideos: PropTypes.func.isRequired,
  clearSpecialVideoList: PropTypes.func.isRequired,
  pendingBackScroll: PropTypes.shape({
    tutorials: PropTypes.shape({
      isPending: PropTypes.bool,
      element: PropTypes.shape({
        index: PropTypes.number,
        name: PropTypes.string
      })
    })
  }),
  setScrollingBackElement: PropTypes.func.isRequired,
  router: PropTypes.shape([
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ]).isRequired,
  route: PropTypes.shape([
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ]).isRequired
}

TutorialList.defaultProps = {
  tutorials: [],
  filters: {},
  showFilters: false,
  pendingBackScroll: {
    tutorials: {
      isPending: false,
      element: {
        index: 0,
        name: 'tutorial-0'
      }
    }
  }
}
TutorialList.contextTypes = {
  router: PropTypes.object
}

export default connect(
  stateSelector,
  null,
  mergeProps
)(withRouter(TutorialList))
