import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  DISPLAY_V2_RECAPTCHA
  HIDE_V2_RECAPTCHA
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  showV2Recaptcha: false
}

export const display = (state = INITIAL_STATE) => ({
  ...state,
  showV2Recaptcha: true
})

export const hide = (state = INITIAL_STATE) => ({
  ...state,
  showV2Recaptcha: false
})

export const HANDLERS = {
  [types.DISPLAY_V2_RECAPTCHA]: display,
  [types.HIDE_V2_RECAPTCHA]: hide
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const displayRecaptcha = createAction(types.DISPLAY_V2_RECAPTCHA)
export const hideRecaptcha = createAction(types.HIDE_V2_RECAPTCHA)
