import * as React from 'react';
import * as _ from 'lodash';
import BookmarkedTutorial from './BookmarkedTutorial';
export default function () {
    function repeatTutorial1(tutorial, tutorialIndex) {
        return React.createElement(BookmarkedTutorial, _.assign({}, {
            'removeBookmark': this.props.removeBookmark,
            'loadBookmark': this.props.loadBookmark,
            'key': tutorial.id
        }, tutorial));
    }
    return React.createElement.apply(this, [
        'div',
        {
            'id': 'BookmarkList',
            'className': 'account-section'
        },
        React.createElement('h3', { 'className': 'account-section__title' }, 'Bookmarks'),
        _.size(this.props.bookmarkedTutorials) > 0 ? _.map(this.props.bookmarkedTutorials, repeatTutorial1.bind(this)) : null,
        _.size(this.props.bookmarkedTutorials) === 0 ? React.createElement('div', {
            'className': 'no-results',
            'key': '466'
        }, React.createElement('span', {}, 'You don\'t have any bookmarks yet.')) : null
    ]);
}