import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  FORUM_POSTS_INITIAL
  FORUM_POSTS_FETCH_SUCCESS
  FORUM_POSTS_REQUEST_FAILURE
  FORUM_POSTS_SUCCESS
  FORUM_POSTS_ERROR
`)

export const forumPostsStatus = {
  initial: types.FORUM_POSTS_INITIAL,
  success: types.FORUM_POSTS_SUCCESS,
  error: types.FORUM_POSTS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: forumPostsStatus.initial,
  list: []
}

export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.discussions,
  status: forumPostsStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: forumPostsStatus.error
})

export const HANDLERS = {
  [types.FORUM_POSTS_FETCH_SUCCESS]: success,
  [types.FORUM_POSTS_REQUEST_FAILURE]: failure
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const forumPostsFetchSuccess = createAction(
  types.FORUM_POSTS_FETCH_SUCCESS
)
export const forumPostsFetchFailure = createAction(
  types.FORUM_POSTS_REQUEST_FAILURE
)
export const forumPostsFetch = fetchHelper(
  'http://forums.stanwinstonschool.com/discussions.json',
  { method: 'GET' },
  forumPostsFetchSuccess,
  forumPostsFetchFailure
)
