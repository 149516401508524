import * as React from 'react';
import * as _ from 'lodash';
import humps from 'humps';
import RadialProgress from '../../../generic/RadialProgress';
import keyUpHandler from '../../../utils/keyUpHandler';
export default function () {
    function onKeyUp1(props, pctg, event) {
        keyUpHandler(event, props.onClick, null, null, [props.current.category]);
    }
    function scopePropsPctg2() {
        var props = this.props;
        var pctg = props.owned ? props.current.completed / props.current.total * 100 : 0;
        return React.createElement('div', {
            'className': _.transform({
                filtrable: this.props.filtrable,
                active: this.props.filterActive
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ') + ' ' + 'category-progress',
            'aria-label': props.filterActive ? 'Remove category filter' : `Show only ${ humps.decamelize(props.current.category, {
                separator: ' ',
                split: /(?=[A-Z&])/
            }) } courses`,
            'onClick': () => props.onClick(props.current.category),
            'onKeyUp': onKeyUp1.bind(this, props, pctg),
            'tabIndex': '0'
        }, React.createElement(RadialProgress, {
            'percentage': pctg,
            'sqSize': props.width < props.amount * 100 + 200 ? props.radialProgressSize : 100
        }, React.createElement('i', { 'className': 'icon-' + this.constructor.getCategoryIconName(props.current.category) })), React.createElement('div', { 'className': 'name' }, humps.decamelize(props.current.category, {
            separator: ' ',
            split: /(?=[A-Z&])/
        })), props.owned ? React.createElement('div', { 'key': '1178' }, `${ props.current.completed }/${ props.current.total }`) : null, !props.owned ? React.createElement('div', { 'key': '1267' }, `${ props.current.total } course${ props.current.total === 1 ? '' : 's' }`) : null);
    }
    return scopePropsPctg2.apply(this, []);
}