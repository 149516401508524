import { createSelector } from 'reselect'
import {
  isMobileSelector,
  confirmationSelector
} from 'reduxModules/app/selectors'
import {
  validatedDiscountCode as validatedSelector,
  showDiscountCodeForm as showForm
} from 'reduxModules/discountCode/selectors'

const discountSuccess = createSelector(
  validatedSelector,
  discount => {
    if (discount && Object.prototype.hasOwnProperty.call(discount, 'status'))
      return discount.status === 'DISCOUNT_CODE_SUCCESS'
    return false
  }
)

export default createSelector(
  [
    isMobileSelector,
    confirmationSelector,
    validatedSelector,
    discountSuccess,
    showForm
  ],
  (
    isMobile,
    confirmation,
    validatedDiscountCode,
    displayDiscount,
    displayForm
  ) => ({
    isMobile,
    confirmation,
    validatedDiscountCode,
    displayDiscount,
    displayForm
  })
)
