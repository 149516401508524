import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import { Field } from 'redux-form';
import moment from 'moment';
import RenderField from '../generic/RenderedField';
import keyUpHandler from '../utils/keyUpHandler';
import { validators } from '../../redux/modules/utils/forms';
export default function () {
    function repeatFbImage1(hasBanner, props, isOver, hasStarted, startDate, fbImage, fbImageIndex) {
        return React.createElement('meta', {
            'property': 'og:image',
            'content': fbImage.original,
            'key': fbImageIndex
        });
    }
    function onKeyUp2(hasBanner, props, isOver, hasStarted, startDate, event) {
        keyUpHandler(event, this.props.toggleNewsletter);
    }
    function scopeHasBannerPropsIsOverHasStartedStartDate3() {
        var hasBanner = !_.isNull(this.props.bannerImage.normal);
        var props = this.props;
        var isOver = this.props.isOver;
        var hasStarted = moment().isAfter(moment(this.props.startDate));
        var startDate = moment(this.props.startDate, 'YYYY-MM-DD HH:mm:ss').toString();
        return React.createElement('div', { 'id': 'Contest' }, React.createElement('div', {
            'className': 'background-image',
            'style': { backgroundImage: hasBanner ? `url(${ props.bannerImage.normal })` : 'none' }
        }), React.createElement.apply(this, [
            Helmet,
            {},
            React.createElement('title', {}, `${ props.title } | Stan Winston School of Character Arts`),
            React.createElement('meta', {
                'property': 'og:title',
                'content': props.title
            }),
            props.facebookShareImages.length ? _.map(props.facebookShareImages, repeatFbImage1.bind(this, hasBanner, props, isOver, hasStarted, startDate)) : null,
            React.createElement('meta', {
                'content': 'article',
                'property': 'og:type'
            })
        ]), React.createElement('section', { 'id': 'contest-box' }, React.createElement('section', {
            'id': 'content',
            'className': 'wrapper'
        }, React.createElement('div', { 'className': 'title' }, React.createElement('h1', {}, props.title)), props.bullets ? React.createElement('div', {
            'className': 'body' + ' ' + _.transform({ wrapper: this.props.isMobile }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'dangerouslySetInnerHTML': { __html: props.bullets },
            'key': '1343'
        }) : null), !isOver && hasStarted ? React.createElement('section', {
            'id': 'participate',
            'className': 'wrapper participate-form',
            'key': '1576'
        }, React.createElement('form', { 'onSubmit': props.handleSubmit }, React.createElement(Field, {
            'name': 'fullName',
            'validate': validators.required,
            'component': RenderField,
            'type': 'text',
            'placeholder': 'Full Name',
            'props': { ariaLabel: 'Full Name' }
        }), React.createElement(Field, {
            'name': 'email',
            'validate': [
                validators.required,
                validators.email
            ],
            'component': RenderField,
            'type': 'text',
            'placeholder': 'Email',
            'props': { ariaLabel: 'Email' }
        }), React.createElement('div', { 'className': 'newsletter' }, React.createElement('div', { 'className': 'checkbox-area' }, React.createElement('div', { 'className': 'checkbox-text' }, React.createElement('div', {}, '\n                        Let me know about upcoming contests, behind-the-scenes features, new course content & exclusive discounts!\n                    ')), React.createElement('div', { 'className': 'checkbox-confirm' }, React.createElement('input', {
            'type': 'checkbox',
            'name': 'newsletter',
            'checked': props.newsletter,
            'value': props.newsletter,
            'id': 'newsletter-checkbox',
            'onChange': () => this.props.toggleNewsletter(),
            'onKeyUp': onKeyUp2.bind(this, hasBanner, props, isOver, hasStarted, startDate)
        }), React.createElement('label', { 'htmlFor': 'newsletter-checkbox' }, ' Yes, let me know ')))), React.createElement('div', { 'className': 'buttons' }, React.createElement('button', {
            'type': 'submit',
            'className': 'btn -orange'
        }, 'Enter contest')))) : null, React.createElement('section', { 'className': 'message' }, React.createElement('div', { 'className': 'message-body' }, !hasStarted && !isOver ? React.createElement('span', { 'key': '3468' }, 'This contest starts on ', startDate) : null, isOver ? React.createElement('span', { 'key': '3561' }, 'This contest is over') : null))));
    }
    return scopeHasBannerPropsIsOverHasStartedStartDate3.apply(this, []);
}