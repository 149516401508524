import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { onForgotPasswordDispatch as dispatchSelector } from 'reduxModules/auth/dispatchers'
import template from './template.rt'
import './styles.css'

export class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    props.hideGlobalNav()
  }

  componentWillUnmount() {
    this.props.showGlobalNav()
  }

  render() {
    return template.call(this)
  }
}

ForgotPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hideGlobalNav: PropTypes.func.isRequired,
  showGlobalNav: PropTypes.func.isRequired
}

export const ForgotPasswordWrapper = reduxForm({
  form: 'forgot-password'
})(ForgotPassword)
export default connect(
  null,
  dispatchSelector
)(ForgotPasswordWrapper)
