/* eslint-disable import/extensions,no-underscore-dangle */
/* globals global, __DEV__, PROD_ENV, SENTRY_DSN, GOOGLE_ANALYTICS_ID, FACEBOOK_PIXEL_ID, GOOGLE_OPTIMIZE_ID, ELASTIC_APM_SERVICE_NAME, ELASTIC_APM_SECRET_TOKEN, ELASTIC_APM_SERVER_URL, ELASTIC_APM_ENVIRONMENT */
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import Promise from 'promise'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import React from 'react'
import Raven from 'raven-js'
import { init as initApm } from '@elastic/apm-rum'
import routes from './routes.jsx'
import store, { history } from './redux/store'

import './index.css'

initApm({
  serviceName: ELASTIC_APM_SERVICE_NAME,
  secretToken: ELASTIC_APM_SECRET_TOKEN,
  serverUrl: ELASTIC_APM_SERVER_URL,
  environment: ELASTIC_APM_ENVIRONMENT
})

require('./favicon.ico') // Tell webpack to load favicon.ico

// To add to global
if (!global.Promise) global.Promise = Promise

if (PROD_ENV) {
  // Facebook Pixel initialize
  ReactPixel.init(FACEBOOK_PIXEL_ID)

  // Google Analytics conf
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.ga('require', GOOGLE_OPTIMIZE_ID)
}

function logPageView() {
  if (PROD_ENV) {
    // Facebook Pixel
    // ReactPixel.pageView()

    // Mouse flow
    window._mfq.push(['newPageView', window.location.pathname])
  }
}

// Setup Raven/Sentry
if (!__DEV__)
  Raven.config(SENTRY_DSN, {
    whitelistUrls: [/stanwinstonschool\.com/, /dev\.swsca\.talpor.com/],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems
      // to reduce this. (thanks @acdha) See
      // http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
    ignoreUrls: [/extensions\//i, /^chrome:\/\//i]
  }).install()

// ------------------------------
render(
  <Provider store={store}>
    <Router history={history} routes={routes} onUpdate={logPageView} />
  </Provider>,
  document.getElementById('app')
)
