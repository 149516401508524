import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  CONTACT_INITIAL
  CONTACT_REQUEST_SUCCESS
  CONTACT_REQUEST_FAILURE
  CAPTCHA_REQUEST_SUCCESS
  CAPTCHA_REQUEST_FAILURE
`)

export const contactStatus = {
  initial: types.CONTACT_INITIAL,
  success: types.CONTACT_REQUEST_SUCCESS,
  error: types.CONTACT_REQUEST_FAILURE,
  captchaSuccess: types.CAPTCHA_REQUEST_SUCCESS,
  captchaError: types.CAPTCHA_REQUEST_FAILURE
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: contactStatus.initial
}

export const success = (state = INITIAL_STATE) => ({
  ...state,
  status: contactStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: contactStatus.error
})

export const captchaSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: contactStatus.captchaSuccess
})

export const captchaFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: contactStatus.captchaError
})

export const HANDLERS = {
  [types.CONTACT_REQUEST_SUCCESS]: success,
  [types.CONTACT_REQUEST_FAILURE]: failure,
  [types.CAPTCHA_REQUEST_SUCCESS]: captchaSuccess,
  [types.CAPTCHA_REQUEST_FAILURE]: captchaFailure
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const contactRequestSuccess = createAction(types.CONTACT_REQUEST_SUCCESS)
export const contactRequestFailure = createAction(types.CONTACT_REQUEST_FAILURE)
export const contactRequest = fetchApi(
  '/contact/',
  { method: 'POST' },
  contactRequestSuccess,
  contactRequestFailure
)

export const captchaRequestSuccess = createAction(types.CAPTCHA_REQUEST_SUCCESS)
export const captchaRequestFailure = createAction(types.CAPTCHA_REQUEST_FAILURE)
export const captchaRequest = fetchApi(
  '/captcha/',
  { method: 'POST' },
  captchaRequestSuccess,
  captchaRequestFailure
)
