import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { openAthensSSODispatchers as stateProps } from '../../redux/modules/app/dispatchers'

function OpenAthensSSO(props) {
  useEffect(() => {
    props.openAthensSSODispatcher(props)
  }, [])

  return <div id="OpenAthensSSO" />
}

export default connect(null, stateProps)(OpenAthensSSO)
