import PropTypes from 'prop-types'
import React from 'react'

const StaticPage = ({ children, id }) => <div id={id}>{children}</div>

StaticPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ]).isRequired,
  id: PropTypes.string.isRequired
}

export default StaticPage
