import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import Tutorial from '../generic/CourseSummary';
export default function () {
    function scopeMetaTitleMetaDescription1(hasBanner) {
        var metaTitle = this.props.title || this.props.subtitle;
        var metaDescription = this.props.subtitle;
        return React.createElement(Helmet, {}, React.createElement('title', {}, metaTitle, ' | Stan Winston School of Character Arts'), React.createElement('meta', {
            'name': 'description',
            'content': metaDescription
        }), React.createElement('meta', {
            'property': 'og:title',
            'content': this.props.fbTitle || metaTitle
        }), React.createElement('meta', {
            'property': 'og:description',
            'content': this.props.fbDescription || metaDescription
        }), React.createElement('meta', {
            'content': 'article',
            'property': 'og:type'
        }));
    }
    function repeatTutorial2(hasBanner, tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'title': tutorial.title,
            'thumb': tutorial.photo.boxThumb,
            'new': tutorial.new
        });
    }
    function scopeHasBanner3() {
        var hasBanner = !_.isNull(this.props.banner.normal);
        return React.createElement('div', { 'id': 'LandingPage' }, scopeMetaTitleMetaDescription1.apply(this, [hasBanner]), React.createElement('section', { 'id': 'banner' }, React.createElement('img', {
            'src': hasBanner ? this.props.banner.normal : this.props.background.normal,
            'role': 'presentation',
            'className': _.transform({ 'has-banner': hasBanner }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'alt': this.props.subtitle
        }), !hasBanner ? React.createElement('div', {
            'className': 'headline',
            'key': '900'
        }, React.createElement('h1', {}, this.props.subtitle)) : null), React.createElement('section', { 'id': 'content' }, hasBanner ? React.createElement('div', {
            'className': 'subtitle',
            'key': '1034'
        }, React.createElement('h1', {}, this.props.subtitle)) : null, this.props.body ? React.createElement('div', {
            'className': 'body' + ' ' + _.transform({ wrapper: this.props.isMobile }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'dangerouslySetInnerHTML': this.getBody(),
            'key': '1129'
        }) : null), !_.isEmpty(this.props.tutorials) ? React.createElement('section', {
            'id': 'tutorials',
            'className': 'wrapper',
            'key': '1305'
        }, React.createElement('div', { 'className': 'title' }, React.createElement('h2', {}, 'Courses')), React.createElement.apply(this, [
            'div',
            { 'className': 'tutorial-list' },
            _.map(this.props.tutorials, repeatTutorial2.bind(this, hasBanner))
        ])) : null);
    }
    return scopeHasBanner3.apply(this, []);
}