import React from 'react'
import './styles.css'

function Switch({ isOn, handleToggle }) {
  return (
    <div className="switch-container">
      <label className={`switch-label ${isOn ? 'active' : ''}`}>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="switch-checkbox"
          type="checkbox"
        />
        <span className="switch-button" />
      </label>
    </div>
  )
}

export default Switch
