/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { playerSelector as stateSelector } from 'reduxModules/player/selectors'
import { playerDispatchers as dispatchers } from 'reduxModules/player/dispatchers'
import { withRouter } from 'react-router'

import { COMPLETION_THRESHOLD } from 'components/NewVideoPlayer/PlayerSidebar/ChapterList'
import {
  TutorialPropTypes,
  TutorialDefaultProps
} from 'components/Tutorials/TutorialDetail'
import _ from 'lodash'

import './styles.css'
import RelevantToggle from '../../../NewVideoPlayer/PlayerSidebar/RelevantToggle'

function TutorialCurriculum({
  playerId,
  setCurrentChapter,
  chapters,
  router,
  slug,
  relevantChapters,
  shouldOnlyShowRelevants,
  location,
  toggleShowingOnlyRelevantChapters,
  userProgress,
  id,
  subscription
}) {
  const getPlayer = () => window.jwplayer(playerId || 'jwplayer-0')
  const playChapter = (i, id) => {
    const chapterIndex = Number(i) - 1
    const player =
      typeof window.jwplayer === 'function' ? getPlayer() : undefined

    // If player is active set chapter
    if (player && typeof player.getPlaylistIndex === 'function') {
      const currentlyPlaying = player.getPlaylistIndex()
      const chapterProgress = userProgress[id]

      if (chapterIndex === -1) player.playlistItem(0)
      if (chapterIndex !== currentlyPlaying) {
        if (
          !chapterProgress?.completed ||
          chapterProgress?.progress <= COMPLETION_THRESHOLD
        )
          player.once('play', () =>
            player.seek(chapterProgress?.currentPosition)
          )

        player.playlistItem(chapterIndex)
      }
      setCurrentChapter(id, chapterIndex)
    }
    // Update route while preserving previous query params
    const searchParams = new URLSearchParams(location?.query)
    searchParams.set('watch', 1)
    searchParams.set('chapter', i)
    router.push(`/tutorials/${slug}?${searchParams.toString()}`)
    window.scrollTo(0, 0)
  }
  const chaptersToDisplay =
    shouldOnlyShowRelevants && relevantChapters.length > 0
      ? relevantChapters
      : chapters
  const isBasicSubscriber = subscription?.plan === 'Basic'
  const shouldBeInteractive = chapter => {
    const hasVideo =
      !_.isUndefined(chapter?.video) &&
      (!_.isEmpty(chapter?.video?.hls) || !_.isEmpty(chapter?.video?.dash))

    // Only allow interaction for basic subscriber if course is activates
    if (isBasicSubscriber)
      return !_.isUndefined(_.find(subscription?.monthlyVideosSeen, { id }))

    return hasVideo
  }

  return (
    <div className="TutorialCurriculum">
      {relevantChapters?.length > 0 && (
        <div className="relevant-toggle-container">
          <RelevantToggle
            shouldOnlyShowRelevants={shouldOnlyShowRelevants}
            toggleShowingOnlyRelevantChapters={
              toggleShowingOnlyRelevantChapters
            }
          />
        </div>
      )}
      <div className="curriculum-items">
        <ol>
          {chaptersToDisplay?.map((chapter, index) => (
            <li key={`${chapter?.title}-${chapter?.order}`}>
              {!shouldBeInteractive(chapter) ? (
                <span className="chapter">{chapter?.title}</span>
              ) : (
                <button
                  className="chapter"
                  onClick={() => playChapter(index + 1, chapter?.id)}
                  type="button"
                  aria-label={`Play chapter "${chapter?.title}"`}
                >
                  {chapter.title}
                </button>
              )}
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

TutorialCurriculum.propTypes = TutorialPropTypes
TutorialCurriculum.defaultProps = TutorialDefaultProps
TutorialCurriculum.contextTypes = {
  router: PropTypes.object
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { getPlayerState } = stateProps
  const { videoId } = ownProps
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    ...getPlayerState(videoId)
  }
}

export default connect(
  stateSelector,
  dispatchers,
  mergeProps
)(withRouter(TutorialCurriculum))
