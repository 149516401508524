import * as React from 'react';
import * as _ from 'lodash';
import getOptions from '../NavBar/options';
import { Link } from 'react-router';
import { getActiveSection } from '../NavBar/utils';
import { getActiveSubsection } from '../NavBar/utils';
export default function () {
    function onClick1(props) {
        props.toggleMobileMenu();
    }
    function scopeActiveSection2(props) {
        var activeSection = getActiveSection(props.pathName);
        return React.createElement('div', {
            'className': 'home-btn-container' + ' ' + _.transform({ current: activeSection === 'home' }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('i', { 'className': 'icon-home' }), React.createElement('span', {}, 'home'));
    }
    function onClick3(props, item, itemIndex, title, activeSection) {
        props.toggleMobileMenu();
    }
    function onClick4(props, item, itemIndex, title, activeSection, option, optionIndex) {
        props.toggleMobileMenu();
    }
    function repeatOption5(props, item, itemIndex, title, activeSection, option, optionIndex) {
        return [
            !option.isExternal ? React.createElement(Link, {
                'to': option.url,
                'className': _.transform({ current: option.title.toLowerCase() === getActiveSubsection(props.pathName) }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' '),
                'onClick': onClick4.bind(this, props, item, itemIndex, title, activeSection, option, optionIndex),
                'key': '22311'
            }, '\n                        ', option.title, '\n                    ') : null,
            option.isExternal ? React.createElement('a', {
                'href': option.url,
                'target': '_blank',
                'key': '22313'
            }, option.title) : null
        ];
    }
    function scopeTitleActiveSection6(props, item, itemIndex) {
        var title = item.title.toLowerCase();
        var activeSection = getActiveSection(props.pathName);
        return React.createElement('div', {
            'key': itemIndex,
            'className': 'menu-item'
        }, React.createElement('div', {
            'className': 'category' + ' ' + _.transform({
                expanded: title === props.active || title === activeSection,
                expandable: _.has(item, 'options'),
                current: title === activeSection
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'onClick': title !== props.active ? () => props.showMenuItem(title) : () => props.hideMenuItem(title)
        }, React.createElement('div', { 'className': 'title' }, !_.has(item, 'options') || _.has(item, 'url') ? React.createElement(Link, {
            'to': item.url,
            'onClick': onClick3.bind(this, props, item, itemIndex, title, activeSection),
            'key': '1575'
        }, React.createElement('span', {}, item.title)) : null, _.has(item, 'options') && !_.has(item, 'url') ? React.createElement('span', { 'key': '1864' }, item.title) : null), _.has(item, 'options') ? React.createElement('div', {
            'className': 'arrow',
            'key': '1983'
        }, React.createElement('i', { 'className': 'icon-right-2' })) : null), title === props.active || title === activeSection ? React.createElement.apply(this, [
            'div',
            {
                'className': 'options',
                'key': '2135'
            },
            _.map(item.options, repeatOption5.bind(this, props, item, itemIndex, title, activeSection))
        ]) : null);
    }
    function repeatItem7(props, item, itemIndex, title, activeSection) {
        return scopeTitleActiveSection6.apply(this, [
            props,
            item,
            itemIndex
        ]);
    }
    function onClick8(props) {
        props.toggleMobileMenu();
    }
    function scopeActiveSection9(props) {
        var activeSection = getActiveSection(props.pathName);
        return React.createElement('div', {
            'className': 'account-btn-container' + ' ' + _.transform({ current: activeSection === 'account' }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('i', { 'className': 'icon-account' }), React.createElement('span', {}, 'account'));
    }
    function onClick10(props) {
        props.toggleMobileMenu();
    }
    function scopeActiveSection11(props) {
        var activeSection = getActiveSection(props.pathName);
        return React.createElement('div', {
            'className': 'account-btn-container' + ' ' + _.transform({ current: activeSection === 'cart' }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('i', { 'className': 'icon-cart' }), React.createElement('span', {}, 'Cart'));
    }
    function onClick12(props) {
        props.toggleMobileMenu();
    }
    function scopeProps13() {
        var props = this.props;
        return React.createElement('div', {
            'id': 'MobileSideBar',
            'style': { height: props.height + 'px' }
        }, React.createElement.apply(this, [
            'div',
            { 'className': 'menu-items' },
            React.createElement('div', { 'className': 'home-btn' }, React.createElement(Link, {
                'to': '/',
                'onClick': onClick1.bind(this, props)
            }, scopeActiveSection2.apply(this, [props]))),
            _.map(getOptions(true), repeatItem7.bind(this, props)),
            props.isLoggedIn ? React.createElement('div', {
                'className': 'account-btn',
                'key': '2842'
            }, React.createElement(Link, {
                'to': '/account',
                'onClick': onClick8.bind(this, props)
            }, scopeActiveSection9.apply(this, [props]))) : null,
            React.createElement('div', { 'className': 'account-btn' }, React.createElement(Link, {
                'to': '/cart',
                'onClick': onClick10.bind(this, props)
            }, scopeActiveSection11.apply(this, [props]))),
            React.createElement('div', { 'className': 'account-btn' }, props.isLoggedIn ? React.createElement('div', {
                'onClick': () => props.logout(),
                'className': 'logout-btn',
                'key': '3939'
            }, React.createElement('div', { 'className': 'account-btn-container' }, React.createElement('i', { 'className': 'icon-logout' }), React.createElement('span', {}, 'logout'))) : null, !props.isLoggedIn ? React.createElement(Link, {
                'to': {
                    pathname: '/login',
                    state: { next: props.pathName }
                },
                'onClick': onClick12.bind(this, props),
                'className': 'login-btn',
                'key': '4281'
            }, React.createElement('div', { 'className': 'account-btn-container' }, React.createElement('i', { 'className': 'icon-login' }), React.createElement('span', {}, 'login'))) : null)
        ]));
    }
    return scopeProps13.apply(this, []);
}