import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { types as userTypes } from 'reduxModules/users/'
import urlParams from 'url-params'
import fetchApi, { getUrl, fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  BLOG_POSTS_INITIAL
  BLOG_POSTS_FETCH_SUCCESS
  BLOG_POSTS_REQUEST_FAILURE
  BLOG_POSTS_SUCCESS
  BLOG_POSTS_ERROR
  BLOG_POST_FETCH_SUCCESS
  BLOG_POST_REQUEST_FAILURE
  BLOG_POSTS_FETCH_MORE_SUCCESS
  BLOG_POSTS_FECTH_MORE_REQUEST_FAILURE
  BLOG_POST_CATEGORIES_FETCH_SUCCESS
  BLOG_POST_CATEGORIES_REQUEST_FAILURE
  BLOG_CLEAR
  BLOG_POST_INITIAL
  BLOG_POST_SUCCESS
  BLOG_POST_ERROR
  BLOG_POSTS_FILTER
  BLOG_POSTS_SET_FILTER
  BLOG_POSTS_CLEAR_FILTER
  BLOG_POSTS_TOGGLE_FILTERS
`)

export const blogPostsStatus = {
  initial: types.BLOG_POSTS_INITIAL,
  success: types.BLOG_POSTS_SUCCESS,
  error: types.BLOG_POSTS_ERROR,
  filter: types.BLOG_POSTS_FILTER
}

export const blogPostStatus = {
  initial: types.BLOG_POST_INITIAL,
  success: types.BLOG_POST_SUCCESS,
  error: types.BLOG_POST_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  requestListStatus: blogPostsStatus.initial,
  requestDetailStatus: blogPostStatus.initial,
  listFilters: {},
  showFilters: false,
  list: [],
  categories: [],
  detail: {
    photo: {},
    favorited: null,
    url: '',
    facebookShareImages: []
  },
  next: null
}

export const successList = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.results,
  next: action.payload.next,
  requestListStatus: blogPostsStatus.success
})

export const successCategoriesList = (state = INITIAL_STATE, action) => ({
  ...state,
  categories: action.payload.results,
  requestListStatus: blogPostsStatus.success
})

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  requestDetailStatus: blogPostStatus.success
})

export const successListMore = (state = INITIAL_STATE, action) => ({
  ...state,
  list: [...state.list, ...action.payload.results],
  next: action.payload.next,
  requestListStatus: blogPostsStatus.success
})

export const failureList = (state = INITIAL_STATE) => ({
  ...state,
  requestListStatus: blogPostsStatus.error
})

export const failureDetail = (state = INITIAL_STATE) => ({
  ...state,
  detail: INITIAL_STATE.detail,
  requestDetailStatus: blogPostStatus.error
})

export const successSetFavorite = (state = INITIAL_STATE, action) => {
  const objectType = action.payload.favoritedModel
  if (objectType === 'post') {
    const objectId = action.payload.id
    return {
      ...state,
      detail: {
        ...state.detail,
        favorited: objectId
      },
      requestDetailStatus: blogPostStatus.success
    }
  }
  return state
}

export const successUnsetFavorite = (state = INITIAL_STATE, action) => {
  const objectType = action.payload.favoritedModel
  if (objectType === 'post')
    return {
      ...state,
      detail: {
        ...state.detail,
        favorited: null
      },
      requestDetailStatus: blogPostStatus.success
    }
  return state
}

export const clearSuccess = (state = INITIAL_STATE) => ({
  ...state,
  detail: INITIAL_STATE.detail,
  list: INITIAL_STATE.list,
  next: null
})

export const setFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  listFilters: action.payload
})

export const clearFilters = (state = INITIAL_STATE) => ({
  ...state,
  listFilters: INITIAL_STATE.listFilters
})

export const toggleListFilters = (state = INITIAL_STATE) => ({
  ...state,
  showFilters: !state.showFilters
})

export const filterBlogPosts = (state = INITIAL_STATE) => ({
  ...state,
  requestListStatus: blogPostsStatus.filter
})

export const HANDLERS = {
  [types.BLOG_POSTS_FETCH_SUCCESS]: successList,
  [types.BLOG_POSTS_REQUEST_FAILURE]: failureList,
  [types.BLOG_POST_CATEGORIES_FETCH_SUCCESS]: successCategoriesList,
  [types.BLOG_POST_CATEGORIES_FAILURE]: failureList,
  [types.BLOG_POST_FETCH_SUCCESS]: successDetail,
  [types.BLOG_POST_REQUEST_FAILURE]: failureDetail,
  [types.BLOG_POSTS_FETCH_MORE_SUCCESS]: successListMore,
  [types.BLOG_POSTS_FECTH_MORE_REQUEST_FAILURE]: failureList,
  [userTypes.USERS_FAVORITE_SET_SUCCESS]: successSetFavorite,
  [userTypes.USERS_FAVORITE_UNSET_SUCCESS]: successUnsetFavorite,
  [types.BLOG_CLEAR]: clearSuccess,
  [types.BLOG_POSTS_FILTER]: filterBlogPosts,
  [types.BLOG_POSTS_SET_FILTER]: setFilter,
  [types.BLOG_POSTS_CLEAR_FILTER]: clearFilters,
  [types.BLOG_POSTS_TOGGLE_FILTERS]: toggleListFilters
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const blogPostsFetchSuccess = createAction(
  types.BLOG_POSTS_FETCH_SUCCESS
)
export const blogPostsFetchFailure = createAction(
  types.BLOG_POSTS_REQUEST_FAILURE
)
export const blogPostsFetch = filters =>
  fetchApi(
    getUrl('/post/', filters),
    { method: 'GET' },
    blogPostsFetchSuccess,
    blogPostsFetchFailure
  )

export const blogPostCategoriesFetchSuccess = createAction(
  types.BLOG_POST_CATEGORIES_FETCH_SUCCESS
)
export const blogPostCategoriesFetch = () =>
  fetchApi(
    '/post_categories/',
    { method: 'GET' },
    blogPostCategoriesFetchSuccess,
    null
  )

export const blogPostFetchSuccess = createAction(types.BLOG_POST_FETCH_SUCCESS)
export const blogPostFetchFailure = createAction(
  types.BLOG_POST_REQUEST_FAILURE
)
export const blogPostFetch = slug =>
  fetchApi(
    `/post/${slug}/`,
    { method: 'GET' },
    blogPostFetchSuccess,
    blogPostFetchFailure
  )

export const blogPostsFetchMoreSuccess = createAction(
  types.BLOG_POSTS_FETCH_MORE_SUCCESS
)
export const blogPostsFetchMoreFailure = createAction(
  types.BLOG_POSTS_FECTH_MORE_REQUEST_FAILURE
)
export const blogPostsFetchMore = next => {
  const url = urlParams.remove(next, 'limit')
  return fetchHelper(
    url,
    { method: 'GET' },
    blogPostsFetchMoreSuccess,
    blogPostsFetchMoreFailure
  )
}

export const clearBlog = createAction(types.BLOG_CLEAR)
export const setFilterList = createAction(types.BLOG_POSTS_SET_FILTER)
export const loadFilterList = createAction(types.BLOG_POSTS_FILTER)
export const clearFilterList = createAction(types.BLOG_POSTS_CLEAR_FILTER)
export const toggleFilters = createAction(types.BLOG_POSTS_TOGGLE_FILTERS)