import * as React from 'react';
import * as _ from 'lodash';
import LearningPathSummary from '../../LearningPaths/LearningPathSummary';
export default function () {
    function repeatPath1(pathways, path, pathIndex) {
        return React.createElement(LearningPathSummary, _.assign({}, { 'key': path.id }, path));
    }
    function scopePathways2() {
        var pathways = this.props.pathways;
        return React.createElement('section', {
            'id': 'recent-pathways',
            'className': 'wrapper'
        }, React.createElement('h3', { 'className': 'title' }, 'My Pathways'), React.createElement.apply(this, [
            'div',
            { 'className': 'pathways-list' },
            _.map(pathways.results, repeatPath1.bind(this, pathways))
        ]), pathways.next ? React.createElement('div', {
            'className': 'load-more-pathways',
            'key': '386'
        }, React.createElement('button', {
            'onClick': () => this.props.loadMore(),
            'className': 'load-more'
        }, 'Load More Pathways')) : null);
    }
    return scopePathways2.apply(this, []);
}