/* global STANDARD_DATE_FORMAT */
import moment from 'moment'
import _ from 'lodash'

const required = value => (value ? undefined : 'This field is required.')

const selectRequired = value =>
  value === 'unselected' ? 'This field is required.' : undefined

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less.` : undefined

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address.'
    : undefined

const date = value =>
  moment(value, STANDARD_DATE_FORMAT, true).isValid()
    ? undefined
    : 'Invalid date.'

const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number.' : undefined

const contactByPhoneRequired = (value, props) => {
  const { phone, contactBy } = props

  if (contactBy === 'phone' && _.isUndefined(phone))
    return 'This field is required.'

  return undefined
}

export const validators = {
  required,
  maxLength,
  email,
  date,
  number,
  selectRequired,
  contactByPhoneRequired
}

export const normalizeErrors = errors => {
  let result = {}
  Object.values(errors).forEach(error => {
    result = _.isArray(error) ? error.join(' ') : error
  })
  return result
}
