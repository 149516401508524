import { createAction } from 'redux-actions'
import { createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  HIJACK_INITIAL
  HIJACK_FETCH_SUCCESS
  HIJACK_FETCH_FAILURE
  HIJACK_SUCCESS
  HIJACK_ERROR
  HIJACK_RELEASE
`)

export const assetsStatus = {
  initial: types.HIJACK_INITIAL,
  success: types.HIJACK_SUCCESS,
  error: types.HIJACK_ERROR
}

//
// ACTION CREATORS
// -----------------------------------------------------------------------------
export const hijackFetchSuccess = createAction(types.HIJACK_FETCH_SUCCESS)
export const hijackFetchFailure = createAction(types.HIJACK_FETCH_FAILURE)
export const hijackFetch = id =>
  fetchApi(
    `/hijack/${id}/`,
    { method: 'GET' },
    hijackFetchSuccess,
    hijackFetchFailure
  )

export const releaseHijackedUser = createAction(types.HIJACK_RELEASE)
