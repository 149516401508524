import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi, { getUrl } from '../utils/fetch'

//
// TYPES
// -----------------------------------------------------------------------------

export const types = createTypes(`
  STATISTICS_INITIAL
  STATISTICS_SUCCESS
  START_STATISTICS_SUCCESS
  START_STATISTICS_FAILURE
  START_STATISTICS_REQUEST_FAILURE
  TICK_STATISTICS_SUCCESS_HIDDEN
  TICK_STATISTICS_REQUEST_FAILURE_HIDDEN
  CLEAR_STATISTICS
`)

export const statisticsStatus = {
  initial: types.STATISTICS_INITIAL,
  success: types.STATISTICS_SUCCESS,
  error: types.STATISTICS_FAILURE
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: statisticsStatus.initial,
  videoStatisticId: null
}

export const startStatistics = (state = INITIAL_STATE, action) => ({
  ...state,
  status: statisticsStatus.success,
  videoStatisticId: action.payload.id
})

export const clearVideoStatistics = (state = INITIAL_STATE) => ({
  ...state,
  status: statisticsStatus.initial,
  videoStatisticId: INITIAL_STATE.videoStatisticId
})

export const HANDLERS = {
  [types.START_STATISTICS_SUCCESS]: startStatistics,
  [types.CLEAR_STATISTICS]: clearVideoStatistics
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const startStatisticsSuccess = createAction(
  types.START_STATISTICS_SUCCESS
)
export const startStatisticsFailure = createAction(
  types.START_STATISTICS_REQUEST_FAILURE
)

export const startStatisticsPost = fetchApi(
  getUrl('/statistics/'),
  { method: 'POST' },
  startStatisticsSuccess,
  startStatisticsFailure
)

export const tickStatisticsSuccess = createAction(
  types.TICK_STATISTICS_SUCCESS_HIDDEN
)
export const tickStatisticsFailure = createAction(
  types.TICK_STATISTICS_REQUEST_FAILURE_HIDDEN
)

export const sendTickStatistics = (
  vsId,
  chapterId,
  currentPosition,
  ccLanguage
) =>
  fetchApi(
    getUrl(`/statistics/${vsId}/tick/`),
    // Pass special option hidden as true to prevent this request from counting towards global loader
    { method: 'PATCH', hidden: true },
    tickStatisticsSuccess,
    tickStatisticsFailure
  )({ chapterId, currentPosition, ccLanguage })

export const clearStatistics = createAction(types.CLEAR_STATISTICS)
