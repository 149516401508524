import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'
import './styles.css'

class NumericInput extends Component {
  render() {
    return template.call(this)
  }
}

NumericInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  id: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired
}

NumericInput.defaultProps = {
  quantity: 1
}

export default NumericInput
