/* eslint-disable import/prefer-default-export */
import cartReselector, {
  cartItemsSelector,
  cartItemsCountSelector,
  subtotalSelector,
  discountSelector
} from 'reduxModules/cart/selectors'
import { createStructuredSelector, createSelector } from 'reselect'
import _ from 'lodash'
import {
  tokenizerSelector,
  clientTokenSelector
} from 'reduxModules/forms/paymentMethod/selectors'
import {
  sortedUserPaymentMethodsSelector,
  userPaypalAccountsSelector,
  userCreditCardsSelector
} from 'reduxModules/users/selectors'
import {
  loadingSelector,
  hasSubscriptionSelector,
  isMobileSelector,
  usedFreeTrialSelector,
  recaptchaSelector
} from 'reduxModules/app/selectors'
import getCardIcon from 'components/utils/getCardIcon'
import math from 'reduxModules/utils/math'
import {
  isLoggedInSelector,
  loggedInFromEmailSelector
} from 'reduxModules/auth/selectors'

const getCardDescription = method =>
  `${method.cardType} ending in ${method.last4} - ${method.expirationMonth}/${method.expirationYear}`

const isSubscribingSelector = state => state.checkout.isSubscribing
const disableCheckoutButtonSelector = state =>
  state.checkout.disableCheckoutButton
export const isDigitalPurchaseSelector = createSelector(
  [cartItemsSelector, isSubscribingSelector],
  (items, isSubscribing) => {
    if (isSubscribing) return true
    return _.isUndefined(_.find(items, { type: 'physical' }))
  }
)
export const userAddressesSelector = state => state.user.addresses.results
export const selectedAddressSelector = state => state.checkout.selectedAddress
export const selectedDeliveryMethodSelector = state =>
  state.checkout.selectedDeliveryMethod
export const selectedDeliveryMethodReselector = createSelector(
  [
    selectedDeliveryMethodSelector,
    isSubscribingSelector,
    isDigitalPurchaseSelector
  ],
  (method, isSubscribing, isDigitalPurchase) => {
    if (_.isEmpty(method) || isSubscribing || isDigitalPurchase)
      return undefined
    return method
  }
)
export const deliveryMethodsSelector = state => {
  const methods = _.cloneDeep(state.checkout.deliveryMethods)
  return methods
    ? methods.sort((a, b) => parseFloat(a.cost) - parseFloat(b.cost))
    : []
}
export const addressesOptionsSelector = createSelector(
  userAddressesSelector,
  addresses => {
    const options = []
    addresses.forEach(address => {
      const extendedAddress = address.extendedAddress
      let label = `${address.firstName} ${address.lastName}, ${address.streetAddress}, `
      if (extendedAddress) label = `${label} ${address.extendedAddress} `
      label = `${label} ${address.locality}, ${address.region} ${address.postalCode}, ${address.countryName}.`

      options.push({ value: address.id, label })
    })
    return options
  }
)
export const validatedCode = state => state.discountCode.validatedCode
export const discountCodeForm = state => state.discountCode.showDiscountCodeForm
export const couponSelector = createSelector(
  [validatedCode, isSubscribingSelector],
  (validatedCode, isSubscribing) => {
    if (!_.isEmpty(validatedCode) && validatedCode.codename) {
      if (isSubscribing) return validatedCode.codename
      return { codename: validatedCode.codename }
    }
    return undefined
  }
)

export const shippingCostSelector = state =>
  math.toFixed(state.checkout.shippingCost)
export const hasSavedAddressesSelector = state =>
  userAddressesSelector(state).length > 0
export const hasSavedPaymentMethodsSelector = state =>
  sortedUserPaymentMethodsSelector(state).length > 0
export const useExistentAddressSelector = state =>
  state.checkout.useExistentAddress
export const useExistentBillingAddressSelector = state =>
  state.checkout.useExistentBillingAddress
export const useExistentAddressReselector = createSelector(
  [useExistentAddressSelector, hasSavedAddressesSelector],
  (useExistentAddress, hasSavedAddresses) => {
    if (hasSavedAddresses) return useExistentAddress
    return false
  }
)
export const useExistentBillingAddressReselector = createSelector(
  [useExistentBillingAddressSelector, hasSavedAddressesSelector],
  (useExistentAddress, hasSavedAddresses) => {
    if (hasSavedAddresses) return useExistentAddress
    return false
  }
)

const taxSelector = state => math.toFixed(state.checkout.tax)
const disableNewAddressFormSelector = state =>
  state.checkout.disableNewAddressForm
const disableBillingAddressFormSelector = state =>
  state.checkout.disableBillingAddressForm
export const userSelectedDeliveryMethodSelector = state =>
  !_.isEmpty(selectedDeliveryMethodSelector(state))
const selectedShippingInfoSelector = state =>
  state.checkout.selectedShippingInfo
const selectedAddressDescriptionSelector = createSelector(
  [
    useExistentAddressSelector,
    userAddressesSelector,
    selectedAddressSelector,
    isDigitalPurchaseSelector,
    hasSavedAddressesSelector
  ],
  (
    useExistentAddress,
    addresses,
    selectedAddress,
    isDigital,
    hasSavedAddresses
  ) => {
    const address = _.find(addresses, { id: selectedAddress })
    if (isDigital) return undefined
    if (useExistentAddress && hasSavedAddresses)
      return _.pickBy(address, _.identity)
    return _.pickBy(selectedAddress, _.identity)
  }
)
export const paymentMethodOptionsSelector = createSelector(
  [userCreditCardsSelector, userPaypalAccountsSelector],
  (cards, paypalAccounts) => {
    const options = []
    cards.forEach(card => {
      const label = getCardDescription(card)
      options.push({ value: card.token, label, ...card })
    })
    paypalAccounts.forEach(account => {
      const label = account.email
      options.push({ value: account.token, label, ...account })
    })
    return options
  }
)
export const selectedPaymentMethodSelector = state =>
  state.checkout.selectedPaymentMethod
export const useExistentPaymentMethodSelector = state =>
  state.checkout.useExistentPaymentMethod
export const useExistentPaymentMethodReselector = createSelector(
  [useExistentPaymentMethodSelector, hasSavedPaymentMethodsSelector],
  (useExistentMethod, hasSavedMethods) => {
    if (hasSavedMethods) return useExistentMethod
    return false
  }
)
export const payWithCreditCardSelector = state =>
  state.checkout.payWithCreditCard
const userSelectedPaymentMethodSelector = state =>
  !_.isNull(selectedPaymentMethodSelector(state))
const selectedPaymentMethodDescriptionSelector = createSelector(
  [
    useExistentPaymentMethodSelector,
    sortedUserPaymentMethodsSelector,
    selectedPaymentMethodSelector,
    payWithCreditCardSelector
  ],
  (
    useExistentPaymentMethod,
    methods,
    selectedPaymentMethod,
    payWithCreditCard
  ) => {
    if (useExistentPaymentMethod) {
      const savedMethod = _.find(methods, { token: selectedPaymentMethod })
      if (_.isObject(savedMethod)) return savedMethod
    }

    if (payWithCreditCard) {
      let cardType
      let imageUrl
      let last4
      let expirationYear
      let expirationMonth

      if (
        Object.prototype.hasOwnProperty.call(selectedPaymentMethod, 'details')
      ) {
        cardType = selectedPaymentMethod.details.cardType
        imageUrl = getCardIcon(cardType)
        last4 = selectedPaymentMethod.details.lastTwo
      }

      if (
        Object.prototype.hasOwnProperty.call(selectedPaymentMethod, 'last4')
      ) {
        cardType = selectedPaymentMethod.cardType
        imageUrl = getCardIcon(cardType)
        last4 = selectedPaymentMethod.last4
        expirationMonth = selectedPaymentMethod.expirationMonth
        expirationYear = selectedPaymentMethod.expirationYear
      }

      return { cardType, imageUrl, last4, expirationMonth, expirationYear }
    }

    return { token: 'paypal' }
  }
)
const nonceSelector = createSelector(selectedPaymentMethodSelector, method =>
  method ? method.nonce : null
)

const orderPaymentMethodSelector = createSelector(
  [
    useExistentPaymentMethodSelector,
    selectedPaymentMethodSelector,
    nonceSelector
  ],
  (useExistentPaymentMethod, userSelectedPaymentMethod, nonce) => {
    if (useExistentPaymentMethod) {
      if (
        _.isObject(userSelectedPaymentMethod) &&
        Object.prototype.hasOwnProperty.call(userSelectedPaymentMethod, 'token')
      )
        return { paymentMethodToken: userSelectedPaymentMethod.token }
      return { paymentMethodToken: userSelectedPaymentMethod }
    }
    return { paymentMethodNonce: nonce }
  }
)
const selectedDeliveryMethodDescriptionSelector = createSelector(
  [deliveryMethodsSelector, selectedDeliveryMethodSelector],
  (methods, selectedMethod) => _.find(methods, { code: selectedMethod.code })
)
export const selectedBillingAddressSelector = state =>
  state.checkout.selectedBillingAddress
const billToShippingAddressSelector = createSelector(
  [state => state.checkout.billToShippingAdddress, isDigitalPurchaseSelector],
  (billToShipping, isDigital) => (isDigital ? false : billToShipping)
)
const selectedBillingAddressDescriptionSelector = createSelector(
  [
    billToShippingAddressSelector,
    selectedBillingAddressSelector,
    selectedAddressDescriptionSelector,
    useExistentBillingAddressReselector,
    userAddressesSelector,
    isSubscribingSelector,
    payWithCreditCardSelector
  ],
  (
    billToShipping,
    billingAddress,
    shippingAddress,
    useExistentBillingAddress,
    addresses,
    isSubscribing,
    payWithCreditCard
  ) => {
    if (isSubscribing) return undefined
    if (!payWithCreditCard) return undefined
    if (billToShipping) return _.pickBy(shippingAddress, _.identity)
    if (useExistentBillingAddress) {
      const addresss = _.find(addresses, { id: billingAddress })
      return _.pickBy(addresss, _.identity)
    }
    return _.pickBy(billingAddress, _.identity)
  }
)
export const readyToReviewOrderSelector = createSelector(
  [
    selectedAddressDescriptionSelector,
    selectedBillingAddressDescriptionSelector,
    selectedPaymentMethodSelector,
    selectedDeliveryMethodDescriptionSelector,
    isDigitalPurchaseSelector,
    isSubscribingSelector,
    payWithCreditCardSelector
  ],
  (
    shippingAddress,
    billingAddress,
    paymentMethod,
    deliveryMethod,
    isDigital,
    isSubscribing,
    payWithCreditCard
  ) =>
    (_.isObject(shippingAddress) || isDigital) &&
    (_.isObject(billingAddress) || isSubscribing || !payWithCreditCard) &&
    !_.isEmpty(paymentMethod) &&
    (_.isObject(deliveryMethod) || isDigital)
)
export const planSelectedSelector = state => state.checkout.planSelected
const orderTotalSelector = createSelector(
  [
    subtotalSelector,
    shippingCostSelector,
    taxSelector,
    discountSelector,
    isSubscribingSelector,
    planSelectedSelector
  ],
  (subtotal, shipping, taxes, discount, isSubscribing, planSelected) => {
    let value = 0
    if (isSubscribing && _.isObject(planSelected))
      value = parseFloat(planSelected.price) - discount
    else
      value =
        math.toFixed(
          parseFloat(subtotal) + parseFloat(shipping) + parseFloat(taxes)
        ) - discount
    return Math.max(0, value).toFixed(2)
  }
)
const storeMethodSelector = state => state.paymentMethod.storeMethod
export const showCheckoutSelector = state => state.checkout.showCheckout
export const utmSelector = createSelector(
  state => state.app.utm,
  utmData => {
    if (!_.isEmpty(utmData) && !_.isUndefined(utmData.utm_source)) {
      const getTrimmedValue = (value, limit) =>
        _.isString(value) ? String(value).substring(0, limit) : undefined

      return {
        source: getTrimmedValue(utmData.utm_source, 100),
        medium: getTrimmedValue(utmData.utm_medium, 255),
        campaign: getTrimmedValue(utmData.utm_campaign, 100),
        term: getTrimmedValue(utmData.utm_term, 50),
        content: getTrimmedValue(utmData.utm_content, 50)
      }
    }
    return undefined
  }
)

const userEmailSelector = state => state.auth.email

const globalPromoSelector = state => state.app.globalPromo

const isGlobalPromoEnabledSelector = state =>
  state.app.globalPromo.codename && state.app.globalPromo.codename.length > 0

const orderSelector = createStructuredSelector({
  billingAddress: selectedBillingAddressDescriptionSelector,
  delivery: selectedDeliveryMethodReselector,
  payment: orderPaymentMethodSelector,
  shippingAddress: selectedAddressDescriptionSelector,
  coupon: couponSelector
})

export const checkoutReselector = createStructuredSelector({
  addresses: userAddressesSelector,
  addressesOptions: addressesOptionsSelector,
  useExistentAddress: useExistentAddressReselector,
  useExistentBillingAddress: useExistentBillingAddressReselector,
  hasSavedAddresses: hasSavedAddressesSelector,
  selectedAddress: selectedAddressSelector,
  cart: cartReselector,
  deliveryMethods: deliveryMethodsSelector,
  selectedDeliveryMethod: selectedDeliveryMethodSelector,
  tax: taxSelector,
  disableNewAddressForm: disableNewAddressFormSelector,
  userSelectedDeliveryMethod: userSelectedDeliveryMethodSelector,
  selectedShippingInfo: selectedShippingInfoSelector,
  selectedAddressDescription: selectedAddressDescriptionSelector,
  tokenizer: tokenizerSelector,
  clientToken: clientTokenSelector,
  billToShippingAddress: billToShippingAddressSelector,
  paymentMethodOptions: paymentMethodOptionsSelector,
  selectedPaymentMethod: selectedPaymentMethodSelector,
  useExistentPaymentMethod: useExistentPaymentMethodReselector,
  userSelectedPaymentMethod: userSelectedPaymentMethodSelector,
  usedFreeTrial: usedFreeTrialSelector,
  readyToReviewOrder: readyToReviewOrderSelector,
  loading: loadingSelector,
  orderIsDigital: isDigitalPurchaseSelector,
  itemsNumber: cartItemsCountSelector,
  disableBillingAddressForm: disableBillingAddressFormSelector,
  payWithCreditCard: payWithCreditCardSelector,
  orderTotal: orderTotalSelector,
  order: orderSelector,
  shippingCost: shippingCostSelector,
  selectedBillingAddress: selectedBillingAddressSelector,
  deliveryMethodDetails: selectedDeliveryMethodDescriptionSelector,
  hasSavedPaymentMethods: hasSavedPaymentMethodsSelector,
  storeMethod: storeMethodSelector,
  isSubscribing: isSubscribingSelector,
  planSelected: planSelectedSelector,
  isLoggedIn: isLoggedInSelector,
  isMobile: isMobileSelector,
  showCheckout: showCheckoutSelector,
  loggedInFromEmail: loggedInFromEmailSelector,
  disableCheckoutButton: disableCheckoutButtonSelector,
  userEmail: userEmailSelector,
  globalPromo: globalPromoSelector,
  isGlobalPromoEnabled: isGlobalPromoEnabledSelector,
  validatedCode,
  discountCodeForm,
  recaptcha: recaptchaSelector
})

export const reviewOrderSelector = createStructuredSelector({
  order: orderSelector,
  cart: cartReselector,
  discount: discountSelector,
  tax: taxSelector,
  paymentMethodDetails: selectedPaymentMethodDescriptionSelector,
  deliveryMethodDetails: selectedDeliveryMethodDescriptionSelector,
  payWithCreditCard: payWithCreditCardSelector,
  clientToken: clientTokenSelector,
  orderTotal: orderTotalSelector,
  orderIsDigital: isDigitalPurchaseSelector,
  useExistentAddress: useExistentAddressSelector,
  useExistentPaymentMethod: useExistentPaymentMethodReselector,
  usedFreeTrial: usedFreeTrialSelector,
  loading: loadingSelector,
  isLoggedIn: isLoggedInSelector,
  isSubscribing: isSubscribingSelector,
  planSelected: planSelectedSelector,
  isSubscribed: hasSubscriptionSelector,
  isMobile: isMobileSelector,
  loggedInFromEmail: loggedInFromEmailSelector,
  utmData: utmSelector,
  userEmail: userEmailSelector,
  recaptcha: recaptchaSelector
})
