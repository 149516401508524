import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import dispatchers from 'reduxModules/confirmation/dispatchers'
import stateSelector from 'reduxModules/confirmation/selectors'
import template from './template.rt'
import './styles.css'

export class Confirmation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shouldBeDisabled: false
    }

    this.changeSubmitButtonStatus = this.changeSubmitButtonStatus.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.resolveFeedback = this.resolveFeedback.bind(this)
  }

  handleClose() {
    if (this.props.cancelCallback)
      this.props.cancelCallback()

    this.props.resolve('reject')
  }

  changeSubmitButtonStatus(shouldBeDisabled) {
    this.setState({ shouldBeDisabled })
  }

  resolveFeedback(resolveValue) {
    return () => {
      this.props.resolve(
        resolveValue, this.renderedChild && this.renderedChild.getResponse()
      )
    }
  }

  render() {
    return template.call(this)
  }
}

Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  rejectText: PropTypes.string,
  showReject: PropTypes.bool,
  showButtons: PropTypes.bool
}

Confirmation.defaultProps = {
  text: '',
  rejectText: 'Cancel',
  showReject: true,
  showButtons: true
}

export default connect(
  stateSelector,
  dispatchers
)(Confirmation)
