import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import Tutorial from '../../generic/CourseSummary';
import LoadMore from '../../generic/LoadMore';
import { userTutorialsFetchMore } from '../../../redux/modules/users';
import { MAX_MONTHLY_COURSES } from './';
export default function () {
    function repeatTutorial1(tutorials, subscription, tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-seen-' + tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'title': tutorial.title,
            'thumb': tutorial.photo.boxThumb,
            'new': tutorial.new
        });
    }
    function repeatTutorial2(tutorials, subscription, tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'title': tutorial.title,
            'thumb': tutorial.photo.boxThumb
        });
    }
    function scopeTutorialsSubscription3() {
        var tutorials = this.props.tutorials;
        var subscription = this.props.subscription;
        return React.createElement('div', {
            'id': 'Courses',
            'className': 'account-section'
        }, tutorials.length || this.props.isSubscribed && _.size(subscription.monthlyVideosSeen) > 0 ? React.createElement('div', {
            'className': 'courses',
            'key': '471'
        }, this.props.isSubscribed && subscription.rank === 1 && _.size(subscription.monthlyVideosSeen) > 0 ? [
            React.createElement('h3', {
                'className': 'account-section__title',
                'key': '5991'
            }, '\n        Monthly Activated Courses\n        (', _.size(subscription.monthlyVideosSeen), '/', subscription.plan === 'Basic' ? MAX_MONTHLY_COURSES.basic : MAX_MONTHLY_COURSES.default, ')\n      '),
            React.createElement.apply(this, [
                'section',
                {
                    'className': 'tutorials-list-content',
                    'key': '5993'
                },
                _.map(subscription.monthlyVideosSeen, repeatTutorial1.bind(this, tutorials, subscription))
            ])
        ] : null, React.createElement('h3', { 'className': 'account-section__title' }, 'My Courses'), React.createElement.apply(this, [
            'section',
            { 'className': 'tutorials-list-content' },
            _.map(this.props.tutorials, repeatTutorial2.bind(this, tutorials, subscription))
        ])) : null, this.props.nextTutorials ? React.createElement('div', {
            'className': 'load-more',
            'key': '2029'
        }, React.createElement(LoadMore, {
            'fetchMoreAction': userTutorialsFetchMore,
            'next': this.props.nextTutorials,
            'listContainerId': 'Courses'
        })) : null, tutorials && tutorials.length === 0 ? React.createElement('div', {
            'className': 'no-results',
            'key': '2251'
        }, !this.props.loading ? React.createElement('span', { 'key': '2326' }, 'You haven\u2019t purchased any course streams.') : null, this.props.loading ? React.createElement('div', {
            'className': 'spinner',
            'key': '2415'
        }, React.createElement('div', { 'className': 'double-bounce1' }), React.createElement('div', { 'className': 'double-bounce2' })) : null) : null);
    }
    return scopeTutorialsSubscription3.apply(this, []);
}