/* eslint-disable import/prefer-default-export */
import { savePlayerId } from 'reduxModules/player'
import { specialVideoFetch, clearSpecialVideo } from './'

export const specialVideoDispatchers = (dispatch, props) => ({
  loadSpecialVideoInfo() {
    dispatch(specialVideoFetch(props.params.specialVideoSlug)())
  },
  clearSpecialVideo() {
    dispatch(clearSpecialVideo())
  }
})

export const specialVideoPlayerDispatchers = (dispatch, props) => ({
  loadSpecialVideo() {
    dispatch(
      specialVideoFetch(props?.params?.specialVideoSlug || props?.slug)()
    )
  },
  savePlayerId(id) {
    dispatch(savePlayerId(id))
  }
})
