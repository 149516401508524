import PropTypes from 'prop-types'
import React from 'react'
import ImageGallery from 'react-image-gallery'
import LazyWrapper from 'components/generic/LazyWrapper'
import 'react-image-gallery/styles/css/image-gallery.css'

import AssetGalleryPropTypes from './propTypes'
import Item from './Item'

import './styles.css'

function AssetsGallery(props) {
  const renderItem = item => (
    <Item
      description={item.description}
      fullscreen={item.fullscreen}
      original={item.original}
      originalAlt={item.originalAlt}
      originalHeight={item.originalHeight}
      originalWidth={item.originalWidth}
      originalTitle={item.originalTitle}
      sizes={item.sizes}
      loading={item.loading}
      srcSet={item.srcSet}
    />
  )
  return (
    <div className="AssetsGallery">
      <div className="wrapper">
        <LazyWrapper>
          <ImageGallery
            infinite
            items={props.assets}
            showThumbnails={props.showThumbnails && !props.isMobile}
            showPlayButton={false}
            showBullets={!props.isMobile}
            showFullscreenButton={false}
            renderItem={renderItem}
          />
        </LazyWrapper>
      </div>
    </div>
  )
}

AssetsGallery.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape(AssetGalleryPropTypes)),
  isMobile: PropTypes.bool,
  showThumbnails: PropTypes.bool
}

AssetsGallery.defaultProps = {
  assets: [],
  isMobile: false,
  showThumbnails: false
}

export default AssetsGallery
