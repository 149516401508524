/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import { Component } from 'react'

import template from './template.rt'

import './styles.css'

export class CourseTabs extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.tabs !== this.props.tabs
  }

  render() {
    return template.call(this)
  }
}

CourseTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })
  ).isRequired
}

export default CourseTabs
