import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { onLoginBoxDispatch as mergeSelector } from 'reduxModules/auth/dispatchers'
import { loginBoxSelector } from 'reduxModules/app/selectors'
import template from './template.rt'
import './styles.css'

export class LoginBox extends Component {
  render() {
    return template.call(this)
  }
}

LoginBox.propTypes = {
  autoFocus: PropTypes.bool,
  inCheckout: PropTypes.bool,
  isMain: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

LoginBox.defaultProps = {
  autoFocus: false,
  inCheckout: false,
  isMain: false
}

export const LoginBoxWrapper = reduxForm({ form: 'signin' })(LoginBox)
export default connect(
  loginBoxSelector,
  null,
  mergeSelector
)(LoginBoxWrapper)
