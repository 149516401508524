import {
  deactivateSubscribingMode,
  clearOrderAction
} from 'reduxModules/checkout'
import { push } from 'react-router-redux'
import _ from 'lodash'
import { loginFromEmail as loginFromEmailAction } from 'reduxModules/auth'
import { subscriptionPlansFetch } from '../subscriptions'
import {
  addToCart,
  cartItemsFetch,
  removeCartItem,
  updateCart,
  itemAdded,
  types,
  setSubscriptionDiscount,
  relatedItemsFetch
} from './'

import { toggleDiscountCode, clearDiscountCode } from '../discountCode'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

export const addObjectToCart = (dispatch, onCart, object) =>
  dispatch(addToCart({ ordered: object }))
    .then(([_, res]) => {
      if (res.type === types.ADD_TO_CART_SUCCESS) {
        dispatch(itemAdded(res.payload))
        dispatch(cartItemsFetch())
        dispatch(clearDiscountCode())
        if (onCart) dispatch(relatedItemsFetch())
      } else toastError(notifications.cartUpdated)
    })
    .catch(payload => {
      toastServerError(payload.errors)
    })

export default (dispatch, props) => ({
  addToCart(objectUrl) {
    const { onCart } = props
    addObjectToCart(dispatch, onCart, objectUrl)
  }
})

export const cartMergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    loadCartItems() {
      dispatch(cartItemsFetch())
    },
    toggleDiscountCode() {
      dispatch(toggleDiscountCode())
    },
    checkoutCart() {
      dispatch(deactivateSubscribingMode())
      dispatch(clearOrderAction())
      dispatch(push('/checkout'))
    },
    loadSubscriptionPlans() {
      dispatch(subscriptionPlansFetch()).then(res => {
        const { subscription } = stateProps

        if (!_.isNull(subscription)) {
          const { plan } = subscription
          const currentPlan = _.find(res[1].payload.results, { name: plan })

          if (currentPlan && _.isNumber(currentPlan.videoTutorialDiscount))
            dispatch(setSubscriptionDiscount(currentPlan.videoTutorialDiscount))
        }
      })
    },
    loadRelatedItems() {
      dispatch(relatedItemsFetch())
    },
    loginFromEmail(query, isLoggedIn) {
      const { token, utm_source, utm_medium } = query // eslint-disable-line camelcase
      if (!isLoggedIn) dispatch(loginFromEmailAction(token))
      dispatch(
        push({ pathname: '/checkout', query: { utm_source, utm_medium } })
      )
    }
  }
}

export const cartItemDispatchers = dispatch => ({
  removeCartItemDispatcher(itemId) {
    dispatch(removeCartItem(itemId)).then(([_, res]) => {
      if (res.type === types.REMOVE_CART_ITEM_SUCCESS) {
        toastSuccess(notifications.itemRemovedFromCart)
        dispatch(clearDiscountCode())
        dispatch(relatedItemsFetch())
      } else toastError(notifications.itemRemovedFromCart)
    })
  },
  updateCartItemQuantity(pk, quantity) {
    dispatch(updateCart(pk, { quantity })).then(([_, res]) => {
      if (res.type === types.UPDATE_CART_SUCCESS) {
        toastSuccess(notifications.cartUpdated)
        dispatch(clearDiscountCode())
      } else toastError(notifications.cartUpdated)
    })
  }
})
