import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import stateSelector from 'reduxModules/gallery/selectors'
import assetsDispatchers from 'reduxModules/gallery/dispatchers'
import { assetsFetchMore } from 'reduxModules/assets'
import PhotoSwipe from './PhotoswipeGallery'

import LoadMore from '../generic/LoadMore'

import './styles.css'

export function Gallery(props) {
  useEffect(() => {
    props.loadAssets()
  }, [])

  return (
    <div className="wrapper" id="Gallery">
      <section className="heading">
        <h1>Gallery</h1>
      </section>
      <PhotoSwipe assets={props.assets} />
      {props.next ? (
        <div className="load-more">
          <LoadMore
            fetchMoreAction={assetsFetchMore}
            next={props.next}
            listContainerId="Gallery"
          />
        </div>
      ) : null}
    </div>
  )
}

Gallery.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      gallerySize: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      caption: PropTypes.string // Combination of title and description
    })
  ).isRequired,
  /* Dispatchers */
  loadAssets: PropTypes.func.isRequired,
  openImageModal: PropTypes.func.isRequired,
  closeImageModal: PropTypes.func.isRequired,
  prevImageModal: PropTypes.func.isRequired,
  nextImageModal: PropTypes.func.isRequired
}

Gallery.defaultProps = {
  assets: []
}

export default connect(stateSelector, assetsDispatchers)(Gallery)
