import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import LoadingComponent from 'components/generic/LoadingComponent'
import { learningPathsStatus } from 'reduxModules/learningPaths'
import { learningPathsStateSelector as stateSelector } from 'reduxModules/learningPaths/selectors'
import { learningPathsDispatchers as stateDispatcher } from 'reduxModules/learningPaths/dispatchers'
import { LearningPathSummaryPropTypes } from '../LearningPathSummary'
import template from './template.rt'
import './styles.css'

export class LearningPathList extends Component {
  componentDidMount() {
    const { getLearningPaths, loadBanners } = this.props
    getLearningPaths()
    loadBanners()
  }
  componentWillUnmount() {
    this.props.clearLearningPathList()
  }
  render() {
    if (
      !this.props.learningPaths ||
      this.props.status !== learningPathsStatus.success
    )
      return <LoadingComponent />

    return template.call(this)
  }
}

LearningPathList.propTypes = {
  learningPaths: PropTypes.arrayOf(
    PropTypes.shape(LearningPathSummaryPropTypes)
  )
}

LearningPathList.defaultProps = {
  learningPaths: []
}

export default connect(
  stateSelector,
  stateDispatcher
)(LearningPathList)
