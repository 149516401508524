import React from 'react'
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs'

import ChapterList from './ChapterList'

import keyUpHandler from '../../utils/keyUpHandler'

import '../styles.css'
import BookmarkList from './BookmarkList'
import RelevantToggle from './RelevantToggle'

function PlayerSidebar({
  playlist,
  isSpecialVideo,
  chaptered,
  playerIsReady,
  createBookmark,
  toggleSidebar,
  playChapter,
  currentChapter,
  userBookmarks,
  displayCreateBookmark,
  removeBookmark,
  player,
  tutorial,
  shouldOnlyShowRelevants,
  toggleShowingOnlyRelevantChapters,
  shouldShowRelevantToggle,
  userProgress,
  isPlaying,
  markChapterAsCompleted,
  updateUserProgress
}) {
  return playlist.length && !isSpecialVideo ? (
    <Tabs>
      <div className="react-tabs__wrap">
        <button
          aria-label="Toggle chapter sidebar"
          className="handle"
          onClick={() => toggleSidebar()}
          onKeyUp={event => keyUpHandler(event, toggleSidebar, null, this)}
          type="button"
        >
          <i className="icon icon-right-2" />
        </button>
        <TabList className="tablist">
          <Tab>
            <span className="total">
              {playlist.length} Chapter{playlist.length === 1 ? '' : 's'}
            </span>
          </Tab>
          <Tab>
            <span className="bookmark-tab">Bookmarks</span>
          </Tab>
        </TabList>
      </div>
      <TabPanel className="tab-panel" forceRender>
        {chaptered ? (
          <div className="chapters">
            {shouldShowRelevantToggle ? (
              <RelevantToggle
                toggleShowingOnlyRelevantChapters={
                  toggleShowingOnlyRelevantChapters
                }
                shouldOnlyShowRelevants={shouldOnlyShowRelevants}
              />
            ) : null}
            <ChapterList
              playlist={playlist}
              currentChapter={currentChapter}
              playChapter={playChapter}
              shouldShowRelevantToggle={shouldShowRelevantToggle}
              userProgress={userProgress}
              isPlaying={isPlaying}
              markChapterAsCompleted={markChapterAsCompleted}
              tutorial={tutorial}
              updateUserProgress={updateUserProgress}
            />
          </div>
        ) : null}
      </TabPanel>
      <TabPanel className="tab-panel">
        <BookmarkList
          playlist={playlist}
          currentChapter={currentChapter}
          playerIsReady={playerIsReady}
          createBookmark={createBookmark}
          tutorial={tutorial}
          player={player}
          displayCreateBookmark={displayCreateBookmark}
          userBookmarks={userBookmarks}
          removeBookmark={removeBookmark}
          playChapter={playChapter}
        />
      </TabPanel>
    </Tabs>
  ) : null
}

export default PlayerSidebar
