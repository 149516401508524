import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { Field } from 'redux-form';
import passwordField from '../../generic/PasswordField';
import { validators } from '../../../redux/modules/utils/forms';
import CaptchaStandalone from '../../CaptchaStandalone/_lazy';
import { CaptchaNotice } from '../../generic/CaptchaNotice';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('div', {
            'id': 'PreSignUp',
            'className': 'auth-page'
        }, React.createElement('div', {}, React.createElement('div', { 'className': 'logo' }, React.createElement(Link, { 'to': '/' }, React.createElement('img', {
            'src': '/global/assets/images/logo/logo-big.png',
            'alt': 'Stan Winston School Of Character Arts'
        }))), React.createElement('div', { 'className': 'reset-password auth-form-box' }, React.createElement('h1', { 'className': 'name' }, ' Hi ', props.firstName, ' ', props.lastName), React.createElement('h3', { 'className': 'email' }, props.email), React.createElement('div', {}, 'Set a password and redeem your Subscription'), React.createElement('form', {
            'id': 'SignInForm',
            'onSubmit': this.props.handleSubmit
        }, React.createElement(Field, {
            'name': 'password1',
            'validate': validators.required,
            'component': passwordField,
            'placeholder': 'New Password'
        }), React.createElement(Field, {
            'name': 'password2',
            'validate': validators.required,
            'component': passwordField,
            'placeholder': 'Retype New Password'
        }), React.createElement('button', {
            'type': 'submit',
            'className': 'btn -wide -orange'
        }, '\n                    Start Membership\n                '), React.createElement(CaptchaStandalone, {})), React.createElement(CaptchaNotice, { 'dark': true }))));
    }
    return scopeProps1.apply(this, []);
}