import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import moment from 'moment';
import Helmet from 'react-helmet';
import Banner from '../../generic/FeaturedElement/index.jsx';
import CourseTabs from '../../Courses/CourseTabs';
import AssetsGallery from '../../generic/AssetsGallery/_lazy';
import CourseArtist from '../../Courses/CourseArtist';
import CourseComments from '../../Courses/CourseComments/_lazy';
import CourseRelated from '../../Courses/CourseRelated';
import VideoModal from '../../generic/VideoModal';
import SpecialVideoPlayer from '../../SpecialVideos/SpecialVideoPlayer';
export default function () {
    function scopePropsSubscribed1() {
        var props = this.props;
        var subscribed = !_.isNull(props.subscription);
        return React.createElement('article', { 'id': 'SpecialVideoDetail' }, React.createElement(Helmet, {}, React.createElement('title', {}, `${ props.title } | Stan Winston School of Character Arts`), React.createElement('meta', {
            'property': 'og:title',
            'content': props.title
        }), React.createElement('meta', {
            'property': 'og:image',
            'content': props.photo.original
        }), React.createElement('meta', {
            'content': 'article',
            'property': 'og:type'
        })), props.photo ? React.createElement('div', {
            'className': 'header',
            'key': '1228'
        }, !this.state.watchMode ? React.createElement('div', {
            'className': 'wrapper',
            'key': '1273'
        }, React.createElement(Banner, {
            'title': props.title,
            'description': props.description,
            'photo': props.photo,
            'linkTo': this.getLinkTo(),
            'btn': {
                text: 'Watch Video',
                action: this.getLinkTo()
            },
            'isMobile': props.isMobile
        })) : null, this.state.watchMode && this.props.id ? React.createElement('div', { 'key': '1595' }, React.createElement(SpecialVideoPlayer, {
            'location': props.location,
            'artist': props.artist,
            'isAuth': props.isAuth,
            'slug': props.slug,
            'router': props.router
        })) : null) : null, this.state.tabs ? React.createElement(CourseTabs, {
            'tabs': this.state.tabs,
            'key': '1875'
        }) : null);
    }
    return scopePropsSubscribed1.apply(this, []);
}