import { showModal, hideModal } from 'reduxModules/generic/modal'

export default dispatch => ({
  closeModal() {
    dispatch(hideModal())
  },
  showModal() {
    dispatch(showModal())
  }
})
