import PropTypes from 'prop-types'
import { Component } from 'react'
import _ from 'lodash'
import template from './template.rt'
import './styles.css'

class LearningPathCourse extends Component {
  static getCategoryIconName(category) {
    return category
      .replace('&', '')
      .replace(',', '')
      .replace(/\s+/g, '-')
      .toLowerCase()
  }

  constructor(props) {
    super(props)
    this.state = { visible: false, cta: 'preview course' }
    this.getChapters = this.getChapters.bind(this)
    this.checkCompletedChapters = this.checkCompletedChapters.bind(this)
    this.isFiltered = this.isFiltered.bind(this)
    this.loadCourse = this.loadCourse.bind(this)
    this.setViewCourse = this.setViewCourse.bind(this)
    this.checkOverallProgress = this.checkOverallProgress.bind(this)
  }

  componentDidMount() {
    this.setViewCourse()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isEnrolled !== this.props.isEnrolled) this.setViewCourse()
  }

  getChapters() {
    const { relevantChapters, chapters } = this.props
    if (relevantChapters && relevantChapters.length > 0) return relevantChapters
    return chapters
  }

  isFiltered(course) {
    return this.props.filter.length === 0 || this.props.filter.includes(course)
  }

  checkCompletedChapters() {
    const { relevantChapters, chapters } = this.props
    if (relevantChapters && relevantChapters.length > 0) {
      const ids = relevantChapters.map(chapter => chapter.id)
      return _.size(
        chapters.filter(
          chapter => ids.includes(chapter.id) && chapter.isComplete === true
        )
      )
    }
    return _.size(_.filter(chapters, { isComplete: true }))
  }

  checkChapter(chapter) {
    const { chapters, relevantChapters } = this.props
    const chapterFound = chapters.find(chap => chap.id === chapter.id)
    if (relevantChapters && relevantChapters.length > 0)
      return !chapterFound.isComplete
    return !chapter.isComplete
  }

  checkOverallProgress() {
    const { relevantChapters, overallProgress } = this.props
    if (relevantChapters) {
      const completedChapters = this.checkCompletedChapters()
      /* No relevant chapters means that ALL chapters are relevant */
      if (relevantChapters.length === 0 && this.props.chapters.length > 0)
        return (completedChapters / this.props.chapters.length) * 100
      return (completedChapters / relevantChapters.length) * 100
    }
    return overallProgress
  }

  loadCourse(tutorial) {
    const {
      openPreview,
      isEnrolled,
      loadLearningPathwayCourse,
      slug,
      id
    } = this.props
    if (isEnrolled) loadLearningPathwayCourse(null, slug, id)
    else openPreview(tutorial)
  }

  setViewCourse() {
    const { overallProgress, isEnrolled } = this.props
    const progress = parseInt(overallProgress, 10)
    if (isEnrolled)
      if (progress < 100 && progress > 0)
        this.setState({ cta: 'continue course' })
      else if (progress >= 100) this.setState({ cta: 'rewatch course' })
      else this.setState({ cta: 'start course' })
    else this.setState({ cta: 'preview course' })
  }

  toggle() {
    this.setState({ visible: !this.state.visible })
  }

  loadChapter(chapter) {
    const { loadLearningPathwayCourse, slug, id } = this.props
    const chapterToLoad = {
      chapterOrder: chapter.order,
      time: 0
    }

    loadLearningPathwayCourse(chapterToLoad, slug, id)
  }

  render() {
    return template.call(this)
  }
}

LearningPathCourse.propTypes = {
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  durationH: PropTypes.number.isRequired,
  durationM: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  loadBookmark: PropTypes.func.isRequired,
  openPreview: PropTypes.func.isRequired,
  overallProgress: PropTypes.number,
  photo: PropTypes.shape({
    bookmarkThumb: PropTypes.string.isRequired,
    boxThumb: PropTypes.string.isRequired,
    boxThumbSm: PropTypes.string.isRequired,
    cartThumb: PropTypes.string.isRequired,
    galleryThumb: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
    original: PropTypes.string.isRequired,
    thumb: PropTypes.string.isRequired
  }).isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

LearningPathCourse.defaultProps = {
  overallProgress: null
}

export default LearningPathCourse
