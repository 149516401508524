import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { subscriptionReselector as stateSelector } from 'reduxModules/auth/selectors'
import template from './template.rt'
import './styles.css'

export function CustomerCare(props) {
  return template.call({ props })
}

CustomerCare.propTypes = {
  subscription: PropTypes.shape({
    free: PropTypes.bool,
    rank: PropTypes.number,
    plan: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  isMobile: PropTypes.bool.isRequired
}

CustomerCare.defaultProps = {
  subscription: null
}

export default connect(stateSelector)(CustomerCare)
