import React from 'react'
import { bool, func, string } from 'prop-types'

const defaultProps = {
  description: '',
  fullscreen: '',
  isFullscreen: false,
  originalAlt: '',
  originalHeight: '',
  originalWidth: '',
  originalTitle: '',
  sizes: '',
  srcSet: '',
  loading: 'eager'
}

const Item = React.memo(props => {
  const {
    description,
    fullscreen, // fullscreen version of img
    isFullscreen,
    onImageError,
    original,
    originalAlt,
    originalHeight,
    originalWidth,
    originalTitle,
    sizes,
    srcSet,
    loading
  } = { ...defaultProps, ...props }
  const itemSrc = isFullscreen ? fullscreen || original : original

  return (
    <React.Fragment>
      <img
        className="image-gallery-image fuckers"
        src={itemSrc}
        alt={originalAlt}
        srcSet={srcSet}
        height={originalHeight}
        width={originalWidth}
        sizes={sizes}
        title={originalTitle}
        onError={onImageError}
        loading={loading}
      />
      {description && (
        <span className="image-gallery-description">{description}</span>
      )}
    </React.Fragment>
  )
})

Item.displayName = 'Item'

Item.propTypes = {
  description: string,
  fullscreen: string, // fullscreen version of img
  handleImageLoaded: func,
  isFullscreen: bool,
  onImageError: func,
  original: string.isRequired,
  originalAlt: string,
  originalHeight: string,
  originalWidth: string,
  originalTitle: string,
  sizes: string,
  srcSet: string,
  loading: string
}

export default Item
