import PropTypes from 'prop-types'
import { Component } from 'react'

import template from './template.rt'
import './styles.css'

class CookieNotice extends Component {
  render() {
    return template.call(this)
  }
}

CookieNotice.propTypes = {
  hideCookieNotice: PropTypes.func.isRequired
}

export default CookieNotice
