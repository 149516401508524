import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  MERCHS_INITIAL
  MERCHS_FETCH_SUCCESS
  MERCHS_REQUEST_FAILURE
  MERCHS_SUCCESS
  MERCHS_ERROR
  SELECT_VARIANT
  OPEN_IMAGES_MODAL
  CLOSE_IMAGES_MODAL
`)

export const merchsStatus = {
  initial: types.MERCHS_INITIAL,
  success: types.MERCHS_SUCCESS,
  error: types.MERCHS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: merchsStatus.initial,
  list: [],
  imagesModal: [],
  modalIsOpen: false
}

export const success = (state = INITIAL_STATE, action) => {
  const list = action.payload.results.map(merch => {
    if (merch.variants && merch.variants.length)
      merch.variants[0] = { ...merch.variants[0], selected: true }
    return merch
  })
  return { ...state, list, status: merchsStatus.success }
}

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: merchsStatus.error
})

export const selectVariant = (state = INITIAL_STATE, action) => {
  const list = state.list.map(merch => {
    let variants = merch.variants
    if (merch.id === action.payload.merchId)
      variants = merch.variants.map(variant => ({
        ...variant,
        selected: variant.url === action.payload.variantURL
      }))
    return { ...merch, variants }
  })
  return { ...state, list }
}

export const openImagesModal = (state = INITIAL_STATE, action) => ({
  ...state,
  imagesModal: action.payload.imagesModalList,
  modalIsOpen: true
})

export const closeModal = (state = INITIAL_STATE) => ({
  ...state,
  modalIsOpen: false,
  imagesModal: []
})

export const HANDLERS = {
  [types.MERCHS_FETCH_SUCCESS]: success,
  [types.MERCHS_REQUEST_FAILURE]: failure,
  [types.SELECT_VARIANT]: selectVariant,
  [types.OPEN_IMAGES_MODAL]: openImagesModal,
  [types.CLOSE_IMAGES_MODAL]: closeModal
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const merchsFetchSuccess = createAction(types.MERCHS_FETCH_SUCCESS)
export const merchsFetchRequestFailure = createAction(
  types.MERCHS_REQUEST_FAILURE
)
export const merchsFetch = fetchApi(
  '/merchs/',
  { method: 'GET' },
  merchsFetchSuccess,
  merchsFetchRequestFailure
)

export const selectVariantAction = createAction(types.SELECT_VARIANT)
export const openImagesModalAction = createAction(types.OPEN_IMAGES_MODAL)
export const closeModalAction = createAction(types.CLOSE_IMAGES_MODAL)
