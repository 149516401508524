import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import RegisterBox from '../../generic/RegisterBox';
export default function () {
    return React.createElement('div', {
        'id': 'SignUp',
        'className': 'auth-page'
    }, React.createElement('div', {}, React.createElement('div', { 'className': 'info' }, React.createElement('div', { 'className': 'logo' }, React.createElement(Link, { 'to': '/' }, React.createElement('img', {
        'src': '/global/assets/images/logo/logo-big.png',
        'alt': 'Stan Winston School Of Character Arts'
    }))), React.createElement('div', { 'className': 'title-container' }, React.createElement('h1', {}, 'welcome to Stan Winston School Of Character Arts')), React.createElement('ul', {}, React.createElement('li', {}, 'Access to behind the scenes monster making magic'), React.createElement('li', {}, 'Exclusive images and videos of your favorites movie creatures'), React.createElement('li', {}, 'Free Access to mini-tutorials and web exclusives'), React.createElement('li', {}, 'Weekly newsletters with content updates and discount codes'), React.createElement('li', {}, 'Be the first to subscribe to our weekly and monthly contests'))), React.createElement('div', { 'className': 'logo-mobile' }, React.createElement(Link, { 'to': '/' }, React.createElement('img', {
        'src': '/global/assets/images/logo/logo-big.png',
        'alt': 'Stan Winston School Of Character Arts'
    }))), React.createElement(RegisterBox, _.assign({}, { 'autoFocus': true }, this.props))));
}