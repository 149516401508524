import { createSelector } from 'reselect'
import { randomTestimonialSelector } from '../testimonials/selectors'

// selectors
const artistsSelector = state =>
  state.artists.list.map(artist => ({
    fullName: `${artist.firstName} ${artist.lastName}`,
    intro: artist.intro,
    slug: artist.slug,
    photo: artist.photo
  }))

const testimonialSelector = createSelector(
  randomTestimonialSelector,
  testimonial => ({
    fullName: testimonial.fullName,
    text: testimonial.text
  })
)

export default createSelector(
  [artistsSelector, testimonialSelector],
  (artists, testimonial) => ({ artists, testimonial })
)
