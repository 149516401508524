import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { Element } from 'react-scroll';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('div', {
            'className': 'profile-summary' + ' ' + _.transform({ [props.className]: props.className }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement(Element, { 'name': props.elementName }, React.createElement(Link, {
            'className': 'profile-summary-wrap',
            'to': `/${ props.variant === 'teamMember' ? 'the-team' : 'artists' }/${ props.slug }`
        }, React.createElement('div', {
            'className': 'profile-summary__photo',
            'aria-label': 'Profile image',
            'style': { backgroundImage: `url(${ props.photo })` }
        }), React.createElement('div', { 'className': 'profile-summary-info' }, React.createElement('h3', { 'className': 'profile-summary-info__name' }, props.name), React.createElement('p', { 'className': 'profile-summary-info__tagline' }, props.tagline)))));
    }
    return scopeProps1.apply(this, []);
}