import PropTypes from 'prop-types'

export default {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string,
  created: PropTypes.string,
  orderDate: PropTypes.string, // Search Component use created rather order_date
  photo: PropTypes.objectOf(PropTypes.string).isRequired,
  elementName: PropTypes.string
}
