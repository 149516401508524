import PropTypes from 'prop-types'
import { LearningPathSummaryPropTypes } from '../../LearningPaths/LearningPathSummary'
import template from './template.rt'
import './styles.css'

function RecentPathways(props) {
  return template.call({ props })
}

RecentPathways.propTypes = {
  pathways: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape(LearningPathSummaryPropTypes)),
    count: PropTypes.number,
    next: PropTypes.string,
    prev: PropTypes.string
  })
}

export default RecentPathways
