import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { orderDetailDispatchers } from 'reduxModules/users/dispatchers'
import _ from 'lodash'
import math from 'reduxModules/utils/math'
import template from './template.rt'
import './styles.css'

// (gsinovsky): waiting on backend adjustment to display subscriber price
// const reduceFunction = (acc, { subscriberPrice, regularPrice, quantity }) => {
//     if (_.isNumber(subscriberPrice) && _.isNumber(regularPrice))
//         return acc + ((subscriberPrice - regularPrice) * quantity)
//     return acc
// }

export class OrderDetail extends Component {
  static getSubtotal(items) {
    return math.toFixed(
      parseFloat(
        items.reduce((acc, item) => acc + item.regularPrice * item.quantity, 0)
      )
    )
  }

  componentDidMount() {
    const { isLoaded, loadOrderDetail, id } = this.props
    if (!isLoaded) loadOrderDetail(id)
  }

  render() {
    return template.call(this)
  }
}

OrderDetail.propTypes = {
  isLoaded: PropTypes.bool,
  loadOrderDetail: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  )
}

OrderDetail.defaultProps = {
  items: [],
  id: '',
  isLoaded: false
}

export default connect(
  null,
  orderDetailDispatchers
)(OrderDetail)
