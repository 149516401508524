/* eslint-disable import/prefer-default-export */
import { searchDispatchers } from 'reduxModules/newSearch/dispatchers'
import { closeBanner } from 'reduxModules/subscriptions'
import { courseCategoryLandingPageFetch, clearCategory } from './'

export const courseCategoryLandingPageDispatchers = (dispatch, props) => ({
  closeBannerSubscription() {
    dispatch(closeBanner())
  },
  loadCourseCategoryLandingPage() {
    dispatch(courseCategoryLandingPageFetch(props.params.slug)())
  },
  searchDispatchers: searchDispatchers(dispatch, props),
  clearCourseCategory() {
    dispatch(clearCategory())
  }
})
