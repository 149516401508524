import { toggleDiscountCode } from 'reduxModules/discountCode'
import { resolveConfirmation } from './'

export default dispatch => ({
  resolve(resolution, feedback = null) {
    dispatch(resolveConfirmation({ resolution, feedback }))
  },
  toggleDiscountCode() {
    dispatch(toggleDiscountCode())
  }
})
