import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function MyLearningPath(props) {
  return template.call({ props })
}

export const LearningPathSummaryPropTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  photo: PropTypes.objectOf(PropTypes.string).isRequired,
  artist: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  difficulty: PropTypes.arrayOf(PropTypes.number).isRequired,
  duration: PropTypes.arrayOf(PropTypes.number).isRequired,
  numTutorials: PropTypes.number.isRequired
}

MyLearningPath.propTypes = LearningPathSummaryPropTypes

export default MyLearningPath
