import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { onAuthDispatch as dispatchSelector } from 'reduxModules/auth/dispatchers'
import template from './template.rt'
import '../styles.css'
import './styles.css'

export class SignIn extends Component {
  constructor(props) {
    super(props)
    props.hideGlobalNav()
  }

  componentWillUnmount() {
    this.props.showGlobalNav()
  }

  render() {
    return template.call(this)
  }
}

SignIn.propTypes = {
  hideGlobalNav: PropTypes.func.isRequired,
  showGlobalNav: PropTypes.func.isRequired
}

export default connect(null, dispatchSelector)(SignIn)
