export default [
  { value: 'order_issue', name: 'Order issue' },
  { value: 'order_status', name: 'Order status' },
  { value: 'return_status', name: 'Return status' },
  { value: 'product_question', name: 'Product question' },
  { value: 'shipping_policies', name: 'Shipping policies' },
  { value: 'return_policies', name: 'Return policies' },
  { value: 'technical_issue', name: 'Technical issue' },
  { value: 'marketing_press', name: 'Marketing/Press' }
]
