import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function repeatMotive1(props, motive, motiveIndex) {
        return React.createElement('option', {
            'key': motiveIndex,
            'value': motive.value
        }, '\n      ', motive.text, '\n    ');
    }
    function scopeProps2() {
        var props = this.props;
        return React.createElement('div', { 'className': 'feedback' }, React.createElement('label', {
            'htmlFor': 'cancellation-motive',
            'className': 'feedback__label'
        }, '\n    ', props.text, '\n  '), props.feedback.options.length > 0 ? React.createElement.apply(this, [
            'select',
            {
                'className': 'feedback__select',
                'id': 'cancellation-motive',
                'value': this.state.feedbackOption,
                'onChange': this.handleSelect,
                'key': '155'
            },
            _.map(props.feedback.options, repeatMotive1.bind(this, props))
        ]) : null, this.state.feedbackOption === 'Other' ? React.createElement('textarea', {
            'className': 'feedback__text',
            'value': this.state.feedbackMessage,
            'onChange': this.handleTextarea,
            'placeholder': 'Please explain your reason',
            'maxLength': '1000',
            'key': '510'
        }) : null);
    }
    return scopeProps2.apply(this, []);
}