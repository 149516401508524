import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import Parser from 'html-react-parser'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/subscriptions/selectors'
import { subscriptionPlansDispatchers as dispatchSelector } from 'reduxModules/subscriptions/dispatchers'
import _ from 'lodash'
import template from './template.rt'
import mobileTemplate from './mobileTemplate.rt'
import './styles.css'

export class SubscriptionPlanList extends Component {
  constructor(props, context) {
    super(props, context)
    this.getDiscountedPrice = this.getDiscountedPrice.bind(this)
    this.filterSubscriptionPlans = this.filterSubscriptionPlans.bind(this)
    this.filterSubscriptionPlansMobile = this.filterSubscriptionPlansMobile.bind(
      this
    )
    this.htmlParser = Parser
  }

  getDiscountedPrice(plan) {
    const globalPromo = this.props.globalPromo
    if (
      this.props.isGlobalPromoEnabled &&
      globalPromo.plans &&
      plan.id &&
      globalPromo.plans[plan.id]
    )
      return this.props.globalPromo.plans[plan.id].priceDiscount
    return plan.price
  }

  filterSubscriptionPlans(plan) {
    return (
      !(!this.props.isSubscribed && plan.name === 'Apprentice') &&
      !(this.props.currentPlanName === 'Apprentice' && plan.name === 'Basic') &&
      !(this.props.currentPlanName === 'Basic' && plan.name === 'Apprentice') &&
      !(this.props.currentPlan > 1 && plan.name === 'Apprentice')
    )
  }

  filterSubscriptionPlansMobile(plan) {
    return this.filterSubscriptionPlans(plan) && plan.name !== 'Group Learning'
  }

  render() {
    const { isMobile } = this.props
    return (isMobile ? mobileTemplate : template).call(this)
  }
}

export const SubscriptionPlanPropTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  trial: PropTypes.string,
  description_1: PropTypes.string,
  description_2: PropTypes.string,
  description_3: PropTypes.string,
  price: PropTypes.number.isRequired,
  owned: PropTypes.bool.isRequired
}

SubscriptionPlanList.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape(SubscriptionPlanPropTypes))
    .isRequired,
  upgrading: PropTypes.bool,
  currentPlan: PropTypes.number,
  isSubscribed: PropTypes.bool,
  currentPlanName: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.number.isRequired,
  closeBannerSubscription: PropTypes.func.isRequired,
  openBannerSubscription: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  globalPromo: PropTypes.shape({
    codename: PropTypes.string,
    discount: PropTypes.number,
    discountType: PropTypes.string,
    plans: PropTypes.shape({
      id: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        price: PropTypes.number,
        discountAmount: PropTypes.number,
        priceDiscount: PropTypes.number
      })
    })
  })
}

SubscriptionPlanList.defaultProps = {
  plans: [],
  upgrading: false,
  isSubscribed: false,
  currentPlanName: '',
  currentPlan: 0,
  globalPromo: {
    codename: '',
    discount: 0,
    discountType: '',
    plans: {}
  }
}

export default connect(stateSelector, dispatchSelector)(SubscriptionPlanList)
