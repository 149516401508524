import { Component } from 'react'
import template from './template.rt'

class RadialProgressCategory extends Component {
  static getCategoryIconName(category) {
    return category
      .replace('&', '')
      .replace(',', '')
      .replace(/\s+/g, '-')
      .toLowerCase()
  }

  render() {
    return template.call(this)
  }
}

export default RadialProgressCategory
