import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { landingPagesStatus } from 'reduxModules/landingPages'
import {
  landingPageStateSelector as stateSelector
} from 'reduxModules/landingPages/selectors'
import {
  landingPageDispatchers as dispatchers
} from 'reduxModules/landingPages/dispatchers'

import {
  TutorialSummaryPropTypes
} from 'components/generic/CourseSummary/propTypes'

import template from './template.rt'

import './styles.css'

export class LandingPage extends Component {
  componentDidMount() {
    this.props.loadLandingPage()
  }

  getBody() {
    return { __html: this.props.body }
  }

  render() {
    if (this.props.status === landingPagesStatus.success)
      return template.call(this)
    return null
  }
}

LandingPage.propTypes = {
  loadLandingPage: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  banner: PropTypes.objectOf(PropTypes.string).isRequired,
  slug: PropTypes.string,
  body: PropTypes.string,
  tutorials: PropTypes
    .arrayOf(PropTypes.shape(TutorialSummaryPropTypes)).isRequired,
  isMobile: PropTypes.bool
}

LandingPage.defaultProps = {
  body: '',
  banner: {
    medium: ''
  },
  tutorials: [],
  slug: '',
  subtitle: '',
  isMobile: false
}

export default connect(
  stateSelector,
  dispatchers
)(LandingPage)
