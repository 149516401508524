import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import math from '../../../redux/modules/utils/math';
import utils from '../../utils/cartItemUtils';
export default function () {
    function onClick1(item) {
        this.props.closeItemModal();
    }
    function scopeItem2() {
        var item = this.props.itemAdded;
        return React.createElement('div', {
            'id': 'CartModal',
            'className': 'cartmodal',
            'ref': modal => {
                this.wrapperRef = modal;
            }
        }, React.createElement('i', {
            'className': 'icon-close',
            'onClick': onClick1.bind(this, item)
        }), React.createElement('div', { 'className': 'msg' }, React.createElement('i', { 'className': 'icon-check' }), 'Item added to Cart'), !_.isEmpty(item) ? React.createElement('div', {
            'className': 'item',
            'key': '471'
        }, React.createElement('div', { 'className': 'thumbnail' }, item.type !== 'plan' ? React.createElement('img', {
            'src': item.thumbnail.cartThumb,
            'key': '559'
        }) : null), React.createElement('div', { 'className': 'description' }, React.createElement('div', {
            'className': 'type' + ' ' + _.transform({ blue: item.type !== 'physical' }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('span', {}, utils.getNaturalType(item.orderedModel, item.product))), React.createElement('div', { 'className': 'title' }, React.createElement('span', {}, utils.getTitle(item.orderedModel, item.product)))), React.createElement('div', { 'className': 'price' }, React.createElement('span', {}, '$', math.toFixed(item.price)))) : null, React.createElement('div', { 'className': 'bottom' }, React.createElement(Link, {
            'to': '/checkout',
            'className': 'btn -orange'
        }, 'Checkout')));
    }
    return scopeItem2.apply(this, []);
}