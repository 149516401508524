import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    function onClick1() {
        this.props.hideCookieNotice();
    }
    return React.createElement('div', { 'className': 'cookie-notice' }, React.createElement('p', { 'className': 'cookie-notice__message' }, '\n    This website uses cookies to improve your experience. See our', ` `, '\n    ', React.createElement(Link, { 'to': '/customer-care/privacy-policy/' }, 'Privacy Policy'), ' to learn more.\n  '), React.createElement('button', {
        'className': 'cookie-notice__cta btn -thin -blue-2',
        'onClick': onClick1.bind(this)
    }, '\n    Accept\n  '));
}