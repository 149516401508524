import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  COURSE_CATEGORY_LANDING_PAGE_INITIAL
  COURSE_CATEGORY_LANDING_PAGE_SUCCESS
  COURSE_CATEGORY_LANDING_PAGE_ERROR
  COURSE_CATEGORY_LANDING_PAGE_FETCH_SUCCESS
  COURSE_CATEGORY_LANDING_PAGE_REQUEST_FAILURE
  COURSE_CATEGORY_LANDING_PAGE_CLEAR
`)

export const courseCategoryLandingPageStatus = {
  initial: types.COURSE_CATEGORY_LANDING_PAGE_INITIAL,
  success: types.COURSE_CATEGORY_LANDING_PAGE_SUCCESS,
  error: types.COURSE_CATEGORY_LANDING_PAGE_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: courseCategoryLandingPageStatus.initial,
  detail: {
    // --
    title: '',
    slug: '',
    socialUrl: '',
    // --
    metaDescription: '',
    metaKeywords: '',
    metaTitle: '',
    // --
    id: 0,
    description: '',
    photo: { original: '', default: '' },
    facebookShareImages: []
  }
}

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  status: courseCategoryLandingPageStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: courseCategoryLandingPageStatus.error
})

export const clear = () => INITIAL_STATE

export const HANDLERS = {
  [types.COURSE_CATEGORY_LANDING_PAGE_FETCH_SUCCESS]: successDetail,
  [types.COURSE_CATEGORY_LANDING_PAGE_REQUEST_FAILURE]: failure,
  [types.COURSE_CATEGORY_LANDING_PAGE_CLEAR]: clear
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const courseCategoryLandingPageFetchSuccess = createAction(
  types.COURSE_CATEGORY_LANDING_PAGE_FETCH_SUCCESS
)
export const courseCategoryLandingPageFetchFailure = createAction(
  types.COURSE_CATEGORY_LANDING_PAGE_REQUEST_FAILURE
)
export const courseCategoryLandingPageFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')

  return fetchApi(
    `/tutorial-categories/${slug}/`,
    { method: 'GET' },
    courseCategoryLandingPageFetchSuccess,
    courseCategoryLandingPageFetchFailure
  )
}

export const clearCategory = createAction(
  types.COURSE_CATEGORY_LANDING_PAGE_CLEAR
)
