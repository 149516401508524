import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  PAGE_INITIAL
  PAGE_SUCCESS
  PAGE_ERROR
  PAGE_FETCH_SUCCESS
  PAGE_REQUEST_FAILURE
`)

export const pagesStatus = {
  initial: types.PAGE_INITIAL,
  success: types.PAGE_SUCCESS,
  error: types.PAGE_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: pagesStatus.initial,
  detail: {}
}

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  status: pagesStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: pagesStatus.error
})

export const HANDLERS = {
  [types.PAGE_FETCH_SUCCESS]: successDetail,
  [types.PAGE_REQUEST_FAILURE]: failure
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const pageFetchSuccess = createAction(types.PAGE_FETCH_SUCCESS)
export const pageFetchFailure = createAction(types.PAGE_REQUEST_FAILURE)
export const pageFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/pages/${slug}/`,
    { method: 'GET' },
    pageFetchSuccess,
    pageFetchFailure
  )
}
