import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function CourseArtist(props) {
  return template.call({ props })
}

CourseArtist.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  intro: PropTypes.string,
  photo: PropTypes.objectOf(PropTypes.string).isRequired
}

export default CourseArtist
