export const getActiveSection = (pathName = '') => {
  switch (
    pathName
      .split('/')
      .slice(0, 2)
      .join('/')
  ) {
    case '/webcourses':
    case '/tutorials':
      return 'courses'
    case '/store':
      return 'store'
    case '/pathways':
      return 'pathways'
    case '/blog':
      return 'community'
    case '/stan-winston-school-of-character-arts':
    case '/gallery':
    case '/artists':
    case '/the-team':
      return 'about'
    case '/cart':
      return 'cart'
    case '/account':
      return 'account'
    case '/home':
      return 'home'
    default:
      return ''
  }
}

export const getActiveSubsection = (pathName = '') => {
  switch (
    pathName
      .split('/')
      .slice(0, 2)
      .join('/')
  ) {
    case '/webcourses':
      return 'live'
    case '/tutorials':
      return 'on-demand'
    case '/store':
      return 'store'
    case '/blog':
      return 'blog'
    case '/stan-winston-school-of-character-arts':
      return 'school'
    case '/gallery':
      return 'gallery'
    case '/artists':
      return 'teachers'
    case '/the-team':
      return 'team'
    default:
      return ''
  }
}
