import PropTypes from 'prop-types'
import { Component } from 'react'

import template from './template.rt'
import './styles.css'


export default class SelectSubscriptionType extends Component {
  render() {
    return template.call(this)
  }
}

SelectSubscriptionType.propTypes = {
  onClose: PropTypes.func.isRequired
}

SelectSubscriptionType.defaultProps = {
  onClose: ()=> {}
}
