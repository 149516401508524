import { combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import app from './app'
import navBar from './navBar'
import artists from './artists'
import auth from './auth'
import assets from './assets'
import banners from './banners'
import blogPosts from './blogPosts'
import contact from './contact'
import forumPosts from './forumPosts'
import landingPages from './landingPages'
import leadMagnetPages from './leadMagnetPages'
import courseCategoryLandingPage from './courseCategoryLandingPage'
import menuItem from './menuItem'
import pages from './pages'
import newSearch from './newSearch'
import testimonials from './testimonials'
import tutorials from './tutorials'
import user from './users'
import webinars from './webinars'
import merchs from './merchs'
import notifications from './generic/notifications'
import gallery from './gallery'
import paymentMethod from './forms/paymentMethod'
import cart from './cart'
import checkout from './checkout'
import discountCode from './discountCode'
import subscriptions from './subscriptions'
import player from './player'
import statistics from './statistics'
import modal from './generic/modal'
import specialVideos from './specialVideos'
import home from './home'
import contests from './contests'
import learningPaths from './learningPaths'
import emailConfirmation from './emailConfirmation'
import captcha from './captcha'

export default combineReducers({
  app,
  navBar,
  artists,
  auth,
  assets,
  banners,
  blogPosts,
  contact,
  emailConfirmation,
  forumPosts,
  gallery,
  landingPages,
  leadMagnetPages,
  courseCategoryLandingPage,
  menuItem,
  pages,
  testimonials,
  tutorials,
  user,
  webinars,
  merchs,
  subscriptions,
  notifications,
  paymentMethod,
  cart,
  checkout,
  discountCode,
  player,
  modal,
  statistics,
  specialVideos,
  home,
  contests,
  learningPaths,
  loadingBar: loadingBarReducer,
  form: formReducer,
  routing: routerReducer,
  newSearch,
  captcha
})
