import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router'

import ChevronLeftIconSmall from '../../icons/ChevronLeftIconSmall'

import './styles.css'

export function PlayerHeader(props) {
  const { title, artist, learningPath, location, to } = props

  return (
    <div className="go-back-video">
      <div className="go-back-video-summary">
        <div className="return-icon">
          <Link
            to={to}
            title="Return to summary"
            aria-label="Return to course summary"
          >
            <ChevronLeftIconSmall
              className="go-back__icon"
              width="18px"
              height="18px"
            />
          </Link>
        </div>
        <div>
          <div className="go-back-title">
            <h1 className="go-back-heading-title">{title}</h1>
          </div>
          {artist && (
            <div className="go-back-artist">
              <Link to={`/artists/${artist.slug}`}>
                <h2>{`${artist.firstName} ${artist.lastName}`}</h2>
              </Link>
            </div>
          )}
          {location.search.includes('path_id=') &&
            learningPath &&
            learningPath.title && (
              <div className="go-back-pathway">
                <h3 className="pathway">
                  You are on Pathway:
                  <Link to={`/pathways/${learningPath.slug}`}>
                    <span className="path-title">{learningPath.title}</span>
                  </Link>
                </h3>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

PlayerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  artist: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  to: PropTypes.string.isRequired,
  learningPath: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    photo: PropTypes.objectOf(PropTypes.string).isRequired,
    artist: PropTypes.shape({
      title: PropTypes.string.isRequired
    }),
    difficulty: PropTypes.arrayOf(PropTypes.number).isRequired,
    duration: PropTypes.arrayOf(PropTypes.number).isRequired,
    numTutorials: PropTypes.number.isRequired
  })
}

export default PlayerHeader
