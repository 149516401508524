import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import { findMember } from '../members';
export default function () {
    function scopeMember1() {
        var member = findMember(this.props.params.memberSlug);
        return React.createElement('div', { 'id': 'TeamMember' }, React.createElement(Helmet, {}, React.createElement('title', {}, `${ member.name } | Stan Winston School of Character Arts`), React.createElement('meta', {
            'content': 'profile',
            'property': 'og:type'
        })), React.createElement('div', { 'className': 'img-holder' }, React.createElement('img', {
            'src': member.landscapeImage,
            'alt': member.name
        })), React.createElement('div', { 'className': 'member-info wrapper' }, React.createElement('h1', {}, member.name), member.bio ? React.createElement('div', {
            'className': 'bio',
            'dangerouslySetInnerHTML': { __html: member.bio },
            'key': '529'
        }) : null));
    }
    return scopeMember1.apply(this, []);
}