import React from 'react'
import SearchTextSuggestions from './SearchTextSuggestions'
import SearchTutorialSuggestions from './SearchTutorialSuggestions'
import SearchPostSuggestions from './SearchPostSuggestions'

import './styles.css'

function SearchSuggestions({ results, query }) {
  const { posts, tutorials, suggestions } = results
  return (
    <div id="SearchSuggestions">
      <SearchTextSuggestions query={query} suggestions={suggestions} />
      <SearchTutorialSuggestions suggestions={tutorials} />
      <SearchPostSuggestions suggestions={posts} />
      <div className="see-all-results-container">
        <a
          aria-label={`Click to see all available results for "${query}"`}
          href={`/search?q=${encodeURIComponent(query).toLowerCase()}`}
          className="see-all-results"
        >
          See all results
        </a>
      </div>
    </div>
  )
}

export default SearchSuggestions
