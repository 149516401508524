import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { addBookmarkDispatchers as dispatchSelector } from 'reduxModules/player/dispatchers'
import _ from 'lodash'

import RenderedField from '../../generic/RenderedField'

import './styles.css'

function AddBookmark({ handleSubmit, hideCreateBookmark }) {
  useEffect(() => {
    const inputFieldComponent = document.getElementById('bookmarkName')
    if (inputFieldComponent) inputFieldComponent.select()
  }, [])

  return (
    <div id="AddBookmark">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <i className="icon icon-bookmark-empty" />
          <Field
            name="bookmarkName"
            component={RenderedField}
            type="text"
            placeholder="Bookmark name"
            autoFocus
            id="bookmarkName"
          />
        </div>
        <div className="cta-container">
          <button
            className="close"
            onClick={() => hideCreateBookmark()}
            type="button"
          >
            Cancel
          </button>
          <button className="btn -mini -blue-2 submit-btn" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

AddBookmark.propTypes = {
  tutorial: PropTypes.number.isRequired,
  chapter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.string,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  getCurrentTime: PropTypes.func.isRequired,
  hideCreateBookmark: PropTypes.func.isRequired
}

export const AddBookmarkWrapper = reduxForm({ form: 'add-bookmark' })(
  AddBookmark
)
export default connect(null, dispatchSelector)(AddBookmarkWrapper)
