import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function RenderedField(props) {
  const hasError =
    props.meta.touched && (props.meta.error || props.meta.warning)
  return template.call({ props: { ...props, hasError } })
}

RenderedField.propTypes = {
  ariaLabel: PropTypes.string,
  icon: PropTypes.string,
  iconAction: PropTypes.func,
  iconLabel: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelFor: PropTypes.string,
  labelHidden: PropTypes.bool,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  country: PropTypes.string,
  autoFocus: PropTypes.bool
}

RenderedField.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  country: '',
  autoFocus: false
}

export default RenderedField
