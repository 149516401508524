import React from 'react'
import { Item } from 'react-photoswipe-gallery'
import useImageDimensions from './useImageDimensions'

import keyUpHandler from '../utils/keyUpHandler'

export default function GalleryItem(photo) {
  const [width, height] = useImageDimensions(photo.src)
  return (
    <Item
      original={photo.src}
      thumbnail={photo.thumbnail}
      cropped
      key={photo.src}
      alt={photo.caption}
      width={width}
      height={height}
    >
      {({ ref, open }) => (
        <div
          onClick={open}
          role="button"
          onKeyUp={event => keyUpHandler(event, open, null, this)}
          tabIndex={0}
          className="gallery-item"
        >
          <div
            ref={ref}
            alt={photo.caption}
            className="gallery-item__thumb"
            style={{ backgroundImage: `url(${photo.thumbnail})` }}
          >
            {photo.description ? (
              <div className="gallery-item-info">
                <p
                  className="gallery-item-info__text"
                  id="gallery-item-info__text"
                >
                  {photo.description}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Item>
  )
}
