import PropTypes from 'prop-types'

import template from './template.rt'
import './styles.css'

function Tag(props) {
  return template.call(props)
}

export const TagPropTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
}

Tag.propTypes = {active: PropTypes.bool, ...TagPropTypes}

export default Tag
