/* global FACEBOOK_AUTH_TOKEN_BUSSINESS, FACEBOOK_BUSSINESS_ID */
import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
    USE_EXISTENT_ADDRESS
    USE_NEW_ADDRESS
    CHOOSE_ADDRESS
    VALIDATE_ADDRESS_SUCCESS
    VALIDATE_ADDRESS_FAILURE
    CHOOSE_DELIVERY_METHOD
    CHOOSE_NEW_ADDRESS
    CANCEL_CHOOSE_NEW_ADDRESS
    SELECT_SHIPPING_INFO
    SELECT_PAYMENT_METHOD
    SELECT_PAY_WITH_CREDIT_CARD
    SELECT_PAY_WITH_PAYPAL
    SELECT_PAY_WITH_EXISTENT_PAYPAL
    TOGGLE_BILL_TO_SHIPPING_ADDRESS
    USE_EXISTENT_PAYMENT_METHOD
    USE_NEW_PAYMENT_METHOD
    SETTLE_ORDER_SUCCESS
    SETTLE_ORDER_REQUEST_FAILURE
    CHOOSE_BILLING_ADDRESS
    CANCEL_CHOOSE_BILLING_ADDRESS
    CANCEL_SELECT_SHIPPING_INFO
    CLEAR_ORDER_SUCCESS
    CLEAR_SELECTED_PAYMENT
    USE_NEW_BILLING_ADDRESS
    USE_EXISTENT_BILLING_ADDRESS
    USE_NEWLY_SAVED_PAYMENT_METHOD
    ACTIVATE_SUBSCRIBING_MODE
    DEACTIVATE_SUBSCRIBING_MODE
    SUBSCRIBE_SUCCESS
    SUBSCRIBE_REQUEST_FAILURE
    HIDE_CHECKOUT
    SHOW_CHECKOUT
    ENABLE_CHECKOUT_BUTTON
    DISABLE_CHECKOUT_BUTTON
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  useExistentAddress: false,
  useExistentBillingAddress: false,
  selectedAddress: null,
  selectedDeliveryMethod: {},
  deliveryMethods: [],
  tax: 0,
  disableNewAddressForm: false,
  selectedShippingInfo: false,
  selectedPaymentMethod: null,
  payWithCreditCard: true,
  billToShippingAdddress: true,
  useExistentPaymentMethod: false,
  selectedBillingAddress: {},
  disableBillingAddressForm: false,
  shippingCost: '0',
  isSubscribing: false,
  planSelected: null,
  showCheckout: false,
  disableCheckoutButton: false
}

export const useNewAddressSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  useExistentAddress: false,
  selectedDeliveryMethod: {},
  deliveryMethods: []
})

export const useExistentAddressSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  useExistentAddress: true,
  selectedDeliveryMethod: {},
  deliveryMethods: []
})

export const useNewBillingAddressSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  useExistentBillingAddress: false
})

export const useExistentBillingAddressSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  useExistentBillingAddress: true
})
export const chooseAddressSuccessful = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedAddress: action.payload
})

export const validateAddressSuccessful = (state = INITIAL_STATE, action) => ({
  ...state,
  deliveryMethods: action.payload.deliveryMethods,
  tax: action.payload.tax
})

export const chooseDeliveryMethodSuccesful = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  selectedDeliveryMethod: {
    code: action.payload.code
  },
  shippingCost: action.payload.price
})
export const cancelChooseNewAddressSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  disableNewAddressForm: false,
  selectedDeliveryMethod: {},
  deliveryMethods: []
})
export const selectedShippingInfoSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  selectedShippingInfo: true
})

export const selectPaymentMethodSuccessful = (
  state = INITIAL_STATE,
  action
) => ({ ...state, selectedPaymentMethod: action.payload })

export const selectPayWithCreditCardSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  payWithCreditCard: true,
  selectedPaymentMethod: null
})

export const selectPayWithPaypalSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  payWithCreditCard: false,
  selectedPaymentMethod: 'paypal'
})

export const selectPayWithExistentPaypalSuccessful = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  payWithCreditCard: false,
  selectedPaymentMethod: action.payload
})

export const toggleBillToShippingAdddressSuccessful = (
  state = INITIAL_STATE
) => ({
  ...state,
  billToShippingAdddress: !state.billToShippingAdddress
})
export const useNewPaymentMethodSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  useExistentPaymentMethod: false,
  selectedPaymentMethod: null,
  payWithCreditCard: true
})
export const useExistentPaymentMethodSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  useExistentPaymentMethod: true,
  selectedPaymentMethod: null,
  payWithCreditCard: true
})
export const chooseNewPaymentMethodSuccessful = (
  state = INITIAL_STATE,
  action
) => ({ ...state, selectedPaymentMethod: action.payload })

export const chooseBillingAddressSuccessful = (
  state = INITIAL_STATE,
  action
) => ({ ...state, selectedBillingAddress: action.payload })

export const chooseNewAddressSuccessful = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedAddress: action.payload,
  disableNewAddressForm: true
})

export const cancelSelectShippingInfoSuccessful = (state = INITIAL_STATE) => ({
  ...state,
  selectedShippingInfo: false
})
export const clearOrderSuccessful = state => ({ ...state, ...INITIAL_STATE })

export const clearSelectedPaymentSuccessful = state => ({
  ...state,
  selectedPaymentMethod: null
})

export const useNewlySavedPaymentMethodSuccessful = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  selectedPaymentMethod: action.payload,
  useExistentPaymentMethod: true,
  payWithCreditCard: true
})

export const subscribingModeActivatedSucessfully = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  isSubscribing: true,
  planSelected: action.payload
})

export const subscribingModeDeactivatedSucessfully = state => ({
  ...state,
  isSubscribing: false,
  planSelected: null
})

export const showCheckoutSuccessfully = state => ({
  ...state,
  showCheckout: true
})

export const hideCheckoutSuccessfully = state => ({
  ...state,
  showCheckout: false
})

export const enableCheckoutButtonSuccessfully = (state = INITIAL_STATE) => ({
  ...state,
  disableCheckoutButton: false
})

export const disableCheckoutButtonSuccessfully = (state = INITIAL_STATE) => ({
  ...state,
  disableCheckoutButton: true
})

export const HANDLERS = {
  [types.USE_NEW_ADDRESS]: useNewAddressSuccessful,
  [types.USE_EXISTENT_ADDRESS]: useExistentAddressSuccessful,
  [types.CHOOSE_ADDRESS]: chooseAddressSuccessful,
  [types.CHOOSE_DELIVERY_METHOD]: chooseDeliveryMethodSuccesful,
  [types.VALIDATE_ADDRESS_SUCCESS]: validateAddressSuccessful,
  [types.SELECT_SHIPPING_INFO]: selectedShippingInfoSuccessful,
  [types.SELECT_PAYMENT_METHOD]: selectPaymentMethodSuccessful,
  [types.SELECT_PAY_WITH_CREDIT_CARD]: selectPayWithCreditCardSuccessful,
  [types.SELECT_PAY_WITH_PAYPAL]: selectPayWithPaypalSuccessful,
  [types.SELECT_PAY_WITH_EXISTENT_PAYPAL]: selectPayWithExistentPaypalSuccessful,
  [types.TOGGLE_BILL_TO_SHIPPING_ADDRESS]: toggleBillToShippingAdddressSuccessful,
  [types.USE_EXISTENT_PAYMENT_METHOD]: useExistentPaymentMethodSuccessful,
  [types.USE_NEW_PAYMENT_METHOD]: useNewPaymentMethodSuccessful,
  [types.CHOOSE_BILLING_ADDRESS]: chooseBillingAddressSuccessful,
  [types.CHOOSE_NEW_ADDRESS]: chooseNewAddressSuccessful,
  [types.CANCEL_CHOOSE_NEW_ADDRESS]: cancelChooseNewAddressSuccessful,
  [types.CANCEL_SELECT_SHIPPING_INFO]: cancelSelectShippingInfoSuccessful,
  [types.CLEAR_ORDER_SUCCESS]: clearOrderSuccessful,
  [types.CLEAR_SELECTED_PAYMENT]: clearSelectedPaymentSuccessful,
  [types.USE_NEW_BILLING_ADDRESS]: useNewBillingAddressSuccessful,
  [types.USE_EXISTENT_BILLING_ADDRESS]: useExistentBillingAddressSuccessful,
  [types.USE_NEWLY_SAVED_PAYMENT_METHOD]: useNewlySavedPaymentMethodSuccessful,
  [types.ACTIVATE_SUBSCRIBING_MODE]: subscribingModeActivatedSucessfully,
  [types.DEACTIVATE_SUBSCRIBING_MODE]: subscribingModeDeactivatedSucessfully,
  [types.SHOW_CHECKOUT]: showCheckoutSuccessfully,
  [types.HIDE_CHECKOUT]: hideCheckoutSuccessfully,
  [types.ENABLE_CHECKOUT_BUTTON]: enableCheckoutButtonSuccessfully,
  [types.DISABLE_CHECKOUT_BUTTON]: disableCheckoutButtonSuccessfully
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const useNewAddress = createAction(types.USE_NEW_ADDRESS)
export const useExistentAddress = createAction(types.USE_EXISTENT_ADDRESS)
export const useNewBillingAddress = createAction(types.USE_NEW_BILLING_ADDRESS)
export const useExistentBillingAddress = createAction(
  types.USE_EXISTENT_BILLING_ADDRESS
)
export const chooseAddress = createAction(types.CHOOSE_ADDRESS)

export const validateAddressSuccess = createAction(
  types.VALIDATE_ADDRESS_SUCCESS
)
export const validateAddressFailure = createAction(
  types.VALIDATE_ADDRESS_FAILURE
)
export const validateAddress = fetchApi(
  '/addresses/validate/',
  { method: 'POST' },
  validateAddressSuccess,
  validateAddressFailure
)

export const saveDeliveryMethod = createAction(types.CHOOSE_DELIVERY_METHOD)
export const saveNewAddress = createAction(types.CHOOSE_NEW_ADDRESS)
export const cancelNewAddress = createAction(types.CANCEL_CHOOSE_NEW_ADDRESS)
export const completeShippingInfo = createAction(types.SELECT_SHIPPING_INFO)
export const selectPaymentMethod = createAction(types.SELECT_PAYMENT_METHOD)

export const selectPayWithCreditCard = createAction(
  types.SELECT_PAY_WITH_CREDIT_CARD
)
export const selectPayWithPaypal = createAction(types.SELECT_PAY_WITH_PAYPAL)
export const selectPayWithExistentPaypal = createAction(
  types.SELECT_PAY_WITH_EXISTENT_PAYPAL
)

export const toggleBillToShippingAddressAction = createAction(
  types.TOGGLE_BILL_TO_SHIPPING_ADDRESS
)

export const useNewPaymentMethod = createAction(types.USE_NEW_PAYMENT_METHOD)

export const useExistentPaymentMethod = createAction(
  types.USE_EXISTENT_PAYMENT_METHOD
)

export const settleOrderSuccess = createAction(types.SETTLE_ORDER_SUCCESS)
export const settleOrderFailure = createAction(
  types.SETTLE_ORDER_REQUEST_FAILURE
)
export const settleOrder = () =>
  fetchApi(
    '/orders/',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    settleOrderSuccess,
    settleOrderFailure
  )

export const saveBillingAddress = createAction(types.CHOOSE_BILLING_ADDRESS)

export const initializeShippingInfoAction = createAction(
  types.CANCEL_SELECT_SHIPPING_INFO
)

export const clearOrderAction = createAction(types.CLEAR_ORDER_SUCCESS)

export const clearSelectedPayment = createAction(types.CLEAR_SELECTED_PAYMENT)

export const useNewlySavedPaymentMethod = createAction(
  types.USE_NEWLY_SAVED_PAYMENT_METHOD
)

export const activateSubscribingMode = createAction(
  types.ACTIVATE_SUBSCRIBING_MODE
)

export const deactivateSubscribingMode = createAction(
  types.DEACTIVATE_SUBSCRIBING_MODE
)

export const subscribeSuccess = createAction(types.SUBSCRIBE_SUCCESS)
export const subscribeFailure = createAction(types.SUBSCRIBE_REQUEST_FAILURE)
export const subscribe = () =>
  fetchApi(
    '/subscriptions/',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    subscribeSuccess,
    subscribeFailure
  )

export const removeCheckoutCloak = createAction(types.SHOW_CHECKOUT)

export const cloakCheckout = createAction(types.HIDE_CHECKOUT)

export const disableCheckoutButton = createAction(types.DISABLE_CHECKOUT_BUTTON)

export const enableCheckoutButton = createAction(types.ENABLE_CHECKOUT_BUTTON)
