import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { Field } from 'redux-form';
import RenderField from '../../../generic/RenderedField';
import { validators } from '../../../../redux/modules/utils/forms';
export default function () {
    function onKeyUp1(event) {
        keyUpHandler(event, this.props.toggleNewsletter);
    }
    return React.createElement('section', {
        'id': 'LeadMagnetFunnelForm',
        'className': this.props.isPopUp ? 'popup' : ''
    }, React.createElement('div', { 'className': 'internal-container wrapper' }, React.createElement('div', { 'className': 'copy' }, React.createElement('h2', { 'className': 'title' }, '\n        Get FREE Popular Course Content!\n      '), React.createElement('div', { 'className': 'subtitle' }, React.createElement('p', { 'className': 'tagline' }, '\n          Enter your email for FREE access to content from 16 of our most popular courses.\n        '))), React.createElement('div', { 'className': 'form' }, React.createElement('form', { 'onSubmit': this.props.handleSubmit }, React.createElement(Field, {
        'name': 'email',
        'validate': validators.email,
        'component': RenderField,
        'type': 'email',
        'placeholder': 'Email',
        'props': { ariaLabel: 'Email' },
        'key': `${ this.props.tag }-email-field`
    }), React.createElement('div', {
        'className': 'newsletter',
        'style': { marginBottom: this.props.formValues.newsletter ? '28px' : 0 }
    }, React.createElement('div', { 'className': 'checkbox-area' }, React.createElement('div', { 'className': 'checkbox-confirm' }, React.createElement('input', {
        'type': 'checkbox',
        'name': 'newsletter',
        'checked': this.props.formValues.newsletter,
        'value': this.props.formValues.newsletter,
        'id': 'newsletter-checkbox',
        'onChange': () => this.props.toggleNewsletter(),
        'onKeyUp': onKeyUp1.bind(this)
    }), React.createElement('label', { 'htmlFor': 'newsletter-checkbox' }, 'I agree to receive email updates')))), !this.props.formValues.newsletter ? React.createElement('div', {
        'className': 'warning',
        'key': '1769'
    }, React.createElement('p', { 'className': 'danger' }, 'You need to agree to receive updates')) : null, React.createElement('button', {
        'type': 'submit',
        'className': 'btn -wide -blue-2',
        'disabled': this.props.pristine || this.props.formValues.isCTADisabled || !this.props.formValues.newsletter,
        'aria-label': 'Enter your email and click submit to receive your free content'
    }, '\n        Get Free Access\n        ')))));
}