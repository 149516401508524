import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { onLeadMagnetFunnelFormDispatch as dispatchSelector } from 'reduxModules/leadMagnetPages/dispatchers'
import { leadMagnetPageStateSelector as stateSelector } from 'reduxModules/leadMagnetPages/selectors'

import template from './template.rt'
import './styles.css'

export class LeadMagnetFunnelForm extends Component {
  render() {
    return template.call(this)
  }
}

LeadMagnetFunnelForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
  isPopUp: PropTypes.bool
}

LeadMagnetFunnelForm.defaultProps = {
  isPopUp: false
}

export const LeadMagnetFunnelFormWrapper = reduxForm({
  form: 'lead-magnet-funnel'
})(LeadMagnetFunnelForm)
export default connect(
  stateSelector,
  dispatchSelector
)(LeadMagnetFunnelFormWrapper)
