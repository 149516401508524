import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import Tutorial from '../../generic/CourseSummary';
export default function () {
    function repeatTutorial1(tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'thumb': tutorial.photo.boxThumb,
            'title': tutorial.title,
            'new': tutorial.new
        });
    }
    return React.createElement('section', { 'id': 'course-related' }, React.createElement('div', { 'className': 'title' }, React.createElement('h3', { 'className': 'title__text' }, 'Related Courses')), React.createElement.apply(this, [
        'div',
        { 'className': 'courses-list' },
        _.map(this.props.courses, repeatTutorial1.bind(this))
    ]), React.createElement(Link, {
        'className': 'see-more',
        'to': {
            pathname: '/search',
            query: { text: this.props.tags.join(',') }
        }
    }, React.createElement('i', { 'className': 'icon-plus' }), ' see more courses'));
}