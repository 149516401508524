import { setUserFavorite, unsetUserFavorite, types } from '../../users'
import notifications, {
  toastError,
  toastSuccess
} from '../../../../global/notifications'

export default dispatch => ({
  setFavorite(objectUrl) {
    dispatch(setUserFavorite({ favorited_object: objectUrl })).then(
      ([_, res]) => {
        if (res.type === types.USERS_FAVORITE_SET_SUCCESS)
          toastSuccess(notifications.favoriteAdded)
        else toastError(notifications.favoriteAdded)
      }
    )
  },
  unsetFavorite(pk) {
    dispatch(unsetUserFavorite(pk)()).then(([_, res]) => {
      if (res.type === types.USERS_FAVORITE_UNSET_SUCCESS)
        toastSuccess(notifications.favoriteRemoved)
      else toastError(notifications.favoriteRemoved)
    })
  }
})
