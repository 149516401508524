import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

function DeliveryMethods(props) {
  return template.call({ props })
}

export const deliveryMethodsPropTypes = {
  code: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  warning: PropTypes.string
}

DeliveryMethods.propTypes = {
  methods: PropTypes.arrayOf(PropTypes.shape(deliveryMethodsPropTypes))
}

export default DeliveryMethods
