import _ from 'lodash'
import { cookie } from 'redux-effects-cookie'

export const UTM_KEYS = [
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_content',
  'utm_term',
  'utm_ad_id'
]

export const UTM_TRIMMING_MAP = {
  source: 100,
  medium: 255,
  campaign: 100,
  term: 50,
  content: 50
}

/*
 * Creates object { <cookieName>: <promise to fetch cookie> }
 */
export const getCookiePromises = (cookieNames, dispatch) => {
  const cookiePromises = {}
  cookieNames.forEach(cookieName => {
    cookiePromises[cookieName] = dispatch(cookie(cookieName))
  })
  return cookiePromises
}

export const getUTMDataFromCookies = async dispatch => {
  const cookies = getCookiePromises(UTM_KEYS, dispatch)
  return _.omitBy(
    _.zipObject(_.keys(cookies), await Promise.all(_.values(cookies))),
    prop => prop === undefined
  )
}

export const removeUTMPrefixFromKeysIn = obj => {
  const result = {}
  _.forEach(obj, (value, key) => {
    const formattedValue = key.replace('utm_', '')
    result[formattedValue] = String(value).substring(
      0,
      UTM_TRIMMING_MAP[formattedValue]
    )
  })
  return result
}

export const getAdDataObjectFrom = rawUTMData => !_.isEmpty(rawUTMData)
    ? { utm: removeUTMPrefixFromKeysIn(rawUTMData) }
    : {}
