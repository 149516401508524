import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { onAuthDispatch as dispatchSelector } from 'reduxModules/auth/dispatchers'
import template from './template.rt'
import '../styles.css'
import './styles.css'

export class SignUp extends Component {
  constructor(props) {
    super(props)
    props.hideGlobalNav()
  }

  componentWillUnmount() {
    this.props.showGlobalNav()
  }

  render() {
    return template.call(this)
  }
}

SignUp.propTypes = {
  showGlobalNav: PropTypes.func.isRequired,
  hideGlobalNav: PropTypes.func.isRequired
}

export default connect(
  null,
  dispatchSelector
)(SignUp)
