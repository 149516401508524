import React from 'react'

import './styles.css'

export default () => (
  <div className="LoadingComponent">
    <div className="spinner">
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  </div>
)
