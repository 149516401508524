import PropTypes from 'prop-types'
import { Component } from 'react'

import template from './template.rt'
import './styles.css'

class Tooltip extends Component {
  render() {
    return template.call(this)
  }
}

Tooltip.propTypes = {
  message: PropTypes.string.isRequired
}

export default Tooltip
