import PropTypes from 'prop-types'
import template from './template.rt'

function ChevronLeftIconSmall(props) {
  return template.call({ props })
}

ChevronLeftIconSmall.propTypes = {
  className: PropTypes.string
}

export default ChevronLeftIconSmall
