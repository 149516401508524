import * as React from 'react';
import * as _ from 'lodash';
import Rodal from 'rodal';
import CancelSubscription from '../../Account/CancelSubscription';
import ChangeSubscriptionConfirmation from '../../Account/ChangeSubscription/ChangeSubscriptionConfirmation';
import SelectSubscriptionType from '../../Account/SelectSubscriptionType';
export default function () {
    function onKeyUp1(props, event) {
        keyUpHandler(event, this.handleClose);
    }
    function onKeyUp2(props, option, optionIndex, event) {
        keyUpHandler(event, this.resolveFeedback, null, null, [option.resolveValue]);
    }
    function repeatOption3(props, option, optionIndex) {
        return React.createElement('button', {
            'className': 'ctaBtn ' + option.class,
            'disabled': this.state.shouldBeDisabled,
            'key': optionIndex,
            'onClick': this.resolveFeedback(option.resolveValue),
            'onKeyUp': onKeyUp2.bind(this, props, option, optionIndex),
            'tabIndex': '0'
        }, '\n      ', option.text || 'Accept', '\n    ');
    }
    function scopeProps4() {
        var props = this.props;
        return React.createElement(Rodal, {
            'className': 'confirmation' + ' ' + _.transform({ [props.componentId]: true }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'visible': true,
            'onClose': this.handleClose,
            'height': props.height || 250,
            'animation': 'fade',
            'customStyles': { width: props.isMobile ? '80%' : '527px' }
        }, React.createElement('div', { 'className': 'header' }, React.createElement('h2', {}, props.title)), !props.feedback && props.componentId !== 'upgrade' && props.componentId !== 'cancellation' && props.componentId !== 'selection' ? React.createElement('div', {
            'className': 'body',
            'dangerouslySetInnerHTML': { __html: props.text },
            'key': '721'
        }) : null, props.componentId === 'cancellation' ? React.createElement(CancelSubscription, _.assign({}, {
            'ref': c => {
                this.renderedChild = c;
            },
            'changeSubmitButtonStatus': this.changeSubmitButtonStatus,
            'key': '949'
        }, this.props)) : null, props.componentId === 'selection' ? React.createElement(SelectSubscriptionType, {
            'ref': c => {
                this.renderedChild = c;
            },
            'onClose': this.handleClose,
            'key': '1160'
        }) : null, props.componentId === 'upgrade' ? React.createElement('div', {
            'className': 'body',
            'key': '1316'
        }, React.createElement(ChangeSubscriptionConfirmation, _.assign({}, {
            'ref': c => {
                this.renderedChild = c;
            }
        }, this.props))) : null, props.showButtons ? React.createElement.apply(this, [
            'div',
            {
                'className': 'buttons' + ' ' + _.transform({ 'with-feedback': !!props.feedback }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' '),
                'key': '1507'
            },
            props.showReject ? React.createElement('button', {
                'className': 'cancel-btn',
                'onClick': this.handleClose,
                'onKeyUp': onKeyUp1.bind(this, props),
                'tabIndex': '0',
                'key': '1612'
            }, '\n      ', props.rejectText || 'Cancel', '\n    ') : null,
            _.map(props.options, repeatOption3.bind(this, props))
        ]) : null);
    }
    return scopeProps4.apply(this, []);
}