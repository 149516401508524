import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { giftSubscriptionDispatchers as mergeSelector } from 'reduxModules/subscriptions/dispatchers'
import { authInfoReselector as stateSelector } from 'reduxModules/auth/selectors'

import template from './template.rt'
import subscriptionPlans from '../../SubscriptionPlans/fixturePlans'
import './styles.css'

export class GiftSubscription extends Component {
    constructor(props) {
        super(props)
        this.subscriptionType = this.subscriptionType.bind(this)
    }

    subscriptionType() {
        const { subscription } = this.props
        const planIds = subscriptionPlans.map((subscription) => subscription.id)
        if (planIds.includes(subscription.planId))
            return subscription.planId === 4 ? 'Year' : 'Month'
        return 'subscription'
    }

    render() {
        return template.call(this)
    }
}

GiftSubscription.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    subscription: PropTypes.shape({
        free: PropTypes.bool,
        rank: PropTypes.number,
        plan: PropTypes.string
    })
}
GiftSubscription.defaultProps = {
    subscription: null
}

export const GiftSubscriptionWrapper = reduxForm({
    form: 'gift-certificate'
})(GiftSubscription)

export default connect(
    stateSelector,
    null,
    mergeSelector
)(GiftSubscriptionWrapper)
