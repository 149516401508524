/* global SENTRY_DSN */
import { EventEmitter } from 'events'
import { SubmissionError } from 'redux-form'
import RavenMiddleware from 'redux-raven-persist-middleware'
import { invalidAuth } from 'reduxModules/auth/'
import { types as confirmationTypes } from 'reduxModules/confirmation'
import { push } from 'react-router-redux'
import { normalizeErrors } from './modules/utils/forms'
import { toastServerError } from '../global/notifications'

export const customRavenMiddleware = store => next => action => {
  // Prevent from catching forms errors
  if (action.payload && action.payload.status === 400) return next(action)
  return RavenMiddleware(SENTRY_DSN)(store)(next)(action)
}

export const fetchFailureMiddleware = () => next => action => {
  if (action.payload && action.payload.status === 400) {
    const response = action.payload.value
    const errors = normalizeErrors(response)
    next({ ...action, payload: response })
    throw new SubmissionError(errors)
  }
  return next(action)
}

export const authFailureMiddleware = store => next => action => {
  if (
    (action.payload && action.payload.status === 403) ||
    (action.payload &&
      action.payload.status === 401 &&
      action.payload.value &&
      (action.payload.value.detail === 'Signature has expired.' ||
        action.payload.value.detail === 'Error decoding signature.' ||
        action.payload.value.detail ===
          'A session is already active in another device, please logout and try again.'))
  ) {
    store.dispatch(invalidAuth())
    store.dispatch(
      push({
        pathname: '/',
        state: { next: location.pathname }
      })
    )
    toastServerError(action.payload.value.detail)
  }
  return next(action)
}

export const multiEffectsMiddleware = ({ dispatch }) => next => action => {
  if (Array.isArray(action))
    return Promise.all(action.filter(Boolean).map(dispatch))
  return next(action)
}

let pending

// eslint-disable-next-line no-unused-vars
export const confirmationMiddleware = store => next => action => {
  switch (action.type) {
    case confirmationTypes.INIT_CONFIRMATION:
      next(action)
      pending = new EventEmitter()
      return pending

    case confirmationTypes.RESOLVE_CONFIRMATION:
      pending.emit(action.payload.resolution, action.payload.feedback)
      pending = null

    // eslint-disable-line no-fallthrough
    default:
      return next(action)
  }
}

export default {
  authFailureMiddleware,
  fetchFailureMiddleware,
  multiEffectsMiddleware,
  confirmationMiddleware
}
