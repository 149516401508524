import { createSelector } from 'reselect'

// selectors
const gallerySelector = state => state.gallery
const assetsGallery = state => state.assets.list
const next = state => state.assets.next

const assetGallerySelector = createSelector(assetsGallery, assets =>
  assets.map(a => ({
    src: a.asset.xlarge,
    title: a.title,
    description: a.caption,
    caption: `${a.title}\n${a.caption}`,
    gallerySize: a.asset.gallerySquare,
    thumbnail: a.asset.large
  }))
)

export default createSelector(
  [gallerySelector, assetGallerySelector, next],
  (gallery, assets, next) => ({
    ...gallery,
    assets,
    next
  })
)
