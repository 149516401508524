import { createSelector } from 'reselect'
import {
  hasSubscriptionSelector,
  isMobileSelector,
  dimensionsSelector,
  usedFreeTrialSelector
} from 'reduxModules/app/selectors'
import { bannersStatus } from 'reduxModules/banners'
import { learningPathRecentsSelector } from 'reduxModules/learningPaths/selectors'

export const blogPostsSelector = state => state.blogPosts.list
export const tutorialsSelector = state => state.tutorials.list.slice(0, 4)
export const latestTutorialsSelector = state => state.tutorials.latest
export const fbImagesSelector = state => state.assets.fbImages
export const homeSelector = state => state.home
export const bannersSelector = state => state.banners.list
export const bannerStatusSelector = state =>
  state.banners.statusMain !== bannersStatus.initial
export const secondaryBannersSelector = state => state.banners.secondaryList
export const freeTrialBannerSelector = state => state.banners.freeTrialImage

export const homeStateSelector = createSelector(
  [
    fbImagesSelector,
    blogPostsSelector,
    tutorialsSelector,
    latestTutorialsSelector,
    hasSubscriptionSelector,
    usedFreeTrialSelector,
    isMobileSelector,
    dimensionsSelector,
    homeSelector,
    bannersSelector,
    bannerStatusSelector,
    secondaryBannersSelector,
    learningPathRecentsSelector,
    freeTrialBannerSelector
  ],
  (
    fbImages,
    blogPosts,
    tutorials,
    latestTutorials,
    hasSubscription,
    usedFreeTrial,
    isMobile,
    dimensions,
    homeData,
    banners,
    bannerStatus,
    secondaryBanners,
    recentsPaths,
    freeTrialBanner
  ) => ({
    fbImages,
    blogPosts,
    tutorials,
    latestTutorials,
    hasSubscription,
    usedFreeTrial,
    isMobile,
    ...dimensions,
    ...homeData,
    banners,
    bannerStatus,
    secondaryBanners,
    recentsPaths,
    freeTrialBanner
  })
)
