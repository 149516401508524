import PropTypes from 'prop-types'

import template from './template.rt'
import './styles.css'

function TeamMember(props) {
  return template.call({ props })
}

TeamMember.propTypes = {
  params: PropTypes.shape({
    memberSlug: PropTypes.string.isRequired
  }).isRequired
}

export default TeamMember
