/* eslint-disable import/prefer-default-export */
import { toggleMobileMenu } from 'reduxModules/app'
import { logoutFlow } from 'reduxModules/navBar/dispatchers'
import { showMenuItem, hideMenuItem } from './'

export const dispatchShowMenuItem = (dispatch, title) =>
  dispatch(showMenuItem({ title }))

export const dispatchHideMenuItem = (dispatch, title) =>
  dispatch(hideMenuItem({ title }))

export default (dispatch, props) => ({
  showMenuItem: () => dispatchShowMenuItem(dispatch, props.title),
  hideMenuItem: () => dispatchHideMenuItem(dispatch, props.title)
})

export const mobileMenuDispatchers = (dispatch, props) => ({
  showMenuItem: title => dispatchShowMenuItem(dispatch, title),
  hideMenuItem: title => dispatchHideMenuItem(dispatch, title),
  toggleMobileMenu() {
    dispatch(toggleMobileMenu())
  },
  logout() {
    logoutFlow(dispatch, props)
    dispatch(toggleMobileMenu())
  }
})
