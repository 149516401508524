import * as React from 'react';
import * as _ from 'lodash';
import { Field } from 'redux-form';
import { validators } from '../../../redux/modules/utils/forms';
import RenderedField from '../../generic/RenderedField';
export default function () {
    return React.createElement('div', {
        'id': 'GiftSubscription',
        'className': 'account-section'
    }, React.createElement('h3', { 'className': 'account-section__title' }, 'Gift a Subscription'), React.createElement('div', { 'className': 'gift-subscription-wrapper' }, React.createElement('div', { 'className': 'gift-subscription-box' }, React.createElement('div', { 'className': 'subheading' }, '\n        Congrats! You are able to gift (1) ', this.subscriptionType(), ' of the ', this.props.subscription.plan, ' subscription\n      '), React.createElement('form', {
        'id': 'GiftSubscriptionForm',
        'onSubmit': this.props.handleSubmit
    }, React.createElement(Field, {
        'name': 'gifterName',
        'validate': validators.required,
        'component': RenderedField,
        'type': 'text',
        'placeholder': 'Your Name'
    }), React.createElement('div', { 'className': 'send-text' }, 'Send subscription to: '), React.createElement('div', { 'className': 'input-names' }, React.createElement(Field, {
        'name': 'firstName',
        'validate': validators.required,
        'component': RenderedField,
        'type': 'text',
        'placeholder': 'First Name'
    }), React.createElement(Field, {
        'name': 'lastName',
        'validate': validators.required,
        'component': RenderedField,
        'type': 'text',
        'placeholder': 'Last Name'
    })), React.createElement(Field, {
        'name': 'email',
        'validate': [
            validators.required,
            validators.email
        ],
        'component': RenderedField,
        'type': 'email',
        'placeholder': 'Email'
    }), React.createElement(Field, {
        'name': 'message',
        'component': 'textarea',
        'type': 'text',
        'placeholder': 'Message'
    }), React.createElement('button', {
        'type': 'submit',
        'className': 'btn -wide submit-btn'
    }, '\n          Send Gift\n        ')))));
}