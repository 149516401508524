import * as React from 'react';
import * as _ from 'lodash';
import Scroll from 'react-scroll';
export default function () {
    function scopeScrollOffset1(props) {
        var scrollOffset = (this.props.amount - 1) * -102;
        return React.createElement('div', { 'className': 'edit' }, React.createElement(Scroll.Link, {
            'to': 'modifyAddress',
            'spy': true,
            'smooth': true,
            'duration': 500,
            'offset': scrollOffset
        }, React.createElement('button', { 'onClick': () => this.props.edit() }, 'edit')));
    }
    function scopeProps2() {
        var props = this.props;
        return React.createElement('div', { 'className': 'address' }, React.createElement('div', { 'className': 'address-body' }, React.createElement('div', { 'className': 'name-header' }, React.createElement('div', { 'className': 'name' }, this.props.firstName, ' ', this.props.lastName)), React.createElement('div', { 'className': 'address-description' }, React.createElement('span', {}, props.streetAddress, ', ', `${ props.extendedAddress ? `${ props.extendedAddress },` : '' } ${ props.locality }, ${ props.region } ${ props.postalCode }, ${ props.countryName }.`))), React.createElement('div', { 'className': 'cta' }, scopeScrollOffset1.apply(this, [props]), React.createElement('div', { 'className': 'remove' }, React.createElement('div', { 'className': 'remove-btn-container' }, React.createElement('div', {
            'onClick': () => this.props.remove(),
            'className': 'cross'
        }, React.createElement('i', { 'className': 'icon-x' }))))));
    }
    return scopeProps2.apply(this, []);
}