import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/cart/selectors'
import { cartMergeProps as mergeProps } from 'reduxModules/cart/dispatchers'
import _ from 'lodash'
import template from './template.rt'
import './styles.css'

export class Cart extends Component {
  componentDidMount() {
    const { location, loginFromEmail, isLoggedIn } = this.props

    if (_.has(location, 'query.token'))
      loginFromEmail(location.query, isLoggedIn)

    this.props.loadSubscriptionPlans()
    this.props.loadRelatedItems()
  }

  render() {
    return template.call(this)
  }
}

export const cartPropTypes = {
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  count: PropTypes.number.isRequired,
  loading: PropTypes.number.isRequired,
  subtotal: PropTypes.string.isRequired
}

Cart.propTypes = {
  ...cartPropTypes,
  loadCartItems: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
}

Cart.defaultProps = {
  isMobile: false
}

export default connect(
  stateSelector,
  null,
  mergeProps
)(Cart)
