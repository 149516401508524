import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  SHOW_NOTIFICATION
  CLEAR_NOTIFICATION
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  globalNotification: null
}
export const addNotification = (state = INITIAL_STATE, action) => ({
  ...state,
  globalNotification: action.payload
})

export const removeNotificatons = (state = INITIAL_STATE) => ({
  ...state,
  globalNotification: null
})

export const HANDLERS = {
  [types.SHOW_NOTIFICATION]: addNotification,
  [types.CLEAR_NOTIFICATION]: removeNotificatons
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const showNotification = createAction(types.SHOW_NOTIFICATION)
export const clearNotification = createAction(types.CLEAR_NOTIFICATION)
