import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { userPaymentMethodsReselector as stateSelector } from 'reduxModules/users/selectors'
import { paymentMethodListDispatchers as dispatchSelector } from 'reduxModules/users/dispatchers'
import template from './template.rt'
import './styles.css'
import { PaymentMethodStructure } from './PaymentMethod'

export class PaymentMethodList extends Component {
  componentDidMount() {
    this.props.loadPaymentMethods()
  }

  render() {
    return template.call(this)
  }
}

PaymentMethodList.propTypes = {
  loadPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape(PaymentMethodStructure)),
  removePaymentMethodDispatcher: PropTypes.func.isRequired,
  markPaymentDefault: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
}

PaymentMethodList.defaultProps = {
  paymentMethods: [],
  isMobile: false
}

export default connect(
  stateSelector,
  dispatchSelector
)(PaymentMethodList)
