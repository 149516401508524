import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('div', { 'className': 'numeric-input' }, React.createElement('button', {
            'className': 'minus',
            'onClick': () => !props.disabled ? props.value > 1 ? props.update(props.id, props.value - 1) : props.remove(props.id) : null
        }, '-'), React.createElement('div', { 'className': 'number' }, React.createElement('input', {
            'aria-label': 'Quantity',
            'type': 'number',
            'name': 'quantity',
            'value': props.value,
            'disabled': true
        })), React.createElement('button', {
            'className': 'plus' + ' ' + _.transform({ disabled: props.value > 98 }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'onClick': () => props.value < 99 && !props.disabled ? props.update(props.id, props.value + 1) : null
        }, '+'));
    }
    return scopeProps1.apply(this, []);
}