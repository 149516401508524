import React, { Component } from 'react'
import RenderedField from '../RenderedField'

export class PasswordField extends Component {
  constructor(props) {
    super(props)
    this.state = { unmasked: false }
    this.toggleMask = this.toggleMask.bind(this)
  }

  toggleMask() {
    this.setState({ unmasked: !this.state.unmasked })
  }

  render() {
    const type = this.state.unmasked ? 'text' : 'password'
    return (
      <RenderedField
        {...this.props}
        type={type}
        icon={
          this.state.unmasked
            ? 'icon-passcodewatch'
            : 'icon-passcodewatch disabled'
        }
        iconAction={this.toggleMask}
      />
    )
  }
}

export default PasswordField
