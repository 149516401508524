import PropTypes from 'prop-types'
import {
  CurrentlyWatchingTutorialSummaryPropTypes
} from 'components/generic/CourseSummary/propTypes'
import template from './template.rt'
import './styles.css'

function CurrentlyWatching(props) {
  return template.call({ props })
}

CurrentlyWatching.propTypes = {
  courses: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        ...CurrentlyWatchingTutorialSummaryPropTypes,
        confirmWatchTutorial: PropTypes.bool
      })
    ),
    count: PropTypes.number,
    next: PropTypes.string,
    previous: PropTypes.string
  })
}

CurrentlyWatching.defaultProps = {
  courses: []
}

export default CurrentlyWatching
