import _ from 'lodash'
import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { getSecondsFromTimestamp } from 'components/NewVideoPlayer/utils'

import { types as tutorialTypes } from '../tutorials'

import { getProgressPercentage } from './utils'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
    SET_CURRENT_CHAPTER
    SET_CHAPTER_PLAYED
    TOGGLE_ADD_BOOKMARK
    HIDE_ADD_BOOKMARK
    SHOW_ADD_BOOKMARK
    LOAD_BOOKMARK
    REMOVE_LOADED_BOOKMARK,
    TOGGLE_RELEVANTS
    SET_PLAYLIST
    CLEAR_PLAYER
    TOGGLE_DISPLAY_CHAPTERS_SIDEBAR
    SAVE_PLAYER_ID
    TOGGLE_RELEVANT_CHAPTERS
    UPDATE_USER_PROGRESS
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  shouldOnlyShowRelevants: window.location.href.search('[?&]path=') !== -1,
  hideChaptersSidebar: false,
  playerId: null,
  chapterProgressById: {}
}
export const INITIAL_VIDEO_STATE = {
  currentChapterIndex: 0,
  played: [],
  showCreateBookmark: false,
  bookmarkToLoad: null
}

export const setChapter = (
  state = INITIAL_STATE,
  { payload: { videoId, currentChapterIndex, currentChapterId } }
) => {
  const videoState = videoId in state ? state[videoId] : INITIAL_VIDEO_STATE
  const played = new Set([...videoState.played, currentChapterIndex])
  return {
    ...state,
    [videoId]: {
      ...videoState,
      currentChapterIndex,
      currentChapterId,
      played: [...played]
    }
  }
}

export const hideAddBookmarkSuccess = (state = INITIAL_STATE) => ({
  ...state,
  showCreateBookmark: false
})

export const showAddBookmarkSuccess = (state = INITIAL_STATE) => ({
  ...state,
  showCreateBookmark: true
})

export const loadBookmarkSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  bookmarkToLoad: action.payload
})

export const removeLoadedBookmarkSuccess = (state = INITIAL_STATE) => ({
  ...state,
  bookmarkToLoad: null
})

export const changeRelevants = (state = INITIAL_STATE) => ({
  ...state,
  showRelevants: !state.showRelevants
})

export const changePlaylist = (state = INITIAL_STATE, action) => ({
  ...state,
  playlist: [...action.payload]
})

export const savePlayerIdSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  playerId: action.payload
})

export const toggleChaptersSidebar = (state = INITIAL_STATE) => ({
  ...state,
  hideChaptersSidebar: !state.hideChaptersSidebar
})

export const toggleShowingOnlyRelevantChapters = (state = INITIAL_STATE) => ({
  ...state,
  shouldOnlyShowRelevants: !state.shouldOnlyShowRelevants
})

export const clearPlayer = () => INITIAL_STATE

export const generateUserProgressMap = (state = INITIAL_STATE, action) => {
  const chapters = action?.payload?.chapters
  const chapterProgressById = chapters?.reduce((acc, cur) => {
    const duration = getSecondsFromTimestamp({
      durationH: cur.durationH,
      durationM: cur.durationM,
      durationS: cur.durationS
    })
    return {
      ...acc,
      [cur.id]: {
        currentPosition: cur.currentPosition,
        completed: cur.completed,
        duration,
        progress: getProgressPercentage(cur.currentPosition, duration)
      }
    }
  }, {})
  return {
    ...state,
    chapterProgressById
  }
}

export const updateUserProgressSuccess = (state = INITIAL_STATE, action) => {
  const { id, currentPosition, completed, duration } = action.payload

  return {
    ...state,
    chapterProgressById: {
      ...state.chapterProgressById,
      [id]: {
        ...state?.chapterProgressById[id],
        ...(completed && { completed }),
        ...(duration && { duration }),
        ...(currentPosition && {
          currentPosition,
          progress: getProgressPercentage(
            currentPosition,
            state?.chapterProgressById[id]?.duration
          )
        })
      }
    }
  }
}

export const HANDLERS = {
  [types.SET_CURRENT_CHAPTER]: setChapter,
  [types.HIDE_ADD_BOOKMARK]: hideAddBookmarkSuccess,
  [types.SHOW_ADD_BOOKMARK]: showAddBookmarkSuccess,
  [types.LOAD_BOOKMARK]: loadBookmarkSuccess,
  [types.REMOVE_LOADED_BOOKMARK]: removeLoadedBookmarkSuccess,
  [types.TOGGLE_RELEVANTS]: changeRelevants,
  [types.CLEAR_PLAYER]: clearPlayer,
  [types.TOGGLE_DISPLAY_CHAPTERS_SIDEBAR]: toggleChaptersSidebar,
  [types.SAVE_PLAYER_ID]: savePlayerIdSuccess,
  [types.TOGGLE_RELEVANT_CHAPTERS]: toggleShowingOnlyRelevantChapters,
  [tutorialTypes.TUTORIAL_USER_DATA_FETCH_SUCCESS]: generateUserProgressMap,
  [types.UPDATE_USER_PROGRESS]: updateUserProgressSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const setCurrentChapter = createAction(types.SET_CURRENT_CHAPTER)
export const hideAddBookmark = createAction(types.HIDE_ADD_BOOKMARK)
export const showAddBookmark = createAction(types.SHOW_ADD_BOOKMARK)
export const toggleRelevants = createAction(types.TOGGLE_RELEVANTS)
export const loadBookmark = createAction(types.LOAD_BOOKMARK)
export const removeLoadedBookmark = createAction(types.REMOVE_LOADED_BOOKMARK)
export const cleanPlayer = createAction(types.CLEAR_PLAYER)
export const toggleChapters = createAction(
  types.TOGGLE_DISPLAY_CHAPTERS_SIDEBAR
)
export const toggleRelevantChapters = createAction(
  types.TOGGLE_RELEVANT_CHAPTERS
)
export const savePlayerId = createAction(types.SAVE_PLAYER_ID)

export const updateUserProgress = createAction(types.UPDATE_USER_PROGRESS)
