import { push } from 'react-router-redux'
import { initConfirmation } from 'reduxModules/confirmation'

export default dispatch => ({
    dispatchSubscriptionPage: promotion => {
      const pathname = '/subscriptions/plans'
      const location = { pathname }

      // If there is an active promotion, customize the URL to apply promo code on subscription page
      if (
        promotion &&
        promotion.couponPromoCode &&
        promotion.couponPromoCode.codename
      )
        location.search = `?codename=${promotion.couponPromoCode.codename}`

      dispatch(push(location))
    },
    dispatchSelectSubscriptionType: () => {
      const confirmation = {
        title: 'Select subscription type',
        options: [{ resolveValue: '', text: '', class: '' }],
        height: 650,
        componentId: 'selection',
        showButtons: false
      }
      dispatch(initConfirmation(confirmation))
    }
  })
