import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { thankYouDispatchers } from 'reduxModules/checkout/dispatchers'
import { contestStateSelector } from 'reduxModules/contests/selectors'
import _ from 'lodash'
import { Link } from 'react-router'
import { categories } from '../Tutorials/TutorialList/filters'

import './styles.css'

export function ThankYou(props) {
  const {
    location: { pathname }
  } = props
  const [step, setStep] = useState(0)
  const [selectedInterests, setSelectedInterests] = useState([])

  const handleCheckboxChange = event => {
    const { value, checked } = event.target
    if (checked) setSelectedInterests([...selectedInterests, value])
    else
      setSelectedInterests(
        selectedInterests.filter(interest => interest !== value)
      )
  }
  const handleSubmitInterests = () => {
    props.updateInterests({ tags: selectedInterests })
    setStep(step + 1)
  }
  if (_.isString(pathname))
    return pathname.startsWith('/contest') ? (
      <div id="ThankYou">
        <Helmet>
          <title>
            Thank you for participating! | Stan Winston School of Character Arts
          </title>
        </Helmet>
        <div className="your-receipt">
          <div className="wrapper">
            <h1>Confirmation</h1>
          </div>
        </div>
        {props.thankYouMessage ? (
          <div
            className="hero"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: props.thankYouMessage }}
          />
        ) : (
          <div className="hero">
            <div className="icon">
              <i className="icon-check" />
            </div>
            <div className="message">
              <span>
                You&apos;ve successfully entered the contest. Good luck!
              </span>
            </div>
            <div className="thanks">
              <div className="next-step-cta">
                <button
                  type="button"
                  className="btn -orange"
                  aria-label="Click this button to go back home"
                  onClick={() => props.goHome()}
                >
                  <span>Go Home</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div id="ThankYou">
        <Helmet>
          <title>
            Thank you for subscribing! | Stan Winston School of Character Arts
          </title>
        </Helmet>
        <div className="your-receipt">
          <div className="wrapper">
            <h1>Confirmation</h1>
          </div>
        </div>
        <div className="hero">
          <div className="icon">
            <i className="icon-check" />
          </div>
          <div className="message">
            <span>{`Your ${
              !props.usedFreeTrial
                ? 'Free Trial Subscription is now Active'
                : 'Subscription is now Active'
            }`}</span>
          </div>
          <div className="thanks">
            {step === 0 ? (
              <div className="poll">
                <div className="help-text">
                  Please take a moment to select the learning categories you are
                  most interested in exploring.
                </div>
                <div className="interests">
                  {categories.map((interest, index) => (
                    <label
                      key={interest}
                      className="interest"
                      htmlFor={`${interest}-${index}`}
                    >
                      <input
                        type="checkbox"
                        name="interests"
                        value={interest}
                        checked={selectedInterests.includes(interest)}
                        onChange={handleCheckboxChange}
                        id={`${interest}-${index}`}
                      />
                      {interest}
                    </label>
                  ))}
                </div>
              </div>
            ) : null}
            {step === 1 ? (
              <div className="help-text">
                Thank you! Please check your email to verify your account and
                receive some helpful tips on starting your creative journey with
                us.
              </div>
            ) : null}
            {step === 0 && (
              <div className="next-step-cta">
                <button
                  type="button"
                  className="btn -orange"
                  aria-label="Select your interests and click this button to go to the next screen"
                  onClick={handleSubmitInterests}
                  disabled={selectedInterests.length === 0}
                >
                  <span>Next</span>
                </button>
              </div>
            )}
            {step === 1 && (
              <div className="next-step-cta">
                <button
                  type="button"
                  className="btn -orange"
                  aria-label="Click this button to go to the course list"
                  onClick={() => props.goToCourses()}
                >
                  <span>See Courses</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
}

ThankYou.propTypes = {
  home: PropTypes.func.isRequired
}

export default connect(contestStateSelector, thankYouDispatchers)(ThankYou)
