import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { userProfileReselector as stateSelector } from 'reduxModules/users/selectors'
import { profileDispatchers as dispatchSelector } from 'reduxModules/users/dispatchers'
import _ from 'lodash'
import { categories } from '../../Tutorials/TutorialList/filters'

import './styles.css'

export function Interests(props) {
  const [selectedInterests, setSelectedInterests] = useState([])
  const handleCheckboxChange = event => {
    const { value, checked } = event.target
    if (checked) setSelectedInterests([...selectedInterests, value])
    else
      setSelectedInterests(
        selectedInterests.filter(interest => interest !== value)
      )
  }
  const handleSubmitInterests = () => {
    props.updateInterests({ tags: selectedInterests })
  }
  useEffect(() => {
    props.loadUserProfile()
  }, [])
  useEffect(() => {
    if (props?.initialValues?.tags)
      setSelectedInterests([...props.initialValues.tags])
  }, [props?.initialValues?.tags])
  return (
    <div id="Interests" className="account-section">
      <h3 className="account-section__title">Interests</h3>
      <div className="text">
        Choose your interests to get personalized content.
      </div>
      <div className="interests">
        {categories.map((interest, index) => (
          <label
            key={interest}
            className="interest"
            htmlFor={`${interest}-${index}`}
          >
            <input
              type="checkbox"
              name="interests"
              value={interest}
              checked={selectedInterests.includes(interest)}
              onChange={handleCheckboxChange}
              id={`${interest}-${index}`}
            />
            {interest}
          </label>
        ))}
      </div>
      <div className="interests-cta">
        <button
          type="button"
          className="btn -orange"
          aria-label="Select your interests and click this button to go to the next screen"
          onClick={handleSubmitInterests}
          disabled={selectedInterests.length === 0}
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  )
}

Interests.propTypes = {}

export default connect(stateSelector, dispatchSelector)(Interests)
