/* eslint-disable import/prefer-default-export */
import {
  isMobileSelector,
  hasSubscriptionSelector,
  usedFreeTrialSelector
} from 'reduxModules/app/selectors'
import { searchStateSelector } from 'reduxModules/newSearch/selectors'

// selectors
const courseCategoryLandingPageSelector = state =>
  state.courseCategoryLandingPage.detail
const statusSelector = state => state.courseCategoryLandingPage.status

// states (mapStateToProps)
export const courseCategoryLandingPageStateSelector = state => ({
  ...courseCategoryLandingPageSelector(state),
  searchSelectors: searchStateSelector(state),
  status: statusSelector(state),
  isMobile: isMobileSelector(state),
  hasSubscription: hasSubscriptionSelector(state),
  usedFreeTrial: usedFreeTrialSelector(state)
})
