/* eslint-disable import/prefer-default-export */
/* globals PROD_ENV */
import ReactPixel from 'react-facebook-pixel'
import logger from 'components/utils/logger'
import { reset } from 'redux-form'
import { push } from 'react-router-redux'
import { contestFetch, participate, types, toggleNewsletterAction } from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

export const contestDispatchers = (dispatch, props) => ({
  loadContest() {
    dispatch(contestFetch(props.params.slug)())
  },
  onSubmit(data, dispatch, props) {
    const fullData = { ...data, newsletter: props.newsletter }
    dispatch(participate(props.params.slug)(fullData))
      .then(([_, res]) => {
        if (res.type === types.CONTEST_PARTICIPATE_SUCCESS) {
          toastSuccess(notifications.contestSubmitted)
          dispatch(reset('contest'))
          dispatch(push(`/contest/${props.params.slug}/thank-you`))

          // track contest
          if (PROD_ENV && props.newsletter)
            try {
              ReactPixel.trackCustom('ContestConfirmation', {
                content_name: props.params.slug
              })
            } catch (e) {
              logger.error(`Error tracking contest: ${e}`)
            }
        } else toastError(notifications.contestSubmitted)
      })
      .catch(payload => {
        const error = payload.errors
        toastServerError(error)
      })
  },
  toggleNewsletter: () => dispatch(toggleNewsletterAction())
})
