import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('div', {}, props.variant === 'link' ? [React.createElement(Link, {
                'to': props.url,
                'className': 'more-button',
                'key': '851'
            }, React.createElement('i', { 'className': 'icon-plus more-button__icon' }), props.cta, '\n    ')] : null, props.variant === 'action' ? [React.createElement('button', {
                'className': 'more-button',
                'onClick': props.action,
                'key': '2671'
            }, React.createElement('i', { 'className': 'icon-plus more-button__icon' }), props.cta, '\n    ')] : null);
    }
    return scopeProps1.apply(this, []);
}