/* global API_AUTH_ROOT, API_ROOT */
import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import { types as userTypes } from 'reduxModules/users'
import { types as checkoutTypes } from 'reduxModules/checkout'
import authTypes from 'reduxModules/auth/types'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  PAYMENT_CARD_TYPE_CHANGE
  PAYMENT_METHOD_SAVE_FIELDS_FAILURE
  SHOW_PAYMENT_METHOD_FORM
  HIDE_PAYMENT_METHOD_FORM
  TOGGLE_STORE_PAYMENT
  SAVE_TOKENIZER
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  type: '',
  braintreeError: {},
  fieldsError: {
    message: '',
    details: {
      invalidFieldKeys: []
    }
  },
  clientToken: '',
  showForm: false,
  storeMethod: true,
  nonceToken: '',
  tokenizer: null
}

export const cardTypeChangedSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  type: action.payload
})

export const paymentMethodSaveFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  braintreeError: action.payload
})

export const paymentMethodSaveSuccess = (state = INITIAL_STATE) => ({
  ...state,
  braintreeError: {},
  fieldsError: { ...INITIAL_STATE.fieldsError },
  type: ''
})

export const paymentMethodFieldsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  fieldsError: action.payload
})

export const fetchUserBraintreeTokenSuccess = (
  state = INITIAL_STATE,
  action
) => ({ ...state, clientToken: action.payload.token })

export const showPaymentMethodSuccess = (state = INITIAL_STATE) => ({
  ...state,
  showForm: true
})

export const hidePaymentMethodSuccess = (state = INITIAL_STATE) => ({
  ...state,
  showForm: false,
  type: ''
})

export const toggleStorePaymentSuccess = (state = INITIAL_STATE) => ({
  ...state,
  storeMethod: !state.storeMethod
})

export const saveTokenizerSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  tokenizer: action.payload
})

export const clearPaymentSuccessful = state => ({ ...state, ...INITIAL_STATE })

export const HANDLERS = {
  [types.PAYMENT_CARD_TYPE_CHANGE]: cardTypeChangedSuccess,
  [types.PAYMENT_METHOD_SAVE_FIELDS_FAILURE]: paymentMethodFieldsFailure,
  [types.SHOW_PAYMENT_METHOD_FORM]: showPaymentMethodSuccess,
  [types.HIDE_PAYMENT_METHOD_FORM]: hidePaymentMethodSuccess,
  [userTypes.USERS_PAYMENT_METHOD_SAVE_SUCCESS]: paymentMethodSaveSuccess,
  [userTypes.USERS_PAYMENT_METHOD_REQUEST_FAILURE]: paymentMethodSaveFailure,
  [userTypes.USERS_BRAINTREE_TOKEN_FETCH_SUCCESS]: fetchUserBraintreeTokenSuccess,
  [types.TOGGLE_STORE_PAYMENT]: toggleStorePaymentSuccess,
  [types.SAVE_TOKENIZER]: saveTokenizerSuccess,
  [checkoutTypes.SELECT_PAYMENT_METHOD]: paymentMethodSaveSuccess,
  [checkoutTypes.CLEAR_ORDER_SUCCESS]: clearPaymentSuccessful,
  [authTypes.AUTH_LOGOUT_SUCCESS]: clearPaymentSuccessful
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const savePaymentMethodFieldsFailure = createAction(
  types.PAYMENT_METHOD_SAVE_FIELDS_FAILURE
)

export const cardTypeChanged = createAction(types.PAYMENT_CARD_TYPE_CHANGE)

export const showNewPaymentMethodForm = createAction(
  types.SHOW_PAYMENT_METHOD_FORM
)
export const hideNewPaymentMethodForm = createAction(
  types.HIDE_PAYMENT_METHOD_FORM
)

export const toggleStorePaymentAction = createAction(types.TOGGLE_STORE_PAYMENT)

export const saveNonceTokenAction = createAction(types.SAVE_NONCE_TOKEN)

export const saveTokenizer = createAction(types.SAVE_TOKENIZER)
