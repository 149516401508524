/* global MOBILE_BREAKPOINT */
/* eslint-disable import/prefer-default-export */
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { cartItemsFetch } from 'reduxModules/cart'
import _ from 'lodash'
import plans from 'components/SubscriptionPlans/plans'
import {
  getUTMDataFromCookies,
  removeUTMPrefixFromKeysIn,
  UTM_KEYS
} from 'reduxModules/utils/utm'

import detectProxyIPCookie from 'reduxModules/utils/proxy'
import {
  getGlobalPromoCookie,
  setGlobalPromoCookie
} from 'reduxModules/utils/promo'
import { getUser } from 'reduxModules/auth'
import authTypes from 'reduxModules/auth/types'

import { cookie } from 'redux-effects-cookie'
import { subscriptionPlansDispatcher } from 'reduxModules/subscriptions/dispatchers'
import {
  dispatchProxyLogin,
  dispatchOpenAthensLogin
} from '../auth/dispatchers'
import { clearNotification } from '../generic/notifications'
import { subscriptionSummaryFetch, closeBanner } from '../subscriptions'
import {
  updateDimensions,
  hideNavigation,
  showNavigation,
  hideInnerNavigation,
  showInnerNavigation,
  setIsMobile,
  unsetIsMobile,
  toggleMobileMenu,
  setFullscreen,
  unsetFullscreen,
  setUTM,
  sendUTM,
  validateGlobalPromoCode,
  updateScrollPercentage,
  getCurrentPromotion,
  displayStandardSubscriptionPlans
} from './'
import { toastServerError } from '../../../global/notifications'

export const navHandlers = dispatch => ({
  hideGlobalNav() {
    dispatch(hideNavigation())
  },
  showGlobalNav() {
    dispatch(showNavigation())
  }
})

export const innerNavHandlers = dispatch => ({
  hideInnerNav() {
    dispatch(hideInnerNavigation())
  },
  showInnerNav() {
    dispatch(showInnerNavigation())
  }
})

export const bannerHandler = dispatch => ({
  closeBannerSubscription() {
    dispatch(closeBanner())
  }
})

export default (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    clearGlobalNotification() {
      dispatch(clearNotification())
    },
    updateDimensions(width, height) {
      const { isMobile } = stateProps

      if (width <= MOBILE_BREAKPOINT) {
        if (!isMobile) dispatch(setIsMobile())
      } else if (isMobile) dispatch(unsetIsMobile())

      dispatch(updateDimensions({ width, height }))
    },
    proxyLogin(props) {
      detectProxyIPCookie(dispatch).then(data => {
        if (data.value !== undefined)
          dispatch(dispatchProxyLogin(dispatch, props.location.query, props))
      })
    },
    globalPromo(codename) {
      getGlobalPromoCookie(dispatch).then(data => {
        const promoCookie = data.value
          ? JSON.parse(data.value)
          : { codename: '' }
        const promocode = codename || promoCookie.codename
        if (promocode) {
          const planPrices = plans.map(p => ({
            id: p.id,
            name: p.name,
            price: p.price
          }))
          planPrices.pop()
          dispatch(validateGlobalPromoCode(promocode, planPrices)())
            .then(([_, res]) => {
              if (res.type !== 'VALIDATE_GLOBAL_PROMO_OK')
                setGlobalPromoCookie(dispatch, JSON.stringify({}))
              else
                setGlobalPromoCookie(
                  dispatch,
                  JSON.stringify({ codename: promocode })
                )
            })
            .catch(payload => {
              setGlobalPromoCookie(dispatch, JSON.stringify(payload.errors))
            })
        } else dispatch(displayStandardSubscriptionPlans())
      })
    },
    loadUserInfo() {
      dispatch(getUser())
        .then(res => {
          if (res[1].type === authTypes.AUTH_FETCH_USER_SUCCESS) {
            const payload = res[1].payload
            if (_.isNumber(payload.subscription)) {
              const subscriptionId = payload.subscription

              dispatch(subscriptionSummaryFetch(subscriptionId)())
            }
          }
        })
        .catch(payload => {
          const error = payload.errors
          toastServerError(error)
        })
    },
    loadSubscriptionSummary() {
      if (_.has(stateProps, 'subscription.id')) {
        const subscriptionId = stateProps.subscription.id
        if (_.isNumber(subscriptionId))
          dispatch(subscriptionSummaryFetch(subscriptionId)())
      }
    },
    showLoadingBar() {
      dispatch(showLoading())
    },
    hideLoadingBar() {
      dispatch(hideLoading())
    },
    toggleMobileMenu() {
      dispatch(toggleMobileMenu())
    },
    loadCartItems() {
      dispatch(cartItemsFetch())
    },
    loadCurrentPromotion() {
      dispatch(getCurrentPromotion())
    },
    updateFullscreenStatus(onFullscreen) {
      if (onFullscreen) dispatch(setFullscreen())
      else dispatch(unsetFullscreen())
    },
    async storeUTM(data) {
      const utmData = _.pick(data, UTM_KEYS)
      const IN_MS_28_DAYS = 2419200000

      // store utm parameters as cookies or refresh old utm cookes (to
      // attribute possible conversion to most recent Facebook ad referer)
      if (!_.isEmpty(utmData)) {
        // TODO: consider not adding utm data to the store to:
        //  - prevent having two sources of truth, and
        //  - make sure that utm data in the frontend gets removed after
        //    28 days.
        dispatch(setUTM(utmData))
        UTM_KEYS.forEach(utmKey => {
          dispatch(
            cookie(utmKey, data[utmKey], { maxage: IN_MS_28_DAYS, path: '/' })
          )
        })
      } else
        getUTMDataFromCookies(dispatch).then(data => {
          const result = removeUTMPrefixFromKeysIn(data)

          if (!_.isEmpty(result)) {
            dispatch(setUTM(result))

            if (stateProps.subscription && stateProps.subscription.active)
              dispatch(sendUTM(result)).then(([_, res]) => {
                if (res.type === 'SET_UTM_SUCCESS')
                  UTM_KEYS.forEach(cookieName => {
                    dispatch(cookie(cookieName, null, { path: '/' }))
                  })
              })
          }
        })
    },
    updateScrollPercentage(percentage) {
      dispatch(updateScrollPercentage(percentage))
    },
    ...bannerHandler(dispatch),
    ...navHandlers(dispatch),
    ...subscriptionPlansDispatcher(dispatch)
  }
}

export const notFoundDispatchers = dispatch => ({
  ...navHandlers(dispatch)
})

export const proxySSODispatchers = dispatch => ({
  proxyLoginSSODispatcher(props) {
    dispatchProxyLogin(dispatch, props?.location?.query, props)
  }
})

export const openAthensSSODispatchers = dispatch => ({
  openAthensSSODispatcher(props) {
    dispatchOpenAthensLogin(dispatch, props?.location?.query, props)
  }
})
