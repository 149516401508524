import * as React from 'react';
import * as _ from 'lodash';
import LearningPath from './LearningPath';
export default function () {
    function repeatPath1(path, pathIndex) {
        return React.createElement(LearningPath, path);
    }
    return React.createElement('div', {
        'id': 'LearningPaths',
        'className': 'account-section'
    }, React.createElement('h3', { 'className': 'account-section__title' }, 'Learning Pathways'), !_.isEmpty(this.props.learningPaths) ? React.createElement.apply(this, [
        'div',
        {
            'className': 'content-list',
            'key': '180'
        },
        _.map(this.props.learningPaths, repeatPath1.bind(this))
    ]) : null, _.isEmpty(this.props.learningPaths) ? React.createElement('div', {
        'className': 'no-results',
        'key': '345'
    }, React.createElement('span', {}, 'You haven\'t taken any learning pathway yet.')) : null);
}