import PropTypes from 'prop-types'

export const TutorialSummaryPropTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string,
  photo: PropTypes.objectOf(PropTypes.string).isRequired,
  artist: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  buyable: PropTypes.bool,
  onCart: PropTypes.bool,
  isQuickPlay: PropTypes.bool,
  onQuickPlayClick: PropTypes.func
}

export const WebinarSummaryPropTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  listingPhoto: PropTypes.objectOf(PropTypes.string).isRequired,
  artist: PropTypes.shape({
    title: PropTypes.string.isRequired
  })
}

export const CurrentlyWatchingTutorialSummaryPropTypes = {
  tutorial: PropTypes.shape(TutorialSummaryPropTypes),
  pctgWatched: PropTypes.number.isRequired,
  id: PropTypes.number,
  confirmWatchTutorial: PropTypes.func.isRequired,
  owned: PropTypes.bool,
  subscription: PropTypes.oneOfType([
    PropTypes.shape({
      active: PropTypes.bool,
      braintreeId: PropTypes.string,
      free: PropTypes.bool,
      id: PropTypes.number
    }),
    PropTypes.number
  ])
}
