import PropTypes from 'prop-types'
import React from 'react'
import './styles.css'
import template from './template.rt'

class MoreButton extends React.Component {
  render() {
    return template.call(this)
  }
}

MoreButton.propTypes = {
  action: PropTypes.func,
  cta: PropTypes.string,
  url: PropTypes.string,
  variant: PropTypes.oneOf(['action', 'link'])
}

MoreButton.defaultProps = {
  cta: 'See More',
  variant: 'link'
}

export default MoreButton
