import PropTypes from 'prop-types'
import { Component } from 'react'

import template from './template.rt'
import './styles.css'

class RelatedModal extends Component {
  constructor() {
    super()
    this.getTitle = this.getTitle.bind(this)
    this.getFullSlug = this.getFullSlug.bind(this)
  }

  getFullSlug(slug) {
    // Update route while preserving previous query params
    const searchParams = new URLSearchParams(window.location?.search)
    // If basic subscriber, don't enable auto-play so they can see credit consumption warning
    searchParams.set('watch', this.props.isBasicSubscriber ? 0 : 1)
    searchParams.delete('chapter')

    return `${slug}?${searchParams.toString()}`
  }

  getTitle() {
    const { learningPath } = this.props
    const title = learningPath ? learningPath.title : ''
    if (window.location.search !== '') return `Next on your ${title} pathway`
    return 'Explore related courses'
  }

  render() {
    return template.call(this)
  }
}

RelatedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired
}

export default RelatedModal
