/* eslint-disable import/prefer-default-export */
import _ from 'lodash'
import { createStructuredSelector, createSelector } from 'reselect'
import { isMobileSelector } from 'reduxModules/app/selectors'
import { learningPathDetailSelector } from 'reduxModules/learningPaths/selectors'
import { INITIAL_VIDEO_STATE } from './'

// selectors
export const playerState = state => videoId =>
  state.player[videoId] || INITIAL_VIDEO_STATE
const videoStatisticId = state => state.statistics.videoStatisticId
const subscriptionSelector = state => state.auth.subscription
const isSubscriptionApprentice = state =>
  !_.isNull(state.auth.subscription) && state.auth.subscription.rank === 1

export const apprenticeVideosSeenSelector = createSelector(
  [subscriptionSelector, isSubscriptionApprentice],
  (subscription, isApprentice) => {
    if (isApprentice) return subscription.monthlyVideosSeen
    return []
  }
)

const recommendsTutorials = state => state.tutorials.recommends
const resumeBookmarkSelector = state => {
  const tutorial = state.tutorials.detail

  if (_.has(tutorial, 'bookmarks')) {
    const bookmark = _.find(tutorial.bookmarks, { autoGenerated: true })
    if (bookmark && _.has(bookmark, 'time') && _.has(bookmark, 'chapterOrder'))
      return { time: bookmark.time, order: bookmark.chapterOrder }
  }
  return undefined
}
const showCreateBookmarkSelector = state => state.player.showCreateBookmark
const showRelevantsSelector = state => state.player.shouldOnlyShowRelevants
const hideChaptersSidebarSelector = state => state.player.hideChaptersSidebar
const playerIdSelector = state => state.player.playerId
const userProgressSelector = state => state.player.chapterProgressById
const loadBookmarkSelector = state => state.player.bookmarkToLoad
const userBookmarksSelector = state =>
  _.sortBy(
    _.filter(state.tutorials.detail.bookmarks, { autoGenerated: false }),
    ['chapterOrder', 'time']
  )

// states (mapStateToProps)
export const playerSelector = createStructuredSelector({
  getPlayerState: playerState,
  videoStatisticId,
  isSubscriptionApprentice,
  shouldOnlyShowRelevants: showRelevantsSelector,
  apprenticeVideosSeen: apprenticeVideosSeenSelector,
  resume: resumeBookmarkSelector,
  createBookmark: showCreateBookmarkSelector,
  bookmarkLoaded: loadBookmarkSelector,
  recommends: recommendsTutorials,
  isMobile: isMobileSelector,
  learningPath: learningPathDetailSelector,
  hideSidebar: hideChaptersSidebarSelector,
  userBookmarks: userBookmarksSelector,
  playerId: playerIdSelector,
  userProgress: userProgressSelector
})
