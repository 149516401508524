import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi, { getUrl, fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  WEBINARS_INITIAL
  WEBINARS_FETCH_SUCCESS
  WEBINARS_REQUEST_FAILURE
  WEBINARS_SUCCESS
  WEBINARS_ERROR
  WEBINAR_FETCH_SUCCESS
  WEBINAR_REQUEST_FAILURE
  WEBINARS_FETCH_MORE_SUCCESS
  WEBINARS_FECTH_MORE_REQUEST_FAILURE
  WEBINAR_ENROLL_SUCCESS
  WEBINAR_ENROLL_REQUEST_FAILURE
  WEBINAR_CLEAR
  WEBINAR_LIST_CLEAR
  TOGGLE_PREVIEW_VIDEO
`)

export const webinarsStatus = {
  initial: types.WEBINARS_INITIAL,
  success: types.WEBINARS_SUCCESS,
  error: types.WEBINARS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  status: webinarsStatus.initial,
  list: [],
  detail: {
    artist: {
      photoThumbnail: {}
    },
    title: '',
    slug: '',
    body: '',
    materials: '',
    courseCurriculum: '',
    facebookShareImages: []
  }
}

export const successList = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.results,
  next: action.payload.next,
  status: webinarsStatus.success
})

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  status: webinarsStatus.success
})

export const successListMore = (state = INITIAL_STATE, action) => ({
  ...state,
  list: [...state.list, ...action.payload.results],
  next: action.payload.next,
  status: webinarsStatus.success
})

export const failureList = (state = INITIAL_STATE) => ({
  ...state,
  status: webinarsStatus.error
})

export const failureDetail = (state = INITIAL_STATE) => ({
  ...state,
  detail: INITIAL_STATE.detail,
  status: webinarsStatus.error
})

export const clearSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: webinarsStatus.initial,
  detail: INITIAL_STATE.detail
})

export const clearListSuccess = (state = INITIAL_STATE) => ({
  ...state,
  status: webinarsStatus.initial,
  list: INITIAL_STATE.list
})

export const toggleVideo = (state = INITIAL_STATE) => ({
  ...state,
  detail: { ...state.detail, videoPreview: !state.detail.videoPreview }
})

export const HANDLERS = {
  [types.WEBINARS_FETCH_SUCCESS]: successList,
  [types.WEBINARS_REQUEST_FAILURE]: failureList,
  [types.WEBINAR_FETCH_SUCCESS]: successDetail,
  [types.WEBINAR_REQUEST_FAILURE]: failureDetail,
  [types.WEBINARS_FETCH_MORE_SUCCESS]: successListMore,
  [types.WEBINARS_FECTH_MORE_REQUEST_FAILURE]: failureList,
  [types.WEBINAR_CLEAR]: clearSuccess,
  [types.WEBINAR_LIST_CLEAR]: clearListSuccess,
  [types.TOGGLE_PREVIEW_VIDEO]: toggleVideo
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const webinarsFetchSuccess = createAction(types.WEBINARS_FETCH_SUCCESS)
export const webinarsFetchFailure = createAction(types.WEBINARS_REQUEST_FAILURE)
export const webinarsFetch = filters => {
  const url = getUrl('/webinars/', filters)
  return fetchApi(
    url,
    { method: 'GET' },
    webinarsFetchSuccess,
    webinarsFetchFailure
  )
}

export const yearlyWebinarEnrollSuccess = createAction(
  types.WEBINAR_ENROLL_SUCCESS
)
export const yearlyWebinarEnrollFetchFailure = createAction(
  types.WEBINAR_ENROLL_REQUEST_FAILURE
)
export const yearlyWebinarEnroll = pk => {
  if (typeof pk === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/webinar-variants/${pk}/give_free_access/`,
    { method: 'POST' },
    yearlyWebinarEnrollSuccess,
    yearlyWebinarEnrollFetchFailure
  )
}

export const webinarsFetchMoreSuccess = createAction(
  types.WEBINARS_FETCH_MORE_SUCCESS
)
export const webinarsFetchMoreFailure = createAction(
  types.WEBINARS_FECTH_MORE_REQUEST_FAILURE
)
export const webinarsFetchMore = next =>
  fetchHelper(
    next,
    { method: 'GET' },
    webinarsFetchMoreSuccess,
    webinarsFetchMoreFailure
  )

export const webinarFetchSuccess = createAction(types.WEBINAR_FETCH_SUCCESS)
export const webinarFetchFailure = createAction(types.WEBINAR_REQUEST_FAILURE)
export const webinarFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/webinars/${slug}/`,
    { method: 'GET' },
    webinarFetchSuccess,
    webinarFetchFailure
  )
}

export const clearWebinar = createAction(types.WEBINAR_CLEAR)
export const clearWebinarList = createAction(types.WEBINAR_LIST_CLEAR)

export const togglePreviewVideo = createAction(types.TOGGLE_PREVIEW_VIDEO)
