import { connect } from 'react-redux'
import { mobileMenuDispatchers as dispatchSelector } from 'reduxModules/menuItem/dispatchers'
import { mobileMenuSelector as stateSelector } from 'reduxModules/menuItem/selectors'
import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'
import './styles.css'

export class MobileSideBar extends Component {
  render() {
    return template.call(this)
  }
}

MobileSideBar.propTypes = {
  active: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  hideMenuItem: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  pathName: PropTypes.string.isRequired,
  showMenuItem: PropTypes.func.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired
}

MobileSideBar.defaultProps = {
  active: null,
  height: 0,
  width: 0
}

export default connect(
  stateSelector,
  dispatchSelector
)(MobileSideBar)
