import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import OrderDetail from '../../../generic/OrderDetail';
import SubscriptionTransactions from '../SubscriptionTransactions';
import keyUpHandler from '../../../utils/keyUpHandler';
export default function () {
    function onKeyUp1(props, isSubscription, isTransaction, event) {
        keyUpHandler(event, this.toggle, null, this);
    }
    function scopeStatus2(props, isSubscription, isTransaction) {
        var status = isTransaction ? this.constructor.getHumanReadableStatus(props.status) : 'Completed';
        return React.createElement('div', {
            'className': 'status',
            'key': '1729'
        }, React.createElement('span', { 'className': status.toLowerCase() }, status));
    }
    function scopePropsIsSubscriptionIsTransaction3() {
        var props = this.props;
        var isSubscription = this.props.isSubscription;
        var isTransaction = this.props.isTransaction;
        return React.createElement('div', {
            'className': 'collapsible-order' + ' ' + _.transform({
                collapsed: !this.state.visible,
                'is-transaction': isTransaction
            }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('div', {
            'aria-label': 'Toggle order details',
            'className': 'order-summary',
            'onClick': () => this.toggle(),
            'onKeyUp': onKeyUp1.bind(this, props, isSubscription, isTransaction),
            'tabIndex': '0'
        }, React.createElement('div', { 'className': 'left-container' }, React.createElement('button', {
            'aria-label': this.state.visible ? 'Collapse' : 'Expand',
            'className': _.transform({ animate: this.state.visible }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('i', { 'className': 'icon-right-2' })), !isSubscription ? React.createElement('div', {
            'className': 'order-date',
            'key': '1050'
        }, moment(isTransaction ? props.createdAt : props.created).format(FULL_DATE_FORMAT)) : null, isSubscription ? React.createElement('div', {
            'className': 'subscription-name',
            'key': '1200'
        }, '\n                ', `${ props.plan } subscription`, '\n                ', isSubscription ? [
            props.active ? React.createElement('span', {
                'className': 'active',
                'key': '13181'
            }, '[Active]') : null,
            !props.active ? React.createElement('span', {
                'className': 'inactive',
                'key': '13183'
            }, '[Inactive]') : null
        ] : null) : null), !isSubscription ? React.createElement('div', {
            'className': 'order-id',
            'key': '1587'
        }, React.createElement('span', {}, isTransaction ? props.id : props.braintreeId)) : null, !isSubscription ? scopeStatus2.apply(this, [
            props,
            isSubscription,
            isTransaction
        ]) : null, !isSubscription ? React.createElement('div', {
            'className': 'price',
            'key': '1967'
        }, '\n            $', isTransaction ? props.amount : props.cost, '\n        ') : null), this.state.visible ? [
            !isSubscription ? React.createElement(OrderDetail, _.assign({}, {
                'isLoaded': _.has(props, 'items') || isTransaction,
                'showFullDetail': true,
                'isMobile': props.isMobile,
                'subscriptionTransaction': isTransaction,
                'subscription': props.subscription,
                'key': '20981'
            }, props)) : null,
            isSubscription ? React.createElement(SubscriptionTransactions, {
                'subscription': props,
                'isMobile': props.isMobile,
                'isLoaded': _.has(props, 'transactions'),
                'key': '20983'
            }) : null
        ] : null);
    }
    return scopePropsIsSubscriptionIsTransaction3.apply(this, []);
}