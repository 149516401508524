/* eslint-disable import/prefer-default-export */
import { assetsGallerySelector } from '../assets/selectors'

// selectors
const pageSelector = state => state.pages.detail
const statusSelector = state => state.pages.status

// states (mapStateToProps)
export const pageStateSelector = state => ({
  ...pageSelector(state),
  assets: assetsGallerySelector(state),
  status: statusSelector(state)
})
