import PropTypes from 'prop-types'
import { Component } from 'react'

import { connect } from 'react-redux'
import dispatchers from 'reduxModules/hijack/dispatchers'
import selectors from 'reduxModules/hijack/selectors'
import _ from 'lodash'

import template from './template.rt'
import './styles.css'

export class Hijack extends Component {
  componentDidMount() {
    const { canHijack, location, hijack } = this.props
    if (canHijack && _.has(location, 'query.userid'))
      hijack(location.query.userid)
  }

  componentDidUpdate(prevProps) {
    const { hijacking, subscription } = this.props
    if (hijacking && hijacking !== prevProps.hijacking)
      if (_.isNumber(subscription)) this.props.loadSubscription(subscription)
  }

  render() {
    return template.call(this)
  }
}

Hijack.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      userid: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  hijacking: PropTypes.bool.isRequired,
  canHijack: PropTypes.bool.isRequired,

  // Dispatchers
  hijack: PropTypes.func,
  loadSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.oneOfType([
    PropTypes.shape({
      active: PropTypes.bool,
      braintreeId: PropTypes.string,
      free: PropTypes.bool,
      id: PropTypes.number
    }),
    PropTypes.number
  ])
}

Hijack.defaultProps = {
  subscription: null,
  hijack: null
}

export default connect(
  selectors,
  dispatchers
)(Hijack)
