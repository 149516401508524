import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { userLearningPathsReselector as stateSelector } from 'reduxModules/users/selectors'
import { learningPathsDispatchers as dispatchSelector } from 'reduxModules/users/dispatchers'
import template from './template.rt'

export class LearningPaths extends Component {
  componentDidMount() {
    this.props.loadUserLearningPaths()
  }

  render() {
    if (!this.props.learningPaths) return null
    return template.call(this)
  }
}

LearningPaths.propTypes = {
  loadUserLearningPaths: PropTypes.func.isRequired,
  learningPaths: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.number.isRequired
}

LearningPaths.defaultProps = {
  learningPaths: []
}

export default connect(
  stateSelector,
  dispatchSelector
)(LearningPaths)
