import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi, { fetchHelper } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  TESTIMONIALS_INITIAL
  TESTIMONIALS_FETCH_SUCCESS
  TESTIMONIALS_REQUEST_FAILURE
  TESTIMONIALS_SUCCESS
  TESTIMONIALS_ERROR
  TESTIMONIAL_RANDOM_FETCH_SUCCESS
  TESTIMONIAL_RANDOM_REQUEST_FAILURE
  RANDOM_TESTIMONIAL_SUCCESS
  RANDOM_TESTIMONIAL_ERROR
  TESTIMONIALS_FETCH_MORE_SUCCESS
  TESTIMONIALS_FETCH_MORE_FAILURE
`)

export const testimonialsStatus = {
  initial: types.TESTIMONIALS_INITIAL,
  success: types.TESTIMONIALS_SUCCESS,
  error: types.TESTIMONIALS_ERROR
}
// Random testimonial
export const randomTestimonialStatus = {
  success: types.RANDOM_TESTIMONIAL_SUCCESS,
  error: types.RANDOM_TESTIMONIAL_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: testimonialsStatus.initial,
  list: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  random: {
    email: '',
    fullName: '',
    text: '',
    published: false
  }
}

export const listSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload,
  status: testimonialsStatus.success
})

export const randomSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  random: action.payload,
  status: randomTestimonialStatus.success
})

export const randomFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: randomTestimonialStatus.error
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: testimonialsStatus.error
})

export const successListMore = (state = INITIAL_STATE, action) => ({
  ...state,
  list: {
    results: [...state.list.results, ...action.payload.results],
    next: action.payload.next
  },
  status: testimonialsStatus.success
})

export const HANDLERS = {
  [types.TESTIMONIALS_FETCH_SUCCESS]: listSuccess,
  [types.TESTIMONIALS_REQUEST_FAILURE]: failure,
  [types.TESTIMONIAL_RANDOM_FETCH_SUCCESS]: randomSuccess,
  [types.TESTIMONIAL_RANDOM_REQUEST_FAILURE]: randomFailure,
  [types.TESTIMONIALS_FETCH_MORE_SUCCESS]: successListMore,
  [types.TESTIMONIALS_FETCH_MORE_FAILURE]: failure
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const testimonialsFetchSuccess = createAction(
  types.TESTIMONIALS_FETCH_SUCCESS
)
export const testimonialsFetchFailure = createAction(
  types.TESTIMONIALS_REQUEST_FAILURE
)
export const testimonialsFetch = fetchApi(
  '/testimonials/?limit=10',
  { method: 'GET' },
  testimonialsFetchSuccess,
  testimonialsFetchFailure
)
export const testimonialRandomFetchSuccess = createAction(
  types.TESTIMONIAL_RANDOM_FETCH_SUCCESS
)
export const testimonialRandomFetchFailure = createAction(
  types.TESTIMONIAL_RANDOM_REQUEST_FAILURE
)
export const testimonialRandomFetch = fetchApi(
  '/testimonials/random/',
  { method: 'GET' },
  testimonialRandomFetchSuccess,
  testimonialRandomFetchFailure
)

export const testimonialsFetchMoreSuccess = createAction(
  types.TESTIMONIALS_FETCH_MORE_SUCCESS
)
export const testimonialsFetchMoreFailure = createAction(
  types.TESTIMONIALS_FETCH_MORE_FAILURE
)
export const testimonialsFetchMore = next =>
  fetchHelper(
    next,
    { method: 'GET' },
    testimonialsFetchMoreSuccess,
    testimonialsFetchMoreFailure
  )
