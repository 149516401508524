import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import Tutorial from '../../generic/CourseSummary';
export default function () {
    function repeatCourse1(course, courseIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + course.tutorial.id,
            'slug': course.tutorial.slug,
            'summary': course.tutorial.description,
            'teacher': course.tutorial.artist.title,
            'teacherSlug': course.tutorial.artist.slug,
            'thumb': course.tutorial.photo.boxThumb,
            'title': course.tutorial.title,
            'isCurrentlyWatching': true,
            'new': course.tutorial.new
        });
    }
    return React.createElement('section', {
        'id': 'currently-watching-courses',
        'className': 'wrapper'
    }, React.createElement('div', { 'className': 'title' }, React.createElement('h3', {}, 'Recently Watched Courses')), React.createElement.apply(this, [
        'div',
        { 'className': 'courses-list' },
        _.map(this.props.courses.results, repeatCourse1.bind(this))
    ]), this.props.courses.next ? React.createElement('div', {
        'className': 'load-more-courses',
        'key': '760'
    }, React.createElement('button', {
        'onClick': () => this.props.loadMore(),
        'className': 'load-more'
    }, 'Load More Courses')) : null);
}