import React from 'react'

import _ from 'lodash'

import AddBookmark from '../AddBookmark/index'
import Bookmark from './Bookmark'

function BookmarkList({
  playlist,
  currentChapter,
  playerIsReady,
  createBookmark,
  tutorial,
  player,
  displayCreateBookmark,
  userBookmarks,
  removeBookmark,
  playChapter
}) {
  // Only display bookmarks of available chapters (in case only relevants are being displayed)
  const finalBookmarks = userBookmarks.filter(b =>
    _.find(playlist, o => o?.extra?.id === b.chapter)
  )
  return (
    <div className="bookmarks">
      <div className="create-bookmark">
        {playerIsReady ? (
          <>
            {createBookmark ? (
              <AddBookmark
                tutorial={tutorial?.id}
                chapter={playlist[currentChapter]}
                getCurrentTime={player.getPosition}
              />
            ) : null}
            {!createBookmark ? (
              <button
                className="add-bookmark-cta"
                onClick={() => displayCreateBookmark(playlist[currentChapter])}
                type="button"
              >
                <div className="plus-icon">+</div>
                <div className="label">add bookmark</div>
              </button>
            ) : null}
          </>
        ) : null}
      </div>

      {_.size(finalBookmarks) > 0 ? (
        <ul className={`bookmarks-list ${createBookmark ? 'creating' : ''}`}>
          {finalBookmarks.map(bookmark => (
            <Bookmark
              bookmark={bookmark}
              playChapter={playChapter}
              playlist={playlist}
              removeBookmark={removeBookmark}
              key={bookmark.id}
            />
          ))}
        </ul>
      ) : null}

      {_.size(finalBookmarks) === 0 ? (
        <div className="no-bookmarks">
          <i className="icon icon-bookmark-empty" />
          <div>No Bookmarks</div>
        </div>
      ) : null}
    </div>
  )
}

export default BookmarkList
