import PropTypes from 'prop-types'
import template from './template.rt'
import './styles.css'

const LearningPath = props => template.call({ props })

export const LearningPathSummaryPropTypes = {
  duration: PropTypes.arrayOf(PropTypes.number),
  numTutorials: PropTypes.number.isRequired,
  photo: PropTypes.shape({
    default: PropTypes.string.isRequired
  }).isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

LearningPath.propTypes = LearningPathSummaryPropTypes

export default LearningPath
