import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('main', {
            'id': 'Hijack',
            'className': 'wrapper'
        }, props.canHijack && !props.hijacking ? React.createElement('div', {
            'className': 'spinner',
            'key': '70'
        }, React.createElement('div', { 'className': 'double-bounce1' }), React.createElement('div', { 'className': 'double-bounce2' })) : null, props.canHijack && !props.hijacking ? React.createElement('div', { 'key': '225' }, '\n      Gathering user information... You will be redirected to the Home page in a moment.\n    ') : null, !props.canHijack && props.hijacking ? React.createElement('div', { 'key': '379' }, '\n      You are already impersonating a user, release the current user before impersonating a new one.\n    ') : null, !props.canHijack && !props.hijacking ? React.createElement('div', { 'key': '545' }, '\n      You don\'t have permissions for this feature.\n    ') : null);
    }
    return scopeProps1.apply(this, []);
}