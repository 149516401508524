import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import Sidebar from '../generic/Sidebar';
import items from './sidebarItems';
export default function () {
    return React.createElement('div', {
        'id': 'CustomerCare',
        'className': _.transform({
            'sidenav-wrapper': !this.props.isMobile,
            'topnav-wrapper': this.props.isMobile,
            wrapper: !this.props.isMobile
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement(Helmet, {}, React.createElement('title', {}, 'Customer Care | Stan Winston School of Character Arts')), React.createElement('div', {
        'className': _.transform({
            sidenav: !this.props.isMobile,
            topnav: this.props.isMobile,
            wrapper: !this.props.isMobile
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement(Sidebar, {
        'items': items,
        'active': this.props.location.pathname,
        'subscription': this.props.subscription,
        'name': 'customer care',
        'isMobile': this.props.isMobile,
        'height': this.props.dimensions.height
    })), React.createElement('div', { 'className': 'customer-care-content wrapper' }, '\n    ', this.props.children, '\n  '));
}