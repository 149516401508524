/* global dataLayer */
/* eslint-disable import/prefer-default-export */
import { cookie } from 'redux-effects-cookie'
import { push } from 'react-router-redux'

import { logout } from 'reduxModules/auth'
import authTypes from 'reduxModules/auth/types'
import { releaseHijackedUser } from 'reduxModules/hijack'
import { toggleMobileMenu } from 'reduxModules/app'
import { closeItemModal } from 'reduxModules/cart'
import { homeFetch } from 'reduxModules/home'
import { hideCookieNotice, showCookieNotice, toggleSearchBox } from './'
import { clearSuggestions, searchSuggestFetch } from '../newSearch'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

const A_WEEK_IN_MS = 604800000

export const logoutFlow = (dispatch, props) =>
  dispatch(logout())
    .then(([_, res]) => {
      if (res.type === authTypes.AUTH_LOGOUT_SUCCESS) {
        toastSuccess(notifications.logout)
        dataLayer.push({ isLoggedIn: false })
        if (props?.pathName === '/') dispatch(homeFetch())
        else dispatch(push('/'))
      } else toastError(notifications.logout)
    })
    .catch(payload => {
      const error = payload.errors
      toastServerError(error)
    })

export const dispatchToggleSearchBox = dispatch => dispatch(toggleSearchBox())

export const releaseHijacked = dispatch => {
  dispatch(releaseHijackedUser())
  dispatch(push('/account'))
  toastSuccess(notifications.userReleased)
}

export default (dispatch, props) => ({
  getInitialShowCookieNoticeState: () => {
    dispatch(cookie('showCookieNotice')).then(cookieValue => {
      if (cookieValue === undefined)
        dispatch(
          cookie('showCookieNotice', true, { maxage: A_WEEK_IN_MS })
        ).then(() => dispatch(showCookieNotice()))
      else if (cookieValue === 'true') dispatch(showCookieNotice())
    })
  },
  hideCookieNotice: () => {
    dispatch(hideCookieNotice())
    dispatch(cookie('showCookieNotice', false, { maxage: A_WEEK_IN_MS }))
  },
  toggleSearchBox: () => {
    dispatch(clearSuggestions())
    dispatchToggleSearchBox(dispatch)
  },
  logout: () => logoutFlow(dispatch, props),
  toggleMobileMenu() {
    dispatch(toggleMobileMenu())
  },
  releaseHijacked: () => releaseHijacked(dispatch),
  closeItemModal: () => dispatch(closeItemModal()),
  getSearchSuggestions(query) {
    dispatch(searchSuggestFetch(query)())
  },
  clearSuggestions() {
    dispatch(clearSuggestions())
  }
})
