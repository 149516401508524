import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import dispatchSelector from 'reduxModules/forms/paymentMethod/dispatchers'
import stateSelector from 'reduxModules/forms/paymentMethod//selectors'
import template from './template.rt'
import './styles.css'

export class PaymentMethodForm extends Component {
  componentWillUnmount() {
    // some iOS devices does not close the keyboard after closing the form.
    document.activeElement.blur()
  }
  render() {
    if (this.props.clientToken !== '' || this.props.showForm)
      return template.call(this)
    return null
  }
}

PaymentMethodForm.propTypes = {
  cardTypeChanged: PropTypes.func.isRequired,
  savePaymentMethod: PropTypes.func,
  errorMessage: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  clientToken: PropTypes.string.isRequired,
  showForm: PropTypes.bool.isRequired,
  enablePaypal: PropTypes.bool,
  payWithCreditCard: PropTypes.bool
}

PaymentMethodForm.defaultProps = {
  enablePaypal: false,
  payWithCreditCard: true,
  savePaymentMethod: null
}

export default connect(
  stateSelector,
  dispatchSelector
)(PaymentMethodForm)
