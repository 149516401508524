import { Component } from 'react'
import { connect } from 'react-redux'

import stateSelector from 'reduxModules/emailConfirmation/selectors'
import dispatchers from 'reduxModules/emailConfirmation/dispatchers'

import './styles.css'
import template from './template.rt'

export class EmailVerify extends Component {
  componentDidMount() {
    this.props.verifyEmail({key: this.props.location.query.key})
  }

  render() {
    return template.call(this)
  }
}

export default connect(
  stateSelector,
  dispatchers
)(EmailVerify)
