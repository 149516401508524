import React, { useState, useEffect } from 'react'

const withAsyncScriptLoader = urls => WrappedComponent => {
  function WithAsyncScriptLoader(props) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
      let loadedScripts = 0

      const handleLoad = () => {
        loadedScripts += 1
        if (loadedScripts === urls.length) {
          setLoading(false)
          setError(false)
        }
      }

      const handleError = () => {
        setLoading(false)
        setError(true)
      }

      const scriptElements = urls.map(url => {
        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.addEventListener('load', handleLoad)
        script.addEventListener('error', handleError)
        document.body.appendChild(script)
        return script
      })

      return () => {
        scriptElements.forEach(script => {
          script.removeEventListener('load', handleLoad)
          script.removeEventListener('error', handleError)
          document.body.removeChild(script)
        })
      }
    }, [urls])

    return (
      <WrappedComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        isScriptLoaded={!loading}
        isScriptLoadSucceed={!error}
      />
    )
  }

  return WithAsyncScriptLoader
}

export default withAsyncScriptLoader
