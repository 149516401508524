import React, { useRef, useEffect, useState, useCallback } from 'react'

import keyUpHandler from '../../../utils/keyUpHandler'
import SearchSuggestions from './SearchSuggestions'

import useDebounceTermInput from './useDebounceTermInput'
import useEventListener from '../../../utils/useEventListener'
import useOnClickOutside from './useOnClickOutside'

import './styles.css'

function SearchBox({
  autocompleteResults,
  toggleSearchBox,
  getSuggestions,
  clearSuggestions,
  inputKeyUp
}) {
  // Reference to input
  const inputEl = useRef(null)
  // Reference to search box
  const searchBoxEl = useRef(null)
  // State
  const [searchTerm, setSearchTerm] = useState('')
  // Debouncing term from input
  const debouncedSearchTerm = useDebounceTermInput(searchTerm, 500)
  // Whether the API returned results or not
  const hasResults =
    autocompleteResults.posts.length ||
    autocompleteResults.tutorials.length ||
    autocompleteResults.suggestions.length
  // Focus on render
  useEffect(() => {
    if (inputEl) inputEl.current.focus()
  }, [])
  // Retrieve suggestions
  const effectSearch = () => {
    if (debouncedSearchTerm.length > 0) getSuggestions(debouncedSearchTerm)
    else clearSuggestions()
  }
  useEffect(
    effectSearch,
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )
  // Handler for clicking outside
  const searchBoxElHandler = useCallback(() => toggleSearchBox())
  // Handler to allow keyboard navigation of results
  const arrowsKeysHandler = e => {
    if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return
    const focusElem = document.querySelector(':focus')
    const tabElements = [
      ...document.querySelectorAll(
        '#searchInput, #SearchSuggestions>a.suggestion'
      )
    ]
    const tabElementsCount = tabElements.length - 1
    if (!tabElements.includes(focusElem)) return
    e.preventDefault()
    const focusIndex = tabElements.indexOf(focusElem)
    let elemToFocus
    if (e.key === 'ArrowUp')
      elemToFocus =
        tabElements[focusIndex > 0 ? focusIndex - 1 : tabElementsCount]
    if (e.key === 'ArrowDown')
      elemToFocus =
        tabElements[focusIndex < tabElementsCount ? focusIndex + 1 : 0]
    elemToFocus.focus()
  }
  // Handler to dismiss suggestions when hitting 'Escape'
  const escapeHandler = e => {
    if (e.key === 'Escape') clearSuggestions()
  }
  // Event listeners attaching each handler
  useEventListener('keydown', arrowsKeysHandler)
  useEventListener('keydown', escapeHandler)
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(searchBoxEl, searchBoxElHandler)
  // Return component
  return (
    <div id="SearchBox" ref={searchBoxEl}>
      <i className="icon-search" />
      <form
        action="."
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <input
          type="text"
          name="search-input"
          id="searchInput"
          onKeyUp={e => {
            inputKeyUp(e)
          }}
          ref={inputEl}
          placeholder="Search Stan Winston School"
          onChange={e => {
            setSearchTerm(e.target.value)
          }}
          role="combobox"
          aria-autocomplete="both"
          aria-haspopup="true"
          aria-expanded={!!hasResults}
          aria-controls="SearchSuggestions"
        />
      </form>
      <button
        type="button"
        onClick={() => {
          toggleSearchBox()
        }}
        onKeyUp={event =>
          keyUpHandler(event, toggleSearchBox(), toggleSearchBox)
        }
        aria-label="Click to close the search bar"
        className="icon-close-container"
      >
        <i className="icon-close" />
      </button>
      {hasResults ? (
        <SearchSuggestions
          results={autocompleteResults}
          query={searchTerm}
          toggleSearchBox={toggleSearchBox}
        />
      ) : null}
    </div>
  )
}

export default SearchBox
