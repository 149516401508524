/* eslint-disable import/prefer-default-export */
import { push } from 'react-router-redux'
import { cartItemsFetch } from 'reduxModules/cart'
import _ from 'lodash'

import { hijackFetch } from './'
import { subscriptionSummaryFetch } from '../subscriptions'
import notifications, { toastSuccess } from '../../../global/notifications'

export const dispatchHijack = (dispatch, userId) =>
  dispatch(hijackFetch(userId)()).then(() => {
    dispatch(cartItemsFetch())
    dispatch(push('/account'))
    toastSuccess(notifications.userImpersonated)
  })

export const dispatchSubscriptionFetch = (dispatch, subscriptionId) => {
  dispatch(subscriptionSummaryFetch(subscriptionId)())
}

export default dispatch => ({
  hijack: userId => dispatchHijack(dispatch, userId),
  loadSubscription: subscriptionId =>
    dispatchSubscriptionFetch(dispatch, subscriptionId)
})
