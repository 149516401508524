import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { Field } from 'redux-form';
import passwordField from '../../generic/PasswordField';
import { validators } from '../../../redux/modules/utils/forms';
export default function () {
    return React.createElement('div', {
        'id': 'ResetPassword',
        'className': 'auth-page'
    }, React.createElement('div', {}, React.createElement('div', { 'className': 'logo' }, React.createElement(Link, { 'to': '/' }, React.createElement('img', {
        'src': '/global/assets/images/logo/logo-big.png',
        'alt': 'Stan Winston School Of Character Arts'
    }))), React.createElement('div', { 'className': 'reset-password auth-form-box' }, React.createElement('h1', {}, 'Reset Password'), React.createElement('form', {
        'id': 'SignInForm',
        'onSubmit': this.props.handleSubmit
    }, React.createElement(Field, {
        'name': 'password1',
        'validate': validators.required,
        'component': passwordField,
        'placeholder': 'New Password',
        'props': { ariaLabel: 'New Password' }
    }), React.createElement(Field, {
        'name': 'password2',
        'validate': validators.required,
        'component': passwordField,
        'placeholder': 'Retype New Password',
        'props': { ariaLabel: 'Retype New Password' }
    }), React.createElement('button', {
        'type': 'submit',
        'className': 'btn -wide -blue-2',
        'disabled': this.props.pristine || this.props.submitting
    }, '\n          Save\n        ')), React.createElement('div', { 'className': 'auth-alt' }, '\n        or ', React.createElement(Link, { 'to': '/login' }, 'Sign In')))));
}