import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TutorialSummaryPropTypes as tutorialProptypes } from 'components/generic/CourseSummary/propTypes'
import LoadingComponent from 'components/generic/LoadingComponent'
import { tutorialsStatus } from 'reduxModules/tutorials'

import './styles.css'
import template from './template.rt'

export class TutorialListContent extends Component {
  render() {
    if (this.props.requestStatus !== tutorialsStatus.success)
      return <LoadingComponent />
    return template.call(this)
  }
}
TutorialListContent.propTypes = {
  tutorials: PropTypes.arrayOf(PropTypes.shape(tutorialProptypes)),
  requestStatus: PropTypes.string
}
export default TutorialListContent
