/* eslint-disable react/require-default-props */

import {
  SpecialVideoPropTypes,
  SpecialVideoDefaultProps
} from 'components/SpecialVideos/SpecialVideoDetail'

import template from './template.rt'
import './styles.css'

function SpecialVideoCurriculum(props) {
  return template.call({ props })
}

SpecialVideoCurriculum.propTypes = SpecialVideoPropTypes
SpecialVideoCurriculum.defaultProps = SpecialVideoDefaultProps

export default SpecialVideoCurriculum
