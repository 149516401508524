/* eslint-disable no-unused-vars, max-len */

export default isMobile => [
  // isMobile ?
  // {
  //     title: 'courses',
  //     url: '/tutorials'
  // } :
  // {
  //     title: 'learn',
  //     options: [
  //         { title: 'courses', url: '/tutorials', isExternal: false, description: '' },
  //         { title: 'learning pathways', url: '/pathways', isExternal: false, description: '' }
  //     ]
  // },
  // ...(isMobile ? [{ title: 'learning pathways', url: '/pathways', isExternal: false, description: '' }] : []),
  {
    // TODO: remove this and uncomment previous code on pathways release
    title: 'courses',
    url: '/tutorials'
  },
  // {
  //     title: 'store',
  //     url: '/store',
  //     hide: ['isLibrarySubscriber']
  // },
  {
    title: 'pathways',
    url: '/pathways'
  },
  {
    title: 'community',
    options: [
      {
        title: 'forums',
        description: 'join the discussion',
        url: 'http://forums.stanwinstonschool.com/',
        isExternal: true
      },
      {
        title: 'blog',
        description: 'read inside stories on monster making',
        url: '/blog',
        isExternal: false
      },
      {
        title: 'testimonials',
        description: 'read the testimonials from students',
        url: '/testimonials',
        isExternal: false
      }
    ]
  },
  {
    title: 'about',
    options: [
      {
        title: 'school',
        description: 'take the video tour',
        url: '/stan-winston-school-of-character-arts',
        isExternal: false
      },
      {
        title: 'FAQ',
        description: 'most frequently asked questions',
        url: '/customer-care/faqs/',
        isExternal: false
      },
      {
        title: 'gallery',
        description: 'read inside stories on monster making',
        url: '/gallery',
        isExternal: false
      },
      {
        title: 'teachers',
        description: 'meet our faculty',
        url: '/artists',
        isExternal: false
      },
      {
        title: 'team',
        description: 'meet our staff',
        url: '/the-team',
        isExternal: false
      }
    ]
  }
]
