import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import CartItemList from './CartItemList';
import DiscountCode from '../DiscountCode';
import { Link } from 'react-router';
import Tutorial from '../generic/CourseSummary';
import math from '../../redux/modules/utils/math';
export default function () {
    function onClick1(isMobile) {
        this.props.checkoutCart();
    }
    function onClick2(isMobile, validatedCode) {
        {
            this.props.toggleDiscountCode();
        }
    }
    function scopeValidatedCode3(isMobile) {
        var validatedCode = this.props.validatedDiscountCode;
        return React.createElement('div', { 'className': 'body' }, React.createElement('div', { 'className': 'subtitles' }, React.createElement('span', { 'className': 'property' }, this.props.count > 1 ? 'items' : 'item', ' (', this.props.count, '):'), !validatedCode.codename ? React.createElement('label', {
            'className': 'property redeem-cta',
            'htmlFor': 'discount-code-input',
            'onClick': onClick2.bind(this, isMobile, validatedCode),
            'key': '2345'
        }, '\n              Redeem a discount code\n            ') : null, !validatedCode.codename ? React.createElement(DiscountCode, { 'key': '2630' }) : null, validatedCode.codename ? React.createElement('span', {
            'className': 'property redeemed',
            'key': '2691'
        }, 'Redeemed Code:') : null), React.createElement('div', { 'className': 'values' }, React.createElement('span', { 'className': 'property' }, '$', this.props.subtotal), React.createElement('span', { 'className': 'property' }, !validatedCode.codename ? React.createElement('span', { 'key': '2939' }, '---') : null, validatedCode.codename ? React.createElement('span', { 'key': '3002' }, React.createElement('span', { 'className': 'minus' }, '- '), '\n                $', math.toFixed(validatedCode.discount), React.createElement('br', {}), '\n                "', validatedCode.codename, '"\n              ') : null)));
    }
    function onClick4(isMobile) {
        this.props.checkoutCart();
    }
    function repeatTutorial5(isMobile, tutorial, tutorialIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + tutorial.id,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.title,
            'teacherSlug': tutorial.artist.slug,
            'thumb': tutorial.photo.boxThumb,
            'title': tutorial.title,
            'new': tutorial.new
        });
    }
    function scopeIsMobile6() {
        var isMobile = this.props.isMobile;
        return React.createElement('div', { 'id': 'Cart' }, React.createElement(Helmet, {}, React.createElement('title', {}, 'Your Cart | Stan Winston School of Character Arts')), React.createElement('div', { 'className': 'wrapper' }, React.createElement('section', { 'className': 'heading' }, React.createElement('h1', {}, 'Your cart'))), !isMobile ? React.createElement('section', {
            'className': 'hero',
            'key': '637'
        }, React.createElement('div', { 'className': 'headline' }, React.createElement('p', {}, 'You have ', React.createElement('span', { 'className': 'accent' }, this.props.count), ' ', this.props.count === 1 ? 'item' : 'items', ' in your cart')), React.createElement('div', { 'className': 'checkout-button' }, this.props.count > 0 ? React.createElement('button', {
            'onClick': onClick1.bind(this, isMobile),
            'key': '886'
        }, '\n        checkout\n      ') : null)) : null, !this.props.isMobile ? React.createElement('section', {
            'className': 'items wrapper',
            'key': '1041'
        }, this.props.count > 0 ? React.createElement(CartItemList, {
            'items': this.props.cartItems,
            'loading': this.props.loading,
            'isMobile': false,
            'subscription': this.props.subscription,
            'plans': this.props.plans,
            'subscriptionDiscount': this.props.subscriptionDiscount,
            'key': '1106'
        }) : null) : null, React.createElement('section', {
            'className': 'mid-section wrapper' + ' ' + _.transform({ 'with-related-items': _.size(this.props.related) > 0 }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, !isMobile ? React.createElement('div', {
            'className': 'terms-and-conditions',
            'key': '1520'
        }, React.createElement('h3', {}, 'Terms and Conditions'), React.createElement('p', {}, '\n        Your Order Confirmation will include shipment details (if applicable) and estimated tax.\n      '), React.createElement('p', {}, '\n        We are not responsible for customer typographical errors. Not all items may be available. We reserve the right to limit quantities, including the right to prohibit sales to resellers.\n      ')) : null, React.createElement('div', { 'className': 'order-summary-box' }, React.createElement('div', { 'className': 'order-summary' }, React.createElement('div', { 'className': 'title' }, React.createElement('span', {}, 'order summary')), scopeValidatedCode3.apply(this, [isMobile]), React.createElement('div', { 'className': 'total' }, React.createElement('span', {}, 'order total:'), React.createElement('span', {}, '$', this.props.total)), React.createElement('div', {
            'className': 'checkout-button' + ' ' + _.transform({ 'justify-end': !isMobile }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, this.props.count > 0 ? React.createElement('button', {
            'onClick': onClick4.bind(this, isMobile),
            'key': '3472'
        }, '\n            checkout\n          ') : null)))), this.props.isMobile ? React.createElement('section', {
            'className': 'items wrapper',
            'key': '3671'
        }, this.props.count > 0 ? React.createElement(CartItemList, {
            'items': this.props.cartItems,
            'loading': this.props.loading,
            'isMobile': true,
            'subscription': this.props.subscription,
            'plans': this.props.plans,
            'subscriptionDiscount': this.props.subscriptionDiscount,
            'key': '3735'
        }) : null) : null, _.size(this.props.related) > 0 ? React.createElement('section', {
            'className': 'related-items wrapper',
            'key': '4032'
        }, React.createElement('h3', { 'className': 'related-items__title' }, 'You may also be interested in'), React.createElement.apply(this, [
            'div',
            { 'className': 'course-list' },
            _.map(this.props.related, repeatTutorial5.bind(this, isMobile))
        ])) : null);
    }
    return scopeIsMobile6.apply(this, []);
}