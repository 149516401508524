import * as React from 'react';
import * as _ from 'lodash';
import { Tab } from 'react-tabs';
import { Tabs } from 'react-tabs';
import { TabList } from 'react-tabs';
import { TabPanel } from 'react-tabs';
export default function () {
    function repeatTab1(tab, tabIndex) {
        return React.createElement(Tab, {
            'key': tab.name,
            'title': tab.name[0].toUpperCase() + tab.name.substring(1) + ' - Use arrows to navigate between tabs'
        }, React.createElement('span', {}, tab.name));
    }
    function repeatTab2(tab, tabIndex) {
        return [React.createElement(TabPanel, {
                'className': tab.name,
                'key': '6421'
            }, tab.content)];
    }
    return React.createElement('section', {
        'id': 'course-tabs',
        'className': 'wrapper-1080' + ' ' + _.transform({ 'on-side': this.props.isSubscribed || this.props.isOwned }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement.apply(this, [
        Tabs,
        {},
        React.createElement.apply(this, [
            TabList,
            {},
            _.map(this.props.tabs, repeatTab1.bind(this))
        ]),
        _.map(this.props.tabs, repeatTab2.bind(this))
    ]));
}