import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import stateSelector from 'reduxModules/emailConfirmation/selectors'
import dispatchers from 'reduxModules/emailConfirmation/dispatchers'

import template from './template.rt'
import './styles.css'

export class VerifyBanner extends Component {
  render() {
    return template.call(this)
  }
}

VerifyBanner.propTypes = {
  isMobile: PropTypes.bool,
  isResendCTADisabled: PropTypes.bool,
  displace: PropTypes.bool
}

VerifyBanner.defaultProps = {
  isMobile: false,
  isResendCTADisabled: false,
  displace: false
}

export default connect(
  stateSelector,
  dispatchers
)(VerifyBanner)
