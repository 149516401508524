import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Element } from 'react-scroll'

import keyUpHandler from '../../utils/keyUpHandler'
import { getHumanReadableChapterLength } from '../utils'
import RadialProgress from '../../generic/RadialProgress'
import { COMPLETION_THRESHOLD } from './ChapterList'

function Chapter({
  chapter,
  isCurrentChapter,
  wasCompleted,
  index,
  playChapter,
  percentageProgress,
  currentPosition,
  isPlaying,
  markAsCompleted,
  tutorial,
  updateUserProgress
}) {
  const [hasBeenCompleted, setHasBeenCompleted] = useState(false)
  const getChapterStatus = () => {
    let status = ''

    if (isCurrentChapter)
      if (isPlaying) status = 'Playing'
      else status = 'Paused'
    else if (wasCompleted) status = 'Completed'

    if (status.length)
      return (
        <>
          <span className="separator">-</span>
          <span className="status">{status}</span>
        </>
      )

    return undefined
  }
  useEffect(() => {
    if (
      isCurrentChapter &&
      isPlaying &&
      percentageProgress >= COMPLETION_THRESHOLD &&
      !hasBeenCompleted
    ) {
      markAsCompleted(tutorial.slug, chapter?.extra?.id)
      setHasBeenCompleted(true)
      updateUserProgress({
        id: chapter?.extra?.id,
        completed: true
      })
    }
  }, [percentageProgress, hasBeenCompleted, isCurrentChapter, isPlaying])
  return (
    <li>
      <Element name={`chapter-${chapter?.extra?.id}`}>
        <button
          aria-label={`Play chapter "${chapter?.extra?.name}"`}
          className={`chapter ${isCurrentChapter ? 'current' : ''} ${
            wasCompleted ? 'played' : ''
          }`}
          onClick={() => playChapter(index, wasCompleted ? 0 : currentPosition)}
          onKeyUp={event =>
            keyUpHandler(event, playChapter, null, null, [index])
          }
          type="button"
          id={`chapter-${chapter?.extra?.id}`}
          title={chapter?.extra?.name}
        >
          <div className="icon-container">
            {!wasCompleted ? (
              <div
                className="progress-icon"
                title={`${percentageProgress}% completed`}
              >
                <RadialProgress
                  percentage={percentageProgress}
                  sqSize={30}
                  isMini
                  strokeWidth={8}
                />
                <i className="icon icon-play-fill" />
              </div>
            ) : (
              <i className="fa-check" title="Chapter completed" />
            )}
          </div>
          <div className="chapter-inner-container">
            <span className="name">
              {`${index + 1}. ${chapter?.extra?.name}`}
            </span>
            <div className="chapter-metadata">
              <span className="time">
                {/* e.g. 13min */}
                {getHumanReadableChapterLength({
                  durationH: chapter?.durationH,
                  durationM: chapter?.durationM,
                  durationS: chapter?.durationS
                })}
              </span>
              {getChapterStatus()}
            </div>
          </div>
        </button>
      </Element>
    </li>
  )
}

Chapter.propTypes = {
  chapter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tracks: PropTypes.arrayOf(
      PropTypes.shape({
        // TODO: fill this
      })
    ),
    image: PropTypes.string,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.string,
        label: PropTypes.string
      })
    )
  }),
  isCurrentChapter: PropTypes.bool.isRequired,
  wasCompleted: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  playChapter: PropTypes.func.isRequired
}

export default Chapter
