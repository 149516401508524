import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    return React.createElement('section', { 'id': 'course-artist' }, React.createElement('div', { 'className': 'wrapper-1080' }, React.createElement('div', { 'className': 'info' }, React.createElement('h2', {}, React.createElement('span', { 'className': 'header' }, 'Artist Biography'), React.createElement('span', { 'className': 'name' }, this.props.title)), React.createElement('div', { 'className': 'intro' }, this.props.intro), React.createElement(Link, {
        'className': 'see-more',
        'to': '/artists/' + this.props.slug
    }, React.createElement('i', { 'className': 'icon-plus' }), '\n        See Full Bio\n      ')), this.props.photo && this.props.photo.boxThumb ? React.createElement('div', {
        'className': 'photo',
        'key': '457'
    }, React.createElement('span', {}, React.createElement('img', {
        'src': this.props.photo.boxThumb,
        'role': 'presentation'
    }))) : null));
}