import PropTypes from 'prop-types'
import template from './template.rt'

function QAIcon(props) {
  return template.call({ props })
}

QAIcon.propTypes = {
  className: PropTypes.string
}

export default QAIcon
