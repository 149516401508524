import { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { specialVideosStatus } from 'reduxModules/specialVideos'
import { specialVideoPlayerStateSelector as stateSelector } from 'reduxModules/specialVideos/selectors'
import { specialVideoPlayerDispatchers as dispatchers } from 'reduxModules/specialVideos/dispatchers'
import {
  SpecialVideoPropTypes,
  SpecialVideoDefaultProps
} from '../SpecialVideoDetail'

import template from './template.rt'
import './styles.css'

export class SpecialVideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.buildVideo = this.buildVideo.bind(this)
  }

  componentDidMount() {
    this.props.loadSpecialVideo()
  }

  buildVideo = obj => {
    const sources = []
    if (!obj.video.hls && !obj.video.dash){
      // This must be deleted after migration to new formats is completed
      if (obj.video.low) sources.push({ file: obj.video.low, label: '360p' })
      if (obj.video.med) sources.push({ file: obj.video.med, label: '720p' })
      if (obj.video.hi) sources.push({ file: obj.video.hi, label: '1080p' })
    }
    else {
      if (obj.video.hls) sources.push({ file: obj.video.hls, type: 'hls' })
      if (obj.video.dash) sources.push({ file: obj.video.dash, type: 'mpd' })
    }
    const video = {
      title: this.props.title,
      extra: {
        title: obj.title,
        order: obj.order || 0
      },
      tracks: obj.subtitles || [],
      image: obj.firstFrameImage.thumb,
      sources
    }
    return [video]
  }

  render() {
    if (this.props.status === specialVideosStatus.success)
      return template.call(this)
    return null
  }
}

SpecialVideoPlayer.propTypes = SpecialVideoPropTypes
SpecialVideoPlayer.defaultPropsTypes = SpecialVideoDefaultProps

export default connect(
  stateSelector,
  dispatchers
)(SpecialVideoPlayer)
