import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import AssetsGallery from '../generic/AssetsGallery';
export default function () {
    return React.createElement('div', { 'id': 'Page' }, React.createElement('h1', {}, this.props.title), React.createElement('section', { 'id': 'content' }, this.props.description ? React.createElement('div', {
        'className': 'description',
        'key': '204'
    }, '\n      ', this.props.description, '\n    ') : null, this.props.photo.medium || this.props.website ? React.createElement('div', {
        'className': 'extras' + ' ' + _.transform({ '-with-photo': this.props.photo.medium }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'key': '310'
    }, this.props.photo.medium ? React.createElement('img', {
        'src': this.props.photo.medium,
        'role': 'presentation',
        'alt': this.props.title,
        'key': '462'
    }) : null, this.props.website ? React.createElement('a', {
        'href': this.props.website,
        'target': '_blank',
        'key': '587'
    }, this.props.website) : null) : null, this.props.body ? React.createElement('div', {
        'className': 'body formatted',
        'dangerouslySetInnerHTML': this.getBody(),
        'key': '706'
    }) : null), this.props.assets && this.props.assets.length ? React.createElement(AssetsGallery, {
        'assets': this.props.assets,
        'key': '848'
    }) : null);
}