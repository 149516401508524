import * as React from 'react';
import * as _ from 'lodash';
import LearningPathCourse from '../LearningPathCourse';
export default function () {
    function repeatCourse1(props, course, courseIndex) {
        return React.createElement(LearningPathCourse, _.assign({}, {
            'key': course.id,
            'pathId': props.pathId,
            'openPreview': tutorial => props.setVideoPreview(tutorial),
            'loadLearningPathwayCourse': (bookmark, slug) => {
                props.loadLearningPathwayCourse.call(props.loadBookmarkContext, bookmark, slug, props.routePath);
            },
            'filter': props.filter,
            'isEnrolled': props.isEnrolled,
            'isBasicSubscriber': props.isBasicSubscriber
        }, Object.assign({ learningPath: props.slug }, course)));
    }
    function scopeProps2() {
        var props = this.props;
        return React.createElement.apply(this, [
            'div',
            { 'className': 'learning-path-courses' },
            _.map(this.state.shownCourses, repeatCourse1.bind(this, props)),
            this.state.nextCourses.length > 0 ? React.createElement('div', {
                'className': 'load-more-courses',
                'key': '729'
            }, React.createElement('button', {
                'className': 'load-more-courses-btn',
                'onClick': () => this.showAllCourses()
            }, React.createElement('i', { 'className': 'icon-plus load-more-courses-btn__icon' }), '\n      Load More Courses (', this.state.nextCourses.length, ')\n    ')) : null
        ]);
    }
    return scopeProps2.apply(this, []);
}