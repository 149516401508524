import React from 'react'
import { Link } from 'react-router'
import template from './template.rt'
import './styles.css'

export function FooterLinks() {
  return (
    <div className="nav menu">
      <div>
        <Link to="/customer-care/contact-us/">Contact Us</Link>
      </div>
      <div>
        <Link to="/customer-care/return-policy/">Returns</Link>
      </div>
      <div>
        <Link to="/customer-care/privacy-policy/">Privacy Policies</Link>
      </div>
      <div>
        <Link to="/customer-care/terms-of-use/">Terms of Use</Link>
      </div>
      <div>
        <Link to="/customer-care/faqs/">FAQ</Link>
      </div>
      <div>
        <Link to="/customer-care/accessibility-statement/">Accessibility</Link>
      </div>
    </div>
  )
}

const Footer = props => template.call({ props })

export default Footer
