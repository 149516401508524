import { createSelector, createStructuredSelector } from 'reselect'
import {
  isMobileSelector,
  dimensionsSelector,
  usedFreeTrialSelector,
  recaptchaSelector,
  loadingSelector
} from 'reduxModules/app/selectors'
import _ from 'lodash'

/* eslint-disable import/prefer-default-export */
export const tokenSelector = state => state.auth.token
export const isLoggedInSelector = state => !!tokenSelector(state)
export const authInfoSelector = state => state.auth
export const subscriptionSelector = state => state.auth.subscription
export const loggedInFromEmailSelector = state => state.auth.loggedInFromEmail
export const profileIsOpenSelector = state => state.user.profileIsOpen
export const authInfoReselector = createSelector(
  [
    authInfoSelector,
    isMobileSelector,
    usedFreeTrialSelector,
    profileIsOpenSelector
  ],
  (authInfo, isMobile, usedFreeTrial, profileIsOpen) => ({
    canGiftSubscription: authInfo.canGiftSubscription,
    couldGiftSubscription: authInfo.couldGiftSubscription,
    email: authInfo.email,
    firstName: authInfo.firstName,
    lastName: authInfo.lastName,
    subscription: authInfo.subscription,
    role: authInfo.role,
    usedFreeTrial,
    isMobile,
    createdByProxy: authInfo.createdByProxy,
    profileIsOpen
  })
)

export const globalPromoSelector = state => state.app.globalPromo

export const isGlobalPromoEnabledSelector = state =>
  state.app.globalPromo.codename && state.app.globalPromo.codename.length > 0

export const canGiftSubscriptionSelector = state =>
  state.auth.canGiftSubscription

export const subscriptionReselector = createStructuredSelector({
  subscription: subscriptionSelector,
  isMobile: isMobileSelector,
  dimensions: dimensionsSelector,
  canGiftSubscription: canGiftSubscriptionSelector
})

export const registerBoxSelector = createStructuredSelector({
  globalPromo: globalPromoSelector,
  isGlobalPromoEnabled: isGlobalPromoEnabledSelector,
  recaptcha: recaptchaSelector
})

const currentlyWatching = state => state.home.currentlyWatching
export const watchHistorySelector = createStructuredSelector({
  currentlyWatching,
  isLoading: loadingSelector
})
