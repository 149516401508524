// selectors
export const tagsSelector = state => state.newSearch.tags
export const resultsSelector = state => state.newSearch.results
export const statusSelector = state => state.newSearch.status
export const activeSelector = state => state.newSearch.activeCategory
export const categoriesSelector = state => state.newSearch.categories
export const loadingSelector = state => state.app.loading

// states (mapStateToProps)
export const searchStateSelector = state => ({
  loading: loadingSelector(state),
  tags: tagsSelector(state),
  results: resultsSelector(state),
  status: statusSelector(state),
  active: activeSelector(state),
  categories: categoriesSelector(state)
})
