// selectors
export const assetsSelector = state => state.assets.list

export const assetsGallerySelector = state =>
  state.assets.list.map(a => ({
    original: a.asset.xlarge,
    description: a.title ? `${a.title}\n${a.caption}` : '',
    thumbnail: a.asset.galleryThumb,
    originalAlt: a.title ? `${a.title}\n${a.caption}` : ''
  }))
