import * as React from 'react';
import * as _ from 'lodash';
import getCardIcon from '../../utils/getCardIcon';
export default function () {
    function scopeProps1() {
        var props = this.props;
        return React.createElement('div', { 'className': 'credit-card' }, props.creditCardPayment ? React.createElement('div', {
            'className': 'payment-method-body',
            'key': '142'
        }, React.createElement('div', { 'className': 'credit-card-icon' }, React.createElement('img', {
            'src': props.creditCardPayment.imageUrl ? props.creditCardPayment.imageUrl : getCardIcon(props.creditCardPayment.cardType),
            'alt': 'card'
        })), React.createElement('div', { 'className': 'payment-method-description' }, React.createElement('div', { 'className': 'card-description' }, !_.isNull(props.creditCardPayment.cardType) ? React.createElement('div', {
            'className': 'card-type',
            'key': '554'
        }, props.creditCardPayment.cardType) : null, !_.isNull(props.creditCardPayment.last4) ? React.createElement('div', {
            'className': 'card-last-four',
            'key': '721'
        }, 'ending in ', props.creditCardPayment.last4) : null, !_.isUndefined(props.creditCardPayment.expirationYear) && !_.isUndefined(props.creditCardPayment.expirationMonth) ? [
            React.createElement('div', {
                'className': 'separator',
                'key': '8951'
            }, '-'),
            React.createElement('div', {
                'className': 'card-expiration',
                'key': '8953'
            }, props.creditCardPayment.expirationMonth, '/', props.creditCardPayment.expirationYear)
        ] : null))) : null, props.paypalPayment ? React.createElement('div', {
            'className': 'paypal',
            'key': '1345'
        }, _.has(props.paypalPayment, 'imageUrl') ? React.createElement('div', {
            'className': 'credit-card-icon',
            'key': '1400'
        }, !_.isNull(props.paypalPayment.imageUrl) ? React.createElement('img', {
            'src': props.paypalPayment.imageUrl,
            'alt': 'paypal',
            'key': '1490'
        }) : null) : null, React.createElement('div', { 'className': 'paypal-description' }, '\n          Charged to\n          ', _.has(props.paypalPayment, 'payerFirstName') && _.has(props.paypalPayment, 'payerLastName') && !_.isNull(props.paypalPayment.payerFirstName) && !_.isNull(props.paypalPayment.payerLastName) ? React.createElement('b', { 'key': '1683' }, '\n            ', `${ props.paypalPayment.payerFirstName } ${ props.paypalPayment.payerLastName }`, '\n          ') : null, !_.isNull(props.paypalPayment.payerEmail) ? [
            '\n            (',
            props.paypalPayment.payerEmail,
            ')\n          '
        ] : null)) : null);
    }
    return scopeProps1.apply(this, []);
}