/* globals PROD_ENV, NODE_ENV, FACEBOOK_PIXEL_ID, dataLayer */
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { sha256 } from 'js-sha256'
import logger from 'components/utils/logger'
import {
  getUserAddresses,
  getUserPaymentMethods,
  types as userTypes,
  savePaymentMethod,
  updateUserProfile
} from 'reduxModules/users'
import { initConfirmation } from 'reduxModules/confirmation'
import { cartItemsFetch, types as cartTypes } from 'reduxModules/cart'
import getCountryCode from 'reduxModules/utils/getCountryCode'
import { push } from 'react-router-redux'
import getErrorMessage from 'reduxModules/utils/getErrorMessage'
import {
  getAdDataObjectFrom,
  getUTMDataFromCookies
} from 'reduxModules/utils/utm'
import { showNewPaymentMethodForm } from 'reduxModules/forms/paymentMethod'
import {
  dispatchGetUserBraintreeToken,
  braintreeErrorHandler
} from 'reduxModules/users/dispatchers'
import { initialize } from 'redux-form'
import _ from 'lodash'
import { bannerHandler, innerNavHandlers } from 'reduxModules/app/dispatchers'
import {
  toggleDiscountCode,
  validateDiscountCode,
  types as discountCodeTypes,
  clearDiscountCode,
  saveValidatedCode
} from 'reduxModules/discountCode'

import googleTrackConversion from 'components/utils/googleConversion'
import { getGlobalPromoCookie } from 'reduxModules/utils/promo'
import {
  getUser,
  loginFromEmail as loginFromEmailAction
} from 'reduxModules/auth'
import { scroller } from 'react-scroll'
import {
  isSubscriptionPending,
  markSubscriptionPending,
  types as subscriptionTypes,
  subscriptionPlansFetch
} from 'reduxModules/subscriptions'
import { clearRecaptchaToken } from 'reduxModules/app'
import {
  useNewAddress,
  useExistentAddress as useExistentAddressAction,
  chooseAddress,
  validateAddress,
  saveDeliveryMethod,
  saveNewAddress,
  cancelNewAddress,
  completeShippingInfo,
  selectPaymentMethod,
  toggleBillToShippingAddressAction,
  useExistentPaymentMethod as useExistentPaymentMethodAction,
  useNewPaymentMethod,
  settleOrder,
  saveBillingAddress,
  initializeShippingInfoAction,
  types,
  clearOrderAction,
  clearSelectedPayment,
  useNewBillingAddress,
  useExistentBillingAddress,
  useNewlySavedPaymentMethod,
  subscribe,
  removeCheckoutCloak,
  cloakCheckout,
  selectPayWithExistentPaypal,
  selectPayWithCreditCard,
  activateSubscribingMode,
  enableCheckoutButton,
  disableCheckoutButton
} from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

const usedFreeTrialConfirmation = {
  title: 'Warning',
  text:
    'We see that you’ve already used your free 7 day trial, If you continue, your subscription will activate immediately and you will be charged',
  options: [{ resolveValue: 'accept', text: 'Accept', class: 'confirm-btn' }]
}

const getCompleteAddress = oldAddress => ({
  ...oldAddress,
  countryCodeAlpha2: getCountryCode(oldAddress.countryName)
})

const dispatchPopulateCheckoutForm = (dispatch, formData) => {
  dispatch(initialize(formData.name, formData.body))
}

const validateCoupon = (dispatch, errorMesage, validatedCode, plan) => {
  if (validatedCode.codename)
    dispatch(validateDiscountCode(validatedCode.codename, plan)())
      .then(([_, res]) => {
        if (res.type !== discountCodeTypes.VALIDATE_DISCOUNT_CODE_SUCCESS) {
          toastError(errorMesage)
          dispatch(clearDiscountCode())
        }
      })
      .catch(payload => {
        const error = payload.errors
        toastServerError(error)
        toastError(errorMesage)
        dispatch(clearDiscountCode())
      })
}

const tokenizeCheckout = (
  dispatch,
  tokenizer,
  options,
  deviceData,
  recaptcha
) =>
  tokenizer()
    .then(payload => {
      if (options.storeMethod)
        return dispatch(
          savePaymentMethod(recaptcha)({
            paymentMethodNonce: payload.nonce,
            deviceData
          })
        )
          .then(([_, res]) => {
            if (res.type === userTypes.USERS_PAYMENT_METHOD_SAVE_SUCCESS)
              dispatch(useNewlySavedPaymentMethod(res.payload))
          })
          .catch(error => toastServerError(error.errors))
      return dispatch(selectPaymentMethod(payload))
    })
    .catch(error => braintreeErrorHandler(dispatch, error))

const tokenize = (dispatch, tokenizer, options, recaptcha) => {
  tokenizer()
    .then(payload => {
      if (options.storeMethod)
        dispatch(
          savePaymentMethod(recaptcha)({
            paymentMethodNonce: payload.nonce
          })
        ).then(([_, res]) => {
          if (res.type === userTypes.USERS_PAYMENT_METHOD_SAVE_SUCCESS) {
            dispatch(useNewlySavedPaymentMethod(res.payload))
            dispatch(push('/review_order'))
          }
        })
      else {
        dispatch(selectPaymentMethod(payload))
        dispatch(push('/review_order'))
      }
    })
    .catch(error => braintreeErrorHandler(dispatch, error))
}

const dispatchClearCheckout = dispatch => ({
  clearCheckout() {
    dispatch(clearOrderAction())
    dispatch(clearDiscountCode())
  }
})

export const dispatchInitAddresses = (
  dispatch,
  stateProps,
  address = {},
  orderIsDigital
) => {
  let selectedAddress
  let completeAddress
  let payloadToValidate

  dispatch(getUserAddresses()).then(res => {
    if (res[1].type === userTypes.USERS_ADDRESSES_FETCH_SUCCESS) {
      const addresses = res[1].payload.results
      let useExistentAddress = stateProps.useExistentAddress
      const currentBillingAddress = stateProps.selectedBillingAddress
      const previousDeliveryMethod = stateProps.selectedDeliveryMethod
      const deliveryMethods = stateProps.deliveryMethods
      const coupon = stateProps.validatedCode.codename

      if (!orderIsDigital) {
        if (_.isNumber(address)) {
          // user selected existent one
          selectedAddress = _.find(addresses, { id: address })
          useExistentAddress = true
        } else if (_.isObject(address))
          // user created a new one
          selectedAddress = address
        else if (!_.isEmpty(addresses)) {
          // no selection, pick the 1st one if able
          selectedAddress = addresses[0]
          useExistentAddress = true
        } // if no option available, nothing can be done
        else return

        if (useExistentAddress) dispatch(useExistentAddressAction())

        completeAddress = getCompleteAddress(selectedAddress)
        payloadToValidate = useExistentAddress
          ? { id: completeAddress.id }
          : completeAddress

        dispatch(validateAddress({ ...payloadToValidate, coupon }))
          .then(res => {
            if (
              res[1].type === types.VALIDATE_ADDRESS_SUCCESS &&
              res[1].payload.deliveryMethods.length > 0
            ) {
              if (_.isEmpty(previousDeliveryMethod)) {
                const defaultMethod = res[1].payload.deliveryMethods[0]
                const code = defaultMethod.code
                const price = defaultMethod.cost

                dispatch(saveDeliveryMethod({ code, price }))
              } else {
                const method = _.find(deliveryMethods, {
                  code: previousDeliveryMethod.code
                })
                dispatch(
                  saveDeliveryMethod({ code: method.code, price: method.cost })
                )
              }

              if (!useExistentAddress)
                // if using a new address, save it
                dispatch(saveNewAddress(completeAddress))

              if (
                _.isEmpty(currentBillingAddress) && // if no billing address
                !_.isNumber(currentBillingAddress)
              )
                // use current as such
                dispatch(
                  saveBillingAddress(
                    useExistentAddress ? completeAddress.id : completeAddress
                  )
                )

              dispatch(
                chooseAddress(
                  useExistentAddress ? completeAddress.id : completeAddress
                )
              )

              dispatch(removeCheckoutCloak())
            }
          })
          .catch(payload => toastServerError(payload.errors))
      } else if (
        !_.isNumber(currentBillingAddress) &&
        _.isEmpty(currentBillingAddress) &&
        !_.isEmpty(addresses)
      ) {
        dispatch(useExistentBillingAddress())
        dispatch(saveBillingAddress(addresses[0].id))
      }
    }
  })
}

export const resolveSuccessfulSettling = (dispatch, isSubscribing) => {
  if (!isSubscribing) dispatch(push('/receipt'))
  else dispatch(push('/subscription/confirmation'))

  dispatch(clearOrderAction())
  toastSuccess(
    isSubscribing ? notifications.subscribe : notifications.orderPlaced
  )
}

export const trackSubscribeSuccess = (res, userEmail) => {
  try {
    const subscription = res.payload
    googleTrackConversion('order', subscription.price)

    const pixelData = {
      content_name: subscription.plan,
      value: `${subscription.price}`,
      currency: 'USD',
      email: sha256(userEmail)
    }
    ReactPixel.trackCustom('SubscriptionConfirmation', pixelData)

    dataLayer.push({
      event: 'GTMEvent',
      eventCategory: 'User Subscribes',
      eventAction: 'Free Trial',
      eventLabel: subscription.plan,
      userId: subscription.userId,
      membershipStatus: 'free'
    })
  } catch (e) {
    logger.error(`Error tracking subscription: ${e}`, {
      parameters: { res, userEmail }
    })
  }
}

export const invokeDispatchSubscription = (
  dispatch,
  dataSubscription,
  userEmail,
  googleConversion
) => {
  if (googleConversion === undefined || googleConversion.clientId === undefined)
    dataSubscription.googleConversion = undefined
  else dataSubscription.googleConversion = googleConversion

  dispatch(subscribe()({ ...dataSubscription }))
    .then(([_, res]) => {
      if (res.type === types.SUBSCRIBE_SUCCESS) {
        resolveSuccessfulSettling(dispatch, true)
        if (PROD_ENV) trackSubscribeSuccess(res, userEmail)
      } else {
        toastServerError(
          getErrorMessage(res.payload, notifications.subscribe.error.message)
        )
        logger.error('Unsuccessful subscription attempt', {
          payload: res.payload,
          parameters: {
            invokeDispatchSubscription: {
              dispatch,
              dataSubscription,
              userEmail,
              googleConversion
            }
          }
        })
        dispatch(enableCheckoutButton())
      }
    })
    .catch(error => {
      dispatch(enableCheckoutButton())
      toastError(notifications.orderPlaced)
      logger.error('Error caught when dispatching subscribe action', {
        error,
        parameters: {
          invokeDispatchSubscription: {
            dispatch,
            dataSubscription,
            userEmail,
            googleConversion
          }
        }
      })
    })
    .finally(() => dispatch(clearRecaptchaToken()))
}

export const actuallySubscribe = (
  dispatch,
  dataSubscription,
  userEmail,
  invokeDispatchSubscription
) => {
  try {
    window.gaCallbackWasCalled = false

    const ga = ReactGA.ga()
    ga(tracker => {
      const googleConversion = {
        clientId: tracker ? tracker.get('clientId') : undefined,
        userAgent: navigator.userAgent
      }
      invokeDispatchSubscription(
        dispatch,
        dataSubscription,
        userEmail,
        googleConversion
      )
      window.gaCallbackWasCalled = true
    })

    if (!window.gaCallbackWasCalled) {
      invokeDispatchSubscription(dispatch, dataSubscription, userEmail)
      logger.warning('GA callback not called', {
        dataSubscription,
        userEmail
      })
    }
  } catch (e) {
    toastError(notifications.orderPlaced)
    logger.error(
      'An error occurred while collecting GA data to track subscription start',
      {
        error: e,
        parameters: {
          actuallySubscribe: {
            dispatch,
            dataSubscription,
            userEmail,
            invokeDispatchSubscription
          }
        }
      }
    )
    dispatch(enableCheckoutButton())
  }
}

export const handleSubscription = (
  dispatch,
  dataSubscription,
  userEmail,
  usedFreeTrial,
  actuallySubscribe
  // We are disabling the rule to avoid errors in first if statement that is expecting return in "on"
  // eslint-disable-next-line consistent-return
) => {
  dispatch(disableCheckoutButton())

  if (usedFreeTrial)
    dispatch(initConfirmation(usedFreeTrialConfirmation))
      .on('accept', () =>
        actuallySubscribe(
          dispatch,
          dataSubscription,
          userEmail,
          invokeDispatchSubscription
        )
      )
      .on('reject', () => {
        dispatch(clearDiscountCode())
        return dispatch(enableCheckoutButton())
      })
  else
    return actuallySubscribe(
      dispatch,
      dataSubscription,
      userEmail,
      invokeDispatchSubscription
    )
}

export const dispatchCreditCardSubscription = async (
  dispatch,
  payload,
  plan,
  adData,
  usedFreeTrial = false,
  userEmail,
  handleSubscription
) => {
  const dataSubscription = {
    ...payload,
    plan,
    ...adData
  }
  return handleSubscription(
    dispatch,
    dataSubscription,
    userEmail,
    usedFreeTrial,
    actuallySubscribe
  )
}

export const dispatchPayPalSubscription = async (
  dispatch,
  nonce,
  order,
  plan,
  adData,
  usedFreeTrial = false,
  userEmail,
  handleSubscription
) => {
  const dataSubscription = {
    ...order,
    plan,
    payment: { paymentMethodNonce: nonce },
    ...adData
  }
  return handleSubscription(
    dispatch,
    dataSubscription,
    userEmail,
    usedFreeTrial,
    actuallySubscribe
  )
}

export const trackOrderSettlingSuccess = (initialPayload, res) => {
  try {
    const order = res.payload
    googleTrackConversion('order', order.cost)
    ReactPixel.trackCustom('DVD/MerchSale', {
      value: order.cost,
      currency: 'USD'
    })
  } catch (error) {
    logger.error('Error tracking settled order', {
      error,
      initialPayload,
      order: res.payload
    })
  }
}

export const dispatchSettleOrder = (
  dispatch,
  payload,
  resolveSuccessfulSettling,
  trackOrderSettlingSuccess
) => {
  dispatch(settleOrder()(payload))
    .then(([_, res]) => {
      if (res.type === types.SETTLE_ORDER_SUCCESS) {
        resolveSuccessfulSettling(dispatch, false)
        if (PROD_ENV) trackOrderSettlingSuccess(payload, res)
      } else {
        toastServerError(
          getErrorMessage(res.payload, notifications.orderPlaced.error.message)
        )
        logger.error('Unsuccessful order settling', {
          initialPayload: payload,
          payload: res.payload
        })
        dispatch(enableCheckoutButton())
      }
    })
    .catch(error => {
      toastError(notifications.orderPlaced)
      logger.error('settleOrder() error', { error, initialPayload: payload })
      dispatch(enableCheckoutButton())
    })
    .finally(() => dispatch(clearRecaptchaToken()))
}

export default (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    showFraudProtectionError() {
      toastError(notifications.fraudProtection)
    },
    loadInitialAddressData(shouldRecoverAbandonedSub) {
      let orderIsDigital
      const {
        isLoggedIn,
        cart,
        selectedAddress,
        isSubscribing,
        loggedInFromEmail
      } = stateProps
      const cartNotEmpty =
        Object.prototype.hasOwnProperty.call(cart, 'cartItems') &&
        _.size(cart.cartItems) > 0

      if (loggedInFromEmail) dispatch(getUser())

      dispatch(cartItemsFetch()).then(res => {
        if (res[1].type === cartTypes.CART_ITEMS_FETCH_SUCCESS)
          if (
            res[1].payload.count === 0 &&
            !isSubscribing &&
            !shouldRecoverAbandonedSub
          ) {
            toastError(notifications.emptyCart)
            dispatch(push('/cart'))
          } else if (isLoggedIn || cartNotEmpty) {
            orderIsDigital = _.isUndefined(
              _.find(res[1].payload.results, { type: 'physical' })
            )
            dispatchInitAddresses(
              dispatch,
              stateProps,
              selectedAddress,
              orderIsDigital
            )
          }
      })
    },
    loadInitialPaymentData() {
      dispatch(getUserPaymentMethods()).then(res => {
        if (res[1].type === userTypes.USERS_PAYMENT_METHODS_FETCH_SUCCESS) {
          const cards = res[1].payload.creditCards
          const paypalAccounts = res[1].payload.paypalAccounts
          const methods = [...cards, ...paypalAccounts]
          const previousMethod = stateProps.selectedPaymentMethod
          const useExistentPaymentMethod = stateProps.useExistentPaymentMethod

          if (useExistentPaymentMethod && _.isString(previousMethod))
            dispatch(selectPaymentMethod(previousMethod))
          else if (methods.length > 0) {
            let token = ''
            let isPayPal = false
            const primary = _.find(methods, { default: true })

            dispatch(useExistentPaymentMethodAction())

            if (_.has(primary, 'token')) {
              token = primary.token
              isPayPal = !_.has(primary, 'cardType')
            } else if (_.has(methods[0], 'token')) {
              token = methods[0].token
              isPayPal = !_.has(methods[0], 'cardType')
            }

            if (!_.isEmpty(token)) dispatch(selectPaymentMethod(token))

            if (isPayPal) dispatch(selectPayWithExistentPaypal(token))
          }
          dispatch(removeCheckoutCloak())
        }
      })
    },
    createNewAddress() {
      dispatch(useNewAddress())
    },
    createNewBillingAddress() {
      dispatch(useNewBillingAddress())
    },
    selectExistentAddress() {
      const addresses = stateProps.addresses
      const coupon = stateProps.validatedCode.codename

      dispatch(useExistentAddressAction())
      dispatch(cancelNewAddress())

      if (_.size(addresses) > 0) {
        const completeAddress = getCompleteAddress(addresses[0])
        const payloadToValidate = { id: completeAddress.id, coupon }

        dispatch(validateAddress(payloadToValidate))
          .then(res => {
            if (
              res[1].type === types.VALIDATE_ADDRESS_SUCCESS &&
              res[1].payload.deliveryMethods.length > 0
            ) {
              const defaultMethod = res[1].payload.deliveryMethods[0]
              const code = defaultMethod.code
              const price = defaultMethod.cost

              dispatch(saveDeliveryMethod({ code, price }))
              dispatch(chooseAddress(completeAddress.id))
            }
          })
          .catch(payload => toastServerError(payload.errors))
      }
    },
    selectExistentBillingAddress() {
      const addresses = stateProps.addresses
      const currentBillingAddress = stateProps.selectedBillingAddress

      dispatch(useExistentBillingAddress())

      if (!_.isNumber(currentBillingAddress) && !_.isEmpty(addresses))
        dispatch(saveBillingAddress(addresses[0].id))
    },
    chooseAddress(addressId, onUserInput = false) {
      const previousDeliveryMethod = stateProps.selectedDeliveryMethod
      const deliveryMethods = stateProps.deliveryMethods
      const coupon = stateProps.validatedCode.codename

      dispatch(chooseAddress(addressId))
      dispatch(validateAddress({ id: addressId, coupon }))
        .then(res => {
          if (
            res[1].type === types.VALIDATE_ADDRESS_SUCCESS &&
            res[1].payload.deliveryMethods.length > 0
          ) {
            if (_.isEmpty(previousDeliveryMethod) || onUserInput) {
              const defaultMethod = res[1].payload.deliveryMethods[0]
              const code = defaultMethod.code
              const price = defaultMethod.cost

              dispatch(saveDeliveryMethod({ code, price }))
            } else {
              const method = _.find(deliveryMethods, {
                code: previousDeliveryMethod.code
              })
              dispatch(
                saveDeliveryMethod({ code: method.code, price: method.cost })
              )
            }

            dispatch(chooseAddress(addressId))
          }
        })
        .catch(payload => toastError(payload.errors))
    },
    chooseNewAddress(data) {
      const completeAddress = getCompleteAddress(data)
      const coupon = stateProps.validatedCode.codename
      const { isMobile } = stateProps

      dispatch(validateAddress({ ...completeAddress, coupon }))
        .then(res => {
          if (
            res[1].type === types.VALIDATE_ADDRESS_SUCCESS &&
            _.size(res[1].payload.deliveryMethods) > 0
          ) {
            const methods = _.cloneDeep(res[1].payload.deliveryMethods)
            const sortedMethods = methods.sort(
              (a, b) => parseFloat(a.cost) - parseFloat(b.cost)
            )
            const { code, cost } = sortedMethods[0]
            toastSuccess(notifications.addressValid)
            dispatch(saveNewAddress(completeAddress))
            dispatch(saveDeliveryMethod({ code, price: cost }))

            if (isMobile)
              scroller.scrollTo('deliveryMethodAnchor', {
                smooth: true,
                offset: 500
              })
          }
        })
        .catch(payload => {
          toastServerError(payload.errors)
          dispatch(cancelNewAddress())
        })
    },
    chooseDeliveryMethod(code, price) {
      dispatch(saveDeliveryMethod({ code, price }))
    },
    cancelChooseNewAddress() {
      dispatch(cancelNewAddress())
    },
    selectShippingInfo(selectedBillingAddress = {}) {
      dispatch(completeShippingInfo())
      dispatch(showNewPaymentMethodForm())
      if (!_.isEmpty(selectedBillingAddress)) dispatch(push('/review_order'))
    },
    getBraintreeClientToken: () => dispatchGetUserBraintreeToken(dispatch),
    savePaymentMethod(tokenizer, options) {
      tokenize(dispatch, tokenizer, options)
    },
    savePaymentMethodCheckout(tokenizer, options, deviceData) {
      return tokenizeCheckout(
        dispatch,
        tokenizer,
        options,
        deviceData,
        stateProps.recaptcha
      )
    },
    toggleBillToShippingAddress: (
      billToShippingAddress,
      selectedBillingAddress = {}
    ) => {
      dispatch(toggleBillToShippingAddressAction())
      if (!billToShippingAddress && selectedBillingAddress)
        dispatchPopulateCheckoutForm(dispatch, {
          name: 'billingAddressForm',
          body: selectedBillingAddress
        })
    },
    chooseExistentPaymentMethod: token => {
      const { paymentMethodOptions } = stateProps
      const method = _.find(paymentMethodOptions, { token })
      const isPayPal = !_.has(method, 'cardType')

      if (isPayPal) dispatch(selectPayWithExistentPaypal())
      else dispatch(selectPayWithCreditCard())

      dispatch(selectPaymentMethod(token))
    },
    selectExistentPaymentMethod: () =>
      dispatch(useExistentPaymentMethodAction()),
    createNewPaymentMethod: () => dispatch(useNewPaymentMethod()),
    chooseBillingAddress(addressId) {
      dispatch(saveBillingAddress(addressId))
    },
    chooseNewBillingAddress(billingAddress) {
      if (!_.isEmpty(billingAddress)) {
        const newData = {
          ...billingAddress,
          countryCodeAlpha2: getCountryCode(billingAddress.countryName)
        }
        const {
          tokenizer,
          storeMethod,
          payWithCreditCard,
          useExistentPaymentMethod
        } = stateProps

        if (payWithCreditCard && !useExistentPaymentMethod)
          tokenize(dispatch, tokenizer, { storeMethod }, stateProps.recaptcha)
        else dispatch(push('/review_order'))
        dispatch(saveBillingAddress(newData))
      }
    },
    cancelChooseBillingAddress(
      billToShippingAddress,
      selectedBillingAddress = {}
    ) {
      if (!billToShippingAddress && selectedBillingAddress)
        dispatchPopulateCheckoutForm(dispatch, {
          name: 'billingAddressForm',
          body: selectedBillingAddress
        })
    },
    initializeShippingInfo(useExistentAddress, selectedAddress = {}) {
      dispatch(initializeShippingInfoAction())
      if (!useExistentAddress && selectedAddress)
        dispatchPopulateCheckoutForm(dispatch, {
          name: 'shippingAddressForm',
          body: selectedAddress
        })
    },
    initializeBillingInfo(selectedBillingAddress = {}) {
      if (selectedBillingAddress)
        dispatchPopulateCheckoutForm(dispatch, {
          name: 'billingAddressForm',
          body: selectedBillingAddress
        })
    },
    clearNewPayment() {
      dispatch(clearSelectedPayment())
    },
    toggleDiscountCode() {
      dispatch(toggleDiscountCode())
    },
    cartChanged() {
      const { planSelected, validatedCode } = stateProps
      const planId = _.has(planSelected, 'id') ? planSelected.id : null

      toastSuccess(notifications.cartUpdated)
      validateCoupon(
        dispatch,
        notifications.couponNoLongerValid,
        validatedCode,
        planId
      )
    },
    validateCoupon() {
      const { planSelected, validatedCode } = stateProps
      const planId = _.has(planSelected, 'id') ? planSelected.id : null
      const errorMesage = 'There has been an error validating the coupon.'

      validateCoupon(dispatch, errorMesage, validatedCode, planId)
    },
    setCheckoutCloak() {
      dispatch(cloakCheckout())
    },
    removeCloakCheckout() {
      dispatch(removeCheckoutCloak())
    },
    setPendingSubscription(plan) {
      dispatch(isSubscriptionPending()).then(res => {
        if (
          res[1].payload.status === 404 ||
          (_.has(res[1], 'payload.plan') && res[1].payload.plan !== plan)
        )
          dispatch(markSubscriptionPending({ plan }))
      })
    },
    loginFromEmail(query, isLoggedIn) {
      const { token } = query // eslint-disable-line camelcase
      if (!isLoggedIn) dispatch(loginFromEmailAction(token))
    },
    getPendingSubscription() {
      dispatch(isSubscriptionPending()).then(res => {
        if (
          res[1].type === subscriptionTypes.IS_SUBSCRIPTION_PENDING_SUCCESS &&
          _.has(res[1], 'payload.plan')
        ) {
          const id = res[1].payload.plan
          dispatch(subscriptionPlansFetch()).then(res => {
            const plans = res[1].payload.results
            dispatch(activateSubscribingMode(_.find(plans, { id })))
          })
        }
      })
    },
    placeSubscription: (payload, plan, usedFreeTrial, userEmail) => {
      getUTMDataFromCookies(dispatch).then(rawUTMdata => {
        dispatchCreditCardSubscription(
          dispatch,
          payload,
          plan,
          getAdDataObjectFrom(rawUTMdata),
          usedFreeTrial,
          userEmail,
          handleSubscription
        )
      })
    },
    placePayPalSubscription: (nonce, order, plan, usedFreeTrial, userEmail) => {
      getUTMDataFromCookies(dispatch).then(rawUTMdata => {
        dispatchPayPalSubscription(
          dispatch,
          nonce,
          order,
          plan,
          getAdDataObjectFrom(rawUTMdata),
          usedFreeTrial,
          userEmail,
          handleSubscription
        )
      })
    },
    placeOrder: payload =>
      dispatchSettleOrder(
        dispatch,
        payload,
        resolveSuccessfulSettling,
        trackOrderSettlingSuccess
      ),
    placePayPalOrder: (nonce, order, deviceData) =>
      dispatchSettleOrder(
        dispatch,
        {
          ...order,
          deviceData,
          payment: { paymentMethodNonce: nonce }
        },
        resolveSuccessfulSettling,
        trackOrderSettlingSuccess
      ),
    applyPromo(plan, globalPromo) {
      getGlobalPromoCookie(dispatch).then(data => {
        const promoCookie = data.value
          ? JSON.parse(data.value)
          : { codename: '' }
        const promocode = globalPromo.codename || promoCookie.codename
        if (promocode) {
          const checkoutPromo = { ...globalPromo }
          checkoutPromo.discount = globalPromo.plans[plan.id].discountAmount
          dispatch(saveValidatedCode(checkoutPromo))
        }
      })
    },
    ...dispatchClearCheckout(dispatch),
    ...innerNavHandlers(dispatch),
    ...bannerHandler(dispatch)
  }
}

export const reviewOrderDispatchers = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    showFraudProtectionError() {
      toastError(notifications.fraudProtection)
    },
    getBraintreeClientToken: () => dispatchGetUserBraintreeToken(dispatch),
    placeOrder: payload =>
      dispatchSettleOrder(
        dispatch,
        payload,
        resolveSuccessfulSettling,
        trackOrderSettlingSuccess
      ),
    placePayPalOrder: (nonce, order, deviceData) =>
      dispatchSettleOrder(
        dispatch,
        {
          ...order,
          deviceData,
          payment: {
            paymentMethodNonce: nonce
          }
        },
        resolveSuccessfulSettling,
        trackOrderSettlingSuccess
      ),
    cancelCompleteShippingInfo(useExistentAddress, selectedAddress = {}) {
      dispatch(initializeShippingInfoAction())
      if (!useExistentAddress && selectedAddress)
        dispatchPopulateCheckoutForm(dispatch, {
          name: 'shippingAddressForm',
          body: selectedAddress
        })
      dispatch(push('/checkout'))
    },
    cancelCompleteBillingInfo(
      selectedBillingAddress = {},
      useExistentPaymentMethod,
      payWithCreditCard
    ) {
      if (selectedBillingAddress)
        dispatchPopulateCheckoutForm(dispatch, {
          name: 'billingAddressForm',
          body: selectedBillingAddress
        })
      if (!useExistentPaymentMethod && payWithCreditCard)
        dispatch(clearSelectedPayment())
      dispatch(push('/checkout'))
    },
    placeSubscription: (payload, plan, usedFreeTrial, userEmail) => {
      getUTMDataFromCookies(dispatch).then(rawUTMdata => {
        exports.dispatchCreditCardSubscription(
          dispatch,
          payload,
          plan,
          getAdDataObjectFrom(rawUTMdata),
          usedFreeTrial,
          userEmail,
          exports.handleSubscription
        )
      })
    },
    placePayPalSubscription: (nonce, order, plan, usedFreeTrial, userEmail) => {
      getUTMDataFromCookies(dispatch).then(rawUTMdata => {
        exports.dispatchPayPalSubscription(
          dispatch,
          nonce,
          order,
          plan,
          getAdDataObjectFrom(rawUTMdata),
          usedFreeTrial,
          userEmail,
          exports.handleSubscription
        )
      })
    },
    ...dispatchClearCheckout(dispatch),
    ...innerNavHandlers(dispatch)
  }
}

export const thankYouDispatchers = dispatch => ({
  goToCourses() {
    dispatch(push('/tutorials'))
  },
  goHome() {
    dispatch(push('/'))
  },
  updateInterests(data) {
    dispatch(updateUserProfile(data))
  }
})
