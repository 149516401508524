import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { tutorialsStatus } from 'reduxModules/tutorials'
import { tutorialStateSelector as stateSelector } from 'reduxModules/tutorials/selectors'
import { tutorialDispatchers as dispatchers } from 'reduxModules/tutorials/dispatchers'
import AssetGalleryPropTypes from 'components/generic/AssetsGallery/propTypes'
import LoadingComponent from 'components/generic/LoadingComponent'
import { SubscriptionPlanPropTypes } from 'components/SubscriptionPlans/SubscriptionPlanList'
import { TutorialSummaryPropTypes } from 'components/generic/CourseSummary/propTypes'
import PLANS from 'components/SubscriptionPlans/fixturePlans'

import template from './template.rt'

import './styles.css'

export const TITLE_FORMAT = /^\s*(.*) -+ (\d\d:\d\d:\d\d)\s*$/

export class TutorialDetail extends Component {
  constructor(props) {
    super(props)
    const subscriptions = PLANS.filter(
      p => p.name !== 'Apprentice' && p.name !== 'Group Learning'
    ).map((plan, index) => ({
      name: plan.name,
      price: `$${plan.price}`,
      url: plan.url,
      icon: `icon-monster-${index + 1}`,
      details: [
        plan.description1,
        plan.checkoutDescription2,
        plan.description3
      ],
      best: true,
      plan
    }))
    this.state = {
      purchaseCategories: [
        {
          name: 'subscriptions',
          order: 3,
          cta: 'Subscribe',
          selector: subscriptions,
          default: true
        }
      ],
      purchaseCategoriesSidebar: [],
      playlist: [],
      watchMode: false,
      isFloating: false
    }
    this.getLinkTo = this.getLinkTo.bind(this)
    this.routerWillLeave = this.routerWillLeave.bind(this)
  }

  componentDidMount() {
    const { loadPathInfo, location, route, router } = this.props

    if (_.has(router, 'setRouteLeaveHook'))
      router.setRouteLeaveHook(route, this.routerWillLeave)

    const fetch = async () => {
      await this.props.loadTutorialInfo()
      if (location.query && location.query.path) await loadPathInfo()
      this.props.loadTutorialUserInfo()
    }

    fetch()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.location.query ||
      !nextProps.location.query.watch ||
      nextProps.location.query.watch !== '1'
    )
      this.setState({ watchMode: false })
    else {
      if (!this.props.isAuth) {
        this.setState({ watchMode: false })
        this.props.router.push(this.props.location.pathname)
      }
      this.setState({ watchMode: true })
    }

    let purchaseCategories = this.state.purchaseCategories
    let purchaseCategoriesSidebar = this.state.purchaseCategoriesSidebar
    if (nextProps.dvdSets && !nextProps.miniLesson) {
      const dvds = nextProps.dvdSets.map(d => ({
        name: d.dvdType || 'dvd',
        price: d.price || d.regularPrice,
        regularPrice: d.regularPrice,
        url: d.url,
        icon: 'icon-dvd',
        details: [
          'Subscribers save up to 30%.',
          'Ideal for offline learning.',
          'DVD plays worldwide.'
        ],
        default: true
      }))

      if (nextProps.dvdSets !== this.props.dvdSets) {
        purchaseCategoriesSidebar = purchaseCategoriesSidebar.filter(
          e => e.name !== 'dvd'
        )

        dvds.forEach((d, index) => {
          purchaseCategoriesSidebar.push({
            // Set default in case backend sends null value
            name: d.name || 'dvd',
            order: index,
            cta: 'Add to Cart',
            selector: [d]
          })
        })
      }
    }

    if (
      nextProps.url !== this.props.url ||
      nextProps.price !== this.props.price ||
      (this.props.price && !_.find(purchaseCategories, { name: 'Stream' }))
    ) {
      const stream = {
        name: 'Stream',
        price: nextProps.price || nextProps.regularPrice,
        regularPrice: nextProps.regularPrice,
        url: nextProps.url,
        icon: 'icon-stream',
        details: [
          'Subscribers save up to 30%.',
          'Watch on your computer, phone or tablet.',
          'Lifetime access to course stream.'
        ]
      }

      purchaseCategoriesSidebar = purchaseCategoriesSidebar.filter(
        e => e.name !== 'stream'
      )
      purchaseCategoriesSidebar.push({
        name: 'stream',
        order: 2,
        cta: 'Add to Cart',
        selector: [stream]
      })
    }

    if (nextProps.owned)
      // if course is owned remove stream
      purchaseCategories = purchaseCategories
        .filter(c => c.name !== 'stream')
        .map(c => ({ ...c, default: true }))

    if (!_.isNull(nextProps.subscription))
      // if user is subscriber remove subscription option
      purchaseCategories = purchaseCategories
        .filter(c => c.name !== 'subscriptions')
        .map((c, index) => ({ ...c, default: index === 0 }))

    this.setState({ purchaseCategories, purchaseCategoriesSidebar })
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const newSlug = nextProps.params.tutorialSlug
    const slug = this.props.params.tutorialSlug
    if (newSlug !== slug) this.props.clearTutorial()
  }

  componentDidUpdate(prevProps) {
    const oldSlug = prevProps.params.tutorialSlug
    const slug = this.props.params.tutorialSlug
    if (
      oldSlug !== slug ||
      this.props.location.query.watch !== prevProps.location.query.watch
    ) {
      this.props.loadTutorialInfo()
      this.props.loadTutorialUserInfo()
    }
  }

  componentWillUnmount() {
    this.props.clearTutorial()
  }

  routerWillLeave(nextLocation) {
    const { triggerPendingScroll } = this.props

    if (nextLocation.pathname.startsWith('/tutorials')) triggerPendingScroll()
    return true
  }

  getBannerText() {
    const { owned, subscription, startedByUser } = this.props
    if (owned || !_.isNull(subscription)) {
      if (startedByUser) return 'Resume Course'
      return 'Watch Course'
    }
    return 'Subscribe to Watch'
  }

  getLinkTo() {
    const {
      id,
      owned,
      subscription,
      slug,
      confirmWatchTutorial,
      location
    } = this.props
    const initialWatchPath = `/tutorials/${slug}?watch=1`
    const hasSubscription = !_.isNull(subscription)
    const hasPathway = _.has(location.query, 'path')
    const watchPath = hasPathway
      ? `${initialWatchPath}&path=${location.query.path}&path_id=${location.query.path_id}`
      : initialWatchPath
    const monthlyVideosSeen =
      _.has(subscription, 'monthlyVideosSeen') &&
      !!subscription.monthlyVideosSeen
        ? subscription.monthlyVideosSeen
        : []
    const currentVideo = hasSubscription
      ? _.find(monthlyVideosSeen, { id })
      : null

    if (
      owned ||
      (hasSubscription && subscription.rank > 1) ||
      (hasSubscription && subscription.free) ||
      (!_.isEmpty(monthlyVideosSeen) && _.isObject(currentVideo)) ||
      this.props.hijacking
    )
      return watchPath

    if (hasSubscription && subscription.rank === 1) {
      const limit = subscription.plan === 'Basic' ? 4 : 6
      const videosSeen = monthlyVideosSeen ? monthlyVideosSeen.length : 0
      return () => {
        confirmWatchTutorial(watchPath, videosSeen, limit)
      }
    }

    return ''
  }

  render() {
    if (this.props.requestStatus === tutorialsStatus.success)
      return template.call(this)
    return <LoadingComponent />
  }
}

export const TutorialPropTypes = {
  id: PropTypes.number.isRequired,
  artist: PropTypes.shape({
    title: PropTypes.string,
    intro: PropTypes.string,
    photoThumbnail: PropTypes.objectOf(PropTypes.string).isRequired
  }),
  photo: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  materials: PropTypes.string,
  referenceMaterials: PropTypes.string,
  trailer: PropTypes.shape({
    web: PropTypes.string,
    raw: PropTypes.shape({
      low: PropTypes.string,
      high: PropTypes.string
    })
  }),
  tags: PropTypes.arrayOf(PropTypes.string),
  price: PropTypes.number.isRequired,
  regularPrice: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  dvdSet: PropTypes.shape({
    url: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    regularPrice: PropTypes.number
  }),
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      order: PropTypes.number,
      subtitles: PropTypes.arrayOf(PropTypes.object),
      firstFrameImage: PropTypes.objectOf(PropTypes.string)
    })
  ),
  owned: PropTypes.bool.isRequired,
  selectPlan: PropTypes.func,
  startedByUser: PropTypes.bool
}

export const TutorialDefaultProps = {
  id: 0,
  materials: '',
  referenceMaterials: '',
  description: '',
  url: '',
  price: 0,
  regularPrice: 0,
  dvdSet: {
    url: '',
    price: 0
  },
  owned: false,
  selectPlan: null
}

TutorialDetail.propTypes = {
  ...TutorialPropTypes,
  assets: PropTypes.arrayOf(PropTypes.shape(AssetGalleryPropTypes)).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape(SubscriptionPlanPropTypes))
    .isRequired,
  related: PropTypes.arrayOf(PropTypes.shape(TutorialSummaryPropTypes))
    .isRequired,
  subscription: PropTypes.shape({
    monthlyVideosSeen: PropTypes.arrayOf(
      PropTypes.shape(TutorialSummaryPropTypes)
    ),
    plan: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired
  }),
  loadSubscriptionPlans: PropTypes.func.isRequired,
  loadTutorialInfo: PropTypes.func.isRequired,
  loadTutorial: PropTypes.func,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
}

TutorialDetail.defaultProps = {
  ...TutorialDefaultProps,
  assets: [],
  plans: [],
  related: []
}

export default connect(stateSelector, dispatchers)(withRouter(TutorialDetail))
