/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { specialVideosStatus } from 'reduxModules/specialVideos'
import { specialVideoStateSelector as stateSelector } from 'reduxModules/specialVideos/selectors'
import { specialVideoDispatchers as dispatchers } from 'reduxModules/specialVideos/dispatchers'
import LoadingComponent from 'components/generic/LoadingComponent'
import Overview from 'components/SpecialVideos/SpecialVideoOverview'
import Chapters from 'components/SpecialVideos/SpecialVideoChapters'

import template from './template.rt'

import './styles.css'

export class SpecialVideoDetail extends Component {
  constructor(props) {
    super(props)
    this.setTabs = this.setTabs.bind(this)
    this.getLinkTo = this.getLinkTo.bind(this)
    this.state = {
      tabs: [],
      watchMode: false
    }
  }

  componentDidMount() {
    this.props.loadSpecialVideoInfo()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTabs(nextProps)

    if (
      !nextProps.location.query ||
      !nextProps.location.query.watch ||
      nextProps.location.query.watch !== '1'
    )
      this.setState({ watchMode: false })
    else {
      if (!this.props.isAuth) {
        this.setState({ watchMode: false })
        this.props.router.push(this.props.location.pathname)
      }
      this.setState({ watchMode: true })
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const newSlug = nextProps.params.specialVideoSlug
    const slug = this.props.params.specialVideoSlug
    if (newSlug !== slug) this.props.clearSpecialVideo()
  }

  componentWillUnmount() {
    this.props.clearSpecialVideo()
  }

  getLinkTo() {
    const { slug } = this.props
    const initialWatchPath = `/special-videos/${slug}?watch=1`

    return initialWatchPath
  }

  setTabs(newProps) {
    const overview = <Overview {...newProps} />
    const tabs = [{ name: 'Overview', content: overview }]

    if (newProps.chapters) {
      const chapters = <Chapters {...newProps} />
      tabs.push({ name: 'Chapters', content: chapters })
    }

    this.setState({ tabs })
  }

  render() {
    if (this.props.status === specialVideosStatus.success)
      return template.call(this)
    return <LoadingComponent />
  }
}

export const SpecialVideoPropTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  socialUrl: PropTypes.string,
  //
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
  photo: PropTypes.objectOf(PropTypes.string).isRequired,
  //
  body: PropTypes.string,
  chapters: PropTypes.string,
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  video: PropTypes.objectOf(PropTypes.string).isRequired,
  firstFrameImage: PropTypes.objectOf(PropTypes.string).isRequired
}

export const SpecialVideoDefaultProps = {
  socialUrl: '',
  description: '',
  body: '',
  chapters: ''
}

SpecialVideoDetail.propTypes = {
  ...SpecialVideoPropTypes,
  status: PropTypes.string.isRequired,
  loadSpecialVideoInfo: PropTypes.func.isRequired,
  location: PropTypes.shape({ hash: PropTypes.string }).isRequired
}

SpecialVideoDetail.defaultProps = {
  ...SpecialVideoDefaultProps
}

export default connect(stateSelector, dispatchers)(SpecialVideoDetail)
