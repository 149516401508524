import React, { useState } from 'react'

import './styles.css'

function createMarkup(string, query) {
  const regex = new RegExp(query, 'i')
  if (typeof string === 'string')
    return { __html: string.toLowerCase().replace(regex, '<b>$&</b>') }
  return undefined
}

function SearchTextSuggestion({ suggestion, query }) {
  // State
  const [isFocused, setIsFocused] = useState(false)
  return (
    <a
      aria-label={`Search: ${suggestion}`}
      role="option"
      href={`/search?q=${suggestion.toLowerCase()}`}
      className="suggestion text"
      aria-selected={isFocused}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <i className="icon-search" />
      {
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={createMarkup(suggestion, query)} />
      }
    </a>
  )
}

function SearchTextSuggestions({ suggestions, query }) {
  return suggestions.map(s => (
    <SearchTextSuggestion suggestion={s} query={query} key={s} />
  ))
}

export default SearchTextSuggestions
