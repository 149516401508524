import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function repeatCourseIndex1(courseIndex, i) {
        return React.createElement('div', {
            'className': 'course-placeholder tutorial',
            'key': 'course-' + i
        }, React.createElement('div', { 'className': 'course-placeholder__header' }), React.createElement('div', { 'className': 'course-placeholder__teacher' }), React.createElement('div', { 'className': 'course-placeholder__title-1' }), React.createElement('div', { 'className': 'course-placeholder__title-2' }));
    }
    return React.createElement('div', {
        'className': 'course-list-placeholder tutorials wrapper',
        'role': 'presentation'
    }, React.createElement('h2', { 'className': 'tutorials__title' }, this.title), React.createElement.apply(this, [
        'div',
        { 'className': 'list' },
        _.map([
            0,
            1,
            2,
            3
        ], repeatCourseIndex1.bind(this))
    ]), React.createElement('div', { 'className': 'more-btn-placeholder' }));
}