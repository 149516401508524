/* eslint-disable react/require-default-props */

import {
  SpecialVideoPropTypes,
  SpecialVideoDefaultProps
} from 'components/SpecialVideos/SpecialVideoDetail'

import template from './template.rt'

function SpecialVideoOverview(props) {
  return template.call({ props })
}

SpecialVideoOverview.propTypes = SpecialVideoPropTypes
SpecialVideoOverview.defaultProps = SpecialVideoDefaultProps

export default SpecialVideoOverview
