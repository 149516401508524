export const categories = [
  'Design',
  'Drawing',
  'Electronics',
  'Eyes, Teeth & Nails',
  'Fabrication',
  'Filmmaking',
  'Hair Work',
  'Lab Work',
  'Life Casting',
  'Makeup Effects',
  'Mask Making',
  'Mechanical',
  'Model Making',
  'Mold Making',
  'Painting',
  'Performance & Animation',
  'Puppet Making',
  'Sculpture'
]

export const difficulties = [
  { name: 'Beginner Level', value: 1 },
  { name: 'Intermediate Level', value: 2 },
  { name: 'Advanced Level', value: 3 }
]

export const ordering = [
  { name: 'Newest', value: '-order_date' },
  { name: 'Oldest', value: 'order_date' },
  { name: 'A to Z', value: 'title' },
  { name: 'Z to A', value: '-title' }
]
