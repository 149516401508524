import { naturalLanguageMapping } from '../Courses/CoursePurchase/index'

export default {
  getNaturalType(type, ordered) {
    if (type === 'webinarvariant') return `live - ${ordered.title}`

    switch (type) {
      case 'merchvariant':
        return 'merchandise'
      case 'videotutorial':
        return 'On-Demand • Stream'
      case 'plan':
        return 'subscription plan'
      case 'specialvideo':
        return 'video'
      case 'dvdset':
        return `On-Demand • ${naturalLanguageMapping(ordered.dvdType)}`
      case 'path':
        return 'Learning Pathway'
      default:
        return 'item'
    }
  },
  getTitle(type, ordered) {
    if (type === 'merchvariant')
      // a special case for merch with id=2: Behind the Scenes Documentary Vol. I
      return ordered.merch.id === 2
        ? ordered.merch.title
        : `${ordered.merch.title} - ${ordered.name}`
    if (type === 'plan') return ordered.name
    if (type === 'webinarvariant') return ordered.webinar.title
    if (type === 'dvdset') return ordered.tutorial.title
    return ordered.title
  }
}
