import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  OPEN_IMAGE_MODAL
  CLOSE_IMAGE_MODAL
  PREV_IMAGE_MODAL
  NEXT_IMAGE_MODAL
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  isOpen: false,
  imageIndex: 0
}

export const open = (state = INITIAL_STATE, action) => ({
  ...state,
  imageIndex: action.payload.imageIndex,
  isOpen: true
})

export const close = (state = INITIAL_STATE) => ({ ...state, isOpen: false })

export const prevImage = (state = INITIAL_STATE) => ({
  ...state,
  imageIndex: state.imageIndex - 1
})

export const nextImage = (state = INITIAL_STATE) => ({
  ...state,
  imageIndex: state.imageIndex + 1
})

export const HANDLERS = {
  [types.OPEN_IMAGE_MODAL]: open,
  [types.CLOSE_IMAGE_MODAL]: close,
  [types.PREV_IMAGE_MODAL]: prevImage,
  [types.NEXT_IMAGE_MODAL]: nextImage
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const openImageModal = createAction(types.OPEN_IMAGE_MODAL)
export const closeImageModal = createAction(types.CLOSE_IMAGE_MODAL)
export const prevImageModal = createAction(types.PREV_IMAGE_MODAL)
export const nextImageModal = createAction(types.NEXT_IMAGE_MODAL)
