// Conversion labels
const GOOGLE_CONVERSIONS = {
  home: {
    id: 974385371,
    color: '000066',
    code: '2W59CJ3pwAcQ2-HP0AM'
  },
  order: {
    id: 985103295,
    color: 'ffffff',
    code: 'FqfDCNG0yQgQv_fd1QM'
  }
}

// Basic method for googleTrackConversion
export default (label, value) => {
  if (typeof window.google_trackConversion !== 'undefined')
    window.google_trackConversion({
      google_conversion_id: GOOGLE_CONVERSIONS[label].id,
      google_conversion_label: GOOGLE_CONVERSIONS[label].code,
      google_conversion_color: GOOGLE_CONVERSIONS[label].color,
      google_conversion_language: 'en',
      google_conversion_format: '2',
      google_conversion_value: value,
      google_remarketing_only: false
    })
}
