import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { onSignupBoxDispatch as mergeProps } from 'reduxModules/auth/dispatchers'
import { registerBoxSelector } from 'reduxModules/auth/selectors'
import template from './template.rt'
import './styles.css'

export class RegisterBox extends Component {
  render() {
    return template.call(this)
  }
}

RegisterBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool
}

RegisterBox.defaultProps = {
  autoFocus: false
}

export const RegisterBoxWrapper = reduxForm({ form: 'signup' })(RegisterBox)
export default connect(
  registerBoxSelector,
  null,
  mergeProps
)(RegisterBoxWrapper)
