/* eslint-disable import/prefer-default-export */
import { createStructuredSelector } from 'reselect'
import { loadingSelector } from 'reduxModules/app/selectors'
import { isLoggedInSelector } from '../../auth/selectors'

// states (mapStateToProps)
export default createStructuredSelector({
  isAuth: isLoggedInSelector,
  loading: loadingSelector
})
