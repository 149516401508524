import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isSubscribedReselector as stateSelector } from 'reduxModules/users/selectors'
import { changeSubscriptionDispatchers as dispatcher } from 'reduxModules/users/dispatchers'
import template from './template.rt'
import './styles.css'

export function ChangeSubscription(props) {
  return template.call({ props })
}

ChangeSubscription.propTypes = {
  isSubscribed: PropTypes.bool.isRequired
}

export default connect(
  stateSelector,
  dispatcher
)(ChangeSubscription)
