import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  CONTEST_INITIAL
  CONTEST_SUCCESS
  CONTEST_ERROR
  CONTEST_FETCH_SUCCESS
  CONTEST_REQUEST_FAILURE
  CONTEST_PARTICIPATE_SUCCESS
  CONTEST_PARTICIPATE_REQUEST_FAILURE
  CONTEST_TOGGLE_NEWSLETTER
`)

export const contestsStatus = {
  initial: types.CONTEST_INITIAL,
  success: types.CONTEST_SUCCESS,
  error: types.CONTEST_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  detail: {
    title: '',
    slug: '',
    name: '',
    startDate: null,
    bullets: '',
    signers: 0,
    subscribers: 0,
    bannerImage: {
      default: null,
      original: null,
      thumb: null,
      normal: null
    },
    isOver: false,
    facebookShareImages: [],
    newsletter: true
  },
  status: contestsStatus.initial
}

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: { ...state.detail, ...action.payload },
  status: contestsStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: contestsStatus.error
})

export const toggleNewsletterSuccess = (state = INITIAL_STATE) => ({
  ...state,
  detail: { ...state.detail, newsletter: !state.detail.newsletter }
})

export const HANDLERS = {
  [types.CONTEST_FETCH_SUCCESS]: successDetail,
  [types.CONTEST_REQUEST_FAILURE]: failure,
  [types.CONTEST_TOGGLE_NEWSLETTER]: toggleNewsletterSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const contestFetchSuccess = createAction(types.CONTEST_FETCH_SUCCESS)
export const contestFetchFailure = createAction(types.CONTEST_REQUEST_FAILURE)
export const contestFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/contests/${slug}/`,
    { method: 'GET' },
    contestFetchSuccess,
    contestFetchFailure
  )
}

export const participateSuccess = createAction(
  types.CONTEST_PARTICIPATE_SUCCESS
)
export const participateFailure = createAction(
  types.CONTEST_PARTICIPATE_REQUEST_FAILURE
)
export const participate = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/contests/${slug}/sign_up/`,
    { method: 'POST' },
    participateSuccess,
    participateFailure
  )
}

export const toggleNewsletterAction = createAction(
  types.CONTEST_TOGGLE_NEWSLETTER
)
