import * as React from 'react';
import * as _ from 'lodash';
import Tutorial from '../../../generic/CourseSummary';
export default function () {
    function repeatSpecialVideo1(specialVideo, idx) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': specialVideo.id,
            'elementName': 'special-video-' + idx,
            'slug': specialVideo.slug,
            'summary': specialVideo.description,
            'title': specialVideo.title,
            'thumb': specialVideo.photo.boxThumb,
            'isSpecial': true
        });
    }
    function repeatWebinar2(webinar, idx) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': webinar.id,
            'elementName': 'webinar-' + idx,
            'slug': webinar.slug,
            'summary': webinar.tagline,
            'teacher': webinar.artist.title,
            'teacherSlug': webinar.artist.slug,
            'title': webinar.title,
            'thumb': webinar.listingPhoto.boxThumb || '',
            'isWebinar': true
        });
    }
    function repeatTutorial3(tutorial, idx) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + tutorial.id,
            'elementName': 'tutorial-' + idx,
            'slug': tutorial.slug,
            'summary': tutorial.description,
            'teacher': tutorial.artist.fullName,
            'teacherSlug': tutorial.artist.slug,
            'title': tutorial.title,
            'thumb': tutorial.photo.boxThumb || '',
            'new': tutorial.new
        });
    }
    return React.createElement.apply(this, [
        'section',
        { 'className': 'tutorials-list-content wrapper' },
        !_.isEmpty(this.props.specialVideos) && _.isEmpty(this.props.filters) ? _.map(this.props.specialVideos, repeatSpecialVideo1.bind(this)) : null,
        !_.isEmpty(this.props.webinars) && _.isEmpty(this.props.filters) ? _.map(this.props.webinars, repeatWebinar2.bind(this)) : null,
        _.map(this.props.tutorials, repeatTutorial3.bind(this))
    ]);
}