import { createStructuredSelector, createSelector } from 'reselect'
import { sortedUserPaymentMethodsSelector } from 'reduxModules/users/selectors'
import { recaptchaSelector } from 'reduxModules/app/selectors'

export const creditCardTypeSelector = state => state.paymentMethod.type
export const invalidFieldsSelector = state =>
  state.paymentMethod.fieldsError.details.invalidFieldKeys
export const errorMessageSelector = state =>
  state.paymentMethod.fieldsError.message
export const clientTokenSelector = state => state.paymentMethod.clientToken
export const showFormSelector = state => state.paymentMethod.showForm
export const tokenizerSelector = state => state.paymentMethod.tokenizer
export const storeMethodSelector = state => state.paymentMethod.storeMethod
export const payWithCreditCardSelector = state =>
  state.checkout.payWithCreditCard
export const paymentMethodsCountSelector = createSelector(
  sortedUserPaymentMethodsSelector,
  methods => (methods ? methods.length : 0)
)

export default createStructuredSelector({
  type: creditCardTypeSelector,
  invalidFields: invalidFieldsSelector,
  errorMessage: errorMessageSelector,
  clientToken: clientTokenSelector,
  showForm: showFormSelector,
  storeMethod: storeMethodSelector,
  payWithCreditCard: payWithCreditCardSelector
})

export const paymentMethodSelector = createStructuredSelector({
  clientToken: clientTokenSelector,
  showForm: showFormSelector,
  tokenizer: tokenizerSelector,
  paymentMethodsCount: paymentMethodsCountSelector,
  recaptcha: recaptchaSelector
})
