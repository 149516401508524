/* eslint-disable import/prefer-default-export */
/* globals PROD_ENV dataLayer */
import logger from 'components/utils/logger'
import { activateSubscribingMode } from 'reduxModules/checkout'
import {
  types,
  closeBanner,
  openBanner,
  subscriptionTransactionsFetch,
  giftSubscription,
  giftSubscriptionDetail,
  gitfSubscriptionRedeem,
  subscriptionPlansLanguageFetch,
  subscriptionPlansLanguageDefault,
  bannerIsReady
} from 'reduxModules/subscriptions'
import { hideNavigation, showNavigation } from 'reduxModules/app'
import {
  cancelFreeTrial,
  revokeGiftPermission,
  revokeFreeTrialPermission,
  getUser
} from 'reduxModules/auth'
import { dispatchLogin } from 'reduxModules/auth/dispatchers'
import { reset } from 'redux-form'
import { push } from 'react-router-redux'
import { initConfirmation } from 'reduxModules/confirmation'
import authTypes from 'reduxModules/auth/types'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

export const subscriptionPlansDispatchers = dispatch => ({
  selectPlan(plan) {
    dataLayer.push({
      event: 'GTMEvent',
      eventCategory: 'Subscriptions',
      eventAction: 'User Selects Plan',
      eventLabel: plan.name
    })
    dispatch(activateSubscribingMode(plan))
    dispatch(push('/checkout'))
  },
  closeBannerSubscription() {
    dispatch(closeBanner())
  },
  openBannerSubscription() {
    dispatch(openBanner())
  }
})

export const subscriptionTransactionsDispatchers = dispatch => ({
  loadSubscriptionTransactions: id =>
    dispatch(subscriptionTransactionsFetch(id))
})

export const giftSubscriptionDispatchers = (
  stateProps,
  dispatchProps,
  ownProps
) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ownProps,
    onSubmit: data => {
      const dispatchGiftSubscription = data => {
        dispatch(giftSubscription(data))
          .then(([_, res]) => {
            if (res.type === types.GIFT_SUBSCRIPTION_SUCCESS) {
              dispatch(reset('gift-subscription'))
              toastSuccess(notifications.giftSubscription)
              dispatch(revokeGiftPermission())
              dispatch(push('/account'))
            } else toastError(notifications.giftSubscription)
          })
          .catch(payload => {
            const error = payload.errors
            toastServerError(error)
          })
      }
      if (!stateProps.usedFreeTrial) {
        const confirmation = {
          title: 'Warning',
          options: [
            { resolveValue: 'accept', text: 'Accept', class: 'confirm-btn' }
          ],
          text:
            'You must be a paid Subscriber to gift a subscription. Do you want to end your free trial now and pay for your subscription?'
        }

        dispatch(initConfirmation(confirmation)).on('accept', () => {
          dispatch(cancelFreeTrial())
            .then(([_, res]) => {
              if (res.type === authTypes.CANCEL_FREE_TRIAL_SUCCESS) {
                toastSuccess(notifications.freeTrialCancelled)
                dispatch(revokeFreeTrialPermission())
                dispatchGiftSubscription(data)
              } else toastError(notifications.freeTrialCancelled)
            })
            .catch(payload => {
              const error = payload.errors
              toastServerError(error)
            })
        })
      } else dispatchGiftSubscription(data)
    }
  }
}

export const giftSubscriptionRedeemDispatcher = dispatch => ({
  getDetails: token => {
    dispatch(giftSubscriptionDetail(token)).catch(payload => {
      const error = payload.errors
      toastServerError(error)
    })
  },
  hideGlobalNav() {
    dispatch(hideNavigation())
  },
  showGlobalNav() {
    dispatch(showNavigation())
  },
  onSubmit(data, dispatch, props) {
    const payload = {
      password: data.password1
    }
    dispatch(gitfSubscriptionRedeem(props.params.token, payload))
      .then(([_, res]) => {
        if (res.type === types.REDEEM_GIFT_SUBSCRIPTION_SUCCESS) {
          const loginPayload = {
            email: props.email,
            password: data.password1,
            recaptcha: props.recaptcha
          }
          dispatch(dispatchLogin(dispatch, loginPayload, props))
          toastSuccess(notifications.redeemedGiftedSubscription)
          setTimeout(() => {
            dispatch(push('/'))
            dispatch(getUser())
          }, 2000)
        } else {
          const error = res?.payload?.value?.error
          toastError(notifications.redeemedGiftedSubscription)
          toastServerError(error)
        }
      })
      .catch(err => {
        if (PROD_ENV) logger.error(`Redeem gift issue: ${err}`)
      })
  }
})

export const subscriptionPlansDispatcher = dispatch => ({
  getSubscriptionPlanLanguage() {
    dispatch(subscriptionPlansLanguageFetch())
      .then(([_, res]) => {
        if (res.type !== types.SUBSCRIPTION_PLANS_LANGUAGE_SUCCESS)
          dispatch(subscriptionPlansLanguageDefault())
        else {
          const { subscriptionBannerFreeTrialOnly } = res.payload
          dispatch(getUser()).then(([_, res]) => {
            if (res.type === authTypes.AUTH_FETCH_USER_SUCCESS) {
              const isSubscriber = res?.payload?.subscription?.id
              const { usedFreeTrial } = res.payload

              if (subscriptionBannerFreeTrialOnly) {
                // Check if user used their free trial and have no sub to show banner
                if (!isSubscriber && !usedFreeTrial) dispatch(openBanner())
                // If flag is false, and the user is not a subscriber, display banner
              } else if (!isSubscriber) dispatch(openBanner())
            }
            // Anon user is always eligible for free trial and has no subscription, always display banner
            else dispatch(openBanner())

            dispatch(bannerIsReady())
          })
        }
      })
      .catch(() => {
        dispatch(subscriptionPlansLanguageDefault())
      })
  }
})
