import { createAction } from 'redux-actions'
import { createTypes } from 'reduxsauce'

export const types = createTypes(`
  INIT_CONFIRMATION
  RESOLVE_CONFIRMATION
`)

export const INIT_CONFIRMATION = 'INIT_CONFIRMATION'
export const initConfirmation = createAction(
  INIT_CONFIRMATION,
  options => options
)

export const RESOLVE_CONFIRMATION = 'RESOLVE_CONFIRMATION'
export const resolveConfirmation = createAction(
  RESOLVE_CONFIRMATION,
  ({ resolution, feedback }) => ({ resolution, feedback })
)
