import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function scopePropsIsPaypalCardType1() {
        var props = this.props;
        var isPaypal = _.isEmpty(props.cardType);
        var cardType = props.cardType;
        return React.createElement('div', { 'className': 'payment-method' }, React.createElement('div', { 'className': 'payment-method-container' }, React.createElement('div', { 'className': 'mobile-container' }, React.createElement('div', { 'className': 'payment-method-body' }, React.createElement('div', { 'className': 'credit-card-icon' }, React.createElement('img', {
            'src': props.imageUrl,
            'alt': cardType
        })), React.createElement('div', { 'className': 'payment-method-description' }, React.createElement('div', { 'className': 'card-description' }, !isPaypal ? React.createElement('div', {
            'className': 'payment-method-name card-type',
            'key': '525'
        }, cardType) : null, isPaypal && _.isString(props.email) ? React.createElement('div', {
            'className': 'payment-method-name paypal-name',
            'key': '629'
        }, props.email.slice(0, 30), props.email.length > 30 ? React.createElement('span', { 'key': '746' }, '...') : null) : null, !isPaypal ? React.createElement('div', {
            'className': 'card-last-four',
            'key': '825'
        }, 'ending in ', this.props.last4) : null, !props.isMobile && !isPaypal ? React.createElement('div', {
            'className': 'separator',
            'key': '932'
        }, '-') : null, !isPaypal ? React.createElement('div', {
            'className': 'card-expiration',
            'key': '1024'
        }, props.expirationMonth, '/', props.expirationYear) : null))), React.createElement('div', { 'className': 'primary' }, React.createElement('div', {
            'className': _.transform({ 'is-primary': props.default }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('button', { 'onClick': props.default ? null : () => props.isSubscribed ? props.setDefaultWithConfirmation() : props.setDefault() }, this.props.default ? 'primary' : 'make primary')))), React.createElement('div', { 'className': 'remove' }, React.createElement('div', { 'className': 'remove-btn-container' }, !props.default ? React.createElement('div', {
            'onClick': () => props.remove(),
            'className': 'cross',
            'key': '1697'
        }, React.createElement('i', { 'className': 'icon-x' })) : null))));
    }
    return scopePropsIsPaypalCardType1.apply(this, []);
}