import * as React from 'react';
import * as _ from 'lodash';
import PaymentMethodForm from '../../generic/PaymentMethodForm';
import PaymentMethodList from './PaymentMethodList';
import { CaptchaNotice } from '../../generic/CaptchaNotice';
import CaptchaStandalone from '../../CaptchaStandalone/_lazy';
import getCardIcon from '../../utils/getCardIcon';
export default function () {
    function onClick1() {
        this.props.hideNewPaymentMethodForm();
    }
    return React.createElement('div', {
        'id': 'PaymentMethods',
        'className': 'account-section'
    }, React.createElement('h3', { 'className': 'account-section__title' }, '\n    payment methods\n  '), this.props.paymentMethodsCount !== 0 ? React.createElement('div', {
        'className': 'payment-methods-content',
        'key': '527'
    }, React.createElement(PaymentMethodList, {})) : null, this.props.paymentMethodsCount === 0 ? React.createElement('p', {
        'className': 'payment-methods-empty-content',
        'key': '647'
    }, 'You don\'t have any credit cards.') : null, !this.props.showForm ? React.createElement('div', {
        'className': 'add-payment-method',
        'key': '772'
    }, React.createElement('div', { 'className': 'accepted-cards' }, React.createElement('img', {
        'src': getCardIcon('visa'),
        'alt': 'visa'
    }), React.createElement('img', {
        'src': getCardIcon('master-card'),
        'alt': 'mastercard'
    }), React.createElement('img', {
        'src': getCardIcon('american-express'),
        'alt': 'amex'
    })), React.createElement('button', {
        'className': 'add-payment-button',
        'onClick': () => this.props.showNewPaymentMethodForm()
    }, React.createElement('i', { 'className': 'icon-plus' }), '\n      add credit card\n    ')) : null, this.props.showForm ? React.createElement('div', {
        'className': 'create-payment-methods',
        'key': '1231'
    }, React.createElement('h3', { 'className': 'account-section__title' }, 'New Credit Card'), React.createElement(PaymentMethodForm, { 'authorization': this.props.clientToken }, React.createElement('div', { 'className': 'captcha-container' }, React.createElement(CaptchaStandalone, {})), React.createElement('div', { 'className': 'buttons-subcontainer' }, this.props.paymentMethodsCount > 0 ? React.createElement('button', {
        'type': 'button',
        'className': 'cancel-payment-btn',
        'onClick': onClick1.bind(this),
        'key': '1562'
    }, 'cancel') : null, React.createElement('button', {
        'type': 'submit',
        'className': 'submit-btn',
        'onClick': () => this.saveCreditCard()
    }, 'Save Credit Card\n        ')), React.createElement(CaptchaNotice, {}))) : null);
}