import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import { members } from './members';
import TeamMember from '../generic/ProfileSummary';
export default function () {
    function repeatMember1(group, groupIndex, member, memberIndex) {
        return React.createElement(TeamMember, {
            'className': 'member',
            'key': 'member-' + memberIndex,
            'name': member.name,
            'photo': member.portraitImage,
            'slug': member.slug,
            'tagline': member.position,
            'variant': 'teamMember'
        });
    }
    function repeatGroup2(group, groupIndex) {
        return React.createElement('div', {
            'key': groupIndex,
            'className': 'group'
        }, React.createElement('h2', {}, group.groupName), React.createElement.apply(this, [
            'div',
            { 'className': 'group-members' },
            _.map(group.groupMembers, repeatMember1.bind(this, group, groupIndex))
        ]));
    }
    return React.createElement('div', { 'id': 'Team' }, React.createElement(Helmet, {}, React.createElement('title', {}, 'The Team | Stan Winston School of Character Arts'), React.createElement('meta', {
        'name': 'description',
        'content': 'Find the best team in the field of Special Effects Character Creation, Character Design & Makeup FX'
    }), React.createElement('meta', {
        'property': 'og:title',
        'content': 'The Team | Stan Winston School of Character Arts'
    }), React.createElement('meta', {
        'property': 'og:description',
        'content': 'Find the best team in the field of Special Effects Character Creation, Character Design & Makeup FX'
    })), React.createElement('div', { 'className': 'banner' }), React.createElement.apply(this, [
        'div',
        { 'className': 'team-groups wrapper' },
        React.createElement('h1', {}, 'It\'s all about collaboration'),
        _.map(members, repeatGroup2.bind(this))
    ]));
}