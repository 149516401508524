import { Component } from 'react'
import { connect } from 'react-redux'
import dispatchers from 'reduxModules/subscribeDialogCTA/dispatchers'
import { promotionSelector } from 'reduxModules/app/selectors'
import template from './template.rt'
import './styles.css'

export class SubscribeDialogCTA extends Component {
  render() {
    return template.call(this)
  }
}

export default connect(
  promotionSelector,
  dispatchers
)(SubscribeDialogCTA)
