import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'

import { tutorialsStatus } from 'reduxModules/tutorials'
import { tutorialPlayerStateSelector as stateSelector } from 'reduxModules/tutorials/selectors'
import { tutorialPlayerDispatchers as dispatchers } from 'reduxModules/tutorials/dispatchers'

import { TutorialSummaryPropTypes } from 'components/generic/CourseSummary/propTypes'
import { TutorialPropTypes, TutorialDefaultProps } from '../TutorialDetail'

import NewVideoPlayer from '../../NewVideoPlayer'

import './styles.css'
import PlayerHeader from './PlayerHeader'

const TITLE_FORMAT = /^\s*(.*) -+ (\d\d:\d\d:\d\d)\s*$/

export function TutorialPlayer(props) {
  const {
    tutorial,
    artist,
    learningPath,
    isMobile,
    hijacking,
    savePlayerId,
    location,
    relevant,
    slug,
    status,
    isAuth,
    shouldOnlyShowRelevants,
    subscription,
    forbiddenVideo,
    router,
    userDetailStatus
  } = props

  const [playlist, setPlaylist] = useState([])
  const isBasicSubscriber = subscription?.plan === 'Basic'
  const buildChapter = (obj, chaptered = true) => {
    let name
    let time
    if (chaptered)
      try {
        ;[, name, time] = TITLE_FORMAT.exec(obj.title)
      } catch (e) {
        // We will use just the name in the meantime
        name = obj.title
      }
    else [name, time] = [obj.title, '']

    const sources = []
    if (!obj?.video?.hls && !obj?.video?.dash) {
      // This must be deleted after migration to new formats is completed
      if (obj?.video?.low) sources.push({ file: obj.video.low, label: '360p' })
      if (obj?.video?.med) sources.push({ file: obj.video.med, label: '720p' })
      if (obj?.video?.hi) sources.push({ file: obj.video.hi, label: '1080p' })
    } else {
      if (obj?.video?.hls) sources.push({ file: obj.video.hls, type: 'hls' })
      if (obj?.video?.dash) sources.push({ file: obj.video.dash, type: 'mpd' })
    }

    let tracks = []
    if (obj.subtitles) tracks = obj.subtitles
    if (obj.vttFile)
      tracks = [...tracks, { file: obj.vttFile, kind: 'thumbnails' }]

    const video = {
      title: obj.title,
      durationH: obj.durationH,
      durationM: obj.durationM,
      durationS: obj.durationS,
      extra: {
        id: obj.id,
        name,
        time,
        order: obj.order || 0
      },
      tracks,
      image: obj.firstFrameImage.thumb,
      sources,
      currentPosition: obj.currentPosition,
      completed: obj.completed
    }
    return video
  }

  const savePlaylist = (video, shouldOnlyShowRelevants) => {
    const chaptersToBuild =
      shouldOnlyShowRelevants && video?.relevantChapters.length > 0
        ? video.relevantChapters
        : video.chapters
    const playlist = video.chaptered
      ? chaptersToBuild.map(chapter => buildChapter(chapter))
      : [buildChapter(video, false)]
    setPlaylist(playlist)
  }

  const prepareVideo = () => {
    // TODO: We need to differentiate limited subscriptions views
    if (isAuth && location.query.watch === '1')
      if (!tutorial.owned && _.isNull(subscription)) forbiddenVideo(tutorial)
      else savePlaylist(tutorial, shouldOnlyShowRelevants)
  }

  useEffect(() => {
    if (userDetailStatus === tutorialsStatus.userDetailSuccess) prepareVideo()
  }, [
    slug,
    userDetailStatus,
    tutorial?.id,
    location?.query?.watch,
    shouldOnlyShowRelevants
  ])

  return status === tutorialsStatus.success ? (
    <article id="TutorialPlayer">
      <PlayerHeader
        title={tutorial?.title}
        artist={artist}
        learningPath={learningPath}
        location={location}
        to={`/tutorials/${slug}`}
      />
      <section>
        {userDetailStatus === tutorialsStatus.userDetailSuccess ? (
          <NewVideoPlayer
            chaptered
            chapters={playlist}
            tutorial={tutorial}
            videoId={tutorial.id}
            isMobile={isMobile}
            relevantChapters={relevant}
            disableStats={hijacking}
            savePlayerId={savePlayerId}
            location={location}
            router={router}
            slug={slug}
            isBasicSubscriber={isBasicSubscriber}
          />
        ) : null}
      </section>
    </article>
  ) : null
}

TutorialPlayer.propTypes = {
  tutorial: PropTypes.shape({
    ...TutorialPropTypes,
    id: PropTypes.number,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    url: PropTypes.string,
    owned: PropTypes.bool
  }),
  loadTutorial: PropTypes.func.isRequired,
  subscription: PropTypes.oneOfType([
    PropTypes.shape({
      monthlyVideosSeen: PropTypes.arrayOf(
        PropTypes.shape(TutorialSummaryPropTypes)
      ),
      plan: PropTypes.string.isRequired,
      rank: PropTypes.number.isRequired
    }),
    PropTypes.number
  ]),
  forbiddenVideo: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

TutorialPlayer.defaultProps = {
  tutorial: { ...TutorialDefaultProps },
  subscription: null
}

export default connect(stateSelector, dispatchers)(TutorialPlayer)
