import _ from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import template from './template.rt'
import './styles.css'

export class CartModal extends Component {
  constructor(props) {
    super(props)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.timeout = null
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.timeout = setTimeout(() => this.props.closeItemModal(), 10000)
  }

  componentWillUnmount() {
    // Cover case when you go to checkout
    if (!_.isEmpty(this.props.itemAdded)) this.props.closeItemModal()
    else clearTimeout(this.timeout)
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target))
      this.props.closeItemModal()
  }

  render() {
    return template.call(this)
  }
}

CartModal.propTypes = {
  closeItemModal: PropTypes.func.isRequired,
  itemAdded: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    orderedModel: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    thumbnail: PropTypes.shape({
      cartThumb: PropTypes.string.isRequired
    }).isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
}

export default CartModal
