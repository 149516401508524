/* global API_ROOT */
/* eslint-disable import/prefer-default-export */
import { push } from 'react-router-redux'
import { activateSubscribingMode } from 'reduxModules/checkout'
import { initConfirmation } from 'reduxModules/confirmation'
import { learningPathFetch } from 'reduxModules/learningPaths/'
import {
  setElementToScroll,
  setPendingScroll,
  unsetPendingScroll
} from 'reduxModules/app'
import { scroller } from 'react-scroll'
import _ from 'lodash'
import { savePlayerId } from 'reduxModules/player'
import {
  tutorialsForSearchFetch,
  tutorialFetch,
  clearTutorial,
  clearTutorialList,
  togglePreviewVideo,
  setFilterList,
  loadFilterList,
  clearFilterList,
  toggleFilters,
  types,
  getBookmarkedTutorials,
  deleteBookmark,
  clearSearchList,
  tutorialUserDataFetch,
  tutorialTrailerFetch
} from './'
import { webinarsFetch, clearWebinarList } from '../webinars'
import { assetsFetch } from '../assets'
import { bannersFetch } from '../banners'
import { subscriptionPlansFetch } from '../subscriptions'
import { specialVideosFetch, clearSpecialVideoList } from '../specialVideos'
import notifications, {
  toastError,
  toastSuccess
} from '../../../global/notifications'

export const dispatchRemoveBookmark = dispatch => ({
  removeBookmark(e, pk) {
    e.stopPropagation()
    const confirmation = {
      title: 'Warning',
      text: 'Are you sure you want to remove this bookmark?',
      options: [
        { resolveValue: 'remove', text: 'Remove', class: 'confirm-btn' }
      ]
    }
    dispatch(initConfirmation(confirmation)).on('remove', () =>
      dispatch(deleteBookmark(pk)).then(([_, res]) => {
        if (res.type === types.TUTORIAL_DELETE_BOOKMARK_SUCCESS)
          toastSuccess(notifications.bookmarkRemoved)
        else toastError(notifications.bookmarkRemoved)
      })
    )
  }
})

export const tutorialsMergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...ownProps,
    loadTutorials(filters) {
      dispatch(tutorialsForSearchFetch(filters)()).then(([_, res]) => {
        if (res.type === types.TUTORIALS_FOR_SEARCH_FETCH_SUCCESS) {
          const { tutorials } = stateProps.pendingBackScroll
          const { isPending, element } = tutorials
          if (isPending) {
            scroller.scrollTo(element.name, {
              smooth: false,
              offset: -500
            })
            dispatch(unsetPendingScroll({ group: 'tutorials' }))
          }
        }
      })
    },
    loadWebinars() {
      dispatch(webinarsFetch()())
    },
    loadBanners() {
      dispatch(bannersFetch({ video_banner: true })())
    },
    setFilters(filters) {
      dispatch(loadFilterList())
      dispatch(setFilterList(filters))
    },
    clearTutorialList() {
      dispatch(clearTutorialList())
    },
    clearWebinarList() {
      dispatch(clearWebinarList())
    },
    clearFilters() {
      dispatch(clearFilterList())
    },
    clearSearch() {
      dispatch(clearSearchList())
    },
    toggleFilters() {
      dispatch(toggleFilters())
    },
    loadSpecialVideos() {
      dispatch(specialVideosFetch()())
    },
    clearSpecialVideoList() {
      dispatch(clearSpecialVideoList())
    },
    setScrollingBackElement(payload) {
      dispatch(setElementToScroll(payload))
    }
  }
}

export const tutorialDispatchers = (dispatch, props) => ({
  loadTutorialInfo() {
    const query = props.location.query
    dispatch(tutorialFetch(props.params.tutorialSlug, query.path)()).then(
      res => {
        if (res[1]?.payload?.status === 404) dispatch(push('/404'))
        else if (res[1]?.payload?.id) {
          dispatch(assetsFetch('videotutorial', res[1].payload.id, 50)())
          dispatch(tutorialTrailerFetch(props.params.tutorialSlug)())
        }
      }
    )
  },
  loadTutorialTrailer() {
    const query = props.location.query
    dispatch(tutorialUserDataFetch(props.params.tutorialSlug, query.path)())
  },
  loadTutorialUserInfo() {
    const query = props.location.query
    dispatch(
      tutorialUserDataFetch(props.params.tutorialSlug, query.path)()
    ).then(([_, res]) => {
      if (
        res?.type === types.TUTORIAL_USER_DATA_REQUEST_FAILURE &&
        res?.payload?.status !== 401
      )
        toastError(notifications.tutorialUserInfo)
    })
  },
  loadPathInfo() {
    const query = props.location.query
    dispatch(learningPathFetch(query.path)())
  },
  confirmWatchTutorial(next, watchedCourses, limit) {
    const confirmation = {
      title: 'Warning',
      options: [
        { resolveValue: 'accept', text: 'Accept', class: 'confirm-btn' }
      ]
    }
    if (watchedCourses < limit) {
      confirmation.text = `You have activated ${watchedCourses} of ${limit} videos in this subscription month. Are you sure you want to use one of your credits to watch this video?`
      dispatch(initConfirmation(confirmation)).on('accept', () =>
        dispatch(push(next))
      )
    } else {
      confirmation.text =
        'Sorry, you have used all your subscription credits for this month. Try upgrading your subscription plan.'
      dispatch(initConfirmation({ ...confirmation, showReject: false }))
    }
  },
  clearTutorial() {
    dispatch(clearTutorial())
  },
  loadSubscriptionPlans() {
    dispatch(subscriptionPlansFetch())
  },
  loadTutorial(slug) {
    const query = props.location.query
    dispatch(tutorialFetch(slug, query.path)())
  },
  toggleVideoPreview() {
    dispatch(togglePreviewVideo())
  },
  selectPlan(plan) {
    dispatch(activateSubscribingMode(plan))
    dispatch(push('/checkout'))
  },
  triggerPendingScroll() {
    dispatch(setPendingScroll({ group: 'tutorials' }))
  },
  forbiddenVideo() {
    // Remove the push action when Player was moved to Details scene
    // dispatch(push(`/tutorials/${video.slug}/?watch=0`))
    toastError(notifications.forbiddenToWatch)
  }
})

export const tutorialPlayerDispatchers = (dispatch, props) => ({
  loadTutorial(slug) {
    const query = props.location.query
    dispatch(tutorialFetch(slug, query.path)())
  },
  forbiddenVideo(video) {
    dispatch(push(`/tutorials/${video.slug}`))
    toastError(notifications.forbiddenToWatch)
  },
  savePlayerId(id) {
    dispatch(savePlayerId(id))
  }
})

export const bookmarkedTutorialsDispatchers = dispatch => ({
  fetchBookmarkedTutorials: () => dispatch(getBookmarkedTutorials()()),
  loadBookmark: (tutorialSlug, bookmark, routePath) => {
    dispatch(
      push(
        `/tutorials/${tutorialSlug}?watch=1&referer=${routePath}&chapter=${bookmark?.chapterOrder}&time=${bookmark?.time}`
      )
    )
  },
  ...dispatchRemoveBookmark(dispatch)
})
