import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  SHOW_MENU_ITEM
  HIDE_MENU_ITEM
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  open: null
}
export const show = (state = INITIAL_STATE, action) => ({
  ...state,
  open: action.payload.title
})

export const hide = (state = INITIAL_STATE) => ({ ...state, open: null })

export const HANDLERS = {
  [types.SHOW_MENU_ITEM]: show,
  [types.HIDE_MENU_ITEM]: hide
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const showMenuItem = createAction(types.SHOW_MENU_ITEM)
export const hideMenuItem = createAction(types.HIDE_MENU_ITEM)
