import * as React from 'react';
import * as _ from 'lodash';
import utils from '../utils/cartItemUtils';
import PaymentMethodDetails from '../generic/PaymentMethodDetails';
import AddressDetails from '../generic/AddressDetails';
import keyUpHandler from '../utils/keyUpHandler';
import math from '../../redux/modules/utils/math';
export default function () {
    function onClick1(props, isSubscribing, plan) {
        props.cancelCompleteShippingInfo(props.useExistentAddress, props.order.shippingAddress);
    }
    function onKeyUp2(props, isSubscribing, plan, event) {
        keyUpHandler(event, props.cancelCompleteShippingInfo, null, null, [
            props.useExistentAddress,
            props.order.shippingAddress
        ]);
    }
    function scopeAddress3(props, isSubscribing, plan) {
        var address = props.order.shippingAddress;
        return React.createElement('div', {
            'className': 'body',
            'key': '1650'
        }, React.createElement(AddressDetails, address), React.createElement('div', { 'className': 'delivery-date' }, React.createElement('span', {}, 'Delivery details: '), props.deliveryMethodDetails.name, ' ', props.deliveryMethodDetails.description));
    }
    function onClick4(props, isSubscribing, plan) {
        props.cancelCompleteBillingInfo(props.order.billingAddress, props.useExistentPaymentMethod, props.payWithCreditCard);
    }
    function onKeyUp5(props, isSubscribing, plan, event) {
        keyUpHandler(event, props.cancelCompleteShippingInfo, null, null, [
            props.useExistentAddress,
            props.order.shippingAddress
        ]);
    }
    function scopeAddressCreditCardPayment6(props, isSubscribing, plan) {
        var address = props.order.billingAddress;
        var creditCardPayment = props.paymentMethodDetails;
        return React.createElement('div', { 'className': 'body' }, props.payWithCreditCard && props.paymentMethodDetails ? React.createElement(PaymentMethodDetails, _.assign({}, { 'key': '3188' }, { creditCardPayment })) : null, !this.props.payWithCreditCard ? React.createElement('div', {
            'className': 'paypal-method-body',
            'key': '3325'
        }, React.createElement('div', { 'className': 'paypal-icon' }, React.createElement('img', {
            'src': 'https://assets.braintreegateway.com/payment_method_logo/paypal.png',
            'alt': 'paypal'
        })), React.createElement('div', { 'className': 'payment-method-description' }, !_.has(props.paymentMethodDetails, 'email') ? React.createElement('div', {
            'className': 'paypal',
            'key': '3690'
        }, 'Pay with PayPal') : null, _.has(props.paymentMethodDetails, 'email') ? React.createElement('div', {
            'className': 'paypal',
            'key': '3812'
        }, props.paymentMethodDetails.email) : null)) : null, !_.isEmpty(address) ? [
            this.props.payWithCreditCard ? React.createElement('div', {
                'className': 'billing-address-title',
                'key': '40021'
            }, '\n                            Billing address\n                        ') : null,
            React.createElement(AddressDetails, _.assign({}, { 'key': '40023' }, address))
        ] : null);
    }
    function repeatItem7(props, isSubscribing, plan, items, item, itemIndex) {
        return React.createElement('div', {
            'className': 'item',
            'key': item.id
        }, React.createElement('div', { 'className': 'thumbnail' }, item.type !== 'plan' ? React.createElement('img', {
            'alt': 'Item image',
            'src': item.thumbnail.cartThumb,
            'key': '5412'
        }) : null, item.type === 'physical' ? React.createElement('div', {
            'className': 'quantity',
            'key': '5650'
        }, item.quantity) : null), React.createElement('div', { 'className': 'column' }, React.createElement('div', { 'className': 'description' }, React.createElement('div', { 'className': 'type' }, React.createElement('span', {}, utils.getNaturalType(item.orderedModel, item.ordered))), React.createElement('div', { 'className': 'title' }, React.createElement('span', {}, utils.getTitle(item.orderedModel, item.ordered)))), React.createElement('div', { 'className': 'price' }, props.isSubscribed && _.isNumber(item.regularPrice) && math.toFixed(item.regularPrice) > math.toFixed(item.price) ? React.createElement('span', {
            'className': 'regular-price',
            'key': '6357'
        }, '$', math.toFixed(item.regularPrice)) : null, React.createElement('span', {}, '$', math.toFixed(item.price * item.quantity)))));
    }
    function scopeItems8(props, isSubscribing, plan) {
        var items = props.cart.cartItems;
        return React.createElement.apply(this, [
            'div',
            { 'className': 'items-body' },
            !isSubscribing ? _.map(items, repeatItem7.bind(this, props, isSubscribing, plan, items)) : null,
            isSubscribing ? React.createElement('div', {
                'className': 'item',
                'key': '6856'
            }, React.createElement('div', { 'className': 'thumbnail' }, React.createElement('div', { 'className': 'plan-icon' }, React.createElement('i', { 'className': 'icon-monster-' + plan.rank }))), React.createElement('div', { 'className': 'column' }, React.createElement('div', { 'className': 'description' }, React.createElement('div', { 'className': 'type' }, React.createElement('span', {}, utils.getNaturalType('plan', plan))), React.createElement('div', { 'className': 'title' }, React.createElement('span', {}, utils.getTitle('plan', plan))), React.createElement('div', { 'className': 'plan-description' }, React.createElement('span', {}, plan.description1, '.'), React.createElement('span', {}, plan.checkoutDescription2, '.'), React.createElement('span', {}, plan.description3, '.'))), React.createElement('div', { 'className': 'price' }, React.createElement('span', {}, '$', plan.price, plan.rank < 3 ? '/Mo' : '/Yr')))) : null
        ]);
    }
    function onClick9(props, isSubscribing, plan, cart) {
        this.proceedPlaceOrder();
    }
    function onClick10(props, isSubscribing, plan, cart) {
        this.proceedPlaceSubscription(plan.id);
    }
    function scopeCart11(props, isSubscribing, plan) {
        var cart = props.cart;
        return React.createElement('div', { 'className': 'order-summary' }, React.createElement('div', { 'className': 'header' }, React.createElement('h2', {}, isSubscribing ? 'Subscription summary' : 'Order summary')), React.createElement('div', { 'className': 'detailed-total' }, React.createElement('div', { 'className': 'subtitles' }, !props.isSubscribing ? React.createElement('span', { 'key': '8727' }, 'Items total:') : null, this.props.discount ? React.createElement('span', { 'key': '8810' }, 'Discount:') : null, !props.orderIsDigital ? React.createElement('span', { 'key': '8889' }, 'Shipping:') : null, !props.isSubscribing ? React.createElement('span', { 'key': '8970' }, 'Taxes:') : null), React.createElement('div', { 'className': 'values' }, !props.isSubscribing ? React.createElement('span', { 'key': '9123' }, '$', cart.subtotal) : null, this.props.discount ? React.createElement('span', { 'key': '9210' }, '- $', this.props.discount) : null, !props.orderIsDigital ? React.createElement('span', { 'key': '9304' }, '$', props.deliveryMethodDetails.cost) : null, !props.isSubscribing ? React.createElement('span', { 'key': '9411' }, '$', props.tax) : null)), React.createElement('div', {
            'className': 'total' + ' ' + _.transform({ subscribing: isSubscribing }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('div', { 'className': 'subtitles' }, React.createElement('span', {}, isSubscribing ? 'subscription total' : 'order total', ':')), React.createElement('div', { 'className': 'values' }, !props.isSubscribing ? React.createElement('span', { 'key': '9853' }, '$', props.orderTotal) : null, props.isSubscribing ? React.createElement('span', { 'key': '9943' }, '$', props.orderTotal, plan.rank < 3 ? '/Mo' : '/Yr') : null)), React.createElement('div', { 'className': 'place-order-button' }, (props.payWithCreditCard || props.useExistentPaymentMethod) && !props.isSubscribing ? React.createElement('button', {
            'onClick': onClick9.bind(this, props, isSubscribing, plan, cart),
            'disabled': props.loading > 0,
            'key': '10170'
        }, '\n                            Place my Order\n                        ') : null, (props.payWithCreditCard || props.useExistentPaymentMethod) && props.isSubscribing ? React.createElement('button', {
            'onClick': onClick10.bind(this, props, isSubscribing, plan, cart),
            'disabled': this.props.loading > 0,
            'key': '10553'
        }, '\n                            subscribe\n                        ') : null));
    }
    function scopePropsIsSubscribingPlan12() {
        var props = this.props;
        var isSubscribing = props.isSubscribing && !_.isEmpty(props.planSelected);
        var plan = props.planSelected;
        return React.createElement('div', { 'id': 'ReviewOrder' }, React.createElement('div', { 'className': 'title wrapper' }, React.createElement('h1', {}, 'Review your ', isSubscribing ? 'Subscription' : 'Order')), React.createElement('div', { 'className': 'wrapper' }, React.createElement('div', { 'className': 'row' }, !props.orderIsDigital ? React.createElement('div', {
            'className': 'ship-to-summary',
            'key': '736'
        }, React.createElement('div', { 'className': 'header' }, React.createElement('h2', {}, 'Ship to'), React.createElement('button', {
            'className': 'edit-address-button',
            'onClick': onClick1.bind(this, props, isSubscribing, plan),
            'onKeyUp': onKeyUp2.bind(this, props, isSubscribing, plan),
            'tabIndex': '0'
        }, '\n                        Edit\n                    ')), !props.orderIsDigital ? scopeAddress3.apply(this, [
            props,
            isSubscribing,
            plan
        ]) : null) : null, React.createElement('div', {
            'className': 'bill-to-summary' + ' ' + _.transform({ digital: props.orderIsDigital }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('div', { 'className': 'header' }, React.createElement('h2', {}, isSubscribing ? plan.rank < 3 ? 'Bill  Monthly to' : 'Bill Yearly to' : 'Bill to'), React.createElement('span', {
            'className': 'edit-address-button',
            'onClick': onClick4.bind(this, props, isSubscribing, plan),
            'onKeyUp': onKeyUp5.bind(this, props, isSubscribing, plan),
            'tabIndex': '0'
        }, '\n                        Edit\n                    ')), scopeAddressCreditCardPayment6.apply(this, [
            props,
            isSubscribing,
            plan
        ]), isSubscribing ? React.createElement('div', {
            'className': 'subscription-note',
            'key': '4349'
        }, React.createElement('h3', {}, 'AUTOMATIC RENEWAL NOTICE'), React.createElement('p', {}, '\n                    By continuing, you agree that now and prior to each renewal cycle, Stan Winston School of Character Arts will charge your credit card/Paypal account the same price in effect for your current plan. You may cancel your subscription at any time by visiting your SWSCA account page. We value your membership and hope you enjoy learning from the Masters of Character Creation.\n                    ')) : null)), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'items' }, React.createElement('div', { 'className': 'header' }, React.createElement('h2', {}, isSubscribing ? 'Subscription Plan' : 'Items')), scopeItems8.apply(this, [
            props,
            isSubscribing,
            plan
        ])), React.createElement('div', { 'className': 'filler' }, scopeCart11.apply(this, [
            props,
            isSubscribing,
            plan
        ])))));
    }
    return scopePropsIsSubscribingPlan12.apply(this, []);
}