import PropTypes from 'prop-types'
import './styles.css'
import template from './template.rt'

const PaymentMethodDetails = props => template.call({ props })

PaymentMethodDetails.propTypes = {
  creditCardPayment: PropTypes.shape({
    cardType: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired,
    expirationMonth: PropTypes.string,
    expirationYear: PropTypes.string,
    imageUrl: PropTypes.string
  }),
  paypalPayment: PropTypes.shape({
    imageUrl: PropTypes.string,
    payerEmail: PropTypes.string,
    payerFirstName: PropTypes.string,
    payerLastName: PropTypes.string
  })
}

PaymentMethodDetails.defaultProps = {
  creditCardPayment: {
    expirationMonth: '',
    expirationYear: '',
    imageUrl: ''
  },
  paypalPayment: null
}

export default PaymentMethodDetails
