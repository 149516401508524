/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect'

export const randomTestimonialSelector = state => state.testimonials.random
export const testimonialsSelector = state => state.testimonials.list

export default createSelector(randomTestimonialSelector, testimonial => ({
  ...testimonial
}))

export const testimonialListSelector = createSelector(
  testimonialsSelector,
  testimonials => ({ testimonials })
)
