import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  HIDE_COOKIE_NOTICE
  SHOW_COOKIE_NOTICE
  TOGGLE_SEARCH_BOX
`)

//
// REDUCER
// -----------------------------------------------------------------------------

export const INITIAL_STATE = {
  openedSearchBox: false,
  showCookieNotice: false
}

export const handleHideCookieNotice = (state = INITIAL_STATE) => ({
  ...state,
  showCookieNotice: false
})

export const handleShowCookieNotice = (state = INITIAL_STATE) => ({
  ...state,
  showCookieNotice: true
})

export const toggleSearch = (state = INITIAL_STATE) => ({
  ...state,
  openedSearchBox: !state.openedSearchBox
})

export const HANDLERS = {
  [types.HIDE_COOKIE_NOTICE]: handleHideCookieNotice,
  [types.SHOW_COOKIE_NOTICE]: handleShowCookieNotice,
  [types.TOGGLE_SEARCH_BOX]: toggleSearch
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const toggleSearchBox = createAction(types.TOGGLE_SEARCH_BOX)
export const showCookieNotice = createAction(types.SHOW_COOKIE_NOTICE)
export const hideCookieNotice = createAction(types.HIDE_COOKIE_NOTICE)
