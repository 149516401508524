/* eslint-disable import/prefer-default-export */
import _ from 'lodash'
import { createStructuredSelector, createSelector } from 'reselect'
import {
  isMobileSelector,
  pendingBackScrollSelector,
  hasSubscriptionSelector
} from 'reduxModules/app/selectors'
import {
  isLibrarySubscriberSelector,
  isGroupSubscriberSelector
} from 'reduxModules/users/selectors'
import { hijackingSelector } from 'reduxModules/hijack/selectors'
import { assetsGallerySelector } from '../assets/selectors'
import { resultsSelector } from '../newSearch/selectors'
// import { subscriptionPlansSelector } from '../subscriptions/selectors'
import { isLoggedInSelector, subscriptionSelector } from '../auth/selectors'
import { specialVideosSelector } from '../specialVideos/selectors'

// selectors
export const userSubscriptionSelector = state => state.auth.subscription
export const tutorialsSelector = state => state.tutorials.list
export const webinarsSelector = state => state.webinars.list
export const showFiltersSelector = state => state.tutorials.showFilters
export const tutorialsFiltersSelector = state => state.tutorials.listFilters
export const bannersSelector = state => state.banners.list
export const tutorialSelector = state => state.tutorials.detail
export const relevantChaptersSelector = state =>
  state.tutorials.detail.relevantChapters
export const requestStatusSelector = state => state.tutorials.status
export const nextTutorialsSelector = state => state.tutorials.next
export const relatedTutorialsSelector = state =>
  state.tutorials.detail.relatedTutorials
const bookmarkedTutorialsSelector = state =>
  _.filter(
    state.tutorials.bookmarked,
    t => _.size(_.filter(t.bookmarks, { autoGenerated: false })) > 0
  )

const learningPathDetailSelector = state => state.learningPaths.detail

const assets = state => assetsGallerySelector(state)
// const plans = state => subscriptionPlansSelector(state)
const searchResults = state => resultsSelector(state)
const showRelevantsSelector = state => state.player.shouldOnlyShowRelevants

export const tutorialBannerSelector = createSelector(bannersSelector, banners =>
  !banners.length ? undefined : banners
)

export const tutorialVideoPreviewSelector = createSelector(
  tutorialSelector,
  tutorial => {
    if (_.has(tutorial, 'trailer')) {
      if (
        _.has(tutorial, 'trailer.raw.high') &&
        !_.isEmpty(tutorial.trailer.raw.high)
      )
        return { path: tutorial.trailer.raw.high, isYoutube: false }
      if (_.has(tutorial, 'trailer.web') && !_.isEmpty(tutorial.trailer.web))
        return { path: tutorial.trailer.web, isYoutube: true }
      if (
        _.has(tutorial, 'trailer.raw.low') &&
        !_.isEmpty(tutorial.trailer.raw.low)
      )
        return { path: tutorial.trailer.raw.low, isYoutube: false }
    }
    return undefined
  }
)

export const relatedTutorialsReselector = createSelector(
  [relatedTutorialsSelector, searchResults, tutorialSelector],
  (related, results, tutorial) => {
    const relatedAmount = _.size(related)
    const relatedIds = _.map([...related, tutorial], 'id')
    // remove any unwanted tutorials
    const usefulExtraTutorials = _.reject(results, r =>
      _.includes(relatedIds, r.id)
    )

    return [...related, ...usefulExtraTutorials.slice(0, 4 - relatedAmount)]
  }
)

export const usedFreeTrialSelector = state => state.auth.usedFreeTrial
const userProgressSelector = state => state.player.chapterProgressById
const userDetailStatusSelector = state => state.tutorials.userDetailStatus
const tutorialTrailerStatusSelector = state =>
  state.tutorials.tutorialTrailerStatus

// states (mapStateToProps)
export const tutorialsStateSelector = createStructuredSelector({
  tutorials: tutorialsSelector,
  webinars: webinarsSelector,
  filters: tutorialsFiltersSelector,
  showFilters: showFiltersSelector,
  banners: tutorialBannerSelector,
  nextTutorials: nextTutorialsSelector,
  requestStatus: requestStatusSelector,
  isMobile: isMobileSelector,
  specialVideos: specialVideosSelector,
  pendingBackScroll: pendingBackScrollSelector,
  usedFreeTrial: usedFreeTrialSelector
})

export const tutorialStateSelector = createSelector(
  [
    tutorialSelector,
    tutorialVideoPreviewSelector,
    requestStatusSelector,
    isLoggedInSelector,
    assets,
    subscriptionSelector,
    userSubscriptionSelector,
    isMobileSelector,
    hasSubscriptionSelector,
    hijackingSelector,
    usedFreeTrialSelector,
    learningPathDetailSelector,
    userProgressSelector,
    tutorialTrailerStatusSelector
  ],
  (
    tutorial,
    videoPreviewInfo,
    requestStatus,
    isAuth,
    assets,
    subscription,
    userSubscription,
    isMobile,
    isSubscribed,
    hijacking,
    usedFreeTrial,
    learningPath,
    userProgress,
    tutorialTrailerStatus
  ) => ({
    ...tutorial,
    videoPreviewInfo,
    requestStatus,
    isAuth,
    assets,
    subscription,
    userSubscription,
    isMobile,
    isSubscribed,
    hijacking,
    usedFreeTrial,
    learningPath,
    userProgress,
    tutorialTrailerStatus
  })
)

export const tutorialPlayerStateSelector = createSelector(
  [
    tutorialSelector,
    relevantChaptersSelector,
    requestStatusSelector,
    subscriptionSelector,
    isMobileSelector,
    hijackingSelector,
    learningPathDetailSelector,
    showRelevantsSelector,
    userDetailStatusSelector
  ],
  (
    tutorial,
    relevant,
    status,
    subscription,
    isMobile,
    hijacking,
    learningPath,
    shouldOnlyShowRelevants,
    userDetailStatus
  ) => ({
    tutorial,
    relevant,
    status,
    subscription,
    isMobile,
    hijacking,
    learningPath,
    shouldOnlyShowRelevants,
    userDetailStatus
  })
)

export const bookmarkedTutorialsReselector = createStructuredSelector({
  bookmarkedTutorials: bookmarkedTutorialsSelector
})

export const coursePurchaseSelector = createStructuredSelector({
  isLibrarySubscriber: isLibrarySubscriberSelector,
  isGroupSubscriber: isGroupSubscriberSelector
})
