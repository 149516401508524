import {
  selectPayWithCreditCard,
  selectPayWithPaypal
} from 'reduxModules/checkout'
import { cardTypeChanged, toggleStorePaymentAction, saveTokenizer } from './'

export const dispatchCardTypeChanged = (dispatch, cards) =>
  dispatch(cardTypeChanged(cards.length === 1 ? cards[0].type : ''))

export default dispatch => ({
  cardTypeChanged: ({ cards }) => dispatchCardTypeChanged(dispatch, cards),
  toggleStorePayment: () => dispatch(toggleStorePaymentAction()),
  storeTokenizer: tokenize => dispatch(saveTokenizer(tokenize)),
  choosePayWithCreditCard: () => dispatch(selectPayWithCreditCard()),
  choosePayWithPaypal: () => dispatch(selectPayWithPaypal())
})
