import * as React from 'react';
import * as _ from 'lodash';
import SlideShow from '../SlideShow';
import BannerSlide from './BannerSlide';
import VideoModal from '../VideoModal';
export default function () {
    function repeatBanner1(banners, manyBanners, banner, bannerIndex) {
        return React.createElement('div', { 'key': banner.id }, React.createElement(BannerSlide, _.assign({}, {
            'video': banner.video,
            'displayVideo': () => {
                this.toggleVideo(banner.video);
            }
        }, banner)));
    }
    function scopeBannersManyBanners2() {
        var banners = this.props.banners;
        var manyBanners = _.size(banners) > 1;
        return React.createElement('div', { 'className': this.props.isSecondary ? 'BannerSlideShowSecondary' : 'BannerSlideShow' }, React.createElement.apply(this, [
            SlideShow,
            {
                'arrows': manyBanners,
                'draggable': this.props.isMobile,
                'dots': manyBanners,
                'autoPlay': !this.props.isMobile
            },
            _.map(banners, repeatBanner1.bind(this, banners, manyBanners))
        ]), React.createElement(VideoModal, {
            'url': this.state.video,
            'visible': this.state.videoShow,
            'close': () => this.toggleVideo()
        }));
    }
    return scopeBannersManyBanners2.apply(this, []);
}