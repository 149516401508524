import PropTypes from 'prop-types'
import React, { Component } from 'react'

import template from './template.rt'
import './styles.css'

export default class LearningPathCourses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nextCourses: props.courses.length > 4
        ? props.courses.slice(4)
        : [],
      shownCourses: props.courses.length <= 4
        ? props.courses
        : props.courses.slice(0, 4)
    }
  }
  showAllCourses() {
    this.setState({
      nextCourses: [],
      shownCourses: [...this.state.shownCourses, ...this.state.nextCourses]
    })
  }
  render() {
    return template.call(this)
  }
}

LearningPathCourses.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadBookmark: PropTypes.func.isRequired,
  loadBookmarkContext: PropTypes.shape({
    location: PropTypes.object.isRequired
  }).isRequired,
  owned: PropTypes.bool.isRequired,
  pathId: PropTypes.number.isRequired,
  routePath: PropTypes.string.isRequired,
  setVideoPreview: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired
}
