import * as React from 'react';
import * as _ from 'lodash';
import { Field } from 'redux-form';
import RenderField from '../generic/RenderedField';
import { validators } from '../../redux/modules/utils/forms';
export default function () {
    function onClick1() {
        {
            this.props.toggleDiscountCode();
        }
    }
    return React.createElement('div', {
        'id': 'DiscountCode',
        'className': _.transform({
            showForm: this.props.showDiscountCodeForm && !this.props.validatedDiscountCode.codename,
            [this.props.className]: this.props.className !== undefined
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement('form', { 'onSubmit': this.props.handleSubmit }, React.createElement(Field, {
        'name': 'code',
        'validate': validators.required,
        'component': RenderField,
        'type': 'text',
        'placeholder': 'Discount Code',
        'props': { id: 'discount-code-input' }
    }), React.createElement('div', { 'className': 'actions' }, React.createElement('span', {
        'className': 'cta',
        'onClick': onClick1.bind(this)
    }, 'Cancel'), React.createElement('button', {
        'type': 'submit',
        'className': 'btn -small -thin -border-white',
        'disabled': this.props.submitting
    }, 'Apply'))));
}