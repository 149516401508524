import React, { useState } from 'react'

import './styles.css'

function TutorialMaterials({ materials, referenceMaterials }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="TutorialMaterials">
      <div
        className={`TutorialMaterials__content ${
          isExpanded ? 'expanded' : 'collapsed'
        }`}
      >
        <div
          className="formatted"
          dangerouslySetInnerHTML={{ __html: materials }}
        />
        {referenceMaterials && (
          <div
            className="formatted"
            dangerouslySetInnerHTML={{ __html: referenceMaterials }}
          />
        )}
      </div>
      <button onClick={toggleExpand} className="btn -blue-2 -thin">
        {isExpanded ? 'Show Less' : 'Show More'}
      </button>
    </div>
  )
}

export default TutorialMaterials
