import _ from 'lodash'
import { createSelector } from 'reselect'
import { isLoggedInSelector } from 'reduxModules/auth/selectors'
import {
  isMobileSelector,
  showMobileMenuSelector,
  isFullscreenSelector,
  hasSubscriptionSelector,
  dimensionsSelector,
  usedFreeTrialSelector,
  userSelector
} from 'reduxModules/app/selectors'
import {
  cartItemsCountSelector,
  cartItemAddedSelector
} from 'reduxModules/cart/selectors'
import { hijackingSelector } from 'reduxModules/hijack/selectors'
import { isLibrarySubscriberSelector } from 'reduxModules/users/selectors'

// selectors
export const navBarSelector = state => state.navBar
const hideInnerNavSelector = state => state.app.hideInnerNav
const autocompleteResultsSelector = state => state.newSearch.autocompleteResults
const createdByProxySelector = state => state.auth.createdByProxy

// states (mapStateToProps)
export const navBarStateSelector = createSelector(
  [
    navBarSelector,
    isLoggedInSelector,
    hideInnerNavSelector,
    isMobileSelector,
    showMobileMenuSelector,
    cartItemsCountSelector,
    cartItemAddedSelector,
    hijackingSelector,
    isFullscreenSelector,
    isLibrarySubscriberSelector,
    hasSubscriptionSelector,
    dimensionsSelector,
    usedFreeTrialSelector,
    userSelector,
    autocompleteResultsSelector,
    createdByProxySelector
  ],
  (
    navBar,
    isLoggedIn,
    hideInnerNav,
    isMobile,
    showMobileMenu,
    cartCount,
    itemAdded,
    hijacking,
    isFullscreen,
    isLibrarySubscriber,
    isSubscriber,
    dimensions,
    usedFreeTrial,
    userSelector,
    autocompleteResults,
    createdByProxy
  ) => ({
    ...navBar,
    isLoggedIn,
    hideInnerNav,
    isMobile,
    showMobileMenu,
    cartCount,
    itemAdded,
    hijacking,
    isFullscreen,
    isLibrarySubscriber,
    isSubscriber,
    dimensions,
    usedFreeTrial,
    userSelector,
    autocompleteResults,
    createdByProxy
  })
)
