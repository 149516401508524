import _ from 'lodash'
import {
  validateDiscountCode,
  toggleDiscountCode as toggleDiscountCodeAction,
  types as discountCodeTypes
} from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

export default dispatch => ({
  onSubmit(data, dispatch, props) {
    let plan
    if (_.isNumber(props.plan)) plan = props.plan
    else if (
      props.plan &&
      Object.prototype.hasOwnProperty.call(props.plan, 'id')
    )
      plan = props.plan.id
    else plan = null
    dispatch(validateDiscountCode(data.code.toUpperCase(), plan)())
      .then(([_, res]) => {
        if (res.type === discountCodeTypes.VALIDATE_DISCOUNT_CODE_SUCCESS)
          toastSuccess(notifications.discountCodeValid)
        else toastError(notifications.discountCodeValid)
      })
      .catch(payload => {
        const error = payload.errors
        toastServerError(error)
        toastError(notifications.discountCodeValid)
      })
  },
  toggleDiscountCode() {
    dispatch(toggleDiscountCodeAction())
  }
})
