import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/learningPaths/selectors'
import { learningPathsStatus } from 'reduxModules/learningPaths'
import learningPathMergeProps from 'reduxModules/learningPaths/dispatchers'
import _ from 'lodash'
import 'rodal/lib/rodal.css'
import LoadingComponent from 'components/generic/LoadingComponent'

import template from './template.rt'
import './styles.css'

export class LearningPathDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cta: '',
      filter: []
    }
    const { getLearningPath, getAllLearningPathCourses } = props
    getLearningPath()
    getAllLearningPathCourses()
  }

  componentDidMount() {
    const cta = this.getMainCTA()
    this.setState({ cta })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { owned, lastBookmark, isSubscriber } = this.props

    if (
      owned !== nextProps.owned ||
      lastBookmark !== nextProps.lastBookmark ||
      isSubscriber !== nextProps.lastBookmark
    ) {
      let cta = this.getMainCTA()
      if (this.state.cta === 'enroll' && nextProps.owned) cta = 'start pathway'
      this.setState({ cta })
    }
  }

  componentWillUnmount() {
    this.props.clearLearningPath()
  }

  filterCourse(course) {
    let newFilter = this.state.filter
    const index = newFilter.indexOf(course)
    if (index !== -1) newFilter.splice(index, 1)
    else newFilter = [...newFilter, course]
    this.setState({
      filter: newFilter
    })
  }

  getMainCTA() {
    const { owned, lastBookmark, isSubscriber } = this.props
    if (!isSubscriber) return 'subscribe'
    if (!owned) return 'enroll'
    if (_.isNull(lastBookmark)) return 'start pathway'
    return 'continue pathway'
  }

  onClickMainCTA() {
    const {
      owned,
      resumeWatching,
      enrollUser,
      url,
      slug,
      lastBookmark,
      courses,
      id,
      route,
      isSubscriber,
      subscription
    } = this.props
    const firstCourse = courses.length > 0 ? courses[0].slug : null // Pick first course if not started

    if (!owned) enrollUser(url, slug, isSubscriber)
    else
      resumeWatching(
        lastBookmark,
        firstCourse,
        slug,
        id,
        route.path,
        subscription
      )
  }

  render() {
    if (this.props.status === learningPathsStatus.success)
      return template.call(this)
    return <LoadingComponent />
  }
}

LearningPathDetail.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired
}

LearningPathDetail.defaultProps = {
  facebookShareImages: [],
  progressByCategory: []
}

export default connect(
  stateSelector,
  null,
  learningPathMergeProps
)(LearningPathDetail)
