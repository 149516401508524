import React, { useState } from 'react'

import './styles.css'

function SearchTutorialSuggestion({ suggestion }) {
  // State
  const [isFocused, setIsFocused] = useState(false)
  return (
    <a
      aria-label={`Go to tutorial: ${suggestion?.title} by ${suggestion?.artist?.fullName}`}
      role="option"
      href={`/tutorials/${suggestion.slug}`}
      className="suggestion tutorial"
      aria-selected={isFocused}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div className="thumbnail">
        <img src={suggestion?.photo?.boxThumbSm} alt={suggestion.title} />
      </div>
      <div className="summary">
        <b className="tutorial-title">{suggestion.title}</b>
        <div className="sub-summary">
          <span className="category">Course</span>
          <span className="artist-name">{suggestion?.artist?.fullName}</span>
        </div>

        <span className="tutorial-description">{suggestion?.description}</span>
      </div>
    </a>
  )
}

function SearchTutorialSuggestions({ suggestions }) {
  return suggestions.map(s => (
    <SearchTutorialSuggestion suggestion={s} key={s?.slug} />
  ))
}

export default SearchTutorialSuggestions
