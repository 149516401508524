import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  LANDING_PAGE_INITIAL
  LANDING_PAGE_SUCCESS
  LANDING_PAGE_ERROR
  LANDING_PAGE_FETCH_SUCCESS
  LANDING_PAGE_REQUEST_FAILURE
`)

export const landingPagesStatus = {
  initial: types.LANDING_PAGE_INITIAL,
  success: types.LANDING_PAGE_SUCCESS,
  error: types.LANDING_PAGE_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: landingPagesStatus.initial,
  detail: {},
  slug: ' ',
  title: ' ',
  subtitle: ' '
}

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  status: landingPagesStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: landingPagesStatus.error
})

export const HANDLERS = {
  [types.LANDING_PAGE_FETCH_SUCCESS]: successDetail,
  [types.LANDING_PAGE_REQUEST_FAILURE]: failure
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const landingPageFetchSuccess = createAction(
  types.LANDING_PAGE_FETCH_SUCCESS
)
export const landingPageFetchFailure = createAction(
  types.LANDING_PAGE_REQUEST_FAILURE
)
export const landingPageFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/landing-pages/${slug}/`,
    { method: 'GET' },
    landingPageFetchSuccess,
    landingPageFetchFailure
  )
}
