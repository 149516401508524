import React, { useState, useRef, useCallback } from 'react'

import { Link } from 'react-router'

import useOnClickOutside from '../SearchBox/useOnClickOutside'
import useEventListener from '../../../utils/useEventListener'

import './styles.css'

function AccountMenu({ logout, createdByProxy }) {
  const [displayOptions, setDisplayOptions] = useState(false)
  // Reference to search box
  const searchBoxEl = useRef(null)
  // Handler for clicking outside
  const searchBoxElHandler = useCallback(() => setDisplayOptions(false))
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(searchBoxEl, searchBoxElHandler)
  // Handler to dismiss menu when hitting 'Escape'
  const escapeHandler = e => {
    if (e.key === 'Escape') setDisplayOptions(false)
  }
  useEventListener('keydown', escapeHandler)

  return (
    <nav
      id="AccountMenu"
      role="navigation"
      aria-label="account menu"
      className="account-actions__action"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(displayOptions && {
        style: {
          backgroundColor: '#262d3d'
        }
      })}
    >
      <button
        className="nav__toggle"
        aria-expanded={!!displayOptions}
        aria-controls="nav__list"
        type="button"
        onClick={() => setDisplayOptions(true)}
      >
        <i className="icon-account" aria-hidden="true" />
      </button>
      {displayOptions ? (
        <div
          className="nav__list__container"
          ref={searchBoxEl}
          style={{ bottom: createdByProxy ? '-40px' : '-72px' }}
        >
          <ul id="nav__list" className="nav__list">
            <li className="nav__list-item">
              <Link
                aria-label="Click to go to your account details"
                className="nav__list-link"
                to="/account"
                onClick={() => setDisplayOptions(false)}
              >
                Account
              </Link>
            </li>
            {!createdByProxy ? (
              <li className="nav__list-item logout">
                <button
                  className="nav__list-link"
                  onClick={() => {
                    setDisplayOptions(false)
                    logout()
                  }}
                  type="button"
                  aria-label="Click to logout"
                >
                  Logout
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      ) : null}
    </nav>
  )
}

export default AccountMenu
