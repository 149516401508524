import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import keyUpHandler from '../../utils/keyUpHandler';
import SubscribeDialogCTA from '../SubscribeDialogCTA';
export default function () {
    return React.createElement('div', {
        'id': 'VerifyBanner',
        'className': _.transform({
            'is-mobile-verify': this.props.isMobile,
            displace: this.props.displace
        }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement('div', { 'className': 'wrapper-verify-banner' }, React.createElement('span', { 'className': 'info' }, React.createElement('b', {}, 'Please verify your email to enjoy all the benefits of Stan Winston School of Character Arts.')), React.createElement('button', {
        'className': 'btn -thin -orange verify-button' + ' ' + _.transform({ disabled: this.props.isResendCTADisabled }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'disabled': this.props.isResendCTADisabled,
        'onClick': () => this.props.resendEmail(),
        'aria-label': 'Click this button to send the verification email again'
    }, React.createElement('span', {}, 'Resend'))));
}