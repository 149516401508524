import * as React from 'react';
import * as _ from 'lodash';
import Helmet from 'react-helmet';
import { Field } from 'redux-form';
import { CaptchaNotice } from '../generic/CaptchaNotice';
import { validators } from '../../redux/modules/utils/forms';
import renderField from '../generic/RenderedField';
import SUBJECT_OPTIONS from './constants';
import CaptchaStandalone from '../CaptchaStandalone/_lazy';
export default function () {
    function repeatOption1(option, optionIndex) {
        return React.createElement('option', {
            'value': option.value,
            'key': optionIndex
        }, option.name);
    }
    return React.createElement('div', { 'id': 'ContactUs' }, React.createElement(Helmet, {}, React.createElement('title', {}, 'Contact Us | Stan Winston School of Character Arts')), React.createElement('h1', {}, 'Contact Us'), React.createElement('p', {}, 'Please contact us with any of your questions, suggestions or other needs.'), React.createElement('p', {}, React.createElement('b', {}, 'Customer Care Hours:'), React.createElement('br', {}), 'Monday - Friday 9:30 am to 5:30 pm PT'), React.createElement('p', {}, 'We strive to respond to all communication within 24 hours, excluding weekends and certain holidays. Also, you can reach us at ', React.createElement('a', { 'href': 'mailto:info@stanwinstonschool.com' }, 'info@stanwinstonschool.com'), React.createElement('br', {}), '\n    - SWSCA Customer Care Team'), React.createElement('form', { 'onSubmit': this.props.handleSubmit }, React.createElement('div', { 'className': 'form-group' }, React.createElement(Field, {
        'name': 'name',
        'validate': validators.required,
        'component': renderField,
        'type': 'text',
        'placeholder': 'Full Name',
        'props': { ariaLabel: 'Full Name' }
    }), React.createElement(Field, {
        'name': 'email',
        'validate': [
            validators.required,
            validators.email
        ],
        'component': renderField,
        'type': 'text',
        'placeholder': 'Email',
        'props': { ariaLabel: 'Email' }
    })), React.createElement('div', { 'className': 'form-group' }, React.createElement.apply(this, [
        Field,
        {
            'name': 'subject',
            'type': 'select',
            'component': renderField,
            'validate': validators.selectRequired,
            'props': { ariaLabel: 'Subject' }
        },
        React.createElement('option', {
            'value': 'unselected',
            'disabled': true
        }, 'Subject'),
        _.map(SUBJECT_OPTIONS, repeatOption1.bind(this))
    ]), React.createElement(Field, {
        'name': 'order_number',
        'component': renderField,
        'type': 'text',
        'placeholder': 'Order # (optional)',
        'props': { ariaLabel: 'Order # (optional)' }
    })), React.createElement(Field, {
        'name': 'message',
        'validate': validators.required,
        'placeholder': 'Comment',
        'component': renderField,
        'type': 'textarea',
        'props': { ariaLabel: 'Comment' }
    }), React.createElement(CaptchaStandalone, {}), React.createElement(CaptchaNotice, {}), React.createElement('div', { 'className': 'submit-button' }, React.createElement('button', {
        'type': 'submit',
        'disabled': this.props.submitting
    }, 'Send message'))));
}