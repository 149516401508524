import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  MODAL_SHOW
  MODAL_HIDE
`)

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  visible: false
}

export const showSuccess = (state = INITIAL_STATE) => ({
  ...state,
  visible: true
})

export const hideSuccess = (state = INITIAL_STATE) => ({
  ...state,
  visible: false
})

export const HANDLERS = {
  [types.MODAL_SHOW]: showSuccess,
  [types.MODAL_HIDE]: hideSuccess
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const hideModal = createAction('MODAL_HIDE')
export const showModal = createAction('MODAL_SHOW')
