import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { orderDetailDispatchers as dispatchers } from 'reduxModules/users/dispatchers'
import { settledOrderSelector as selector } from 'reduxModules/users/selectors'
import template from './template.rt'
import './styles.css'

export const Receipt = props => template.call({ props })

Receipt.propTypes = {
  loadOrderDetail: PropTypes.func.isRequired,
  invalidAuth: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  loggedInFromEmail: PropTypes.bool
}

Receipt.defaultProps = {
  items: [],
  loggedInFromEmail: false
}

export default connect(
  selector,
  dispatchers
)(Receipt)
