export default [
  {
    url: '/customer-care/accessibility-statement/',
    name: 'Accessibility'
  },
  {
    url: '/customer-care/contact-us/',
    name: 'Contact Us'
  },
  {
    url: '/customer-care/return-policy/',
    name: 'Return Policy'
  },
  {
    url: '/customer-care/privacy-policy/',
    name: 'Privacy Policy'
  },
  {
    url: '/customer-care/terms-of-use/',
    name: 'Terms of Use'
  },
  {
    url: '/customer-care/warnings-and-risks',
    name: 'Warnings and Risks'
  },
  {
    url: '/customer-care/cookie-policy/',
    name: 'Cookie Policy'
  },
  {
    url: '/customer-care/faqs/',
    name: 'FAQ'
  }
]
