import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi, { getUrl } from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  BANNERS_INITIAL
  BANNERS_FETCH_SUCCESS
  AD_BANNERS_FETCH_SUCCESS
  AD_BANNERS_REQUEST_FAILURE
  SECONDARY_BANNERS_FETCH_SUCCESS
  SECONDARY_BANNERS_REQUEST_FAILURE
  BANNERS_SUCCESS
  BANNERS_ERROR
  FREE_TRIAL_IMAGE_FETCH_SUCCESS
  FREE_TRIAL_IMAGE_FETCH_FAILURE
`)

export const bannersStatus = {
  initial: types.BANNERS_INITIAL,
  success: types.BANNERS_SUCCESS,
  error: types.BANNERS_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: bannersStatus.initial,
  statusMain: bannersStatus.initial,
  list: [],
  adList: [],
  secondaryList: [],
  freeTrialImage: {}
}
export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.payload.results,
  status: bannersStatus.success,
  statusMain: bannersStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: bannersStatus.error
})

export const adSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  adList: action.payload.results,
  status: bannersStatus.success
})

export const adFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: bannersStatus.error
})

export const secondarySuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  secondaryList: action.payload.results,
  status: bannersStatus.success
})

export const secondaryFailure = (state = INITIAL_STATE) => ({
  ...state,
  status: bannersStatus.error
})

export const freeTrialImageSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  freeTrialImage: action.payload.results.length ? action.payload.results[0] : {},
  status: bannersStatus.success
})

export const freeTrialImageFailure = (state = INITIAL_STATE) => ({
  ...state,
  error: bannersStatus.error
})

export const HANDLERS = {
  [types.BANNERS_FETCH_SUCCESS]: success,
  [types.BANNERS_REQUEST_FAILURE]: failure,
  [types.AD_BANNERS_FETCH_SUCCESS]: adSuccess,
  [types.AD_BANNERS_REQUEST_FAILURE]: adFailure,
  [types.SECONDARY_BANNERS_FETCH_SUCCESS]: secondarySuccess,
  [types.SECONDARY_BANNERS_REQUEST_FAILURE]: secondaryFailure,
  [types.FREE_TRIAL_IMAGE_FETCH_SUCCESS]: freeTrialImageSuccess,
  [types.FREE_TRIAL_IMAGE_FETCH_FAILURE]: freeTrialImageFailure

}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const bannersFetchSuccess = createAction(types.BANNERS_FETCH_SUCCESS)
export const bannersFetchFailure = createAction(types.BANNERS_REQUEST_FAILURE)
export const secondaryBannersFetchSuccess = createAction(
  types.SECONDARY_BANNERS_FETCH_SUCCESS
)
export const secondaryBannersFetchFailure = createAction(
  types.SECONDARY_BANNERS_REQUEST_FAILURE
  )
export const adBannersFetchSuccess = createAction(
  types.AD_BANNERS_FETCH_SUCCESS
)
export const adBannersFetchFailure = createAction(
  types.AD_BANNERS_REQUEST_FAILURE
  )
export const freeTrialImageFetchSuccess = createAction(types.FREE_TRIAL_IMAGE_FETCH_SUCCESS)
export const freeTrialImageFetchFailure = createAction(types.FREE_TRIAL_IMAGE_FETCH_FAILURE)

export const bannersFetch = (filters = {}) =>
  fetchApi(
    getUrl('/banners/', filters),
    { method: 'GET' },
    bannersFetchSuccess,
    bannersFetchFailure
  )

export const adBannersFetch = (filters = {}) =>
  fetchApi(
    getUrl('/banners-ad/', filters),
    { method: 'GET' },
    adBannersFetchSuccess,
    adBannersFetchFailure
  )

export const secondaryBannersFetch = (filters = {}) =>
  fetchApi(
    getUrl('/banners/', filters),
    { method: 'GET' },
    secondaryBannersFetchSuccess,
    secondaryBannersFetchFailure
  )

export const freeTrialimageFetch = () =>
  fetchApi('/banners-free-trial',
    { method: 'GET' },
    freeTrialImageFetchSuccess,
    freeTrialImageFetchFailure
  )