import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import moment from 'moment';
import logoMain from '../../../global/assets/images/logo/swsca-logo-white.svg';
import logoSVG from '../../../global/assets/images/logo/logo-talpor.svg';
import { FooterLinks } from './';
export default function () {
    return React.createElement('footer', {
        'id': 'Footer',
        'className': 'wrapper'
    }, React.createElement('div', { 'className': 'menu-networks-holder' }, React.createElement('div', { 'className': 'iso-logo' }, React.createElement(Link, { 'to': '/' }, React.createElement('img', {
        'src': logoMain,
        'alt': 'Stan Winston School Of Character Arts'
    }))), !this.props.isMobile ? React.createElement(FooterLinks, { 'key': '574' }) : null, React.createElement('div', { 'className': 'social-networks' }, React.createElement('a', {
        'aria-label': 'TikTok Page',
        'href': 'https://www.tiktok.com/@stan_winston_school',
        'className': 'tk-btn',
        'target': '_blank',
        'title': 'Stan Winston School on TikTok'
    }, React.createElement('div', { 'className': 'tik-tok-container' }, React.createElement('svg', {
        'xmlns': 'http://www.w3.org/2000/svg',
        'viewBox': '0 0 50 50',
        'width': '50px',
        'height': '50px'
    }, React.createElement('path', { 'd': 'M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z' })))), React.createElement('a', {
        'aria-label': 'Facebook Page',
        'href': 'https://www.facebook.com/StanWinstonSchool',
        'className': 'fb-btn',
        'target': '_blank',
        'title': 'Stan Winston School on Facebook'
    }, React.createElement('i', { 'className': 'icon-facebook' })), React.createElement('a', {
        'aria-label': 'Instagram Profile',
        'href': 'https://www.instagram.com/stanwinstonschool/',
        'className': 'ig-btn',
        'target': '_blank',
        'title': 'Stan Winston School on Instagram'
    }, React.createElement('i', { 'className': 'icon-instagram' })), React.createElement('a', {
        'aria-label': 'YouTube Profile',
        'href': 'https://www.youtube.com/stanwinstonschool',
        'className': 'yt-btn',
        'target': '_blank',
        'title': 'Stan Winston School on YouTube'
    }, React.createElement('i', { 'className': 'icon-youtube' })), React.createElement('a', {
        'aria-label': 'Pinterest Profile',
        'href': 'https://www.pinterest.com/stanwinston/',
        'className': 'pt-btn',
        'target': '_blank',
        'title': 'Stan Winston School on Pinterest'
    }, React.createElement('i', { 'className': 'icon-pinterest' })))), React.createElement('hr', {}), this.props.isMobile ? React.createElement(FooterLinks, { 'key': '2630' }) : null, React.createElement('div', { 'className': 'rights' }, React.createElement('div', { 'className': 'copyright' }, '\n      Copyright \xA9 ', moment().year(), ' Stan Winston School.  All rights reserved.\n    '), React.createElement('div', { 'className': 'developed' }, '\n      Designed & developed by\n      ', React.createElement('a', {
        'className': 'logo-talpor',
        'href': 'https://www.talpor.com/',
        'target': '_blank'
    }, React.createElement('img', {
        'src': logoSVG,
        'alt': 'TALPOR solutions'
    })))));
}