/* global DISPLAY_DATE_FORMAT */
import React, { useState } from 'react'

import './styles.css'

function SearchPostSuggestion({ suggestion }) {
  // State
  const [isFocused, setIsFocused] = useState(false)
  return (
    <a
      aria-label={`Go to Blog Post: ${suggestion?.title}`}
      role="option"
      href={`/blog/${suggestion.slug}`}
      className="suggestion post"
      aria-selected={isFocused}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div className="thumbnail">
        <img src={suggestion?.photo?.thumb} alt={suggestion.title} />
      </div>
      <div className="summary">
        <b className="post-title">{suggestion.title}</b>
        <div className="sub-summary">
          <span className="category">Post</span>
        </div>

        <span className="post-description">{suggestion?.description}</span>
      </div>
    </a>
  )
}

function SearchPostSuggestions({ suggestions }) {
  return suggestions.map(s => (
    <SearchPostSuggestion suggestion={s} key={s?.slug} />
  ))
}

export default SearchPostSuggestions
