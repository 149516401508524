import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    return React.createElement('div', {
        'className': 'learning-path ' + (this.props.className || ''),
        'style': { backgroundImage: `url(${ this.props.photo.default })` }
    }, React.createElement(Link, {
        'className': 'learning-path__wrap',
        'to': '/pathways/' + this.props.slug
    }, React.createElement('h3', { 'className': 'learning-path__title' }, this.props.title), React.createElement('div', { 'className': 'learning-path__info' }, '\n      ', this.props.numTutorials, ' Courses\n      ', this.props.duration ? React.createElement('span', { 'key': '394' }, ' \xB7 ', this.props.duration[0], ' Hours') : null)));
}