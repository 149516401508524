import PropTypes from 'prop-types'
import React from 'react'
import './styles.css'

function SearchCategory(props) {
  const { name, active, select } = props
  return (
    <button
      className={`SearchCategory ${active === name ? 'active' : ''}`}
      onClick={() => select(name)}
      type="button"
    >
      <span className="SearchCategory__wrap">{name}</span>
    </button>
  )
}

export const CategoryStructure = {
  name: PropTypes.string.isRequired,
  active: PropTypes.string,
  select: PropTypes.func.isRequired
}

SearchCategory.propTypes = CategoryStructure

SearchCategory.defaultProps = {
  active: ''
}

export default SearchCategory
