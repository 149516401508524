import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { watchHistorySelector as stateSelector } from 'reduxModules/auth/selectors'
import { watchHistoryDispatcher as dispatchSelector } from 'reduxModules/auth/dispatchers'
import LoadingComponent from 'components/generic/LoadingComponent'
import Tutorial from '../../generic/CourseSummary'

import './styles.css'

export function WatchHistory(props) {
  useEffect(() => {
    props.loadWatchHistory()
  }, [])
  return (
    <div id="WatchHistory" className="account-section">
      {props?.isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {props?.currentlyWatching?.results.length === 0 && (
            <div className="no-results">
              <span>You haven&apos;t watched any videos yet.</span>
            </div>
          )}
          {props?.currentlyWatching?.results.length > 0 && (
            <div className="courses">
              <h3 className="account-section__title">Watch History</h3>
              <section className="tutorials-list-content">
                {props?.currentlyWatching?.results.map(bookmark => (
                  <Tutorial
                    className="tutorial"
                    key={bookmark?.tutorial?.id}
                    slug={bookmark?.tutorial?.slug}
                    summary={bookmark?.tutorial?.description}
                    teacher={bookmark?.tutorial?.artist.title}
                    teacherSlug={bookmark?.tutorial?.artist?.slug}
                    title={bookmark?.tutorial?.title}
                    thumb={bookmark?.tutorial?.photo?.boxThumb}
                    new={bookmark?.tutorial?.new}
                    completed={bookmark?.pctgCompleted >= 99}
                  />
                ))}
              </section>
            </div>
          )}
        </>
      )}
    </div>
  )
}

WatchHistory.propTypes = {
  loadFavorites: PropTypes.func.isRequired,
  favorites: PropTypes.objectOf(PropTypes.any),
  status: PropTypes.string
}

WatchHistory.defaultProps = {
  history: [],
  status: ''
}

export default connect(stateSelector, dispatchSelector)(WatchHistory)
