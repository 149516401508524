/* eslint-disable import/prefer-default-export */
import { createStructuredSelector, createSelector } from 'reselect'
import {
  loadingSelector,
  hasSubscriptionSelector,
  isMobileSelector,
  dimensionsSelector
} from 'reduxModules/app/selectors'
import {
  subscriptionSelector,
  loggedInFromEmailSelector
} from 'reduxModules/auth/selectors'
import _ from 'lodash'

export const userProfileSelector = state => state.user.profile
export const userFavoritesSelector = state => state.user.favorites
export const userTutorialsSelector = state =>
  state.user.tutorials ? state.user.tutorials.list : []
export const nextTutorialsSelector = state =>
  state.user.tutorials ? state.user.tutorials.next : null
export const userAddressesSelector = state => state.user.addresses
export const createAddressFormData = state => state.form.createAddressForm
export const sortedUserPaymentMethodsSelector = state => {
  const cards = _.cloneDeep(state.user.paymentMethods.results.creditCards)
  const paypalAccounts = _.cloneDeep(
    state.user.paymentMethods.results.paypalAccounts
  )
  const methods = [...cards, ...paypalAccounts]
  return methods.sort((x, y) => {
    if (x.default === y.default) return 0
    if (x.default) return -1
    return 1
  })
}
const statusSelector = state => state.user.status
const subscriptionTransactionsSelector = state =>
  state.user.subscriptionTransactions
export const subscriptionsSelector = state => {
  const subscriptions = _.cloneDeep(state.subscriptions.list)
  return subscriptions
    ? subscriptions.sort((a, b) => {
        if (a.id > b.id) return 1
        if (a.id < b.id) return -1
        return 0
      })
    : []
}
export const isLibrarySubscriberSelector = state =>
  state.auth.role === 'free_library_subscriber'
export const isGroupSubscriberSelector = state =>
  state.auth.role === 'free_group_subscriber'
export const isAnnualSubscriberSelector = state =>
  state.auth.role === 'annual_gift_certificate_recipients'

export const userPaypalAccountsSelector = state =>
  state.user.paymentMethods.results.paypalAccounts
export const userCreditCardsSelector = state =>
  state.user.paymentMethods.results.creditCards
export const userOrdersSelector = state => {
  const orders = _.cloneDeep(state.user.orders.results)
  return orders
    ? orders.sort((a, b) => new Date(b.created) - new Date(a.created))
    : []
}
export const nextOrdersSelector = state =>
  state.user.orders ? state.user.orders.next : null

export const userLearningPathsSelector = state =>
  state.user.learningPaths.results

export const userProfileReselector = createSelector(
  [userProfileSelector, isMobileSelector],
  (profile, isMobile) => ({
    initialValues: profile,
    enableReinitialize: true,
    isMobile
  })
)

export const userFavoritesReselector = createStructuredSelector({
  favorites: userFavoritesSelector,
  status: statusSelector
})

export const addressEditingIdSelector = createSelector(
  createAddressFormData,
  addressFormData =>
    !_.isEmpty(addressFormData) && !_.isEmpty(addressFormData.values)
      ? addressFormData.values.id
      : null
)

export const userAddressesReselector = createStructuredSelector({
  addresses: userAddressesSelector,
  editingAddressId: addressEditingIdSelector
})

export const userPaymentMethodsReselector = createStructuredSelector({
  paymentMethods: sortedUserPaymentMethodsSelector,
  paypalAccounts: userPaypalAccountsSelector,
  isSubscribed: hasSubscriptionSelector,
  isMobile: isMobileSelector
})

export const isSubscribedReselector = createStructuredSelector({
  isSubscribed: hasSubscriptionSelector,
  subscription: subscriptionSelector
})

export const userTutorialsReselector = createStructuredSelector({
  tutorials: userTutorialsSelector,
  nextTutorials: nextTutorialsSelector,
  loading: loadingSelector,
  isSubscribed: hasSubscriptionSelector,
  subscription: subscriptionSelector
})

export const userOrdersReselector = createStructuredSelector({
  orders: userOrdersSelector,
  nextOrders: nextOrdersSelector,
  isMobile: isMobileSelector,
  isSubscribed: hasSubscriptionSelector,
  subscription: subscriptionSelector,
  subscriptionTransactions: subscriptionTransactionsSelector,
  subscriptions: subscriptionsSelector
})

export const addressesSelector = createSelector(
  [userAddressesSelector, loadingSelector, isMobileSelector],
  (addresses, isLoading, isMobile) => ({
    hasAddresses: addresses.results.length > 0,
    showForm: addresses.showAddressForm,
    editing: addresses.editing,
    loading: isLoading,
    isMobile
  })
)

export const settledOrderSelector = createSelector(
  [
    state => state.user.orders.settled,
    dimensionsSelector,
    loggedInFromEmailSelector
  ],
  (order, dimensions, loggedInFromEmail) => ({
    ...order,
    ...dimensions,
    loggedInFromEmail
  })
)

export const userLearningPathsReselector = createStructuredSelector({
  learningPaths: userLearningPathsSelector
})
