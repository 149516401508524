import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
import RadialProgress from '../../../generic/RadialProgress';
import keyUpHandler from '../../../utils/keyUpHandler';
export default function () {
    function onKeyUp1(props, chaptersAmount, pctgWatched, url, event) {
        keyUpHandler(event, this.toggle, null, this);
    }
    function onKeyUp2(props, chaptersAmount, pctgWatched, url, chapter, index, event) {
        keyUpHandler(event, this.loadChapter, null, this, [chapter]);
    }
    function repeatChapter3(props, chaptersAmount, pctgWatched, url, chapter, index) {
        return React.createElement('div', {
            'aria-label': 'Watch chapter ' + chapter.title,
            'className': 'chapter',
            'key': index,
            'onClick': () => this.loadChapter(chapter),
            'onKeyUp': onKeyUp2.bind(this, props, chaptersAmount, pctgWatched, url, chapter, index),
            'tabIndex': '0'
        }, React.createElement('div', { 'className': 'title' }, chapter.title), React.createElement('div', {
            'className': 'check-circle' + ' ' + _.transform({ unchecked: this.checkChapter(chapter) }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement('i', { 'className': 'icon-checkmark' })));
    }
    function scopePropsChaptersAmountPctgWatchedUrl4() {
        var props = this.props;
        var chaptersAmount = _.size(this.getChapters());
        var pctgWatched = this.props.isEnrolled ? this.checkOverallProgress() : 0;
        var url = `/tutorials/${ this.props.slug }`;
        return React.createElement('div', {
            'className': 'learning-path-course',
            'key': '215'
        }, React.createElement(Link, {
            'aria-label': props.title,
            'to': `/tutorials/${ this.props.slug }?watch=${ props.isBasicSubscriber ? 0 : 1 }&path=${ props.learningPath }&path_id=${ props.pathId }`
        }, _.has(props, 'photo.default') ? React.createElement('div', {
            'className': 'image-container',
            'key': '715'
        }, React.createElement('div', { 'className': 'overlay' }), React.createElement('div', {
            'style': { 'backgroundImage': `url(${ this.props.photo.boxThumbSm })` },
            'className': 'image'
        }), React.createElement('div', {
            'className': 'progress' + ' ' + _.transform({ completed: pctgWatched === 100 }, function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' ')
        }, React.createElement(RadialProgress, {
            'percentage': pctgWatched,
            'sqSize': 128
        }, React.createElement('i', { 'className': 'icon-' + this.constructor.getCategoryIconName(props.category) })), props.owned ? React.createElement('span', { 'key': '1239' }, parseInt(pctgWatched), '%') : null)) : null), React.createElement('div', { 'className': 'body' }, React.createElement('h3', { 'className': 'title' }, React.createElement(Link, { 'to': `/tutorials/${ this.props.slug }?watch=${ props.isBasicSubscriber ? 0 : 1 }&path=${ props.learningPath }&path_id=${ props.pathId }` }, props.title)), React.createElement('div', { 'className': 'small-wrapper' }, React.createElement('div', { 'className': 'course-description' }, React.createElement('p', {}, props.description, React.createElement(Link, { 'to': `/tutorials/${ this.props.slug }?path=${ props.learningPath }&path_id=${ props.pathId }` }, 'See more.'))), _.has(this.props, 'trailer') && !_.isEmpty(this.props.trailer) ? React.createElement('div', {
            'className': 'preview-course',
            'key': '1823'
        }, React.createElement('button', { 'onClick': () => this.loadCourse(this.props) }, '\n                  ', this.state.cta, '\n                ')) : null, _.has(props, 'artist.title') && _.has(props, 'artist.slug') ? React.createElement('div', {
            'className': 'author',
            'key': '2086'
        }, React.createElement('i', { 'className': 'icon-account' }), React.createElement('span', {}, React.createElement(Link, { 'to': `/artists/${ props.artist.slug }` }, props.artist.title))) : null, React.createElement('div', { 'className': 'level' }, React.createElement('i', { 'className': 'icon-level' + props.difficulty }), props.difficulty === 1 ? React.createElement('span', { 'key': '2444' }, 'Beginner Level') : null, props.difficulty === 2 ? React.createElement('span', { 'key': '2517' }, 'Intermediate Level') : null, props.difficulty === 3 ? React.createElement('span', { 'key': '2594' }, 'Advanced Level') : null), React.createElement('div', { 'className': 'duration' }, React.createElement('i', { 'className': 'fa-clock' }), props.durationH ? React.createElement('span', { 'key': '2760' }, props.durationH, 'h ') : null, React.createElement('span', {}, props.durationM, 'm'), '\n              of duration\n            '), props.isEnrolled ? [
            React.createElement('div', {
                'aria-label': 'See chapter list',
                'className': 'completion',
                'onClick': () => this.toggle(),
                'onKeyUp': onKeyUp1.bind(this, props, chaptersAmount, pctgWatched, url),
                'tabIndex': '0',
                'key': '29241'
            }, React.createElement('i', { 'className': 'icon-stream' }), React.createElement('div', { 'className': 'text' }, React.createElement('span', {}, `${ this.checkCompletedChapters() } chapters completed`), React.createElement('i', {
                'className': 'icon-right-2' + ' ' + _.transform({ animate: this.state.visible }, function (res, value, key) {
                    if (value) {
                        res.push(key);
                    }
                }, []).join(' ')
            })), parseInt(props.overallProgress, 10) === 100 ? React.createElement('div', {
                'className': 'cleared',
                'key': '3574'
            }, React.createElement('span', {}, 'cleared'), React.createElement('div', { 'className': 'check-circle' }, React.createElement('i', { 'className': 'icon-checkmark' }))) : null),
            this.state.visible ? React.createElement.apply(this, [
                'div',
                {
                    'className': 'chapters',
                    'key': '29243'
                },
                _.map(this.getChapters(), repeatChapter3.bind(this, props, chaptersAmount, pctgWatched, url))
            ]) : null
        ] : null)));
    }
    return _.size(this.getChapters()) > 0 && this.isFiltered(this.props.category) ? scopePropsChaptersAmountPctgWatchedUrl4.apply(this, []) : null;
}