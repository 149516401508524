import * as React from 'react';
import * as _ from 'lodash';
import CollapsibleOrder from './CollapsibleOrder';
export default function () {
    function repeatSubscription1(isEmpty, subscription, subscriptionIndex) {
        return React.createElement(CollapsibleOrder, _.assign({}, {
            'key': subscriptionIndex,
            'isMobile': this.props.isMobile,
            'activeSubscription': this.props.subscription,
            'isSubscription': true
        }, subscription));
    }
    function repeatOrder2(isEmpty, order, orderIndex) {
        return React.createElement(CollapsibleOrder, _.assign({}, {
            'key': orderIndex,
            'isMobile': this.props.isMobile
        }, order));
    }
    function scopeIsEmpty3() {
        var isEmpty = _.size(this.props.orders) === 0 && _.size(this.props.subscriptions) === 0;
        return React.createElement.apply(this, [
            'div',
            {
                'className': 'account-section',
                'id': 'Orders'
            },
            React.createElement('h3', { 'className': 'account-section__title' }, 'order history'),
            _.size(this.props.subscriptions) > 0 ? React.createElement('h4', { 'key': '280' }, 'Subscriptions') : null,
            _.map(this.props.subscriptions, repeatSubscription1.bind(this, isEmpty)),
            _.size(this.props.orders) > 0 ? React.createElement('h4', { 'key': '593' }, 'Purchased items') : null,
            this.props.orders.length ? React.createElement('div', {
                'className': 'table-header',
                'key': '658'
            }, React.createElement('div', { 'className': 'date' }, 'order date'), React.createElement('div', { 'className': 'order-id' }, 'order id'), React.createElement('div', { 'className': 'status' }, 'status'), React.createElement('div', { 'className': 'total' }, 'total')) : null,
            _.map(this.props.orders, repeatOrder2.bind(this, isEmpty)),
            isEmpty ? React.createElement('div', {
                'className': 'no-results',
                'key': '1028'
            }, React.createElement('span', {}, 'You don\'t have any orders yet.')) : null
        ]);
    }
    return scopeIsEmpty3.apply(this, []);
}