import React from 'react'
import { Link } from 'react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'

import keyUpHandler from '../../utils/keyUpHandler'
import SubscribeDialogCTA from '../../App/SubscribeDialogCTA'
import getRoutePath from '../../utils/getRoutePath'

import './styles.css'

export const parseSubscriberAction = action => {
  if (typeof action === 'string' && /tutorials\/.*\/watch/.test(action)) {
    const routePath = getRoutePath(window.location.href)
    if (/\?.*=.*/.test(action)) return `${action}&referer=${routePath}`
    return `${action}?referer=${routePath}`
  }
  return action
}

function FeaturedElement(props) {
  const withClick = props.onClick
  const hasBtn = !_.isEmpty(props.btn)
  const trailerBtn = (
    <button
      aria-labelledby="preview-label"
      onClick={() => props.onClick()}
      onKeyUp={event => keyUpHandler(event, props.onClick)}
      tabIndex="0"
      type="button"
    >
      <i className="icon-play" />
      <span id="preview-label" className="preview">
        Preview
      </span>
    </button>
  )

  return (
    <div className="FeaturedElement">
      <div
        className="photo"
        style={{ backgroundImage: `url('${props?.photo.original}')` }}
      >
        {withClick && <div className="overlay">{trailerBtn}</div>}
        {props.new && <div className="new-course-badge">new course</div>}
      </div>
      <div className="headline">
        {props.categoryLinkTo && (
          <Link to={props.categoryLinkTo} className="category">
            {props.categoryName}
          </Link>
        )}
        <div className="title-container">
          <h1 className="title">{props.title}</h1>
          <div className="separator" />
          <h2 className="artist">{props.artist}</h2>
        </div>
        {hasBtn &&
          props?.btn.text !== 'Subscribe to Watch' &&
          typeof props?.btn.action !== 'function' && (
            <Link
              to={parseSubscriberAction(props?.btn.action)}
              className={
                props?.btn.class
                  ? props?.btn.class
                  : 'read-on btn -thin -blue-2 no-margin'
              }
            >
              {props?.btn.text}
            </Link>
          )}
        {hasBtn &&
          props?.btn.text === 'Subscribe to Watch' &&
          typeof props?.btn.action !== 'function' && (
            <SubscribeDialogCTA>
              <div className="subscribe-to-watch-btn">
                <span>{props?.btn.text}</span>
              </div>
            </SubscribeDialogCTA>
          )}
        {!props.usedFreeTrial &&
          hasBtn &&
          props?.btn.text === 'Subscribe to Watch' &&
          typeof props?.btn.action !== 'function' && (
            <span className="add-to-cart-msg">Free 7-Day Trial</span>
          )}
        {hasBtn && typeof props?.btn.action === 'function' && (
          <button
            className={
              props?.btn.class
                ? props?.btn.class
                : 'read-on btn -thin -blue-2 no-margin'
            }
            onClick={() => props?.btn.action()}
            type="button"
          >
            {props?.btn.text}
          </button>
        )}
        {props.extraHTML && <div className="extra-html">{props.extraHTML}</div>}
      </div>
    </div>
  )
}

export const FeaturedElementStructure = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  photo: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
  btn: PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    class: PropTypes.string
  }),
  categoryName: PropTypes.string,
  categoryLinkTo: PropTypes.string,
  isMobile: PropTypes.bool,
  usedFreeTrial: PropTypes.bool
}

FeaturedElement.propTypes = FeaturedElementStructure

FeaturedElement.defaultProps = {
  btn: {},
  category: null,
  categoryLinkTo: '',
  categoryName: '',
  description: '',
  isMobile: false,
  linkTo: '',
  onClick: null,
  usedFreeTrial: false,
  videoAction: null
}

export default FeaturedElement
