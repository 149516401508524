import PropTypes from 'prop-types'
import { Component } from 'react'

import BlogPostStructure from './BlogPost/propTypes'
import { facebookReparse } from '../../utils/facebookUtils'

import template from './template.rt'
import './styles.css'

export class BlogPosts extends Component {
  componentDidMount() {
    facebookReparse()
  }

  render() {
    return template.call(this)
  }
}

BlogPosts.propTypes = {
  blogPosts: PropTypes.arrayOf(PropTypes.shape(BlogPostStructure)).isRequired,
  showSocial: PropTypes.bool
}

BlogPosts.defaultProps = {
  blogPosts: [],
  showSocial: true
}

export default BlogPosts
