import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    function repeatChapter1(props, chapter, chapterIndex) {
        return React.createElement('li', { 'key': chapterIndex }, React.createElement('span', { 'className': 'chapter' }, chapter));
    }
    function scopeProps2() {
        var props = this.props;
        return React.createElement('div', { 'className': 'SpecialVideoChapters' }, React.createElement.apply(this, [
            'ol',
            {},
            _.map(props.chapters.split('\r\n\r\n'), repeatChapter1.bind(this, props))
        ]));
    }
    return scopeProps2.apply(this, []);
}