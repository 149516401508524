/* global FB, FACEBOOK_APP_ID, API_ROOT */
/* eslint-disable import/prefer-default-export */
import loadScript from 'tiny-load-script'

export const facebookIsDefined = () => typeof FB !== 'undefined' && FB != null

let SDK

const loadFBSDK = () => {
  SDK =
    SDK ||
    new Promise(resolve => {
      function sdkReady() {
        resolve(window.FB)
      }
      if (window.FB && window.FB.init) sdkReady()
      else {
        window.fbAsyncInit = () => {
          FB.init({
            appId: FACEBOOK_APP_ID,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v5.0'
          })
        }
        if (!document.querySelector('script[src*="connect.facebook.net"]'))
          loadScript('//connect.facebook.net/en_US/sdk.js')
      }
    })
  return SDK
}

export const facebookReparse = () => {
  if (facebookIsDefined()) FB.XFBML.parse()
  else loadFBSDK().then(FB => FB.XFBML.parse())
}
