/* global JWPLAYER_LICENSE_KEY */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import logger from 'components/utils/logger'

import { playerSelector as stateSelector } from 'reduxModules/player/selectors'
import { playerDispatchers as dispatchers } from 'reduxModules/player/dispatchers'

import JWPlayer from '@jwplayer/jwplayer-react'
import _ from 'lodash'

import { TutorialSummaryPropTypes } from 'components/generic/CourseSummary/propTypes'
import RelatedModal from './RelatedModal'

import {
  TutorialPropTypes,
  TutorialDefaultProps
} from '../Tutorials/TutorialDetail'
import PlayerSidebar from './PlayerSidebar'

import {
  getChapterFromURL,
  getSecondsFromTimestamp,
  getChapterTimePositionFromURL
} from './utils'

import './styles.css'
import { COMPLETION_THRESHOLD } from './PlayerSidebar/ChapterList'

const CHAPTER_PROGRESS_UPDATE_FREQUENCY = 1000 // One second
const TICK_FREQUENCY = 5000 // Five seconds

function NewVideoPlayer(props) {
  /**
   * Props
   */
  const {
    chapters,
    startTutorialStatistics,
    tutorial,
    setCurrentChapter,
    videoId,
    bookmarkLoaded,
    resume,
    currentChapterIndex,
    recommends,
    played,
    disableStats,
    videoStatisticId,
    loadNextTutorials,
    sendTick,
    isSubscriptionApprentice,
    apprenticeVideosSeen,
    addVideoSeen,
    isMobile,
    markChapterAsCompleted,
    chaptered,
    isSpecialVideo,
    createBookmark,
    toggleChaptersSidebar,
    hideSidebar,
    displayCreateBookmark,
    removeBookmark,
    userBookmarks,
    savePlayerId,
    learningPath,
    shouldOnlyShowRelevants,
    toggleShowingOnlyRelevantChapters,
    relevantChapters,
    cleanPlayer,
    updateUserProgress,
    userProgress,
    slug,
    router,
    isBasicSubscriber
  } = props
  /**
   * States
   */
  const [player, setPlayer] = useState(null)
  const [displayModal, setDisplayModal] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playerIsReady, setPlayerIsReady] = useState(false)
  const [playlistIsComplete, setPlaylistIsComplete] = useState(false)
  const [recommendedWereDisplayed, setRecommendedWereDisplayed] = useState(
    false
  )
  /**
   *  Methods
   */
  // Keeps the chapter query param url in sync when present
  const updateCurrentChapterURL = (index, shouldClearTime) => {
    // Don't update URL if is a special video (no chapters)
    if (isSpecialVideo) return

    const searchParams = new URLSearchParams(window.location?.search)
    const position = index + 1

    if (index !== undefined) {
      if (
        searchParams.has('chapter') &&
        searchParams.get('chapter') !== position
      )
        searchParams.set('chapter', position)
    } else searchParams.delete('chapter')

    // Remove time query param if requested (auto-advancing case)
    if (shouldClearTime) searchParams.delete('time')
    // Push new route
    router.push(`/tutorials/${slug}?${searchParams.toString()}`)
  }
  const playChapter = (index, time) => {
    // Get current playlist item playing
    const currentlyPlaying = player.getPlaylistIndex()
    const playlist = player.getPlaylist()
    const chapterId = playlist[index]?.extra?.id
    const isChangingChapters =
      currentChapterIndex !== index || index !== currentlyPlaying
    // If no valid index is passed, play from the beginning
    if (index === -1) player.playlistItem(0)
    // If chapter to play is not already playing, jump to play it
    if (isChangingChapters) player.playlistItem(index)

    // Update current chapter
    setCurrentChapter(videoId, index, chapterId)
    updateCurrentChapterURL(index)
    // Scroll to the top if mobile
    if (isMobile) window.scrollTo(0, 0)

    if (time !== undefined)
      if (isChangingChapters)
        // If time is valid, subscribe to jump to time after auto-play's "play" event is triggered
        player.once('play', () => player.seek(time))
      // If there is no chapter change, seek immediately
      else player.seek(time)
  }

  const throttledChapterProgressUpdate = useCallback(
    _.throttle((currentTime, currentChapterId, currentDuration) => {
      updateUserProgress({
        id: currentChapterId,
        currentPosition: currentTime,
        duration: currentDuration
      })
    }, CHAPTER_PROGRESS_UPDATE_FREQUENCY),
    []
  )
  const cancelChapterProgressUpdate = () => {
    // If a progress update is pending from previous chapter, cancel it
    throttledChapterProgressUpdate.cancel()
  }
  /**
   *  Handlers
   */
  // Handler for when the playlist index changes to a new playlist item. This event occurs before the player begins playing the new playlist item.
  const playlistItemHandler = ({ item }) => {
    const playlistItemId = item?.extra?.id
    const playlist = player.getPlaylist()
    const currentChapterIndex = playlist.findIndex(
      chapter => chapter?.extra?.id === playlistItemId
    )
    const chapterIndexToUpdate =
      currentChapterIndex === -1 ? 0 : currentChapterIndex
    // Abort any progress tracking since playlist items are changing
    cancelChapterProgressUpdate()
    // Update redux and URL with new chapter as current chapter
    setCurrentChapter(videoId, chapterIndexToUpdate, playlistItemId)
  }
  // Handler when the playlist is completed (the course finished)
  const playlistCompleteHandler = () => {
    setCurrentChapter(videoId, 0, null)
    updateCurrentChapterURL(0)
    setIsPlaying(false)
    setPlaylistIsComplete(true)
    cancelChapterProgressUpdate()
  }
  const playHandler = ({ playReason }) => {
    setIsPlaying(true)

    // Auto-advance to previous playback position if chapter is playing due to autoadvancing
    if (playReason === 'playlist') {
      const currentChapterIndex = player.getPlaylistIndex()
      const currentChapter = player.getPlaylistItem(currentChapterIndex)
      const playlistItemId = currentChapter?.extra?.id
      const progress = userProgress[playlistItemId]

      if (progress?.currentPosition > 0 && !progress?.completed)
        player.seek(progress?.currentPosition)

      // Update query param and remove time query param if present to keep it consistent in case the user reloads the page
      updateCurrentChapterURL(currentChapterIndex, true)
    }

    if (disableStats) return

    /* Mark video as seen */
    if (
      isSubscriptionApprentice &&
      _.findIndex(apprenticeVideosSeen, { id: tutorial.id }) === -1 &&
      !tutorial.owned
    )
      addVideoSeen(tutorial)
  }
  const errorHandler = ({ error }) => {
    logger.error(error)
  }
  /**
   *  Effects
   */
  // Effect to send tick every five seconds to server to track user progress
  useEffect(() => {
    if (
      !chapters.length ||
      !player ||
      !videoStatisticId ||
      currentChapterIndex === undefined ||
      !isPlaying ||
      disableStats
    )
      // If player is not ready, paused, or stats are disabled then disable interval
      return undefined
    // Get current chapter id
    const playlist = player.getPlaylist()
    const currentChapterId = playlist[currentChapterIndex]?.extra?.id
    // Get current caption selection
    const getCaptionsLabel = () => {
      const config = player.getConfig()

      return config?.captionsTrack?.label
    }
    // Create five seconds interval to send tick to server
    const intervalId = setInterval(() => {
      sendTick(
        videoStatisticId,
        currentChapterId,
        player.getPosition(),
        getCaptionsLabel()
      )
    }, TICK_FREQUENCY)

    // Clean up hook
    return () => {
      clearInterval(intervalId)
    }
  }, [chapters, player, videoStatisticId, currentChapterIndex, isPlaying])
  // Effect for resuming progress
  useEffect(() => {
    if (playerIsReady && player) {
      /**
       *  Resume progress logic
       *  Step 1 - Set active chapter
       */
      // Attempt to read chapter to play from URL
      const chapterViaQueryParam = getChapterFromURL(chapters.length)
      const playlist = player.getPlaylist()
      if (chapterViaQueryParam !== undefined) {
        const chapterId = playlist[chapterViaQueryParam]?.extra?.id
        setCurrentChapter(videoId, chapterViaQueryParam, chapterId)
      } else if (bookmarkLoaded) {
        const index = bookmarkLoaded.chapterOrder - 1
        const chapterId = playlist[index]?.extra?.id
        setCurrentChapter(videoId, index, chapterId)
      } else if (resume && playlist.length) {
        const index = resume.order - 1
        const chapterId = playlist[index]?.extra?.id
        setCurrentChapter(videoId, index, chapterId)
      }
      /**
       *  Resume progress logic
       *  Step 2 - Set active time and play chapter
       */
      if (chapterViaQueryParam !== undefined) {
        const chapter = playlist[chapterViaQueryParam]
        const chapterLength = getSecondsFromTimestamp({
          durationH: chapter?.durationH,
          durationM: chapter?.durationM,
          durationS: chapter?.durationS
        })
        const playlistItemId = chapter?.extra?.id
        const chapterProgress = userProgress[playlistItemId]
        // Look for time data in URL, or fall back to previous user progress if exists and chapter hasn't been completed
        const time =
          getChapterTimePositionFromURL(chapterLength) ||
          (chapterProgress?.completed &&
          chapterProgress?.progress > COMPLETION_THRESHOLD
            ? 0
            : chapterProgress?.currentPosition)
        playChapter(chapterViaQueryParam, time)
      }
      // If no chapter data is found in URL, attempt to auto-resume from stored data
      else if (resume) {
        const time = resume.time
        playChapter(resume.order - 1, time)
      }
      // If everything else fails, start from the beginning
      else playChapter(0, 0)
    }
  }, [playerIsReady, player])
  // Effect to load next tutorials on pathway
  useEffect(() => {
    if (playlistIsComplete) {
      const url = new URL(window.location.href)
      loadNextTutorials(tutorial.slug, url.searchParams.get('path_id'))
    }
  }, [currentChapterIndex, tutorial, playlistIsComplete])
  // Effect to display recommended tutorials at the end of the course
  useEffect(() => {
    if (recommends.length && !recommendedWereDisplayed && playlistIsComplete) {
      setDisplayModal(true)
      // Display recommended modal only once
      setRecommendedWereDisplayed(true)
    }
  }, [recommends, recommendedWereDisplayed, playlistIsComplete])
  // Effect to reload playlist and resume playback when toggling relevant chapters
  useEffect(() => {
    if (chapters && player) {
      const playStatus = player.getState()
      player.load(chapters)

      // If player is playing, auto-play new playlist item after switching
      if (playStatus === 'playing')
        player.once('playlistItem', () => player.play())

      // Remove chapter url if present to avoid inconsistencies
      updateCurrentChapterURL()
    }
  }, [shouldOnlyShowRelevants, chapters])
  /**
   * Callbacks
   */
  const onReadyCallback = status => {
    if (status?.type === 'ready' && !isSpecialVideo) {
      // Init videoStatisticId
      const url = new URL(window.location.href)
      startTutorialStatistics(tutorial.id, url.searchParams.get('path_id'))

      // Permanent events bindings
      player.on('playlistItem', playlistItemHandler)
      player.on('playlistComplete', playlistCompleteHandler)
      player.on('play', playHandler)
      player.on('error', errorHandler)
      player.on('setupError', errorHandler)
      player.on('pause', () => setIsPlaying(false))
      player.on('idle', () => setIsPlaying(false))
      player.on('time', ({ currentTime }) => {
        const currentlyPlaying = player.getPlaylistIndex()
        const playlist = player.getPlaylist()
        const chapterId = playlist[currentlyPlaying]?.extra?.id
        // Extract video duration from player config, this could be calculated once elsewhere, considering it doesn't change
        const config = player.getConfig()

        throttledChapterProgressUpdate(currentTime, chapterId, config?.duration)
      })
      player.once('viewable', viewable => setPlayerIsReady(!!viewable))
    }
  }
  const didMountCallback = ({ player, id }) => {
    setPlayer(player)
    savePlayerId(id)
  }
  const willUnmountCallback = () => {
    cleanPlayer()
  }
  /**
   * Component
   */
  return chapters.length > 0 ? (
    <div className={`video-player ${hideSidebar ? 'full' : ''}`}>
      <div className="video-player__video">
        <JWPlayer
          library="../../global/vendor/jwplayer-8.36.2/jwplayer.js"
          playlist={chapters}
          didMountCallback={didMountCallback}
          willUnmountCallback={willUnmountCallback}
          onReady={onReadyCallback}
          config={{
            key: JWPLAYER_LICENSE_KEY,
            floating: {
              dismissible: true
            },
            ga: {
              label: 'mediaid',
              useUniversalAnalytics: false,
              sendEnhancedEvents: true
            },
            width: '100%',
            height: '100%',
            autostart: true,
            playbackRateControls: true,
            playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
            cast: {},
            captions: {
              backgroundColor: '#000000',
              backgroundOpacity: 60,
              color: '#ffffff',
              edgeColor: 'black',
              edgeStyle: 'raised',
              fontFamily: 'Tahoma, sans-serif',
              fontOpacity: 100,
              fontSize: 14,
              windowColor: '000000',
              windowOpacity: 0
            }
          }}
        />
      </div>
      <PlayerSidebar
        player={player}
        playlist={chapters}
        isSpecialVideo={isSpecialVideo}
        chaptered={chaptered}
        playerIsReady={playerIsReady}
        createBookmark={createBookmark}
        toggleSidebar={toggleChaptersSidebar}
        playChapter={playChapter}
        currentChapter={currentChapterIndex}
        played={played}
        userBookmarks={userBookmarks}
        displayCreateBookmark={displayCreateBookmark}
        removeBookmark={removeBookmark}
        tutorial={tutorial}
        shouldOnlyShowRelevants={shouldOnlyShowRelevants}
        toggleShowingOnlyRelevantChapters={toggleShowingOnlyRelevantChapters}
        shouldShowRelevantToggle={relevantChapters?.length > 0}
        userProgress={userProgress}
        isPlaying={isPlaying}
        markChapterAsCompleted={markChapterAsCompleted}
        updateUserProgress={updateUserProgress}
      />
      <RelatedModal
        visible={displayModal}
        close={() => setDisplayModal(false)}
        recommends={recommends}
        isMobile={isMobile}
        learningPath={learningPath}
        isBasicSubscriber={isBasicSubscriber}
      />
    </div>
  ) : null
}

NewVideoPlayer.propTypes = {
  videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tutorial: PropTypes.shape(TutorialPropTypes),
  playlist: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      tracks: PropTypes.arrayOf(
        PropTypes.shape({
          // TODO: fill this
        })
      ),
      image: PropTypes.string,
      sources: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      )
    })
  ),
  videoStatisticId: PropTypes.number,
  apprenticeVideosSeen: PropTypes.arrayOf(
    PropTypes.shape(TutorialSummaryPropTypes)
  ).isRequired,
  isSubscriptionApprentice: PropTypes.bool.isRequired,
  played: PropTypes.arrayOf(PropTypes.number).isRequired,
  chapterParam: PropTypes.number,
  isMobile: PropTypes.bool,
  setCurrentChapter: PropTypes.func.isRequired,
  getPlayerState: PropTypes.func.isRequired,
  startTutorialStatistics: PropTypes.func.isRequired,
  sendTick: PropTypes.func.isRequired,
  clearStatistics: PropTypes.func.isRequired,
  addVideoSeen: PropTypes.func.isRequired,
  disableStats: PropTypes.bool,
  resume: PropTypes.shape({
    time: PropTypes.number,
    order: PropTypes.number
  }),
  isSpecialVideo: PropTypes.bool
}

NewVideoPlayer.defaultProps = {
  videoStatisticId: null,
  chapterParam: null,
  isMobile: false,
  disableStats: false,
  tutorial: { ...TutorialDefaultProps },
  resume: null,
  playlist: [],
  isSpecialVideo: false
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { getPlayerState } = stateProps
  const { videoId } = ownProps
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    ...getPlayerState(videoId)
  }
}

export default connect(stateSelector, dispatchers, mergeProps)(NewVideoPlayer)
