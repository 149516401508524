/* global paypal, braintree, BRAINTREE_ENVIRONMENT */
import logger from 'components/utils/logger'

export default (
  authorization,
  amount,
  callback,
  order,
  isSubscribing,
  plan,
  usedFreeTrial,
  deviceData,
  userEmail
) =>
  braintree.client.create({ authorization }, (clientErr, clientInstance) => {
    // Stop if there was a problem creating the client.
    // This could happen if there is a network error or if the authorization
    // is invalid.
    if (clientErr) {
      logger.error(`Error creating client: ${clientErr}`)
      return
    }

    // Create a PayPal Checkout component.
    braintree.paypalCheckout.create(
      { client: clientInstance },
      (paypalCheckoutErr, paypalCheckoutInstance) => {
        // Stop if there was a problem creating PayPal Checkout.
        // This could happen if there was a network error or if it's incorrectly
        // configured.
        if (paypalCheckoutErr) {
          logger.error(`Error creating PayPal Checkout: ${paypalCheckoutErr}`)
          return
        }

        // Set up PayPal with the checkout.js library
        paypal.Button.render(
          {
            env: BRAINTREE_ENVIRONMENT, // or 'sandbox'
            payment: () =>
              paypalCheckoutInstance.createPayment({
                flow: 'vault',
                amount,
                currency: 'USD',
                intent: 'authorize'
              }),
            onAuthorize: data =>
              paypalCheckoutInstance.tokenizePayment(data).then(payload => {
                if (isSubscribing)
                  return callback(
                    payload.nonce,
                    order,
                    plan,
                    usedFreeTrial,
                    userEmail
                  )
                return callback(payload.nonce, order, deviceData)
              })
          },
          '#paypal-button-container'
        )
      }
    )
  })
