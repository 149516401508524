import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { leadMagnetPagesStatus } from 'reduxModules/leadMagnetPages'
import { leadMagnetPageStateSelector as stateSelector } from 'reduxModules/leadMagnetPages/selectors'
import { leadMagnetPageDispatchers as dispatchers } from 'reduxModules/leadMagnetPages/dispatchers'
import { TutorialSummaryPropTypes } from 'components/generic/CourseSummary/propTypes'

import template from './template.rt'

import './styles.css'

export class LeadMagnetPage extends Component {
  componentDidMount() {
    this.props.loadLeadMagnetPage()
  }

  render() {
    if (this.props.status === leadMagnetPagesStatus.success)
      return template.call(this)
    return null
  }
}

LeadMagnetPage.propTypes = {
  loadLeadMagnetPage: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  banner: PropTypes.shape({
    asset: PropTypes.shape({
      original: PropTypes.string,
      default: PropTypes.string,
      mobile: PropTypes.string,
      mobilexs: PropTypes.string,
      normal: PropTypes.string
    }),
    countdownDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    link: PropTypes.string,
    orderDate: PropTypes.string,
    title: PropTypes.string,
    hasGradient: PropTypes.bool
  }).isRequired,
  slug: PropTypes.string,
  body: PropTypes.string,
  tutorials: PropTypes.arrayOf(PropTypes.shape(TutorialSummaryPropTypes))
    .isRequired,
  isMobile: PropTypes.bool
}

LeadMagnetPage.defaultProps = {
  body: '',
  banner: {
    medium: ''
  },
  tutorials: [],
  slug: '',
  subtitle: '',
  isMobile: false
}

export default connect(
  stateSelector,
  dispatchers
)(LeadMagnetPage)
