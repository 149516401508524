/* eslint-disable import/prefer-default-export */
import { createStructuredSelector, createSelector } from 'reselect'
import {
  isMobileSelector,
  hasSubscriptionSelector
} from 'reduxModules/app/selectors'
import {
  isGroupSubscriberSelector,
  isAnnualSubscriberSelector
} from 'reduxModules/users/selectors'
import { assetsGallerySelector } from '../assets/selectors'
import { subscriptionSelector } from '../auth/selectors'

// selectors
export const webinarsSelector = state => state.webinars.list
export const bannersSelector = state => state.banners.list
export const webinarSelector = state => state.webinars.detail
export const statusSelector = state => state.webinars.status
export const nextWebinarsSelector = state => state.webinars.next
export const cartItemsSelector = state => state.cart.list
const assets = state => assetsGallerySelector(state)

export const webinarBannerSelector = createSelector(
  bannersSelector,
  banners => {
    if (!banners.length) return null
    const banner = banners.filter(b => b.webinarBanner)[0]
    return {
      title: banner.title,
      description: banner.description,
      linkTo: banner.link,
      photo: banner.asset
    }
  }
)

export const webinarVideoPreviewSelector = createSelector(
  webinarSelector,
  webinar => {
    if (webinar.trailer && webinar.trailer.raw && webinar.trailer.raw.high)
      return webinar.trailer.raw.high
    if (webinar.trailer && webinar.trailer.raw && webinar.trailer.raw.low)
      return webinar.trailer.raw.low
    return null
  }
)

export const webinarVariantOwnedSelector = createSelector(
  webinarSelector,
  webinar => webinar.variants && webinar.variants.some(v => v.owned)
)

// states (mapStateToProps)
export const webinarsStateSelector = createStructuredSelector({
  webinars: webinarsSelector,
  banner: webinarBannerSelector,
  nextWebinars: nextWebinarsSelector,
  status: statusSelector
})

export const webinarStateSelector = createSelector(
  [
    webinarSelector,
    webinarVideoPreviewSelector,
    statusSelector,
    assets,
    subscriptionSelector,
    webinarVariantOwnedSelector,
    isMobileSelector,
    hasSubscriptionSelector,
    isGroupSubscriberSelector,
    isAnnualSubscriberSelector
  ],
  (
    webinar,
    videoPreviewURL,
    status,
    assets,
    subscription,
    variantOwned,
    isMobile,
    isSubscriber,
    isGroupSubscriber,
    isAnnualSubscriber
  ) => ({
    ...webinar,
    videoPreviewURL,
    status,
    assets,
    subscription,
    variantOwned,
    isMobile,
    isSubscriber,
    isGroupSubscriber,
    isAnnualSubscriber
  })
)
