import * as React from 'react';
import * as _ from 'lodash';
import Tutorial from '../../../generic/CourseSummary';
import Teacher from '../../../generic/ProfileSummary';
import BlogPost from '../../../Blog/BlogPosts/BlogPost/_lazy';
import getArtistTagline from '../../../utils/getArtistTagline';
export default function () {
    function repeatT1(results, tutorials, artists, posts, t, tIndex) {
        return React.createElement(Tutorial, {
            'className': 'tutorial',
            'key': 'tutorial-' + t.favoritedObject.id,
            'slug': t.favoritedObject.slug,
            'summary': t.favoritedObject.title,
            'teacher': t.favoritedObject.artist.title,
            'teacherSlug': t.favoritedObject.artist.slug,
            'title': t.favoritedObject.title,
            'thumb': t.favoritedObject.photo.boxThumb,
            'new': t.favoritedObject.new
        });
    }
    function repeatArtist2(results, tutorials, artists, posts, artist, artistIndex) {
        return React.createElement(Teacher, {
            'className': 'artist',
            'key': 'artist-' + artistIndex,
            'name': artist.favoritedObject.title,
            'photo': artist.favoritedObject.photo.boxThumb,
            'slug': artist.favoritedObject.slug,
            'tagline': getArtistTagline(artist.favoritedObject.tags)
        });
    }
    function repeatBlogPost3(results, tutorials, artists, posts, blogPost, blogPostIndex) {
        return React.createElement(BlogPost, _.assign({}, {
            'key': blogPostIndex,
            'showSocial': false
        }, blogPost.favoritedObject));
    }
    function scopeResultsTutorialsArtistsPosts4() {
        var results = this.props.favorites.results;
        var tutorials = _.filter(results, { favoritedModel: 'videotutorial' });
        var artists = _.filter(results, { favoritedModel: 'artist' });
        var posts = _.filter(results, { favoritedModel: 'post' });
        return React.createElement('div', {
            'id': 'FavoriteList',
            'className': 'account-section'
        }, tutorials.length ? React.createElement('div', {
            'className': 'courses',
            'key': '628'
        }, React.createElement('h3', { 'className': 'account-section__title' }, 'courses'), React.createElement.apply(this, [
            'section',
            { 'className': 'tutorials-list-content' },
            _.map(tutorials, repeatT1.bind(this, results, tutorials, artists, posts))
        ])) : null, artists.length ? React.createElement('div', {
            'className': 'teachers',
            'key': '1253'
        }, React.createElement('h3', { 'className': 'account-section__title' }, 'teachers'), React.createElement.apply(this, [
            'div',
            { 'className': 'artists-list-content' },
            _.map(artists, repeatArtist2.bind(this, results, tutorials, artists, posts))
        ])) : null, posts.length ? React.createElement('div', {
            'className': 'posts',
            'key': '1742'
        }, React.createElement('h3', { 'className': 'account-section__title' }, 'blog articles'), React.createElement.apply(this, [
            'div',
            { 'className': 'blog-posts-content' },
            _.map(posts, repeatBlogPost3.bind(this, results, tutorials, artists, posts))
        ])) : null, results && results.length === 0 ? React.createElement('div', {
            'className': 'no-results',
            'key': '2062'
        }, React.createElement('span', {}, 'You don\'t have any favorites yet.')) : null);
    }
    return scopeResultsTutorialsArtistsPosts4.apply(this, []);
}