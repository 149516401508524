import { createAction } from 'redux-actions'
import { createReducer, createTypes } from 'reduxsauce'
import fetchApi from '../utils/fetch'

//
// ACTIONS
// -----------------------------------------------------------------------------

export const types = createTypes(`
  LEAD_MAGNET_PAGE_INITIAL
  LEAD_MAGNET_PAGE_SUCCESS
  LEAD_MAGNET_PAGE_ERROR
  LEAD_MAGNET_PAGE_FETCH_SUCCESS
  LEAD_MAGNET_PAGE_REQUEST_FAILURE
  LEAD_MAGNET_PAGE_PLAY_VIDEO
  LEAD_MAGNET_PAGE_CLEAR_VIDEO
  LEAD_MAGNET_PAGE_FUNNEL_TOGGLE_NEWSLETTER
  ENABLE_LEAD_MAGNET_FUNNEL_FORM_CTA
  DISABLE_LEAD_MAGNET_FUNNEL_FORM_CTA
  CLAIM_LEAD_MAGNET_REQUEST_SUCCESS
  CLAIM_LEAD_MAGNET_REQUEST_FAILURE
  DISPLAY_LEAD_MAGNET_DIALOG
  HIDE_LEAD_MAGNET_DIALOG
  RESET_LEAD_MAGNET_DIALOG
  LEAD_MAGNET_DIALOG_FORM_SUBMITTED
`)

export const leadMagnetPagesStatus = {
  initial: types.LEAD_MAGNET_PAGE_INITIAL,
  success: types.LEAD_MAGNET_PAGE_SUCCESS,
  error: types.LEAD_MAGNET_PAGE_ERROR
}

//
// REDUCER
// -----------------------------------------------------------------------------

const INITIAL_STATE = {
  status: leadMagnetPagesStatus.initial,
  detail: {
    banner: {
      id: 0,
      title: '',
      description: '',
      asset: {
        original: '',
        default: '',
        mobile: '',
        mobilexs: '',
        normal: ''
      },
      orderDate: '',
      countdownDate: null,
      ctaLabel: '',
      ctaUrl: '',
      hasGradient: false,
      video: null,
      videoThumbnail: ''
    },
    videos: [],
    slug: '',
    title: '',
    socialUrl: ''
  },
  player: {
    isPlaying: false,
    urlHi: '',
    urlLow: '',
    subtitles: [],
    vtt: ''
  },
  form: {
    newsletter: true,
    email: '',
    isCTADisabled: false
  },
  dialog: {
    displayLeadMagnetFunnelModal: false,
    wasLeadMagnetFunnelModalDismissed: false,
    wasLeadMagnetFunnelModalSubmitted: false
  }
}

export const successDetail = (state = INITIAL_STATE, action) => ({
  ...state,
  detail: action.payload,
  status: leadMagnetPagesStatus.success
})

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  status: leadMagnetPagesStatus.error
})

export const playVideo = (state = INITIAL_STATE, action) => ({
  ...state,
  player: {
    isPlaying: true,
    ...action.payload
  }
})

export const clearVideo = (state = INITIAL_STATE) => ({
  ...state,
  player: INITIAL_STATE.player
})

export const toggleNewsletterSuccess = (state = INITIAL_STATE) => ({
  ...state,
  form: { ...state.form, newsletter: !state.form.newsletter }
})

const enableCtaHandler = (state = INITIAL_STATE) => ({
  ...state,
  form: { ...state.form, isCTADisabled: false }
})

const disableCtaHandler = (state = INITIAL_STATE) => ({
  ...state,
  form: { ...state.form, isCTADisabled: true }
})

const displayDialogHandler = (state = INITIAL_STATE) => ({
  ...state,
  dialog: { ...state.dialog, displayLeadMagnetFunnelModal: true }
})

const hideDialogHandler = (state = INITIAL_STATE) => ({
  ...state,
  dialog: {
    ...state.dialog,
    displayLeadMagnetFunnelModal: false,
    wasLeadMagnetFunnelModalDismissed: true
  }
})

const resetDialogHandler = (state = INITIAL_STATE) => ({
  ...state,
  dialog: {
    ...state.dialog,
    displayLeadMagnetFunnelModal: false,
    wasLeadMagnetFunnelModalDismissed: false
  }
})

const dialogSubmittedHandler = (state = INITIAL_STATE) => ({
  ...state,
  dialog: { ...state.dialog, wasLeadMagnetFunnelModalSubmitted: true }
})

export const HANDLERS = {
  [types.LEAD_MAGNET_PAGE_FETCH_SUCCESS]: successDetail,
  [types.LEAD_MAGNET_PAGE_REQUEST_FAILURE]: failure,
  [types.LEAD_MAGNET_PAGE_PLAY_VIDEO]: playVideo,
  [types.LEAD_MAGNET_PAGE_CLEAR_VIDEO]: clearVideo,
  [types.LEAD_MAGNET_PAGE_FUNNEL_TOGGLE_NEWSLETTER]: toggleNewsletterSuccess,
  [types.ENABLE_LEAD_MAGNET_FUNNEL_FORM_CTA]: enableCtaHandler,
  [types.DISABLE_LEAD_MAGNET_FUNNEL_FORM_CTA]: disableCtaHandler,
  [types.DISPLAY_LEAD_MAGNET_DIALOG]: displayDialogHandler,
  [types.HIDE_LEAD_MAGNET_DIALOG]: hideDialogHandler,
  [types.RESET_LEAD_MAGNET_DIALOG]: resetDialogHandler,
  [types.LEAD_MAGNET_DIALOG_FORM_SUBMITTED]: dialogSubmittedHandler
}

export default createReducer(INITIAL_STATE, HANDLERS)

//
// ACTION CREATORS
// -----------------------------------------------------------------------------

export const leadMagnetPageFetchSuccess = createAction(
  types.LEAD_MAGNET_PAGE_FETCH_SUCCESS
)
export const leadMagnetPageFetchFailure = createAction(
  types.LEAD_MAGNET_PAGE_REQUEST_FAILURE
)
export const leadMagnetPageFetch = slug => {
  if (typeof slug === 'undefined')
    throw TypeError('first param cannot be undefined')
  return fetchApi(
    `/lead-magnet-pages/${slug}/`,
    { method: 'GET' },
    leadMagnetPageFetchSuccess,
    leadMagnetPageFetchFailure
  )
}
export const leadMagnetPagePlayVideo = createAction(
  types.LEAD_MAGNET_PAGE_PLAY_VIDEO
)
export const leadMagnetPageClearVideo = createAction(
  types.LEAD_MAGNET_PAGE_CLEAR_VIDEO
)

export const toggleNewsletterAction = createAction(
  types.LEAD_MAGNET_PAGE_FUNNEL_TOGGLE_NEWSLETTER
)

export const claimLeadMagnetSuccess = createAction(
  types.CLAIM_LEAD_MAGNET_REQUEST_SUCCESS
)
export const claimLeadMagnetFailure = createAction(
  types.CLAIM_LEAD_MAGNET_REQUEST_FAILURE
)
export const claimLeadMagnet = fetchApi(
  '/lead-magnet-funnel/',
  { method: 'POST' },
  claimLeadMagnetSuccess,
  claimLeadMagnetFailure
)

export const enableCtaAction = createAction(
  types.ENABLE_LEAD_MAGNET_FUNNEL_FORM_CTA
)
export const disableCtaAction = createAction(
  types.DISABLE_LEAD_MAGNET_FUNNEL_FORM_CTA
)

export const displayDialogAction = createAction(
  types.DISPLAY_LEAD_MAGNET_DIALOG
)
export const hideDialogAction = createAction(types.HIDE_LEAD_MAGNET_DIALOG)

export const resetDialogAction = createAction(types.RESET_LEAD_MAGNET_DIALOG)

export const dialogSubmittedAction = createAction(
  types.LEAD_MAGNET_DIALOG_FORM_SUBMITTED
)
