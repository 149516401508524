import React from 'react'
import moment from 'moment'

import './styles.css'

export default () => (
  <div id="SimpleFooter" className="wrapper">
    Copyright © {moment().year()} Stan Winston School. All rights reserved.
  </div>
)
