import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router';
export default function () {
    return React.createElement('div', {
        'className': 'wrapper-categories' + ' ' + _.transform({ wrapper: !this.props.isMobile }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement('h2', {
        'className': _.transform({ wrapper: this.props.isMobile }, function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, 'Popular Categories'), React.createElement('div', { 'className': 'categories' }, !this.props.isMobile ? [
        React.createElement('div', {
            'className': 'category-group',
            'key': '2301'
        }, React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('puppet making')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Puppet Making'))), React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('mechanical')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Mechanical')))),
        React.createElement(Link, {
            'className': 'category big',
            'to': this.getCategoryURL('fabrication'),
            'key': '2303'
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Fabrication'))),
        React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('makeup effects'),
            'key': '2305'
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Makeup Effects'))),
        React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('sculpture'),
            'key': '2307'
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Sculpture'))),
        React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('puppet painting'),
            'key': '2309'
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Painting')))
    ] : null, this.props.isMobile ? [
        React.createElement('div', {
            'className': 'category-group',
            'key': '16121'
        }, React.createElement(Link, {
            'className': 'category big',
            'to': this.getCategoryURL('fabrication')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Fabrication'))), React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('mechanical')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Mechanical'))), React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('sculpture')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Sculpture')))),
        React.createElement('div', {
            'className': 'category-group',
            'key': '16123'
        }, React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('puppet making')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Puppet Making'))), React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('makeup effects')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Makeup Effects'))), React.createElement(Link, {
            'className': 'category',
            'to': this.getCategoryURL('puppet painting')
        }, React.createElement('div', { 'className': 'category-info' }, React.createElement('div', { 'className': 'overlay' }), React.createElement('h3', {}, 'Painting'))))
    ] : null));
}