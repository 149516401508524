import { createSelector } from 'reselect'
import {
  dimensionsSelector,
  isMobileSelector,
  hasSubscriptionSelector
} from 'reduxModules/app/selectors'
import { bannersSelector } from 'reduxModules/tutorials/selectors'
import { hijackingSelector } from 'reduxModules/hijack/selectors'
import { subscriptionSelector } from '../auth/selectors'

const learningPathCoursePreviewInfoSelector = state =>
  state.learningPaths.videoPreviewDetail
const learningPathCoursePreviewSelector = state =>
  state.learningPaths.videoPreview
export const learningPathDetailSelector = state => state.learningPaths.detail
const learningPathDetailCoursesSelector = state =>
  state.learningPaths.detail.courses.results
const learningPathDetailStatusSelector = state => state.learningPaths.status
const learningPathDetailIsEnrolled = state =>
  state.learningPaths.detail.courses?.isEnrolled
const learningPathDetailNextCoursesSelector = state =>
  state.learningPaths.detail.courses.next
const learningPathDetailCountCoursesSelector = state =>
  state.learningPaths.detail.courses.count
const learningPathListSelector = state => state.learningPaths.list.results
export const learningPathRecentsSelector = state => state.learningPaths.recents

export default createSelector(
  [
    dimensionsSelector,
    isMobileSelector,
    learningPathCoursePreviewInfoSelector,
    learningPathCoursePreviewSelector,
    learningPathDetailSelector,
    learningPathDetailCoursesSelector,
    hasSubscriptionSelector,
    subscriptionSelector,
    learningPathDetailStatusSelector,
    learningPathDetailNextCoursesSelector,
    learningPathDetailCountCoursesSelector,
    hijackingSelector,
    learningPathDetailIsEnrolled
  ],
  (
    dimensions,
    isMobile,
    videoPreviewDetail,
    videoPreview,
    learningPathDetail,
    courses,
    isSubscriber,
    subscription,
    status,
    next,
    count,
    hijacking,
    isEnrolled
  ) => ({
    ...dimensions,
    ...learningPathDetail,
    isMobile,
    videoPreviewDetail,
    videoPreview,
    courses,
    isSubscriber,
    subscription,
    status,
    next,
    count,
    hijacking,
    isEnrolled
  })
)

export const learningPathsStateSelector = createSelector(
  [
    learningPathListSelector,
    learningPathDetailStatusSelector,
    bannersSelector,
    isMobileSelector
  ],
  (learningPaths, status, banners, isMobile) => ({
    learningPaths,
    status,
    banners,
    isMobile
  })
)
