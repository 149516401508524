/* eslint-disable import/prefer-default-export */
import { reset } from 'redux-form'

import {
  leadMagnetPageFetch,
  leadMagnetPagePlayVideo,
  leadMagnetPageClearVideo,
  toggleNewsletterAction,
  claimLeadMagnet,
  disableCtaAction,
  enableCtaAction,
  types,
  hideDialogAction,
  dialogSubmittedAction
} from './'
import notifications, {
  toastError,
  toastServerError,
  toastSuccess
} from '../../../global/notifications'

export const leadMagnetPageDispatchers = (dispatch, props) => ({
  loadLeadMagnetPage() {
    dispatch(leadMagnetPageFetch(props.params.slug)())
  },
  loadLeadMagnetPageVideo(payload) {
    dispatch(leadMagnetPagePlayVideo(payload))
  },
  clearLeadMagnetPageVideo() {
    dispatch(leadMagnetPageClearVideo())
  }
})

export const onLeadMagnetFunnelFormDispatch = dispatch => ({
  onSubmit: (data, dispatch, props) => {
    dispatch(disableCtaAction())
    dispatch(claimLeadMagnet({ email: data.email, tag: props.tag }))
      .then(([_, res]) => {
        if (res.type === types.CLAIM_LEAD_MAGNET_REQUEST_SUCCESS) {
          toastSuccess(notifications.funnelConversion)
          dispatch(hideDialogAction())
          dispatch(reset('lead-magnet-funnel'))
          dispatch(dialogSubmittedAction())
        }
        if (res.type === types.CLAIM_LEAD_MAGNET_REQUEST_FAILURE) {
          toastError(notifications.funnelConversion)
          if (
            // eslint-disable-next-line no-undef
            res?.payload?.email[0]
          )
            toastServerError(res?.payload?.email[0])
        }
      })
      .catch(payload => {
        const error = payload.errors
        toastServerError(error)
      })
      .finally(() => dispatch(enableCtaAction()))
  },
  toggleNewsletter: () => dispatch(toggleNewsletterAction())
})
